import Link from 'next/link';
import BigNumber from 'bignumber.js';
import { useRouter } from 'next/router';
import { clientWithoutAuth } from '../../apollo/clients';
import { Currency, useTokenInfoQuery } from '../../generated/graphql';
import { useFiatRate } from '../../hooks/useFiatRate';
import { useMobileNav } from '../../hooks/useMobileNav';
import { usePrice } from '../../hooks/usePrice';
import { MobileMenuName } from '../../redux/slices/globalStateSlice';
import { isMobile } from '../../utils/userAgent';
import { TokenPercentageText } from '../Token/TokenPercentageText';
import { checkNaN } from '../Token/checkNaN';
import { PERCENTAGE_DECIMAL, SHFL_TOKEN_PRICE_DECIMAL } from '../Token/tokenConfig';
import styles from './NavigationToken.module.scss';
type Props = {
  isExpanded: boolean;
};
const POLLING_MS = 30_000;
export const NavigationToken = ({
  isExpanded
}: Props) => {
  const {
    pathname
  } = useRouter();
  const {
    data,
    loading
  } = useTokenInfoQuery({
    client: clientWithoutAuth,
    pollInterval: POLLING_MS
  });
  const {
    setActiveMobileNav
  } = useMobileNav();
  const priceInUsd = usePrice(Currency.SHFL);
  const fiatRate = useFiatRate();
  const tokenPricePercentage = checkNaN(BigNumber(data?.tokenInfo.twentyFourHourPercentageChange)).decimalPlaces(PERCENTAGE_DECIMAL);
  const isPositive = BigNumber(tokenPricePercentage).isPositive();
  const handleOnClick = () => setActiveMobileNav(MobileMenuName.CASINO);
  return <Link href="/token" className={`${styles.token} ${isExpanded ? '' : styles.collapsed} ${pathname === '/token' ? styles.active : ''} ${loading ? styles.loading : ''}`} onClick={isMobile ? handleOnClick : undefined}>
      <img src="/icons/token.svg" alt="token" />
      <div className={styles.textContainer}>
        <p>
          Shuffle <span className={styles.code}>(SHFL)</span>
        </p>
        <p className={styles.coming}>
          <span className={styles.code}>
            $
            {checkNaN(BigNumber(priceInUsd ?? 0).dividedBy(fiatRate)).decimalPlaces(SHFL_TOKEN_PRICE_DECIMAL).toFixed(4)}
          </span>{' '}
          <TokenPercentageText value={tokenPricePercentage.toNumber()}>
            {(isPositive ? '+' : '') + tokenPricePercentage}%
          </TokenPercentageText>
        </p>
      </div>
    </Link>;
};