import CryptoIcon from 'components/CurrencyIcons/CryptoIcon';
import FiatIcon from 'components/CurrencyIcons/FiatIcon';
import { SelectOption } from 'components/FormControls/Select';
import { Chain, Currency, FiatCurrency } from 'generated/graphql';
import { i18n } from 'next-i18next';
const t = (name: string) => i18n?.t?.(name) ?? '';
export type TagMemo = 'tag' | 'memo';
interface CurrencyConfig {
  defaultChain: Chain;
  name: string;
  icon: string;
  fullyDisabled?: boolean;
  moonpayDefaultChain?: Chain;
  moonpay?: string;
  bitinvestor?: string;
  tagMemo?: TagMemo; // Only for currencies that have tags/memos
}

// This will also dictates the order in which things will appear
// supported currencies: https://github.com/BitinvestorNet/merchant-docs?tab=readme-ov-file#supported-cryptocurrencies
export const CURRENCY_CONFIGS: Record<Currency, CurrencyConfig> = {
  [Currency.BTC]: {
    defaultChain: Chain.BITCOIN,
    name: 'Bitcoin',
    icon: 'btc.svg',
    moonpay: 'BTC',
    bitinvestor: 'BTC'
  },
  [Currency.ETH]: {
    defaultChain: Chain.ETHEREUM,
    name: 'Ethereum',
    icon: 'eth.svg',
    moonpay: 'ETH',
    bitinvestor: 'ETH'
  },
  [Currency.SHFL]: {
    defaultChain: Chain.ETHEREUM,
    name: 'Shuffle',
    bitinvestor: 'SHFL',
    icon: 'shfl.svg'
  },
  [Currency.LTC]: {
    defaultChain: Chain.LITECOIN,
    name: 'Litecoin',
    icon: 'ltc.svg',
    moonpay: 'LTC',
    bitinvestor: 'LTC'
  },
  [Currency.TRX]: {
    defaultChain: Chain.TRON,
    name: 'Tron',
    icon: 'trx.svg',
    moonpay: 'TRX',
    bitinvestor: 'TRX'
  },
  [Currency.XRP]: {
    defaultChain: Chain.XRP,
    name: 'Ripple',
    icon: 'xrp.svg',
    moonpay: 'XRP',
    bitinvestor: 'XRP',
    tagMemo: 'tag'
  },
  [Currency.USDT]: {
    defaultChain: Chain.ETHEREUM,
    moonpayDefaultChain: Chain.TRON,
    name: 'Tether',
    icon: 'usdt.svg',
    moonpay: 'USDT_TRX',
    bitinvestor: 'USDT_TRON'
  },
  [Currency.USDC]: {
    defaultChain: Chain.ETHEREUM,
    name: 'USD Coin',
    icon: 'usdc.svg',
    moonpay: 'USDC',
    bitinvestor: 'USDC_ETHEREUM'
  },
  [Currency.BUSD]: {
    defaultChain: Chain.BINANCE_SMART_CHAIN,
    fullyDisabled: true,
    name: 'Binance USD',
    icon: 'busd.svg'
  },
  [Currency.DOGE]: {
    defaultChain: Chain.DOGE,
    name: 'Dogecoin',
    icon: 'doge.svg',
    moonpay: 'DOGE',
    bitinvestor: 'DOGE'
  },
  [Currency.MATIC]: {
    defaultChain: Chain.MATIC_POLYGON,
    name: 'Polygon',
    icon: 'matic.svg',
    moonpay: 'MATIC'
  },
  [Currency.SOL]: {
    defaultChain: Chain.SOLANA,
    name: 'Solana',
    icon: 'sol.svg',
    moonpay: 'SOL',
    bitinvestor: 'SOL'
  },
  [Currency.BNB]: {
    defaultChain: Chain.BINANCE_SMART_CHAIN,
    name: 'Binance Coin',
    icon: 'bnb.svg',
    moonpay: 'BNB'
  },
  [Currency.AVAX]: {
    defaultChain: Chain.AVAXC,
    name: 'Avalanche',
    icon: 'avax.svg',
    moonpay: 'avax_cchain'
  },
  [Currency.TON]: {
    defaultChain: Chain.TON,
    name: 'Toncoin',
    icon: 'ton.svg',
    moonpay: Currency.TON,
    bitinvestor: Currency.TON,
    tagMemo: 'memo'
  },
  [Currency.SHIB]: {
    defaultChain: Chain.ETHEREUM,
    name: 'Shiba Inu',
    icon: 'shib.webp',
    moonpay: 'SHIB'
  },
  [Currency.BONK]: {
    defaultChain: Chain.SOLANA,
    name: 'Bonk',
    icon: 'bonk.jpg'
  },
  [Currency.WIF]: {
    defaultChain: Chain.SOLANA,
    name: 'dogwifhat',
    icon: 'wif.jpg'
  }
};
export const selectableCurrencies = (Object.entries(CURRENCY_CONFIGS).filter(([_key, {
  fullyDisabled
}]) => !fullyDisabled).map(([key]) => key) as Currency[]);
export function createOption(parmas: {
  currency: Currency | FiatCurrency;
  name?: string;
  asFiat?: boolean;
}): SelectOption<Currency | FiatCurrency> {
  const {
    currency,
    name,
    asFiat
  } = parmas;
  return {
    label: name ? `${name} (${currency})` : currency,
    value: currency,
    icon: asFiat ? <FiatIcon currency={(currency as FiatCurrency)} /> : <CryptoIcon currency={(currency as Currency)} />
  };
}
export const currencyOptions = (selectableCurrencies.map(currency => createOption({
  currency: (currency as Currency),
  name: CURRENCY_CONFIGS[currency].name
})) as SelectOption<Currency>[]);
export const currencyOptionsShortenName = (selectableCurrencies.map(currency => createOption({
  currency: (currency as Currency)
})) as SelectOption<Currency>[]);
export const fiatCurrencyOptions = (Object.keys(FiatCurrency).map(fiat => createOption({
  currency: (fiat as FiatCurrency),
  name: t(fiat),
  asFiat: true
})) as SelectOption<FiatCurrency>[]);
export const fiatCurrencyOptionsShortenName = (Object.keys(FiatCurrency).map(fiat => createOption({
  currency: (fiat as FiatCurrency),
  asFiat: true
})) as SelectOption<FiatCurrency>[]);
export const BALANCE_MAX_DIGITS = 11;