import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const onBetTypes = [
  'every',
  'everyStreakOf',
  'firstStreakOf',
  'streakGreaterThan',
  'streakLowerThan',
] as const;

export const onProfitTypes = ['balance', 'loss', 'profit'] as const;
export const onBetEvents = ['wins', 'losses', 'bets'] as const;
export const onProfitComparisonTypes = [
  'greaterThan',
  'lowerThan',
  'greaterThanOrEqualTo',
  'lowerThanOrEqualTo',
  'equalTo',
] as const;
export const doTypes = [
  'increaseBetAmountPercentage',
  'decreaseBetAmountPercentage',
  'increaseWinChancePercentage',
  'decreaseWinChancePercentage',
  'addToBetAmount',
  'subtractFromBetAmount',
  'addToWinChance',
  'subtractFromWinChance',
  'setBetAmount',
  'setWinChance',
  'switchOverUnder',
  'resetBetAmount',
  'resetWinChance',
  'stopAutobet',
] as const;

export interface DoAction {
  type: (typeof doTypes)[number];
  value: string; // this is to be converted to BigNumber during calculations but stored as a string in local storage
}

export interface EventBlock {
  id: string;
  do: DoAction;
}

export interface BetEventBlock extends EventBlock {
  type: 'bet';
  on: {
    type: (typeof onBetTypes)[number];
    value: string;
    event: (typeof onBetEvents)[number];
  };
}

export interface ProfitEventBlock extends EventBlock {
  type: 'profit';
  on: {
    comparisonType: (typeof onProfitComparisonTypes)[number];
    value: string; // this is to be converted to BigNumber during calculations but stored as a string in local storage
    type: (typeof onProfitTypes)[number];
  };
}

export type ConditionBlock = BetEventBlock | ProfitEventBlock;
export interface AdvancedBet {
  label: string;
  id: string;
  conditions: ConditionBlock[];
  isDefault: boolean;
}

export interface AdvancedDiceState {
  settings: AdvancedBet[];
  selectedStrategyId: string;
}

export const defaultBetConditionBlock: BetEventBlock = {
  type: 'bet',
  do: {
    type: 'addToBetAmount',
    value: '0',
  },
  on: {
    type: 'every',
    value: '1',
    event: 'bets',
  },
  id: '',
};

export const defaultProfitConditionBlock: ProfitEventBlock = {
  type: 'profit',
  do: {
    type: 'addToBetAmount',
    value: '0',
  },
  on: {
    type: 'profit',
    value: '0',
    comparisonType: 'greaterThan',
  },
  id: '',
};

const initialState: AdvancedDiceState = {
  settings: [
    {
      id: 'martingale',
      label: 'Martingale',
      isDefault: true,
      conditions: [
        {
          id: 'mart1',
          type: 'bet',
          on: { type: 'every', value: '1', event: 'losses' },
          do: { type: 'increaseBetAmountPercentage', value: '100' },
        },
        {
          id: 'mart2',
          type: 'bet',
          on: { type: 'every', value: '1', event: 'wins' },
          do: { type: 'resetBetAmount', value: '0' },
        },
        {
          id: 'mart3',
          type: 'profit',
          on: { type: 'balance', value: '28.9868', comparisonType: 'greaterThan' },
          do: { type: 'addToBetAmount', value: '0.0001' },
        },
      ],
    },
    {
      id: 'delayed-martingale',
      label: 'Delayed Martingale',
      isDefault: true,
      conditions: [
        {
          id: 'delay1',
          type: 'bet',
          on: { type: 'streakGreaterThan', value: '3', event: 'losses' },
          do: { type: 'increaseBetAmountPercentage', value: '100' },
        },
        {
          id: 'delay2',
          type: 'bet',
          on: { type: 'firstStreakOf', value: '3', event: 'losses' },
          do: { type: 'increaseBetAmountPercentage', value: '100' },
        },
        {
          id: 'delay3',
          type: 'bet',
          on: { type: 'every', value: '1', event: 'wins' },
          do: { type: 'resetBetAmount', value: '0' },
        },
      ],
    },
    {
      id: 'paroli',
      label: 'Paroli',
      isDefault: true,
      conditions: [
        {
          id: 'paroli1',
          type: 'bet',
          on: { type: 'every', value: '1', event: 'wins' },
          do: { type: 'increaseBetAmountPercentage', value: '100' },
        },
        {
          id: 'paroli2',
          type: 'bet',
          on: { type: 'streakGreaterThan', value: '3', event: 'wins' },
          do: { type: 'resetBetAmount', value: '0' },
        },
        {
          id: 'paroli3',
          type: 'bet',
          on: { type: 'every', value: '1', event: 'losses' },
          do: { type: 'resetBetAmount', value: '0' },
        },
      ],
    },
    {
      id: 'd-alembert',
      label: "D 'Alembert",
      isDefault: true,
      conditions: [
        {
          id: 'Alembert1',
          type: 'bet',
          on: { type: 'every', value: '1', event: 'wins' },
          do: { type: 'addToBetAmount', value: '0' },
        },
        {
          id: 'Alembert2',
          type: 'bet',
          on: { type: 'every', value: '1', event: 'losses' },
          do: { type: 'subtractFromBetAmount', value: '0' },
        },
      ],
    },
  ],

  selectedStrategyId: 'martingale',
};

export const diceSlice = createSlice({
  name: 'dice',
  initialState,
  reducers: {
    updateAdvancedDiceStore: (_: AdvancedDiceState, action: PayloadAction<AdvancedDiceState>) => {
      if (action.payload.settings.length === 0) {
        return initialState;
      }
      const isValidStrategyId = action.payload.settings.some(
        setting => setting.id === action.payload.selectedStrategyId,
      );

      if (!isValidStrategyId) {
        if (action.payload.settings[0])
          action.payload.selectedStrategyId = action.payload.settings[0].id;
        else {
          return initialState;
        }
      }
      return action.payload;
    },

    resetAdvancedDiceStore: () => initialState,

    editSelectedStrategyId: (state: AdvancedDiceState, action: PayloadAction<string>) => {
      const strategyIdExists = state.settings.some(strategy => strategy.id === action.payload);
      if (strategyIdExists) {
        state.selectedStrategyId = action.payload;
      }
    },

    editStrategyLabel: (state: AdvancedDiceState, action: PayloadAction<AdvancedBet>) => {
      const strategy = state.settings.find(strategy => strategy.id === action.payload.id);

      if (strategy) {
        strategy.label = action.payload.label;
      }
    },

    addStrategy: (
      state: AdvancedDiceState,
      action: PayloadAction<{ strategyName: string; strategyId: string; conditionId: string }>,
    ) => {
      const { strategyName, strategyId, conditionId } = action.payload;

      state.settings.push({
        id: strategyId,
        conditions: [
          {
            id: conditionId,
            type: 'bet',
            on: { type: 'every', event: 'bets', value: '1' },
            do: { type: 'addToBetAmount', value: '0' },
          },
        ],
        isDefault: false,
        label: strategyName,
      });
    },

    deleteStrategy: (state: AdvancedDiceState, action: PayloadAction<string>) => {
      const strategyIndex = state.settings.findIndex(strategy => strategy.id === action.payload);
      if (strategyIndex !== -1) {
        state.settings.splice(strategyIndex, 1);
      }

      if (state.selectedStrategyId === action.payload) {
        state.selectedStrategyId = state.settings?.[0]?.id ?? '';
      }
    },

    updateConditions: (
      state: AdvancedDiceState,
      action: PayloadAction<{ strategyId: string; conditions: ConditionBlock[] }>,
    ) => {
      const setting = state.settings.find(setting => setting.id === action.payload.strategyId);

      if (setting) {
        const copyOfConditions = [...action.payload.conditions];
        setting.conditions = copyOfConditions;
      }
    },
  },
});

export const {
  updateAdvancedDiceStore,
  addStrategy,
  deleteStrategy,
  editStrategyLabel,
  resetAdvancedDiceStore,
  editSelectedStrategyId,
  updateConditions,
} = diceSlice.actions;

export default diceSlice.reducer;
