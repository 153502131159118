import { ReactNode } from 'react';
import styles from './InputSuffix.module.scss';
export const InputSuffix = ({
  children,
  dimmed,
  className
}: {
  children: ReactNode;
  dimmed?: boolean;
  className?: string;
}) => <span className={`${styles.root} ${dimmed ? styles.dimmed : ''} ${className ?? ''}`}>
    {children}
  </span>;