import { ReactNode } from 'react';
import styles from './Icon.module.scss';
export const Icon = ({
  children,
  $noFixedDimensions
}: {
  $noFixedDimensions?: boolean;
  children: ReactNode;
}) => <span className={`${styles.root} ${$noFixedDimensions ? styles.noFixedDimensions : ''}`}>
    {children}
  </span>;