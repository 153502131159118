import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import styles from './Loader.module.scss';
export type LoaderProps = {
  invert?: boolean;
  className?: string;
  variant?: 'logo' | 'circle';
  size?: 'small' | 'medium';
  containerHeight?: number;
};
export const MIN_LOADER_CONTAINER_HEIGHT = 320;
export function Loader({
  invert,
  className,
  variant = 'logo',
  size = 'small',
  containerHeight
}: LoaderProps) {
  const {
    t
  } = useTranslation();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  if (variant === 'circle') {
    return <div style={containerHeight ? {
      height: `${containerHeight}px`
    } : {}} className={`${styles.root} ${styles.circle} ${mounted ? styles.mounted : ''} ${className || ''} ${size === 'medium' ? styles.medium : ''} ${invert ? styles.invert : ''}`}>
        <img className={styles.animated} aria-label={t('btnLoading')} src="/icons/loader-circle.svg" alt="loader circle" />
      </div>;
  }
  return <div className={`${styles.root} ${styles.logo} ${mounted ? styles.mounted : ''} ${className || ''} ${size === 'medium' ? styles.medium : ''}`} aria-label={t('btnLoading')} style={containerHeight ? {
    height: `${containerHeight}px`
  } : {}}>
      <div className={`${styles.container} ${invert ? styles.invert : ''}`}>
        <img src="/icons/shuffle-logo-top.svg" alt="logo" width={21} height={12} className={styles.top} />
        <img src="/icons/shuffle-logo-middle.svg" alt="logo" width={21} height={12} className={styles.middle} />
        <img src="/icons/shuffle-logo-bottom.svg" alt="logo" width={21} height={12} className={styles.bottom} />
      </div>
    </div>;
}