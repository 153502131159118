import { Middleware } from '@reduxjs/toolkit';
import { get } from 'lodash';
import {
  PERSIST_STORE_NAME,
  PERSIST_STORE_NAMES_METHODS,
  VERSION,
} from '../hooks/usePersistStoreData';

export const persistStorageMiddleware: Middleware = ({ getState }) => {
  return next => action => {
    if (!action || !action?.type) {
      return;
    }

    try {
      const returnValue = next(action);

      const store: AppState = getState();
      const shouldSaveStorage = !!PERSIST_STORE_NAMES_METHODS.find(({ name: storeName }) =>
        action.type.startsWith(storeName),
      );

      if (!shouldSaveStorage) {
        return;
      }

      const toBeSavedStoreData: Record<string, string> = {
        _persist: `{"version":${VERSION}}`,
      };

      for (const key in store) {
        const persistMethod = PERSIST_STORE_NAMES_METHODS.find(({ name }) => name.includes(key));

        if (persistMethod) {
          const storeData = persistMethod.key
            ? get(store, persistMethod.key)
            : store[key as keyof AppState];
          const keyName = persistMethod.key ? persistMethod.key : persistMethod.name;

          toBeSavedStoreData[keyName] = JSON.stringify(storeData);
        }
      }
      window.localStorage.setItem(PERSIST_STORE_NAME, JSON.stringify(toBeSavedStoreData));

      return returnValue;
    } catch (e) {
      console.error(`Failed to update storage ${(e as Error)?.message ?? ''}`);
    }
  };
};
