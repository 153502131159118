import {
  type Maybe,
  SportsAcceptOddsChange,
  type SportsMarketSelectionUpdatedPayload,
  SportsMarketStatus,
  type SportsMarketUpdatedPayload,
} from '../generated/graphql';

import BigNumber from 'bignumber.js';
import { OddsFormat } from '../redux/slices/browserPreferenceSlice';
import { BetSlipItem, BetSlipStatus } from '../redux/slices/sportsBetSlice.type';
import { calculateEstimatePayout } from './calculateSportsBet';
import { checkInvalidOddsDisplay } from './checkInvalidOddsDisplay';
import { getSportBetOdds } from './getSportBetOdds';

type selectionUpdate = Omit<
  SportsMarketSelectionUpdatedPayload,
  'updatedAt' | 'fixtureId' | 'marketId'
>;

export type MarketUpdate = Omit<
  SportsMarketUpdatedPayload,
  'updatedAt' | 'fixtureId' | 'selections'
> & {
  selections?: Maybe<selectionUpdate[]>;
};

export function handleBetSlipsUpdate({
  betSlips,
  market,
  betSlipStatus,
  acceptOddsChange,
  updateChangeOddsIds,
}: {
  betSlips: BetSlipItem[];
  market: MarketUpdate;
  betSlipStatus?: BetSlipStatus;
  acceptOddsChange: SportsAcceptOddsChange | undefined;
  updateChangeOddsIds?: (id: string) => void;
}) {
  const { id: marketId, disabled, status } = market;
  let newMarketStatus = disabled ? SportsMarketStatus.CLOSED : status;

  return betSlips.map(bet => {
    if (bet.marketId !== marketId || !market.selections?.length) {
      return bet;
    }

    for (const selection of market.selections) {
      if (bet.id === selection.id) {
        const { oddsNumerator, oddsDenominator, status } = selection;
        const skipUpdateDisplayFields = BetSlipStatus.BET_PLACED === betSlipStatus;

        if (updateChangeOddsIds && !skipUpdateDisplayFields) {
          if (acceptOddsChange === SportsAcceptOddsChange.NONE) {
            updateChangeOddsIds(bet.id);
          } else if (
            acceptOddsChange === SportsAcceptOddsChange.HIGHER &&
            BigNumber(
              getSportBetOdds({
                oddsFormat: OddsFormat.Decimal,
                oddsNumerator: bet.oddsNumerator,
                oddsDenominator: bet.oddsDenominator,
              }).outputDecimal,
            ).isGreaterThan(
              getSportBetOdds({
                oddsFormat: OddsFormat.Decimal,
                oddsNumerator: selection.oddsNumerator,
                oddsDenominator: selection.oddsDenominator,
              }).outputDecimal,
            )
          ) {
            updateChangeOddsIds(bet.id);
          }
        }

        if (
          !disabled &&
          checkInvalidOddsDisplay({ oddsNumerator, oddsDenominator }) &&
          newMarketStatus === SportsMarketStatus.OPEN
        ) {
          newMarketStatus = SportsMarketStatus.SUSPENDED;
        }

        return {
          ...bet,
          estPayout: bet.estPayout
            ? calculateEstimatePayout({
                betAmount: bet.betAmount,
                oddsNumerator,
                oddsDenominator,
              })
            : '',
          marketStatus: skipUpdateDisplayFields ? bet.marketStatus : newMarketStatus,
          oddsNumerator: skipUpdateDisplayFields ? bet.oddsNumerator : oddsNumerator,
          oddsDenominator: skipUpdateDisplayFields ? bet.oddsDenominator : oddsDenominator,
          status: skipUpdateDisplayFields ? bet.status : status,
          updatedOddsNumerator: oddsNumerator,
          updatedOddsDenominator: oddsDenominator,
          updatedStatus: status,
          updatedMarketStatus: newMarketStatus,
        };
      }
    }
    return { ...bet, marketStatus: newMarketStatus };
  });
}
