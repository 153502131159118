import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { USER_LOGOUT } from 'constants/general';

import appSetting from '././slices/appSettingSlice';
import alerts from './slices/alertsSlice';
import auth from './slices/authSlice';
import autobet from './slices/autobetSlice';
import browserPreference from './slices/browserPreferenceSlice';
import campaign from './slices/campaignSlice';
import crash from './slices/crashSlice';
import dice from './slices/diceSlice';
import gameStatistics from './slices/gameStatisticSlice';
import games from './slices/gamesSlice';
import globalState from './slices/globalStateSlice';
import login from './slices/loginSlice';
import prices from './slices/pricesSlice';
import sportsBet from './slices/sportsBetSlice';
import tip from './slices/tipSlice';
import vip from './slices/vipSlice';
import withdraw from './slices/withdrawSlice';

const appReducer = combineReducers({
  dice,
  alerts,
  auth,
  campaign,
  login,
  browserPreference,
  withdraw,
  tip,
  crash,
  prices,
  games,
  autobet,
  globalState,
  sportsBet,
  gameStatistics,
  vip,
  appSetting,
});

// biome-ignore lint/suspicious/noExplicitAny: redux
const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === USER_LOGOUT) {
    const browserPreference = state.browserPreference;
    state = { browserPreference };
    return appReducer(state, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
