import { useMount } from 'react-use';
import { captureException } from '@sentry/nextjs';
import styles from './License.module.scss';
const XCMLicense = () => {
  useMount(() => {
    try {
      window.xcm_69fb7e94_6ecd_4b46_83a2_24bac03464d0.init();
    } catch (error) {
      captureException(error, {
        extra: {
          action: 'XCM licensor script'
        }
      });
    }
  });
  return <div className={styles.licenseLink} id="xcm-69fb7e94-6ecd-4b46-83a2-24bac03464d0" data-xcm-seal-id="69fb7e94-6ecd-4b46-83a2-24bac03464d0" data-xcm-image-size="128" data-xcm-image-type="basic-small" />;
};
export default XCMLicense;