import { ModalTypes, WalletModalTabType } from 'constants/modal';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { signOut } from '../../../apollo/utils';
import { INTERCOM_WIDGET_CLASSNAME } from '../../../constants/intercom';
import { AvatarProperties } from '../../../hooks/useAvatar';
import { useSetting } from '../../../hooks/useSetting';
import { isMobile } from '../../../utils/userAgent';
import UserMenuVipCard from '../../../views/Vip/UserMenuVipCard';
import { ModalType, ModalUrl } from '../../Modal/GlobalModal.type';
import { openGlobalModal } from '../../Modal/openGlobalModal';
import GeneralModal, { GeneralModalProps } from '../../modals/GeneralModal/GeneralModal';
import styles from './ExpandMenuElement.module.scss';
interface MenuData {
  label: string;
  icon: React.ReactNode;
  className: string;
  url?: string;
  modalType?: ModalType['type'];
  modalTab?: ModalType['tab'];
}
const MENU_DATA: MenuData[] = [{
  label: 'userMenuWallet',
  url: '',
  icon: <img src="/icons/wallet.svg" alt="wallet" />,
  className: '',
  modalType: ModalUrl.WALLET,
  modalTab: WalletModalTabType.DEPOSIT
}, {
  label: 'userMenuVIP',
  url: '/vip-program',
  icon: <img src="/icons/crown.svg" alt="crown" />,
  className: ''
}, {
  label: 'walletVault',
  url: '',
  icon: <img src="/icons/shield-lock.svg" alt="lock" />,
  className: '',
  modalType: ModalUrl.VAULT
}, {
  label: 'token',
  url: '/token',
  icon: <img src="/icons/token-white.svg" alt="token" />,
  className: ''
}, {
  label: 'userMenuAffiliateProgram',
  url: '/affiliate/overview',
  icon: <img src="/icons/setting-affiliate.svg" alt="affiliate" />,
  className: ''
}, {
  label: 'userMenuTransactions',
  url: '/transactions/deposits',
  icon: <img src="/icons/transactions.svg" alt="transactions" />,
  className: ''
}, {
  label: 'userMenuRedeemCode',
  url: '',
  icon: <img src="/icons/double-ticks.svg" alt="double tick" />,
  className: '',
  modalType: ModalUrl.REDEEM
}, {
  label: 'userMenuSettings',
  url: '/settings/account',
  icon: <img src="/icons/setting.svg" alt="setting" />,
  className: ''
}, {
  label: 'userMenuSupport',
  icon: <img src="/icons/live-support.svg" alt="support" />,
  className: INTERCOM_WIDGET_CLASSNAME
}];
export const ExpandMenuElement = ({
  show = false,
  onClick,
  avatar
}: {
  show?: boolean;
  avatar?: AvatarProperties | null;
  onClick: (show: boolean) => void;
}) => {
  const [currentModal, setCurrentModal] = useState<GeneralModalProps['currentModal']>({
    type: '',
    config: {
      header: ''
    }
  });
  const [disablePointerEvent, setDisablePointerEvent] = useState(false);
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const {
    removeActiveSession
  } = useSetting();
  const onCancel = () => {
    setCurrentModal(data => ({
      ...data,
      type: ''
    }));
  };
  const handleLogout = useCallback(async () => {
    setCurrentModal(data => ({
      ...data,
      type: ''
    }));
    try {
      await removeActiveSession();
    } catch (e) {
      console.error(`Failed to remove active session ${(e as Error)?.message || ''}`);
    } finally {
      signOut();
      window.location.href = '/';
    }
  }, [removeActiveSession]);
  const onConfirmLogout = () => {
    setCurrentModal({
      type: ModalTypes.GENERAL,
      config: {
        header: t('msgConfirmLogout'),
        icon: 'logout',
        confirmBtnText: t('btnYes'),
        onConfirm: handleLogout,
        cancelBtnText: t('btnNo'),
        onCancel
      }
    });
  };
  const handleDisablePointerEvent = () => {
    if (isMobile) {
      onClick(false);
    } else {
      setDisablePointerEvent(true);
      setTimeout(() => {
        setDisablePointerEvent(false);
      }, 200);
    }
  };
  // TODO: Optimise UserMenuVipCard so that it doesn't re-render whenever vip xp changes
  return <>
      <div className={`${styles.menuWrapper} ${show ? styles.show : ''}`}>
        <div className={`${styles.expandMenu} ${disablePointerEvent ? styles.disablePointerEvent : ''}`}>
          <UserMenuVipCard avatar={avatar} />

          {MENU_DATA.map((item: MenuData) => {
          const className = `${styles.menuItem} ${item.className || ''}`;
          return item.url ? <Link key={item.label} href={item.url} className={className} onClick={handleDisablePointerEvent}>
                <span className={styles.menuIcon}>{item.icon}</span>
                {t(item.label)}
              </Link> : <button key={item.label} className={className} onClick={() => {
            handleDisablePointerEvent();
            item.modalType && openGlobalModal({
              router,
              type: item.modalType,
              tab: item.modalTab
            });
          }}>
                <span className={styles.menuIcon}>{item.icon}</span>
                {t(item.label)}
              </button>;
        })}

          <button className={styles.menuItem} onClick={onConfirmLogout}>
            <img className={styles.menuIcon} src="/icons/logout.svg" alt="log out" />
            {t('userMenuLogout')}
          </button>
        </div>
      </div>

      <GeneralModal currentModal={currentModal} setCurrentModal={setCurrentModal} />
    </>;
};