import {
  Currency,
  Sports,
  SportsBetSelectionStatus,
  SportsMarketProvider,
  SportsMarketSelectionResultStatus,
  SportsMarketSelectionStatus,
  SportsMarketStatus,
  SportsMarketType,
} from '../../generated/graphql';
import { SportsBetTicketStatusType } from '../../views/BetSlip/components/BetsGroup.type';

export interface BetSlipItem {
  id: string;
  href: string;
  sport: Sports;
  categoryId: string;
  competitionName: string;
  competitionId: string;
  fixtureId: string;
  fixtureName: string;
  inPlayAllowed: boolean;
  marketId: string;
  marketName: string;
  marketNameTranslation?: string;
  marketStatus: SportsMarketStatus;
  marketTypeId: string;
  marketExpiryTime?: string;
  startTime: string;
  betAmount: string;
  inputAmount: string;
  estPayout: string;
  oddsDenominator?: string;
  oddsNumerator?: string;
  status: SportsMarketSelectionStatus;
  name: string;
  nameTranslation?: string;
  inPlay: boolean;
  provider: SportsMarketProvider;
  sportsBetStatus?: SportsBetTicketStatusType;
  sportsBetSelectionStatus?: SportsBetSelectionStatus;
  cashOutStatus?: string;
  cashOutEnabled?: boolean;
  canCashOut?: boolean;
  totalOddsDecimal?: string;
  multiple?: boolean;
  errorMessage?: string;
  placedBetId?: string;
  isEventLive?: boolean;
  isHome?: boolean;
  marketSelectionResultStatus?: SportsMarketSelectionResultStatus;
  // these fields are used for updating the bet slip after press reuse / retry bet button
  updatedOddsDenominator?: string;
  updatedOddsNumerator?: string;
  updatedStatus?: SportsMarketSelectionStatus;
  updatedMarketStatus?: SportsMarketStatus;
  layout: Partial<SportsMarketType>;
}

export enum BetSlipStatus {
  EMPTY = 'EMPTY',
  ADDING_BET = 'ADDING_BET',
  PENDING_BET = 'PENDING_BET',
  RESULTED_BET = 'RESULTED_BET',
  CONFIRMING_BET = 'CONFIRMING_BET',
  BET_PLACED = 'BET_PLACED',
  BET_PLACING = 'BET_PLACING',
  BET_VIEW_INFO = 'BET_VIEW_INFO',
}

export type MultiBet = {
  betAmount: string;
  inputAmount: string;
  selectionIds: string[];
};

export type PositionType = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export type BetSlipPlacedErrorsType = {
  message: string;
  id: string;
  maxAllowedStake?: string;
  maxAllowedRolloverBet?: string;
  isMultiBet?: boolean;
};

export type CashOutBetSlip = {
  amount: string;
  currency: Currency;
  odds: string;
  status?: '' | 'confirm' | 'loading' | 'completed';
};

export interface SportsBetState {
  betSlips: BetSlipItem[];
  multiBet: MultiBet;
  betSlipStatus: BetSlipStatus;
  betSlipPlacedErrors: BetSlipPlacedErrorsType[];
  betSlipOddChangesIds: string[];
  cashOutBetSlips: Record<string, CashOutBetSlip>;
  mobileBetAddedPosition: PositionType;
  betAddedPosition: PositionType;
  viewData: { region: string; dma: string; device: string };
  preferredDefaultMarket: Partial<Record<Sports, string>>;
}
