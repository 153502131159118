import { FiatCurrency } from '../generated/graphql';
export const Fiat = {
  [FiatCurrency.USD]: () => <img src="/icons/fiat/USD.svg" alt="USD" />,
  [FiatCurrency.EUR]: () => <img src="/icons/fiat/EUR.svg" alt="EUR" />,
  [FiatCurrency.MXN]: () => <img src="/icons/fiat/MXN.svg" alt="MXN" />,
  [FiatCurrency.BRL]: () => <img src="/icons/fiat/BRL.svg" alt="BRL" />,
  [FiatCurrency.JPY]: () => <img src="/icons/fiat/JPY.svg" alt="JPY" />,
  [FiatCurrency.IDR]: () => <img src="/icons/fiat/IDR.svg" alt="IDR" />,
  [FiatCurrency.CAD]: () => <img src="/icons/fiat/CAD.svg" alt="CAD" />,
  [FiatCurrency.CNY]: () => <img src="/icons/fiat/CNY.svg" alt="CNY" />,
  [FiatCurrency.DKK]: () => <img src="/icons/fiat/DKK.svg" alt="DKK" />,
  [FiatCurrency.KRW]: () => <img src="/icons/fiat/KRW.svg" alt="KRW" />,
  [FiatCurrency.INR]: () => <img src="/icons/fiat/INR.svg" alt="INR" />,
  [FiatCurrency.PHP]: () => <img src="/icons/fiat/PHP.svg" alt="PHP" />,
  [FiatCurrency.TRY]: () => <img src="/icons/fiat/TRY.svg" alt="TRY" />,
  [FiatCurrency.NZD]: () => <img src="/icons/fiat/NZD.svg" alt="NZD" />,
  [FiatCurrency.ARS]: () => <img src="/icons/fiat/ARS.svg" alt="ARS" />,
  [FiatCurrency.RUB]: () => <img src="/icons/fiat/RUB.svg" alt="RUB" />
};