import { shallowEqual, useSelector } from 'react-redux';
import { checkValidJWT } from 'utils/jwt';

export const useIsLoggedIn = (): boolean => {
  const auth = useSelector((state: AppState) => state.auth, shallowEqual);
  if (!auth.refreshToken || !auth.accessToken) {
    return false;
  }
  return checkValidJWT(auth.refreshToken) && checkValidJWT(auth.accessToken);
};
