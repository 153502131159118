import React, { useCallback, useEffect, useState } from 'react';

import useWindowWidth from './interface/useWindowWidth';
import { useStore } from './useStore';

const ARROW_WIDTH = 10;

export type Placement =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'top-left'
  | 'bottom-left'
  | 'bottom-right';

type Props = {
  childrenRef: React.MutableRefObject<HTMLDivElement | null>;
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
  shouldListenEvents?: boolean;
  placement: Placement;
};

export function useRelativeFixPosition({
  placement,
  childrenRef,
  contentRef,
  shouldListenEvents,
}: Props) {
  const { state: scrollTop } = useStore(state => state.scrollTop);
  const { width: windowWidth } = useWindowWidth();
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });

  const updatePosition = useCallback(() => {
    if (childrenRef.current && contentRef.current) {
      const childrenRect = childrenRef.current.getBoundingClientRect();
      const tooltipRect = contentRef.current.getBoundingClientRect();

      if (placement === 'right') {
        setPosition({
          top: childrenRect.top + childrenRect.height / 2 - tooltipRect.height / 2,
          left: childrenRect.width + childrenRect.left + ARROW_WIDTH,
        });
      } else if (placement === 'top') {
        setPosition({
          top: childrenRect.top - tooltipRect.height - ARROW_WIDTH,
          left: childrenRect.left + childrenRect.width / 2 - tooltipRect.width / 2,
        });
      } else if (placement === 'left') {
        setPosition({
          top: childrenRect.top + childrenRect.height / 2 - tooltipRect.height / 2,
          left: childrenRect.left - tooltipRect.width - ARROW_WIDTH,
        });
      } else if (placement === 'bottom') {
        setPosition({
          top: childrenRect.top + childrenRect.height + ARROW_WIDTH,
          left: childrenRect.left + childrenRect.width / 2 - tooltipRect.width / 2,
        });
      } else if (placement === 'top-left') {
        setPosition({
          top: childrenRect.top - tooltipRect.height - ARROW_WIDTH,
          left: childrenRect.left + childrenRect.width / 2 - childrenRect.width / 2,
        });
      } else if (placement === 'bottom-left') {
        setPosition({
          top: childrenRect.top + childrenRect.height + ARROW_WIDTH,
          left: childrenRect.left + childrenRect.width / 2 - childrenRect.width / 2,
        });
      } else if (placement === 'bottom-right') {
        setPosition({
          top: childrenRect.top + childrenRect.height,
          left: childrenRect.left - tooltipRect.width + childrenRect.width,
        });
      }
    }
  }, [childrenRef, contentRef, placement]);

  useEffect(() => {
    if (shouldListenEvents && scrollTop >= 0) {
      updatePosition();
    }
  }, [updatePosition, scrollTop, shouldListenEvents]);

  useEffect(() => {
    if (shouldListenEvents && windowWidth) {
      updatePosition();
    }
  }, [windowWidth, shouldListenEvents, updatePosition]);

  return {
    updatePosition,
    position,
    width: childrenRef.current ? childrenRef.current.getBoundingClientRect().width : 0,
  };
}
