import React from 'react';
import styles from './index.module.scss';
export interface SwitchProps {
  /**
   * Is the switch initially checked?
   */
  value: boolean;
  /**
   * The label placed to the right of the switch.
   */
  label?: string | React.ReactNode;
  /**
   * Allow the position of the label and switch to be swapped.
   */
  labelFloat?: 'left' | 'right';
  /**
   * Callback; when the switch is toggled.
   */
  onToggle: (checked: boolean) => void;
  /**
   * When disabled, user interactivity will not register. Useful for loading states.
   */
  disabled?: boolean;
  testId?: string;
  backgroundColor?: string;
}
const Switch = ({
  value,
  label,
  backgroundColor,
  labelFloat = 'right',
  onToggle,
  disabled = false,
  testId
}: SwitchProps) => {
  const onClickToggle = () => onToggle(!value);
  return <button className={`${styles.switchWrapper} ${labelFloat ? styles.labelFloat : ''}`} disabled={disabled} role="switch" type="button" aria-checked={value ? 'true' : 'false'} data-testid={testId} onClick={onClickToggle}>
      <div className={`${styles.switchElement} ${value ? styles.checked : ''}`} aria-hidden="true" style={backgroundColor && !value ? {
      backgroundColor
    } : {}}>
        <span className={styles.knob} />
      </div>

      {label && <p className={styles.label} onClick={onClickToggle}>
          {label}
        </p>}
    </button>;
};
export default Switch;