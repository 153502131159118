import React from 'react';
import { ButtonColor } from './Button';
import { PrimaryButton } from './PrimaryButton';
export type ButtonHeight = 'sm' | 'md' | 'lg';
import styles from './ButtonVariants.module.scss';
interface ButtonWrapperProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  height?: ButtonHeight;
  color: ButtonColor;
  hasMargin?: boolean;
  fillIcon?: boolean;
  bold?: boolean;
  fontWeight?: string;
  children: React.ReactNode;
  noDisabledStyle?: boolean;
}
export const ButtonVariants = ({
  height = 'md',
  color = 'primary',
  hasMargin = false,
  bold = false,
  fontWeight = '500',
  noDisabledStyle,
  className,
  children,
  ...rest
}: ButtonWrapperProps) => {
  return <>
      {color === 'primary' && <PrimaryButton bold={bold} hasMargin={hasMargin} height={height} className={className} {...rest}>
          {children}
        </PrimaryButton>}
      {color === 'secondary' && <PrimaryButton bold={bold} hasMargin={hasMargin} height={height} {...rest} className={`${className || ''} ${styles.secondary} ${!noDisabledStyle ? styles.noDisabledStyle : ''}`}>
          {children}
        </PrimaryButton>}
      {color === 'tertiary' && <PrimaryButton bold={bold} hasMargin={hasMargin} height={height} {...rest} className={`${className || ''} ${styles.tertiary} ${!noDisabledStyle ? styles.noDisabledStyle : ''}`}>
          {children}
        </PrimaryButton>}
      {color === 'success' && <PrimaryButton bold={bold} hasMargin={hasMargin} height={height} {...rest} className={`${className || ''} ${styles.success} ${!noDisabledStyle ? styles.noDisabledStyle : ''}`}>
          {children}
        </PrimaryButton>}
    </>;
};