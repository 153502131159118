export const ChevronDownIcon = ({
  pointUp,
  className
}: {
  pointUp?: boolean;
  className?: string;
}) => <img style={{
  ...(pointUp ? {
    transform: 'rotate(180deg)'
  } : {})
}} className={className} src="/icons/chevron.svg" alt="arrow" />;