import styles from './AnimateLiveEventTime.module.scss';
type Props = {
  time: string;
  text: string;
  variants?: 'grey' | 'green';
};
export function AnimateLiveEventTime({
  time,
  text,
  variants
}: Props) {
  if (!time) {
    return <p>{text}</p>;
  }
  return <>
      <span className={styles.animateTime} style={{
      animationName: variants === 'green' ? styles.pulseGreen : styles.pulseWhite
    }}>
        {time}
      </span>{' '}
      <span>{text}</span>
    </>;
}