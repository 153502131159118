import dynamic from 'next/dynamic';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
type Props = {};
const SportsDevToolLazyContent = dynamic(() => import('./SportsDevToolContent'));
export const SportsDevTool = (_props: Props) => {
  const enabled = useSelector((state: AppState) => state.globalState.sportsDevtool.enabled);
  const hasBeenEnabled = useRef(false);
  useEffect(() => {
    if (enabled) {
      hasBeenEnabled.current = true;
    }
  }, [enabled]);
  if (!enabled && !hasBeenEnabled.current) {
    return null;
  }
  return <div style={{
    display: enabled ? 'block' : 'none'
  }}>
      <SportsDevToolLazyContent />
    </div>;
};