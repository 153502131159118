import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

export const useSportsPopularNavItems = () => {
  const { query } = useRouter();
  const [sportsPopularNavItems, setSportsPopularNavItems] = useState<
    {
      icon: string;
      name: string;
      url: string;
    }[]
  >([]);
  const [loadingSportsPopularNavItems, setLoading] = useState(true);
  const contentLoaded = useRef(false);
  const preview = query.cms === 'preview';

  useEffect(() => {
    if (contentLoaded.current) {
      return;
    }

    fetch(`/api/v1/sports/popular-sports${preview ? '?preview=true' : ''}`)
      .then(async data => {
        const output = await data.json();
        const navItems = output.sportPopularNavsCollection.items[0].sportsNavsCollection.items;

        if (Array.isArray(navItems)) {
          setSportsPopularNavItems(navItems);
        }

        contentLoaded.current = true;
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [preview]);

  return {
    sportsPopularNavItems,
    loadingSportsPopularNavItems,
  };
};
