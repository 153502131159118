const colors = {
  black100: '#0F0E1A',
  black200: '#1e2c37',
  white100: '#f0eeea',
  background: '#f0f2f5',
  mainBackground: '#282c34',
  red: 'red',

  // app colors
  eerieBlack: '#1f1f1f',
  primaryBackground: '#1B1927',
  primary: '#353E52',
  secondary: '#5D6575',
  tertiary: '#282E3D',
  border: '#E5ECE9',
  borderPanel: '#eceef1',
  borderInput: '#D8DFEC',
  disabled: '#2C3344',
  green: '#6FEF8A',
  wildBlueYonder: '#9BA8C7',
  chineseBlack: '#101110',
  iconBlack: '#222831',

  // buttons
  btnHover: '#6675FF',
  btnPressed: '#4700AB',
  btnGreen: '#00bd71',
  btnGreenHover: '#55BA78',

  // Official style guide

  // Primary Blue
  primaryBlue: '#1A2FFF',
  white: '#FFFFFF',

  // New primary colors
  // Used for icons, text, highlights
  primaryViolet: '#886CFF',
  // Primary for buttons
  primaryNeonPurple: '#7717FF',

  // Secondary
  lightBlue: '#4185F0',
  lightGray: '#EEF1FA',
  darkViolet: '#42009E',
  lightViolet: '#690CEC',

  // Grayscale
  black: '#000000',
  black900: '#080808',
  gray900: '#121418',
  gray800: '#202329',
  gray700: '#2A2E38',
  gray600: '#343843',
  gray500: '#4D5361',
  gray400: '#828998',
  gray300: '#9BA5B4',
  gray200: '#BEC6D1',
  gray100: '#E6ECF1',
  grayScaleBlack: '#080808',

  // System
  error: '#F1323E',
  warning: '#FF774A',
  info: '#3EAFFF',
  success: '#3DD179',
  lightError: '#FDE9E7',
  lightWarning: '#FFF8E1',
  lightInfo: '#DDE9FA',
  lightSuccess: '#EDFDEC',

  // Others
  purple: '#8042FF',
  pink: '#D250E7',
  yellow: '#EF6E43',
  gold: '#FFC634',
  mint: '#08B9AF',
  overlay: 'rgba(51, 49, 70, 0.5)',
  msgHighlight: 'rgba(65, 133, 240, 0.3)',
  greenLive: '#149200',
  arrowHighlight: 'rgba(119, 23, 255, 0.3)',
  darkSlateBlue: '#431E7F',
  lavenderBlue: '#BDADFF',
};

export default colors;
