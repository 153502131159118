import { ReactNode, useEffect, useRef } from 'react';
interface Props {
  children: ReactNode;
  onClickOutside: (element: HTMLElement) => void;
  className?: string;
}
export default function ClickOutsideWrapper({
  children,
  onClickOutside,
  className
}: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isTouch = useRef(false);
  useEffect(() => {
    function handleClickOutside(event: Event) {
      // desktop with touch screen will trigger twice, this code prevents double triggers
      if (event.type === 'touchend') isTouch.current = true;
      if (event.type === 'click' && isTouch.current) {
        isTouch.current = false;
        return;
      }
      if (event.target && event.target instanceof HTMLElement && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickOutside((event.target as HTMLElement));
      }
    }
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [onClickOutside]);
  return <div className={className} ref={wrapperRef}>
      {children}
    </div>;
}