import BigNumber from 'bignumber.js';
import { usePendingVipRewardsQuery } from 'generated/graphql';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import { useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RewardStatus, VipRewardType } from 'redux/slices/vipSlice';
import { deriveBonusIssuances } from 'shufflecom-calculations/lib/utils/derive-vip-bonus';

import { useStore } from '../useStore';

export const SHOW_ADDITIONAL_BONUS_THRESHOLD = 0.01; // 1%

export const useVipRewardQuery = () => {
  const { state: vipLevel } = useStore(state => state.profile.vipLevel);
  const { data, loading, refetch } = usePendingVipRewardsQuery({
    skip: !useIsLoggedIn(),
    variables: { skipRaceReward: false },
  });
  const currentVipLevel = useRef(vipLevel);

  const {
    rewardStatus,
    vipUpgradeRewardStatus,
    raceRewardStatus,
    vipBonusStatus,
    vipBonusIndex,
    challengeRewardStatus,
  } = useSelector(
    (state: AppState) => ({
      rewardStatus: state.vip.rewardStatus,
      vipUpgradeRewardStatus: state.vip.vipUpgradeRewardStatus,
      raceRewardStatus: state.vip.raceRewardStatus,
      vipBonusStatus: state.vip.vipBonusStatus,
      vipBonusIndex: state.vip.vipBonusIndex,
      challengeRewardStatus: state.vip.challengeRewardStatus,
    }),
    shallowEqual,
  );

  const dailyRakeback = data?.vipDailyRakeback;
  const weeklyBonus = data?.vipWeeklyBonus;
  const monthlyBonus = data?.vipMonthlyBonus;
  const vipUpgradeBonuses = data?.vipUpgradeBonuses ?? [];
  const raceBonuses = data?.raceUserReward ?? [];
  const vipBonuses = data?.vipBonus
    ? data.vipBonus.map(bonus => ({
        ...bonus,
        bonusClaims: deriveBonusIssuances(bonus),
      }))
    : [];

  const challengeRewards =
    data?.challengeRewards
      ?.filter(item => !item.claimedAt)
      .map(item => {
        return { ...item, ...item?.challenge };
      }) ?? [];

  const dailyRakebackAmounts =
    dailyRakeback?.currencyAmounts.map(currencyAmt => ({
      currency: currencyAmt.currency,
      amount: BigNumber(currencyAmt.amount),
    })) ?? [];

  const weeklyBonusAmount = BigNumber(weeklyBonus?.usdValue ?? 0);
  const additionalShflWagerUsdWeeklyBonusAmount = BigNumber(
    weeklyBonus?.additionalShflWagerUsdBonusAmount ?? 0,
  );

  const monthlyBonusAmount = BigNumber(monthlyBonus?.usdValue ?? 0);
  const additionalShflWagerUsdMonthlyBonusAmount = BigNumber(
    monthlyBonus?.additionalShflWagerUsdBonusAmount ?? 0,
  );

  const dailyRakebackClaimDate = new Date(dailyRakeback?.claimDate ?? Date.now());
  const weeklyBonusClaimDate = new Date(weeklyBonus?.claimDate ?? Date.now());
  const monthlyBonusClaimMonth = monthlyBonus?.claimMonth ?? '';

  let vipNotificationsCount = 0;

  vipUpgradeBonuses.forEach(bonus => {
    const rewardStatus = vipUpgradeRewardStatus[bonus.vipLevel];
    if (rewardStatus === undefined || rewardStatus === RewardStatus.NONE) vipNotificationsCount++;
  });

  vipBonuses.forEach(bonus => {
    const currentIndex = vipBonusIndex[bonus.id];
    if (currentIndex) {
      const bonusStatus = vipBonusStatus[`${bonus.id}-${currentIndex}`];
      if (bonusStatus === RewardStatus.NONE) vipNotificationsCount++;
    }
  });

  raceBonuses.forEach(bonus => {
    const rewardStatus = raceRewardStatus[bonus.race.id];
    if (rewardStatus === undefined || rewardStatus === RewardStatus.NONE) vipNotificationsCount++;
  });

  dailyRakebackAmounts.length > 0 &&
    rewardStatus[VipRewardType.DAILY_RAKEBACK] === RewardStatus.NONE &&
    vipNotificationsCount++;
  weeklyBonusAmount.isGreaterThan(0) &&
    rewardStatus[VipRewardType.WEEKLY_BONUS] === RewardStatus.NONE &&
    vipNotificationsCount++;
  monthlyBonusAmount.isGreaterThan(0) &&
    rewardStatus[VipRewardType.MONTHLY_BONUS] === RewardStatus.NONE &&
    vipNotificationsCount++;

  challengeRewards.forEach(bonus => {
    const rewardStatus = challengeRewardStatus[bonus.id];
    if (rewardStatus === undefined || rewardStatus === RewardStatus.NONE) vipNotificationsCount++;
  });

  useEffect(() => {
    if (vipLevel && vipLevel !== currentVipLevel.current) {
      refetch().catch();
      currentVipLevel.current = vipLevel;
    }
  }, [refetch, vipLevel]);

  return {
    vipUpgradeBonuses,
    vipBonuses,
    dailyRakebackAmounts,
    weeklyBonusAmount,
    additionalShflWagerUsdWeeklyBonusAmount,
    additionalShflWagerUsdMonthlyBonusAmount,
    monthlyBonusAmount,
    dailyRakebackClaimDate,
    weeklyBonusClaimDate,
    monthlyBonusClaimMonth,
    raceBonuses,
    challengeRewards,
    loading,
    vipNotificationsCount,
  };
};
