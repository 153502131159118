import { LoginVerificationMethod, UserAuthMethod } from 'generated/graphql';
import { useStore } from 'hooks/useStore';
import { useTranslation } from 'react-i18next';

export const useTwoFA = () => {
  const { t } = useTranslation();
  const { state: profile } = useStore(state => {
    const profile = state.profile;
    return {
      email: profile.email,
      linkedOauthProviders: profile.linkedOauthProviders[0],
      verificationMethod: profile.verificationMethod,
      loginVerificationMethod: profile.loginVerificationMethod,
      isTotpEnabled: profile.loginVerificationMethod === LoginVerificationMethod.TOTP,
    };
  });

  const userEmail = profile?.email;
  const linkedProvider = profile?.linkedOauthProviders;
  const verificationMethod = profile?.verificationMethod;
  const isTotpEnabled = profile?.loginVerificationMethod === LoginVerificationMethod.TOTP;
  const isOauthUser = verificationMethod === UserAuthMethod.OAUTH && linkedProvider;
  const verifyWithOauth = !isTotpEnabled && isOauthUser;

  const twoFaHeader = isTotpEnabled
    ? t('titleVerificationViaAuthApp')
    : t('titleVerificationViaEmail');

  return {
    userEmail,
    twoFaHeader,
    linkedProvider,
    isTotpEnabled,
    isOauthUser,
    verifyWithOauth,
    verificationMethod,
  };
};
