import colors from 'assets/styles/colors';
import clsx from 'clsx';
import CryptoIcon from '../../../../components/CurrencyIcons/CryptoIcon';
import FormattedAmount from '../../../../components/FormattedAmount';
import { Currency } from '../../../../generated/graphql';
import { SportsBetTicketStatusType } from '../BetsGroup.type';
import { BetTicketAmount } from './BetTicketAmount';
import { BetTicketText } from './BetTicketText';
import { getBetViewConfig } from './getBetViewConfig';
import { useTranslation } from 'next-i18next';
import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice.type';
import styles from './BetStakeAndPayout.module.scss';
type Props = {
  sportsBetStatus?: SportsBetTicketStatusType;
  betSlipStatus?: BetSlipStatus;
  currency: Currency;
  estimatePayoutOutput: string;
  isPayoutMoreThanStakeAmount: boolean;
  publicView?: boolean;
};
export function BetStakeAndPayout({
  sportsBetStatus,
  betSlipStatus,
  currency,
  estimatePayoutOutput,
  isPayoutMoreThanStakeAmount,
  publicView = false
}: Props) {
  const {
    t
  } = useTranslation();
  const {
    isWon,
    isLost,
    isDeadHeat,
    isCanceled,
    isWin,
    isLoss,
    isPartial,
    isPushed,
    isVoid
  } = getBetViewConfig({
    betSlipStatus,
    sportsBetStatus
  });
  const isPartialAndPayoutMoreThanStake = isPartial && isPayoutMoreThanStakeAmount;
  const isDeadHeatAndPayoutMoreThanStake = isDeadHeat && isPayoutMoreThanStakeAmount;
  let color = colors.gray300;
  if (isWin || isPartialAndPayoutMoreThanStake || isDeadHeatAndPayoutMoreThanStake) {
    color = colors.success;
  }
  return <>
      {(isWin || isLoss) && <div className={clsx(styles.field, {
      [String(styles.win)]: isWin,
      [String(styles.loss)]: isLoss
    })}>
          <BetTicketText capitalize color={color}>
            {isWin && t('yoWon')}
            {isLoss && t('noReturn')}
          </BetTicketText>
          <BetTicketAmount>
            <CryptoIcon currency={currency} />
            <FormattedAmount value={estimatePayoutOutput} currency={currency} />
          </BetTicketAmount>
        </div>}

      {(isWon || isVoid || isPushed || isCanceled) && <span>
          <div className={clsx(styles.betStatus, {
        [String(styles.whiteText)]: isVoid || isPushed,
        [String(styles.field)]: true
      })}>
            <BetTicketText capitalize color={color}>
              {publicView ? t('txtPayout') : t('yoWon')}
            </BetTicketText>
            <BetTicketAmount>
              <CryptoIcon currency={currency} />
              <FormattedAmount value={estimatePayoutOutput} currency={currency} />
            </BetTicketAmount>
          </div>
        </span>}

      {isLost && <span>
          <div className={`${styles.field} ${styles.betStatusLoss}`}>
            <BetTicketText capitalize color={color}>
              {t('noReturn')}
            </BetTicketText>
            <BetTicketAmount>
              <CryptoIcon currency={currency} />
              <FormattedAmount value={estimatePayoutOutput} currency={currency} />
            </BetTicketAmount>
          </div>
        </span>}

      {(isDeadHeat || isPartial) && <span>
          <div className={styles.field}>
            <BetTicketText noCapitalize color={color}>
              {t('partialReturn')}
            </BetTicketText>
            <BetTicketAmount isPayout={isPartialAndPayoutMoreThanStake || isDeadHeatAndPayoutMoreThanStake}>
              <CryptoIcon currency={currency} />
              <FormattedAmount value={estimatePayoutOutput} currency={currency} />
            </BetTicketAmount>
          </div>
        </span>}
    </>;
}