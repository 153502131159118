import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Loader } from '../Loader';
import { ButtonHeight, ButtonVariants } from './ButtonVariants';
import { Icon } from './Icon';
import styles from './BaseButton.module.scss';
export type ButtonColor = 'primary' | 'secondary' | 'tertiary' | 'success';

// only the primary button has a custom loading style
const loadingStyle: Record<ButtonColor, string> = {
  primary: styles.loading ?? '',
  success: '',
  secondary: '',
  tertiary: styles.tertiaryLoading ?? ''
};
export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'value'> {
  /**
   * Button height
   */
  height?: ButtonHeight;
  /**
   * Button style
   */
  color?: ButtonColor;
  /**
   * Button contents
   */
  value?: string | React.ReactNode;
  /**
   * Icon
   */
  icon?: Element | React.ReactNode | string;
  /**
   * Trigger submitting
   */
  /**
   * Add margin top
   */
  hasMargin?: boolean;
  /**
   * use only the dimensions of the icon instead of setting a fixed width and height
   */
  iconNoFixedDimensions?: boolean;

  /** Should icon SVG have filled property when disabled? */
  fillIcon?: boolean;

  /** Should button text be bolder? */
  bold?: boolean;

  /** Custom button text font weight */
  loading?: boolean;
  loadingText?: boolean;
  children?: React.ReactNode[] | React.ReactNode;
  noDisabledStyle?: boolean;
  as?: string;
  isRound?: boolean;
}

/**
 * Primary UI component for user interaction
 */
const Button = ({
  noDisabledStyle,
  height = 'md',
  color = 'primary',
  value,
  disabled,
  icon = '',
  hasMargin = false,
  fillIcon = true,
  iconNoFixedDimensions = false,
  bold = false,
  loading,
  loadingText,
  children,
  className,
  ...rest
}: ButtonProps) => {
  const LoadingContent = <>
      <Loader invert className={styles.loader} /> {loadingText}
    </>;
  const NoneLoadingContent = <>
      {icon && <Icon $noFixedDimensions={iconNoFixedDimensions}>{(icon as ReactNode)}</Icon>}
      {children ?? value}
    </>;
  return <ButtonVariants disabled={disabled || loading} hasMargin={hasMargin} fillIcon={fillIcon} bold={bold} {...rest} color={color} className={`${className || ''} ${loading ? loadingStyle[color] : ''}`} height={height} noDisabledStyle={noDisabledStyle}>
      {loading ? LoadingContent : NoneLoadingContent}
    </ButtonVariants>;
};
export default Button;