import { useEffect, useRef } from 'react';
import { DismissButton, Overlay, usePopover } from 'react-aria';
import type { AriaPopoverProps } from 'react-aria';
import { type OverlayTriggerState } from 'react-stately';
import styles from './Popover.module.scss';
interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
  children: React.ReactNode;
  state: OverlayTriggerState;
  minWidth?: number;
}
export function Popover({
  children,
  state,
  minWidth,
  offset = 12,
  ...props
}: PopoverProps) {
  const popoverRef = useRef<HTMLDivElement>(null);
  const {
    popoverProps,
    underlayProps
  } = usePopover({
    ...props,
    offset,
    popoverRef
  }, state);

  // Prevent popup receives pointer events after selecting an option
  useEffect(() => {
    popoverRef.current?.addEventListener('touchend', (event: TouchEvent) => {
      event.preventDefault();
    }, {
      passive: false,
      once: true
    });
  }, []);
  return <Overlay>
      <div {...underlayProps} className={styles.underlay} />
      <div {...popoverProps} style={{
      ...popoverProps.style,
      minWidth
    }} ref={popoverRef} className={styles.root} data-body-scroll-lock-ignore="true">
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>;
}