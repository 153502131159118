import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';

import {
  EventState,
  Sports,
  SportsMatchPhase,
  SportsMatchPhaseLabel,
  SportsMatchState,
} from '../generated/graphql';

import { useCallback, useMemo } from 'react';
import { convertMatchSetNumber, getEventState } from '../utils/sportsGenerator';

export type LiveMatchProgressText = {
  content: string;
  parts: string[];
};

function calculateTimeDisplayText({ time, hasSeconds }: { time: number; hasSeconds?: boolean }) {
  const minutes = dayjs.duration(time).asMinutes();
  const timeDisplayByMinutes = `${minutes <= 1 ? 1 : Math.floor(minutes)}'`;
  return hasSeconds ? dayjs.duration(time).format('mm:ss') : timeDisplayByMinutes;
}

export const useLiveMatchProgressText = ({
  matchState,
  startTime,
  formattedDateTime,
  inPlayAllowed,
  sports,
}: {
  matchState?: SportsMatchState | null;
  startTime?: string;
  inPlayAllowed?: boolean | null;
  formattedDateTime?: string;
  sports?: Sports;
}) => {
  const { t } = useTranslation();

  if (!sports) {
    return useMemo(
      () => ({
        content: '',
        parts: [],
      }),
      [],
    );
  }

  const MATCH_PHASE_LIST: Record<SportsMatchPhase, string> = useMemo(
    () => ({
      [SportsMatchPhase.PREMATCH]: t('txtSportLiveLabelsPreMatch'),
      [SportsMatchPhase.FIRSTHALF]: t('txtFirstHalf'),
      [SportsMatchPhase.HALFTIME]: t('txtSportLiveLabelsHalftime'),
      [SportsMatchPhase.SECONDHALF]: t('txtSecondHalf'),
      [SportsMatchPhase.FULLTIMENORMALTIME]: t('txtSportLiveLabelsFullTime'),
      [SportsMatchPhase.EXTRATIMEFIRSTHALF]: t('txtExtraTimeFirstHalf'),
      [SportsMatchPhase.EXTRATIMEHALFTIME]: t('txtSportLiveLabelsExtraTimeHalftime'),
      [SportsMatchPhase.EXTRATIMESECONDHALF]: t('txtExtraTimeSecondHalf'),
      [SportsMatchPhase.FULLTIMEEXTRATIME]: t('txtSportLiveLabelsFullTime'),
      [SportsMatchPhase.PENALTIES]: t('txtSportLiveLabelsPenalties'),
      [SportsMatchPhase.POSTMATCH]: t('txtPostMatch'),
      [SportsMatchPhase.MATCHABANDONED]: t('txtMatchAbandoned'),
      [SportsMatchPhase.PREGAME]: t('txtSportLiveLabelsPreGame'),
      [SportsMatchPhase.FIRSTQUARTER]: t('txtFirstQuarter'),
      [SportsMatchPhase.BEFORESECONDQUARTER]: t('txtSecondQuarter'),
      [SportsMatchPhase.SECONDQUARTER]: t('txtSecondQuarter'),
      [SportsMatchPhase.THIRDQUARTER]: t('txtThirdQuarter'),
      [SportsMatchPhase.BEFOREFOURTHQUARTER]: t('txtFourthQuarter'),
      [SportsMatchPhase.FOURTHQUARTER]: t('txtFourthQuarter'),
      [SportsMatchPhase.BEFOREOVERTIME]: t('txtSportLiveLabelsOvertime'),
      [SportsMatchPhase.OVERTIME]: t('txtSportLiveLabelsOvertime'),
      [SportsMatchPhase.POSTGAME]: t('txtPostGame'),
      [SportsMatchPhase.GAMEABANDONED]: t('txtGameAbandoned'),
      [SportsMatchPhase.FIRSTPERIOD]: t('txtFirstPeriod'),
      [SportsMatchPhase.ENDOFFIRSTPERIOD]: t('txtEndOfFirstPeriod'),
      [SportsMatchPhase.SECONDPERIOD]: t('txtSecondPeriod'),
      [SportsMatchPhase.ENDOFSECONDPERIOD]: t('txtEndOfSecondPeriod'),
      [SportsMatchPhase.THIRDPERIOD]: t('txtThirdPeriod'),
      [SportsMatchPhase.ENDOFTHIRDPERIOD]: t('txtEndOfThirdPeriod'),
      [SportsMatchPhase.OVERTIMEPERIOD]: t('txtSportLiveLabelsOvertime'),
      [SportsMatchPhase.ENDOFOVERTIMEPERIOD]: t('txtSportLiveLabelsOvertime'),
      [SportsMatchPhase.PENALTYSHOOTOUT]: t('txtSportLiveLabelsPenalties'),
      [SportsMatchPhase.SUSPENDED]: t('txtSuspended'),
      [SportsMatchPhase.ABANDONED]: t('txtAbandoned'),
      [SportsMatchPhase.INPROGRESS]: t('txtInProgress'),
      [SportsMatchPhase.CANCELLED]: t('txtCancelled'),
      [SportsMatchPhase.POSTPONED]: t('txtPostponed'),
      [SportsMatchPhase.INPLAY]: t('txtInPlay'),
      [SportsMatchPhase.BREAKINPLAY]: t('txtBreakInPlay'),
      [SportsMatchPhase.COMPLETED]: t('txtCompleted'),
      [SportsMatchPhase.FIRSTMAP]: t('txtFirstMap'),
      [SportsMatchPhase.SECONDMAP]: t('txtSecondMap'),
      [SportsMatchPhase.THIRDMAP]: t('txtThirdMap'),
      [SportsMatchPhase.FOURTHMAP]: t('txtFourthMap'),
      [SportsMatchPhase.FIFTHMAP]: t('txtFifthMap'),
      [SportsMatchPhase.SIXTHMAP]: t('txtSixthMap'),
      [SportsMatchPhase.SEVENTHMAP]: t('txtSeventhMap'),
      [SportsMatchPhase.FIRSTINNING]: t('txtFirstInning'),
      [SportsMatchPhase.SECONDINNING]: t('txtSecondInning'),
      [SportsMatchPhase.THIRDINNING]: t('txtThirdInning'),
      [SportsMatchPhase.FOURTHINNING]: t('txtFourthInning'),
      [SportsMatchPhase.DELAYED]: t('txtDelayed'),
      [SportsMatchPhase.CLOSED]: t('txtClosed'),
      [SportsMatchPhase.NOTSTARTED]: t('txtNotStarted'),
      [SportsMatchPhase.FIRSTGAME]: t('txtFirstGame'),
      [SportsMatchPhase.SECONDGAME]: t('txtSecondGame'),
      [SportsMatchPhase.THIRDGAME]: t('txtThirdGame'),
      [SportsMatchPhase.FOURTHGAME]: t('txtFourthGame'),
      [SportsMatchPhase.FIFTHGAME]: t('txtFifthGame'),
      [SportsMatchPhase.SIXTHGAME]: t('txtSixthGame'),
      [SportsMatchPhase.SEVENTHGAME]: t('txtSeventhGame'),
    }),
    [t],
  );

  const MATCH_PHASE_LABEL_LIST = useMemo(
    () => ({
      [SportsMatchPhaseLabel.INNING]: t('txtInning'),
      [SportsMatchPhaseLabel.GAME]: t('txtGame'),
      [SportsMatchPhaseLabel.SET]: t('txtSet'),
      [SportsMatchPhaseLabel.ROUND]: t('txtRound'),
      [SportsMatchPhaseLabel.LAP]: t('txtLap'),
    }),
    [t],
  );

  const timeRemaining = matchState?.matchSummary?.timeRemaining ?? null;
  const timeElapsed = matchState?.matchSummary?.timeElapsed ?? null;

  let timeDisplayText = '';

  if (timeElapsed !== null) {
    timeDisplayText = calculateTimeDisplayText({
      time: timeElapsed,
      hasSeconds: sports === Sports.EFOOTBALL,
    });
  } else if (timeRemaining !== null && timeRemaining > 0) {
    if (timeRemaining > 0) {
      timeDisplayText = calculateTimeDisplayText({
        time: timeRemaining,
        hasSeconds: sports === Sports.EBASKETBALL,
      });
    }
  }

  const isPreMatch =
    matchState?.matchSummary?.eventState === EventState.PREMATCH ||
    dayjs(startTime).isAfter(dayjs());

  const getLiveProgressText = useCallback(() => {
    const matchPhase = matchState?.matchSummary?.matchPhase;
    const matchStatusDisplay = matchState?.matchSummary?.matchStatusDisplay;

    const content = matchStatusDisplay
      ?.map(({ freeText, matchPhase, matchPhaseLabel, rawNumber }) => {
        if (matchPhase) {
          return MATCH_PHASE_LIST[matchPhase as SportsMatchPhase];
        }
        if (matchPhaseLabel) {
          return MATCH_PHASE_LABEL_LIST[matchPhaseLabel];
        }
        if (rawNumber) {
          return convertMatchSetNumber(rawNumber);
        }
        return freeText;
      })
      .join(' ');

    if (timeDisplayText) {
      if (
        matchPhase &&
        [SportsMatchPhase.HALFTIME, SportsMatchPhase.PENALTIES].includes(matchPhase)
      ) {
        return {
          content: MATCH_PHASE_LIST[matchPhase],
        };
      }

      return {
        content: `${timeDisplayText} ${content}`,
        parts: [timeDisplayText, `${content}`] as [string, string],
      };
    }
    return {
      content,
    };
  }, [matchState, timeDisplayText, MATCH_PHASE_LABEL_LIST, MATCH_PHASE_LIST]);

  return useMemo(() => {
    const eventState = matchState?.matchSummary?.eventState;

    if (matchState && !isPreMatch) {
      const output = getLiveProgressText();

      return {
        content: output.content?.trim() ?? '',
        parts: output?.parts ?? [],
      };
    }

    return {
      content: getEventState({
        startTime: startTime ?? '',
        inPlayAllowed,
        formattedDateTime: formattedDateTime ?? '',
        eventState,
      }),
      parts: [],
    };
  }, [startTime, matchState, getLiveProgressText, isPreMatch, inPlayAllowed, formattedDateTime]);
};
