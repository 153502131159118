import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import colors from '../../../../assets/styles/colors';
import { SportsMatchState } from '../../../../generated/graphql';
import { BetSlipItem } from '../../../../redux/slices/sportsBetSlice.type';
import { getLiveMatchScoreText } from '../../../../utils/sportsMatchStateUtils';
import { AnimateLiveEventTime } from '../../../SportsHome/components/SportCardElements/AnimateLiveEventTime';
import { AnimateScoreNumber } from '../../../SportsHome/components/SportCardElements/AnimateScoreNumber';
import { PieTimer } from '../../../SportsHome/components/SportCardElements/PieTimer';
import { BetSlipProps } from '../BetsGroup.type';
import styles from './BetGameEventInfo.module.scss';
import { BetSlipGameEventInfoActionPanel } from './BetSlipGameEventInfoActionPanel';
import { BetTicketText } from './BetTicketText';
import { getBetViewConfig } from './getBetViewConfig';
type Props = {
  shouldShowLiveEventInfo: boolean;
  viewConfig: ReturnType<typeof getBetViewConfig>;
  isEventClosed: boolean;
  matchState?: SportsMatchState;
  time?: string;
  startTimeColor: string;
  text?: string;
  shouldHideDate: boolean;
  liveMatchProgressTextContent: string;
  hasMarketNotOpenInBetAdd: boolean;
  shouldDisplayPieTimer: boolean;
  minutesRemaining: number;
  startTimeForDisplay: string;
  bet: BetSlipItem;
} & Pick<BetSlipProps, 'actionPanelData'>;
export function BetGameEventInfoTextLink({
  startTimeForDisplay,
  minutesRemaining,
  shouldDisplayPieTimer,
  startTimeColor,
  hasMarketNotOpenInBetAdd,
  shouldHideDate,
  actionPanelData,
  shouldShowLiveEventInfo,
  liveMatchProgressTextContent,
  bet,
  bet: {
    sport
  },
  viewConfig: {
    isInBetAddOrConfirmed
  },
  time,
  text,
  isEventClosed,
  matchState
}: Props) {
  const {
    t
  } = useTranslation();
  if (!bet.href) {
    return;
  }
  const {
    liveScoreText,
    liveScoreList,
    homeScore,
    awayScore,
    homeCurrentScore,
    awayCurrentScore
  } = getLiveMatchScoreText({
    sport,
    matchState
  });
  const shouldShowFinalScore = !isInBetAddOrConfirmed && isEventClosed && matchState;
  if (shouldShowLiveEventInfo) {
    return <div className={styles.liveEventBlock}>
        {time && text && bet.href && <Link href={bet.href} className={styles.link}>
            <BetTicketText color={colors.success}>
              <AnimateLiveEventTime time={time} text={text} variants="green" />
              <img src="/icons/chevron.svg" alt="chevron" className={`${styles.arrow} ${styles.greenArrow}`} />
            </BetTicketText>
          </Link>}

        {!time && !text && bet.href && liveMatchProgressTextContent && <BetTicketText color={colors.success}>
            <Link href={bet.href} className={styles.link}>
              {liveMatchProgressTextContent}
              <img src="/icons/chevron.svg" alt="chevron" className={`${styles.arrow} ${styles.greenArrow}`} />
            </Link>
          </BetTicketText>}

        {liveScoreList.length > 1 ? <div className={styles.liveCurrentScoreBlock}>
            <BetTicketText>
              <AnimateScoreNumber className={styles.liveCurrentScore}>
                {homeCurrentScore}
              </AnimateScoreNumber>
              -
              <AnimateScoreNumber className={styles.liveCurrentScore}>
                {awayCurrentScore}
              </AnimateScoreNumber>
            </BetTicketText>
            <BetTicketText bold color={colors.success}>
              <AnimateScoreNumber>{homeScore}</AnimateScoreNumber>-
              <AnimateScoreNumber>{awayScore}</AnimateScoreNumber>
            </BetTicketText>
            {actionPanelData?.[0] && <BetSlipGameEventInfoActionPanel content={liveMatchProgressTextContent} link={bet.href} name={bet.fixtureName} time={time} text={text} actionPanelSource={actionPanelData[0]} />}
          </div> : <div className={styles.liveScoreBlock}>
            <BetTicketText bold color={colors.success}>
              <AnimateScoreNumber>{homeScore}</AnimateScoreNumber>-
              <AnimateScoreNumber>{awayScore}</AnimateScoreNumber>
            </BetTicketText>
            {actionPanelData?.[0] && <BetSlipGameEventInfoActionPanel content={liveMatchProgressTextContent} link={bet.href} time={time} name={bet.fixtureName} text={text} actionPanelSource={actionPanelData[0]} />}
          </div>}
      </div>;
  }
  if (!shouldShowLiveEventInfo) {
    if (shouldShowFinalScore) {
      return <div className={styles.liveEventBlock}>
          <Link href={bet.href} className={styles.link}>
            <BetTicketText>
              {t('txtFinalScore')}
              <img src="/icons/chevron.svg" alt="chevron" className={styles.arrow} />
            </BetTicketText>
          </Link>

          <BetTicketText bold>{liveScoreText}</BetTicketText>
        </div>;
    }
    if (!shouldHideDate && !isInBetAddOrConfirmed) {
      return <Link href={bet.href} className={styles.link}>
          <BetTicketText dimmed={hasMarketNotOpenInBetAdd} color={startTimeColor} className={shouldDisplayPieTimer ? styles.countdown : ''}>
            {shouldDisplayPieTimer && <PieTimer className={styles.pieTimer} count={minutesRemaining} />}
            <span className={styles.startTimeCountdown}>{startTimeForDisplay}</span>
            <img src="/icons/chevron.svg" alt="chevron" className={`${styles.arrow} ${startTimeColor === colors.success ? styles.greenArrow : ''}`} />
          </BetTicketText>
        </Link>;
    }
  }
  if (!isInBetAddOrConfirmed) {
    return <Link href={bet.href} className={styles.link}>
        <BetTicketText>
          {t('txtNoScore')}
          <img src="/icons/chevron.svg" alt="chevron" className={styles.arrow} />
        </BetTicketText>
      </Link>;
  }
  return;
}