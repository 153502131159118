import { FiatCurrency } from 'generated/graphql';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserPreferencesState, setInitialDefault } from 'redux/slices/browserPreferenceSlice';
import { getCookie } from 'utils/appCookie';

export const useUpdateDefaultFiatPreference = ({
  browserPreference,
  isPersistStorePopulated,
}: {
  browserPreference: BrowserPreferencesState;
  isPersistStorePopulated: boolean;
}) => {
  const dispatch = useDispatch();

  const { fiatPreferenceSet, displayInFiatSet } = browserPreference;

  useEffect(() => {
    const ipCountry = getCookie('ip-country');
    if (isPersistStorePopulated && !displayInFiatSet && !fiatPreferenceSet && ipCountry === 'JP') {
      dispatch(
        setInitialDefault({
          fiatPreference: FiatCurrency.JPY,
          displayInFiat: true,
        }),
      );
    }
  }, [displayInFiatSet, fiatPreferenceSet, dispatch, isPersistStorePopulated]);
};
