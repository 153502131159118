import { Language } from 'generated/graphql';
import unset from 'lodash/unset';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCookie, setLanguagePreferenceCookie } from 'utils/appCookie';

import { updateAdvancedDiceStore } from 'redux/slices/diceSlice';
import { PlinkoGamePersistState, updatePlinkoLocalStorage } from 'redux/slices/gamesSlice';
import { updateAuthStore } from '../redux/slices/authSlice';
import { updateBrowserPreferenceStore } from '../redux/slices/browserPreferenceSlice';
import { updateCampaignStore } from '../redux/slices/campaignSlice';
import { setPersistStorePopulated } from '../redux/slices/globalStateSlice';

export const PERSIST_STORE_NAME = 'persist:root';
export const VERSION = 2;
export const PERSIST_STORE_NAMES_METHODS = [
  { name: 'auth', method: updateAuthStore },
  {
    name: 'browserPreference',
    method: updateBrowserPreferenceStore,
  },
  {
    name: 'games/updatePlinkoLocalStorage',
    method: (state: PlinkoGamePersistState['localStorage']) => updatePlinkoLocalStorage(state),
    key: 'games.persistStateGames.PLINKO.localStorage',
  },
  { name: 'campaign', method: updateCampaignStore },
  { name: 'dice', method: updateAdvancedDiceStore },
];

export function getPersistStoreData() {
  try {
    if (typeof window === 'undefined') {
      return;
    }

    const store = window.localStorage.getItem(PERSIST_STORE_NAME);
    if (!store) {
      return;
    }

    const parsedStore = JSON.parse(store);

    // if (parsedStore?._persist) {
    //   const persist = JSON.parse(parsedStore?._persist);
    //   if (VERSION > persist?.version && parseInt(persist?.version)) {
    //     return;
    //   }
    // }

    PERSIST_STORE_NAMES_METHODS.forEach(({ name, key }) => {
      const storeName = key ? key : name;
      parsedStore[storeName] = parsedStore?.[storeName] ? JSON.parse(parsedStore[storeName]) : {};
    });

    return parsedStore;
  } catch (e) {
    console.error(`Failed retrieve local storage data ${(e as Error)?.message ?? ''}`);
  }
}

export function usePersistStoreData() {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    try {
      const parsedStore = getPersistStoreData();
      if (parsedStore) {
        const languagePreference = parsedStore?.browserPreference?.languagePreference;

        if (
          languagePreference &&
          languagePreference !== Language.EN &&
          !getCookie('language-preference')
        ) {
          setLanguagePreferenceCookie(languagePreference);
          unset(parsedStore.browserPreference, 'languagePreference');
          router.reload();
        }

        PERSIST_STORE_NAMES_METHODS.forEach(({ method, name, key }) => {
          const storeName = key ? key : name;
          if (parsedStore?.[storeName] && Object.keys(parsedStore[storeName]).length > 0) {
            dispatch(method(parsedStore[storeName]));
          }
        });
      }
    } catch (e) {
      console.error(`Failed to update redux store ${(e as Error)?.message ?? ''}`);
    }

    dispatch(setPersistStorePopulated(true));
  }, [dispatch, router]);

  return;
}
