import { GetChallengeCountQuery, useGetRaceLeaderBoardV2Query } from '../../generated/graphql';
import { usePreventScrolling } from '../../hooks/usePreventScrolling';
import { MobileMenuName } from '../../redux/slices/globalStateSlice';
import NavigationContent from './NavigationContent';
import styles from './MobileNavigationBar.module.scss';
type Props = {
  challengeCountData?: GetChallengeCountQuery;
  raceLeaderBoardResult: ReturnType<typeof useGetRaceLeaderBoardV2Query>;
  activeMobileNav?: MobileMenuName;
};
export const MobileNavigationBar = ({
  challengeCountData,
  raceLeaderBoardResult,
  activeMobileNav
}: Props) => {
  const isActive = activeMobileNav === MobileMenuName.MENU;
  usePreventScrolling(isActive);
  return <div className={`${styles.container} ${isActive ? styles.active : ''}`}>
      <div className={styles.root}>
        <NavigationContent raceLeaderBoardResult={raceLeaderBoardResult} isExpanded challengeCountData={challengeCountData} isMobile />
      </div>
    </div>;
};