import { SPORTS_CONFIG, SportsConfig } from 'constants/SPORTS_CONFIG';
import { Sports } from 'generated/graphql';
import React from 'react';
const sportsMapping: Record<string, React.ReactNode> = SPORTS_CONFIG.reduce((obj, item: SportsConfig) => Object.assign(obj, {
  [item.id]: <img src={item.iconUrl} alt={item.name.toLowerCase()} />
}), {});
const mapping: {
  [key: string]: React.JSX.Element;
} = {
  ...sportsMapping,
  POPULAR: <img src="/icons/global-star.svg" alt="global star" />,
  ALLSPORTS: <img src="/icons/sports-leagues.svg" alt="all sports" />
};
const SportIcon = ({
  sport
}: {
  sport?: Sports;
}) => {
  if (!sport) {
    return null;
  }
  return mapping[(sport as Sports)] ?? null;
};
export default SportIcon;