import * as React from 'react';
import { useEffect, useState } from 'react';
type Props = {
  children: React.ReactNode | React.ReactNode[];
};
export function RenderClient(props: Props) {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (isMounted) {
    return <>{props.children}</>;
  }
  return null;
}