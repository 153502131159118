import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CampaignState {
  code: string;
}

const initialState: CampaignState = {
  code: '',
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    updateCampaignCode: (state: CampaignState, action: PayloadAction<CampaignState>) => {
      state.code = action.payload.code;
    },
    updateCampaignStore: (_, action: PayloadAction<CampaignState>) => action.payload,
  },
});

export const { updateCampaignCode, updateCampaignStore } = campaignSlice.actions;

export default campaignSlice.reducer;
