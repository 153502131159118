import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GameOutcome } from 'constants/games';
import { Currency } from '../../generated/graphql';

/**
 * @note Export from here to ensure the dynamic import of
 * LiveGameStatsChart is not circular
 **/
export const ALL_GAMES_OPTION_VALUE = 'ALL';

/**
 * A bet outcome of the shape [wagered, payout, currency, timestamp].
 * Timestamp is required to consolidate time-series data across multiple games in order.
 */
export type GeneralGameOutcome = [string, string, Currency, number];

export interface GameStatistics {
  name: string;
  wins: number;
  losses: number;
  outcomes: GeneralGameOutcome[];
}

export interface GameStatisticState {
  // We store each game outcome and calculate the fiat totals only when we display the statistics
  // since exchange rates can change, user's fiat preference can change, etc, etc.
  gameStatisticsMap: Record<string, GameStatistics>;
  widgetPosition: { x: number; y: number };
  isWidgetOpen: boolean;
  isWidgetLoaded: boolean;

  // A key of `gameStatisticsMap` as a slug.
  selectedGame: string;
}

export type LogGameOutcomePayload = {
  name: string;
  slug: string;
  wagered: string;
  payout: string;
  currency: Currency;
  gameOutcome: GameOutcome;
};

export const DEFAULT_POSITION_X = 100;
export const DEFAULT_POSITION_Y = 100;

const initialState: GameStatisticState = {
  gameStatisticsMap: {},
  widgetPosition: { x: DEFAULT_POSITION_X, y: DEFAULT_POSITION_Y },
  selectedGame: ALL_GAMES_OPTION_VALUE,
  isWidgetOpen: false,
  isWidgetLoaded: false,
};

export const gameStatisticSlice = createSlice({
  name: 'gameStatistics',
  initialState,
  reducers: {
    logGameOutcome: (state: GameStatisticState, action: PayloadAction<LogGameOutcomePayload>) => {
      const outcome: GeneralGameOutcome = [
        action.payload.wagered,
        action.payload.payout,
        action.payload.currency,
        Date.now(),
      ];

      const statistics: GameStatistics = state.gameStatisticsMap[action.payload.slug] ?? {
        name: action.payload.name,
        wins: 0,
        losses: 0,
        outcomes: [],
      };

      if (action.payload.gameOutcome !== GameOutcome.DRAW) {
        statistics[action.payload.gameOutcome === GameOutcome.WIN ? 'wins' : 'losses'] += 1;
      }

      statistics.outcomes = [...statistics.outcomes, outcome];
      state.gameStatisticsMap[action.payload.slug] = statistics;
    },
    resetGameStatistics: (state: GameStatisticState) => {
      const _gameStatisticsMap = { ...state.gameStatisticsMap };

      // Clear a specific game or clear ALL games.
      if (state.selectedGame === ALL_GAMES_OPTION_VALUE) {
        state.gameStatisticsMap = initialState.gameStatisticsMap;
        return;
      }

      // Reset a specific slug's statistics
      delete _gameStatisticsMap[state.selectedGame];
      state.gameStatisticsMap = _gameStatisticsMap;
    },
    setWidgetLoaded: (state: GameStatisticState) => {
      state.isWidgetLoaded = true;
    },
    setSelectedGame: (state: GameStatisticState, action: PayloadAction<string>) => {
      const exists = !!state.gameStatisticsMap[action.payload];
      state.selectedGame = exists ? action.payload : ALL_GAMES_OPTION_VALUE;
    },
  },
});

export const { logGameOutcome, resetGameStatistics, setSelectedGame, setWidgetLoaded } =
  gameStatisticSlice.actions;

export default gameStatisticSlice.reducer;
