import { CSSProperties, ReactNode } from 'react';
import styles from './FormControlWrapper.module.scss';
export const FormControlWrapper = (props: {
  children: ReactNode;
  $dimmed?: boolean;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
  tabIndex?: number;
  'data-testid'?: string;
}) => {
  const {
    children,
    $dimmed,
    className,
    onClick,
    style,
    tabIndex
  } = props;
  return <div tabIndex={tabIndex} className={`${styles.root} ${className ?? ''}`} onClick={onClick} data-testid={props['data-testid']} style={{
    ...style,
    opacity: $dimmed ? 0.6 : 1
  }}>
      {children}
    </div>;
};