import { memo } from 'react';
import styles from './VipIcon.module.scss';
interface VipIconProps {
  icon: string;
  size?: number;
}

/**
 * Raw VipIcon, without any labels or further bells and whistles.
 *
 * Intrinsic size should be 2x the rendered size to ensure maximal quality, given
 * browser's sub-pixel FFT interpolation.
 */
const VipIcon = ({
  icon,
  size = 16
}: VipIconProps) => <span className={styles.root}>
    <img src={icon} alt="vip icon" width={size} height={size} />
  </span>;
export default memo(VipIcon);