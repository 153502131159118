import { sportsClient } from 'apollo/clients';
import { Esports, SPORTS_CONFIG, SPORTS_CONFIG_OBJECT } from 'constants/SPORTS_CONFIG';
import { Sports, SportsBetStatus, useGetMySportsBetsTotalQuery, useGetRaceLeaderBoardV2Query, useGetSportsFixturesCounterNavbarQuery } from 'generated/graphql';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import { useSportsData } from 'hooks/useSportsData';
import { useTranslation } from 'next-i18next';
import { usePathname } from 'next/navigation';
import React, { useMemo, useState } from 'react';
import { sortSportsListByAlphabet } from 'utils/sportsSort';
import { INTERCOM_WIDGET_CLASSNAME } from '../../constants/intercom';
import { ExpandableLinks } from './ExpandableLinks';
import { NavList } from './NavList';
import { NavigationLink } from './NavigationLink';
import { NavigationProfile } from './NavigationProfile';
import { NavigationWeekly } from './NavigationWeekly';
import { SPORTS_NAV } from './menu';
import { DEFAULT_UPCOMING_HOURS } from 'constants/sports';
import { useRouter } from 'next/router';
import { useSportsPopularNavItems } from '../../hooks/useSportsPopularNavItems';
import { useStore } from '../../hooks/useStore';
import { Loader } from '../Loader';
import styles from './SportsTab.module.scss';
type Props = {
  isExpanded: boolean;
  raceLeaderBoardResult: ReturnType<typeof useGetRaceLeaderBoardV2Query>;
};
const initExpand = <T extends {
  href: string;
  name: string;
  iconUrl: string;
},>(sportsSorted: T[], esportsSorted: T[], pathname: string) => {
  if (sportsSorted.find(({
    href
  }) => href === pathname)) {
    return 'sports';
  }
  if (esportsSorted.find(({
    href
  }) => href === pathname)) {
    return 'esports';
  }
  return '';
};
export const SportsTab = ({
  isExpanded,
  raceLeaderBoardResult
}: Props) => {
  const {
    t
  } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const {
    query
  } = useRouter();
  const mySportsBet = useGetMySportsBetsTotalQuery({
    variables: {
      statuses: [SportsBetStatus.PENDING, SportsBetStatus.PARTIAL_PENDING]
    },
    fetchPolicy: 'cache-and-network'
  });
  const {
    state: {
      liveTotal,
      upcomingTotal
    }
  } = useStore(state => ({
    liveTotal: state.sportsCounter.liveTotal,
    upcomingTotal: state.sportsCounter.upcomingTotal
  }));
  const {
    sportsPopularNavItems,
    loadingSportsPopularNavItems
  } = useSportsPopularNavItems();
  const {
    data: sportsFixturesCounter
  } = useGetSportsFixturesCounterNavbarQuery({
    client: sportsClient,
    fetchPolicy: 'network-only',
    variables: {
      liveOnly: true,
      upcomingHour: DEFAULT_UPCOMING_HOURS
    }
  });
  const {
    getSportsName
  } = useSportsData();
  const allSportsData = SPORTS_CONFIG.filter(sports => !Esports.includes(sports.id)).map(item => {
    return {
      href: item.href,
      name: getSportsName(item.id),
      iconUrl: item.iconUrl
    };
  });
  const sportsSorted = sortSportsListByAlphabet(allSportsData);
  const alleSportsData = SPORTS_CONFIG.filter(sports => Esports.includes(sports.id)).map(item => {
    return {
      href: item.href,
      name: getSportsName(item.id),
      iconUrl: item.iconUrl
    };
  });
  const esportsSorted = sortSportsListByAlphabet(alleSportsData);
  const pathname = usePathname();
  const init = useMemo(() => initExpand(sportsSorted, esportsSorted, pathname), [sportsSorted, esportsSorted, pathname]);
  const [expandGroup, setExpandGroup] = useState<'sports' | 'esports' | ''>(init);
  const preview = query.cms === 'preview';
  const blogHomeUrl = `/blog${preview ? '?cms=preview' : ''}`;
  return <>
      <NavList isExpanded={isExpanded}>
        {SPORTS_NAV.map(({
        icon,
        ...item
      }, i) => {
        let count = 0;
        if (item.name === 'navBetLive') {
          count = liveTotal || sportsFixturesCounter?.betLive?.count || 0;
        }
        if (item.name === 'navUpcoming') {
          count = upcomingTotal || sportsFixturesCounter?.upcoming.count || 0;
        }
        const isMyBets = item.name === 'tabMyBets';
        if (isMyBets) {
          count = mySportsBet.data?.sportsBets.totalCount ?? 0;
        }
        return item.isPrivate && !isLoggedIn ? null : <React.Fragment key={i}>
              {item.hasCounter ? <NavigationLink path={item.href} title={t(item.name)} icon={icon} iconUrl={item.iconUrl} isExpanded={isExpanded} counterVariant={item.name === 'navBetLive' ? 'green' : 'purple'} counter={count} disabled={item.disabled} shallow /> : <NavigationLink path={item.href} title={t(item.name)} icon={icon} shallow iconUrl={item.iconUrl} isExpanded={isExpanded} counter={count} disabled={item.disabled} />}
            </React.Fragment>;
      })}

        <NavigationWeekly isExpanded={isExpanded} raceLeaderBoardResult={raceLeaderBoardResult} />

        {loadingSportsPopularNavItems ? <Loader variant={'circle'} /> : sportsPopularNavItems.map(item => <NavigationLink path={item.url} title={t(item.name)} iconUrl={SPORTS_CONFIG_OBJECT[(item.icon as Sports)]?.iconUrl} key={item.url} isExpanded={isExpanded} />)}
      </NavList>

      <ExpandableLinks className={`${styles.allSportsLink} ${isExpanded ? styles.isExpanded : ''}`} name={t('allSports')} icon="/icons/all-sports.svg" isParentNavExpanded={isExpanded} links={sportsSorted.map(item => {
      return {
        title: t(item.name),
        href: item.href,
        iconUrl: item.iconUrl
      };
    })} open={expandGroup === 'sports'} toggleIsOpen={() => {
      setExpandGroup(expandGroup === 'sports' ? '' : 'sports');
    }} />

      <ExpandableLinks className={`${isLoggedIn ? styles.allSportsLink : ''} ${isExpanded ? styles.isExpanded : ''} ${isLoggedIn ? styles.allSportsLink : styles.allSportsLinkUnAuth}`} name={t('allEsports')} icon="/icons/esports.svg" isParentNavExpanded={isExpanded} links={esportsSorted.map(item => {
      return {
        title: t(item.name),
        href: item.href,
        iconUrl: item.iconUrl
      };
    })} open={expandGroup === 'esports'} toggleIsOpen={() => {
      setExpandGroup(expandGroup === 'esports' ? '' : 'esports');
    }} />

      <NavigationProfile isExpanded={isExpanded} />

      <NavigationLink path="/vip-program" title="VIP" iconUrl="/icons/vip.svg" isExpanded={isExpanded} />

      <NavigationLink path={blogHomeUrl} title={t('Blog')} iconUrl="/icons/blog.svg" isExpanded={isExpanded} />

      <NavigationLink path={isLoggedIn ? '/affiliate/overview' : '/affiliate'} title={t('affiliate')} iconUrl="/icons/affiliate.svg" isExpanded={isExpanded} />

      <NavigationLink title={t('footer.liveChat.text')} iconUrl="/icons/live-support.svg" isExpanded={isExpanded} onClick={() => {}} className={INTERCOM_WIDGET_CLASSNAME} />
    </>;
};
export default SportsTab;