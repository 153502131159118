import colors from 'assets/styles/colors';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import CryptoIcon from '../../../../components/CurrencyIcons/CryptoIcon';
import FormattedAmount from '../../../../components/FormattedAmount';
import { QueryTypes } from '../../../../components/Modal/GlobalModal.type';
import { Props } from './BetOddsStakeAndStatus.type';
import { BetSlipCashOutButton } from './BetSlipCashOutButton';
import { BetStakeAndPayout } from './BetStakeAndPayout';
import { BetTicketAmount } from './BetTicketAmount';
import { BetTicketText } from './BetTicketText';
import { getBetViewConfig } from './getBetViewConfig';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { Currency, SportsBetStatus } from '../../../../generated/graphql';
import { setCashOutBetSlips } from '../../../../redux/slices/sportsBetSlice';
import styles from './BetOddsStakeAndStatus.module.scss';
export function BetOddsStakeAndStatus({
  bet,
  sportsBetStatus,
  bet: {
    cashOutStatus,
    status: marketSelectionStatus
  },
  odds,
  oddsOutputText,
  publicView,
  betSlipStatus,
  estimatePayoutOutput,
  currency,
  amount,
  settmentPayoutOddsOutput,
  cashoutOddsDecimal,
  isPayoutMoreThanStakeAmount,
  isEventClosed,
  betShortId,
  setBetStatus,
  betStatus,
  bets,
  cashOutBetSlips,
  cashOutInfo
}: Props) {
  const dispatch = useDispatch();
  const {
    query
  } = useRouter();
  const {
    t
  } = useTranslation();
  const setCashOutInfo = (data: {
    amount: string;
    currency: Currency;
    odds: string;
  }) => {
    if (betShortId) {
      dispatch(setCashOutBetSlips({
        id: betShortId,
        ...data
      }));
    }
  };
  const {
    shouldShowStats,
    shouldShowReceipt,
    isInBetInfoView,
    shouldShowEstimateAmount,
    isInBetPlacedView,
    isInBetAddOrConfirmed,
    isCanceled,
    shouldShowCashedOut,
    isVoid,
    isPushed
  } = getBetViewConfig({
    sportsBetStatus,
    marketSelectionStatus,
    betSlipStatus,
    cashOutStatus
  });
  if (!shouldShowStats) {
    return null;
  }
  const isInBetResultView = query[QueryTypes.modal] === 'sportBet';
  const isViewMyBetResult = isInBetResultView && !publicView;
  const settmentOddsText = bet.sportsBetStatus === SportsBetStatus.LOST ? odds?.outputText || oddsOutputText : settmentPayoutOddsOutput;
  const oddsAmount = cashOutInfo.odds || settmentOddsText || odds?.outputText || oddsOutputText;
  return <div className={isVoid || isPushed ? styles.voided : ''}>
      <div className={clsx(styles.betTicketFooter, {
      [String(styles.betTicketFooterNoRounded)]: shouldShowReceipt,
      [String(styles.betTicketFooterDimmed)]: isCanceled
    })}>
        {!isInBetAddOrConfirmed && (isViewMyBetResult || isInBetPlacedView || isInBetInfoView || sportsBetStatus) && <div className={styles.fieldInfo}>
              <BetTicketText capitalize color={colors.gray300}>
                {t('totalOdds')}
              </BetTicketText>
              <BetTicketAmount title={oddsAmount} className={styles.oddsAmount}>
                {oddsAmount}
              </BetTicketAmount>
            </div>}

        <div className={styles.fieldInfo}>
          <BetTicketText capitalize color={colors.gray300}>
            {publicView ? t('totalStake') : t('lblYourStake')}
          </BetTicketText>
          <BetTicketAmount>
            <CryptoIcon currency={currency} />
            <FormattedAmount value={amount} currency={currency} />
          </BetTicketAmount>
        </div>

        <BetStakeAndPayout sportsBetStatus={sportsBetStatus} betSlipStatus={betSlipStatus} currency={currency} estimatePayoutOutput={estimatePayoutOutput} isPayoutMoreThanStakeAmount={isPayoutMoreThanStakeAmount} publicView={publicView} />

        {shouldShowEstimateAmount && betStatus !== SportsBetStatus.CASHED_OUT && sportsBetStatus !== SportsBetStatus.CASHED_OUT && <div className={styles.fieldInfo}>
              <BetTicketText capitalize color={colors.gray300}>
                {shouldShowCashedOut || isVoid ? t('txtPayout') : t('lblEstPayout')}
              </BetTicketText>
              <BetTicketAmount isPayout={BigNumber(estimatePayoutOutput).isGreaterThan(0)}>
                <CryptoIcon currency={currency} />
                <span className={shouldShowCashedOut ? styles.greenText : ''}>
                  <FormattedAmount value={estimatePayoutOutput} currency={currency} />
                </span>
              </BetTicketAmount>
            </div>}

        {(betStatus === SportsBetStatus.CASHED_OUT || sportsBetStatus === SportsBetStatus.CASHED_OUT) && <div className={styles.fieldInfo}>
            <BetTicketText capitalize color={colors.success}>
              {t('cashed_out')}
            </BetTicketText>
            <BetTicketAmount>
              <CryptoIcon currency={currency} />
              <span className={estimatePayoutOutput ? styles.greenText : ''}>
                <FormattedAmount value={cashOutInfo.amount || estimatePayoutOutput} currency={currency} />
              </span>
            </BetTicketAmount>
          </div>}

        {betShortId && <BetSlipCashOutButton bet={bet} cashOutBetSlips={cashOutBetSlips} setCashOutInfo={setCashOutInfo} setBetStatus={setBetStatus} betShortId={betShortId} isEventClosed={isEventClosed} publicView={publicView} currency={currency} bets={bets} betSlipStatus={betSlipStatus} cashoutOddsDecimal={cashoutOddsDecimal} />}
      </div>
    </div>;
}