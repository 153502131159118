type Props = {
  element: HTMLDivElement;
  padding?: {
    bottom: number;
  };
};

export function isElementInViewport({ element, padding }: Props) {
  const rect = element.getBoundingClientRect();

  const isInViewPort =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= document.documentElement.clientHeight - (padding?.bottom || 0) &&
    rect.right <= document.documentElement.clientWidth;

  return {
    isInViewPort,
    rightOffBy: rect.right - document.documentElement.clientWidth,
    bottomOffBy: rect.bottom - document.documentElement.clientHeight,
  };
}
