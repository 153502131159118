import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSportsBet } from '../../../hooks/useSportsBet';
import { useVipRewardQuery } from '../../../hooks/vip/useVipRewardQuery';
import { RightSidebarVariant, toggleBetSlipSidebar, toggleVipSidebar } from '../../../redux/slices/browserPreferenceSlice';
import { getBetViewConfig } from '../../../views/BetSlip/components/BetTicket/getBetViewConfig';
import RoundButton from '../../Buttons/RoundButton';
import { Tooltip } from '../../Tooltip';
import { ChatMenuButton } from './ChatMenuButton';
import { BetSlipStatus } from '../../../redux/slices/sportsBetSlice.type';
import styles from './RenderMenuItem.module.scss';
type Props = {
  isMenuExpanded?: boolean;
  rightSidebarVariant: RightSidebarVariant;
};
export const RenderMenuItem = ({
  isMenuExpanded,
  rightSidebarVariant
}: Props) => {
  const dispatch = useDispatch();
  const {
    t
  } = useTranslation();
  const {
    clearBetSlip
  } = useSportsBet();
  const {
    vipNotificationsCount
  } = useVipRewardQuery();
  const {
    sportsBetMaintenance,
    sportsBet: {
      betSlipStatus,
      betSlips
    }
  } = useSelector((state: AppState) => {
    return {
      sportsBet: state.sportsBet,
      sportsBetMaintenance: state.globalState.sportsBetMaintenanceShow
    };
  }, shallowEqual);
  const {
    isInBetPlacedView
  } = getBetViewConfig({
    betSlipStatus
  });
  const shouldShowBetSlip = rightSidebarVariant === RightSidebarVariant.BET_SLIP;
  const shouldShowVip = rightSidebarVariant === RightSidebarVariant.VIP;
  const toggleVipSidebarOpen = useCallback(() => {
    dispatch(toggleVipSidebar({
      open: !shouldShowVip
    }));
  }, [dispatch, shouldShowVip]);
  const toggleBetSlipSidebarOpen = useCallback(() => {
    dispatch(toggleBetSlipSidebar({
      open: !shouldShowBetSlip
    }));
    if (shouldShowBetSlip && betSlipStatus === BetSlipStatus.BET_PLACED) {
      clearBetSlip();
    }
  }, [betSlipStatus, clearBetSlip, dispatch, shouldShowBetSlip]);
  return <>
      <Tooltip content={t('txtBetSlip')} placement={isMenuExpanded ? 'right' : 'bottom'}>
        <div className={`${styles.countWrapper} ${shouldShowBetSlip ? styles.countActive : ''} `}>
          {!sportsBetMaintenance && <RoundButton onClick={toggleBetSlipSidebarOpen} color="tertiary" aria-label={shouldShowBetSlip ? 'hide bets panel' : 'show bets panel'} icon={<img src="/icons/bet-slip.svg" alt="bet slip" />} />}
          {!isInBetPlacedView && <motion.div initial={{
          opacity: 0
        }} animate={{
          opacity: betSlips.length ? 1 : 0
        }}>
              <span aria-label={`${betSlips.length || 0} bet slips`} className={styles.count}>
                {betSlips.length || 1}
              </span>
            </motion.div>}
        </div>
      </Tooltip>

      <Tooltip content={t('txtVIP')} placement={isMenuExpanded ? 'right' : 'bottom'}>
        {vipNotificationsCount > 0 && <span className={styles.iconNotification}>{vipNotificationsCount}</span>}
        <RoundButton onClick={toggleVipSidebarOpen} color={shouldShowVip ? 'primary' : 'tertiary'} icon={<img src="/icons/crown.svg" alt="crown" />} />
      </Tooltip>

      <ChatMenuButton isMenuExpanded={isMenuExpanded} rightSidebarVariant={rightSidebarVariant} />
    </>;
};