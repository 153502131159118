import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
export const useFormatExpiryText = (expiry: Date) => {
  const {
    t
  } = useTranslation();
  const expiryDate = dayjs.utc(expiry);
  const currentTime = dayjs.utc();
  const yearsToExpiry = expiryDate.diff(currentTime, 'years');
  const daysToExpiry = expiryDate.diff(currentTime, 'days');
  const hoursToExpiry = expiryDate.diff(currentTime, 'hours');
  const minutesToExpiry = expiryDate.diff(currentTime, 'minutes');
  const secondsToExpiry = expiryDate.diff(currentTime, 'seconds');
  if (yearsToExpiry >= 5) return {
    formattedExpiry: t('permanent'),
    remaining: ''
  };
  if (daysToExpiry >= 1) {
    return {
      formattedExpiry: t('dayRemaining', {
        count: daysToExpiry
      }),
      remaining: `${daysToExpiry}d`
    };
  }
  if (hoursToExpiry >= 1) {
    return {
      formattedExpiry: t('hourRemaining', {
        count: hoursToExpiry
      }),
      remaining: `${hoursToExpiry}h`
    };
  }
  if (minutesToExpiry >= 1) {
    return {
      formattedExpiry: t('minuteRemaining', {
        count: minutesToExpiry
      }),
      remaining: `${minutesToExpiry}m`
    };
  }
  if (secondsToExpiry >= 1) {
    return {
      formattedExpiry: t('secondRemaining', {
        count: secondsToExpiry
      }),
      remaining: `${secondsToExpiry}s`
    };
  }

  // should never reach here
  return {
    formattedExpiry: t('expired'),
    remaining: ''
  };
};