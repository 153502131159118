import { NextRouter } from 'next/router';

type Props<K extends 'search'> = {
  router: NextRouter;
  pathname?: string;
  routerOptions?: {
    shallow?: boolean;
    locale?: string | false;
    scroll?: boolean;
    unstable_skipClientCache?: boolean;
  };
  type: K;
  value: K extends 'search' ? 'sports' | 'casino' : never;
};

export function openGlobalOverlay<K extends 'search'>({
  router,
  type,
  value,
  pathname,
  routerOptions = { shallow: true },
}: Props<K>) {
  router
    .replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          [type]: value,
        },
      },
      pathname,
      routerOptions,
    )
    .catch();
}
