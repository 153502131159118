import { RaceType } from 'generated/graphql';

export const RaceTypeTranslationText: Record<RaceType, string> = {
  [RaceType.DAILY]: 'tabDailyRace',
  [RaceType.WEEKLY]: 'tabWeeklyRace',
  [RaceType.MONTHLY]: 'tabMonthlyRace',
};

export const RaceInfoTranslationText: Record<RaceType, string> = {
  [RaceType.DAILY]: 'raceDailyInfoHeader',
  [RaceType.WEEKLY]: 'raceInfoHeader',
  [RaceType.MONTHLY]: 'raceMonthlyInfoHeader',
};
