import * as React from 'react';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Placement, useRelativeFixPosition } from '../hooks/useRelativeFixPosition';
import { isMobile } from '../utils/userAgent';
import styles from './Tooltip.module.scss';
export type TooltipProps = {
  children: React.ReactNode | React.ReactNode[];
  content: string | React.ReactNode;
  disabled?: boolean;
  placement?: Placement;
  classNames?: Partial<{
    content: string;
    children: string;
    root: string;
  }>;
  positionDisabled?: boolean;
  enableOnMobile?: boolean;
  variant?: 'light' | 'dark';
  style?: CSSProperties;
  arrowStyle?: CSSProperties;
  'data-testid'?: string;
};
function TooltipContent(props: TooltipProps) {
  const [show, setShow] = useState(false);
  const [animate, setAnimate] = useState(false);
  const {
    placement = 'top',
    positionDisabled,
    variant = 'light',
    disabled,
    children,
    classNames,
    content,
    style,
    arrowStyle,
    enableOnMobile = false
  } = props;
  const childrenRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const {
    updatePosition,
    position,
    width
  } = useRelativeFixPosition({
    placement,
    childrenRef,
    contentRef,
    shouldListenEvents: !positionDisabled || isMobile || disabled
  });
  useEffect(() => {
    if (show) {
      updatePosition();
      setAnimate(true);
    }
  }, [show, updatePosition]);
  if (!enableOnMobile && isMobile || disabled) {
    return <>{children}</>;
  }
  return <div className={`${styles.root} ${classNames?.root || ''} tooltip`} data-testid={props['data-testid']} onMouseOver={() => {
    updatePosition();
    setShow(true);
  }} onMouseLeave={() => {
    setShow(false);
    setAnimate(false);
  }}>
      <span className={`${styles.children} ${classNames?.children || ''}`} ref={childrenRef}>
        {children}
      </span>

      {show && <div ref={contentRef} role="tooltip" className={`${styles.content} ${animate ? styles.animate : ''} ${classNames?.content ?? ''} ${placement === 'top' ? styles.topTooltip : ''} ${placement === 'left' ? styles.leftTooltip : ''} ${placement === 'bottom' ? styles.bottomTooltip : ''} ${placement === 'top-left' ? styles.topLeftMenu : ''} ${variant === 'dark' ? styles.darkTooltip : ''}`} style={positionDisabled ? style : {
      left: position.left,
      top: position.top
    }}>
          <div className={styles.contentWrapper}>
            <span className={styles.arrow} style={arrowStyle || (placement === 'top-left' ? {
          left: width ? width / 2 - 4 : '5%'
        } : {})} />
            {content}
          </div>
        </div>}
    </div>;
}
export const Tooltip = React.memo(TooltipContent);