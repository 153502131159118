import * as React from 'react';
import { useState } from 'react';
import { SpringEvent } from 'react-spring-bottom-sheet/dist/types';
import { usePreventScrolling } from '../../hooks/usePreventScrolling';
import { MobileSheet } from '../LazyLoader/MobileSheet';
type Props = {
  open: boolean;
  footer?: React.ReactNode;
  onModalClose?: () => void;
  children?: React.ReactNode;
  className?: string;
};
export const MobileModal = ({
  open,
  footer,
  onModalClose,
  children,
  className
}: Props) => {
  const [resetPosition, setResetPosition] = useState(Date.now());
  const onSpringEnd = (event: SpringEvent) => {
    if (event.type === 'CLOSE') {
      setResetPosition(Date.now());
    }
  };
  usePreventScrolling();
  return <MobileSheet className={className} key={resetPosition} open={open} footer={footer} onDismiss={onModalClose} onSpringEnd={onSpringEnd}>
      {children}
    </MobileSheet>;
};