import { RaceTypeTranslationText } from 'constants/race';
import { useGetRaceLeaderBoardV2Query } from 'generated/graphql';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { ModalTypes } from '../../constants/modal';
import { formatCompatNumber } from '../../utils/formatCompatNumber';
import { useFormatExpiryText } from '../Chat/useFormatExpiryText';
import { Loader } from '../Loader';
import { MODAL_NAME, Modal } from '../Modal/Modal';
import dynamic from 'next/dynamic';
import { ModalLoaderContent } from '../Modal/ModalLoadContent';
import { NavigationLink } from './NavigationLink';
import { NavigationLinkWrapper } from './NavigationLinkWrapper';
const RaceInfoModal = dynamic(() => import('../modals/VipRewards/RaceInfoModal'), {
  loading: ModalLoaderContent
});
import styles from './navigationWeekly.module.scss';
type Props = {
  isExpanded: boolean;
  className?: string;
  raceLeaderBoardResult: ReturnType<typeof useGetRaceLeaderBoardV2Query>;
};
export function NavigationWeekly({
  isExpanded,
  className,
  raceLeaderBoardResult
}: Props) {
  const [currentModal, setCurrentModal] = useState<ModalTypes | ''>('');
  const {
    t
  } = useTranslation();
  const data = raceLeaderBoardResult?.data;
  const loading = raceLeaderBoardResult?.loading;
  const activeRace = data?.raceLeaderBoardV2.race ?? null;
  const {
    remaining
  } = useFormatExpiryText(new Date(activeRace?.endAt ?? Date.now()));
  if (loading) return <NavigationLinkWrapper className={`${styles.root} ${isExpanded ? styles.isExpanded : ''} ${className || ''}`}>
        <Loader variant="circle" />
      </NavigationLinkWrapper>;
  if (!activeRace) {
    return null;
  }
  return <>
      <Modal onClose={() => setCurrentModal('')} isOpen={!!currentModal} target={MODAL_NAME}>
        <RaceInfoModal />
      </Modal>

      <div>
        <NavigationLinkWrapper className={`${styles.root} ${isExpanded ? styles.isExpanded : ''} ${className || ''}`}>
          <NavigationLink iconUrl="/icons/trophy.svg" onClick={() => {
          setCurrentModal(ModalTypes.RACE_INFO);
        }} disabled={isExpanded} isExpanded={isExpanded} title={`$${formatCompatNumber(activeRace.totalPrizeUsd)} ${t(RaceTypeTranslationText[activeRace.type])}`} counterVariant="text" counter={<span>{remaining}</span>} />
        </NavigationLinkWrapper>
      </div>
    </>;
}