import React from 'react';
import styles from './BetTicketAmount.module.scss';
interface BetTicketAmountProps {
  children: React.ReactNode | string;
  isPayout?: boolean;
  className?: string;
  title?: string;
}
export function BetTicketAmount({
  children,
  title,
  className,
  isPayout
}: BetTicketAmountProps) {
  return <span title={title} className={`${styles.amount} ${isPayout ? styles.amountPayout : ''} ${className || ''}`}>
      {children}
    </span>;
}