const CRASH = {
  /** The curve fills up the fill width of the graph after X milliseconds of game starting. */
  GRAPH_FILLED_AT_MS: 45000,
  ARC_PATH_COLOR_INITIAL: '#FF8F28',
  ARC_PATH_COLOR_FINAL: '#7717FF',
  GROWTH_FACTOR: 0.00006,
  BASE_MULTIPLIER: 1,
  QTY_AXIS_DIVISIONS: 5,
  GREEN_THRESHOLD: 2,
  GOLD_THRESHOLD: 10,
  MIN_STEPS_IN_PATH: 2,
  ROUND_DELAY_MS: 7000,
  THROTTLE_INTERVAL_MS: 250,
  CASHOUT_AT_MIN: 1.01,
  CASHOUT_AT_DEFAULT: 2,
  CASHOUT_AT_MAX: 1000000,
  MAX_USERNAME_LABEL_WIDTH_PX: 100,
  SLUG: 'originals/crash',
};

export default CRASH;
