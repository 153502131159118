import { useRouter } from 'next/router';

export const useSportRouteCheck = () => {
  const { pathname } = useRouter();

  const isSportPage = pathname.startsWith('/sports');
  const isSportHome = pathname === '/sports';
  const isSportEventPage = pathname === '/sports/[sport]/[category]/[tournament]/[fixture]';
  const isSportCategoryPage = pathname === '/sports/[sport]';
  const isSportLeaguePage = pathname === '/sports/[sport]/[category]';
  const isSportTournamentPage = pathname === '/sports/[sport]/[category]/[tournament]';
  const isSportTransactions = pathname.startsWith('/sports/transactions');

  return {
    isSportPage,
    isSportTransactions,
    isSportHome,
    isSportEventPage,
    isSportCategoryPage,
    isSportLeaguePage,
    isSportTournamentPage,
  };
};
