import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { updateCampaignCode } from 'redux/slices/campaignSlice';
import { setCookie } from 'utils/appCookie';
export const CAMPAIGN_QUERY_KEY = 'r';
export const CAMPAIGN_CLICKID_QUERY_KEY = 'clickID';
const CLICKID_MAX_AGE = 60 * 60 * 24 * 14; // 14 days

// This hook extracts the campaign code from the url and updates the redux store
export const useGlobalCampaignSetup = () => {
  const dispatch = useDispatch();
  useMount(() => {
    // Do not use next router because it is not available on mount
    const campaignCode = new URLSearchParams(window.location.search).get(CAMPAIGN_QUERY_KEY);
    const campaignClickId = new URLSearchParams(window.location.search).get(CAMPAIGN_CLICKID_QUERY_KEY);
    if (campaignCode) {
      dispatch(updateCampaignCode({
        code: campaignCode
      }));
    }
    if (campaignClickId) {
      setCookie(CAMPAIGN_CLICKID_QUERY_KEY, campaignClickId, CLICKID_MAX_AGE);
    }
  });
};