import cache from 'apollo/cache';
import { client } from 'apollo/clients';
import { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useGlobalCampaignSetup } from 'hooks/useGlobalCampaignSetup';
import { useSegment } from 'hooks/useSegment';
import { useSportsSubscriptions } from 'hooks/useSportsSubscriptions';
import { useSyncLocalStorage } from 'hooks/useSyncLocalStorage';
import { useUpdateDefaultFiatPreference } from 'hooks/useUpdateDefaultFiatPreference';
import { memo, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useIsLoggedIn } from '../../hooks/auth/useIsLoggedIn';
import { useContentful } from '../../hooks/useContentful';
import { usePersistStoreData } from '../../hooks/usePersistStoreData';
import { useGeoLocationCheck } from 'hooks/useGeoLocationCheck';
import { usePricesAndAppSettingPoll } from 'hooks/useGlobalPricesAndAppSettingPoll';
import { useSportsRouteCheck } from 'hooks/useSportsRouteCheck';
import useGlobalBreakpointsAndScrollPosition from '../../hooks/interface/useGlobalBreakpointsAndScrollPosition';
import { useSportsMaintenance } from '../../hooks/useSportsMaintenance';
import { LoggedInUserSetup } from './LoggedInUserSetup';
import { useSetDefaultCurrency } from './useSetDefaultCurrency';
import { useGlobalSubscriptionAlerts } from './useSubscriptionAlerts';
extend(utc);
const MINUTE = 60 * 1000;

// This has to be a component and not a hook
// because it is used in _app.tsx with the provider code
// if it was a hook it would not have access to some of the provider data
const GlobalSetup = ({
  setShowGeoRestriction
}: {
  setShowGeoRestriction: (v: boolean) => void;
}) => {
  const {
    isContentfulPreview,
    accessToken,
    browserPreference,
    isPersistStorePopulated,
    sportsMaintenance
  } = useSelector((app: AppState) => ({
    isContentfulPreview: app.globalState.isContentfulPreview,
    accessToken: app.auth.accessToken,
    browserPreference: app.browserPreference,
    isPersistStorePopulated: app.globalState.isPersistStorePopulated,
    sportsMaintenance: app.appSetting.details.sportsMaintenance
  }), shallowEqual);
  const isLoggedIn = useIsLoggedIn();
  usePersistStoreData();
  useSegment();
  useContentful({
    isContentfulPreview
  });
  useGlobalCampaignSetup();
  usePricesAndAppSettingPoll({
    browserPreference
  });
  useSetDefaultCurrency({
    browserPreference
  });
  useSyncLocalStorage({
    browserPreference,
    isPersistStorePopulated
  });
  useSportsSubscriptions();
  useUpdateDefaultFiatPreference({
    browserPreference,
    isPersistStorePopulated
  });
  useGlobalSubscriptionAlerts({
    isLoggedIn
  });
  useGlobalBreakpointsAndScrollPosition();
  useSportsMaintenance({
    sportsMaintenance
  });
  useGeoLocationCheck({
    setShowGeoRestriction
  });
  useSportsRouteCheck();
  useUpdateEffect(() => {
    // reset store so that the web socket link client reconnects with new auth token
    client.resetStore().catch();
  }, [accessToken]);
  useEffect(() => {
    // Setup cache.gc to run every minute to prevent memory leakage issues
    const interval = setInterval(() => {
      cache.gc({
        resetResultCache: true
      });
    }, MINUTE);
    return () => clearInterval(interval);
  }, []);
  return <>{isLoggedIn && <LoggedInUserSetup />}</>;
};
export default memo(GlobalSetup);