import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CryptoPrice, Currency, FiatCurrency, FiatPrice } from 'generated/graphql';

// We use price reducer for two purposes
// 1. it allows us to optimise a little bit using useSelector to select only the currency we're interested in
// 2. We need it for the CurrencyInput rate change because there is a window where when user changes their currency but does not yet have the new price
export interface PricesState {
  prices: Record<Currency, string>;
  fiatPrices: Record<FiatCurrency, string>;
  pricesFiatCurrency: FiatCurrency | null;
  pollingPaused: boolean;
}

export const initialState: PricesState = {
  prices: {
    BTC: '0',
    ETH: '0',
    USDC: '0',
    LTC: '0',
    TRX: '0',
    MATIC: '0',
    USDT: '0',
    XRP: '0',
    SOL: '0',
    BUSD: '0',
    DOGE: '0',
    BNB: '0',
    SHIB: '0',
    SHFL: '0',
    BONK: '0',
    WIF: '0',
    TON: '0',
    AVAX: '0',
  },
  fiatPrices: {
    USD: '0',
    EUR: '0',
    JPY: '0',
    IDR: '0',
    MXN: '0',
    BRL: '0',
    CAD: '0',
    CNY: '0',
    DKK: '0',
    KRW: '0',
    INR: '0',
    PHP: '0',
    TRY: '0',
    NZD: '0',
    ARS: '0',
    RUB: '0',
  },
  pricesFiatCurrency: null,
  pollingPaused: false,
};

export const PricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    updatePrices: (
      state: PricesState,
      action: PayloadAction<{
        fiatCurrency: FiatCurrency;
        prices: Array<CryptoPrice>;
        fiatPrices: Array<FiatPrice>;
      }>,
    ) => {
      // polling is paused when auto bet is running to maintain a consistent exchange rate
      if (state.pollingPaused) return;

      action.payload.prices.forEach((priceObj: CryptoPrice) => {
        state.prices[priceObj.name] = priceObj.price;
      });

      action.payload.fiatPrices.forEach((priceObj: FiatPrice) => {
        state.fiatPrices[priceObj.name] = priceObj.price;
      });

      state.pricesFiatCurrency = action.payload.fiatCurrency;
    },

    // when autobet is stopped, setPollingPaused will be called with false to resume polling
    setPollingPaused: (state: PricesState, action: PayloadAction<boolean>) => {
      state.pollingPaused = action.payload;
    },
  },
});

export const { updatePrices, setPollingPaused } = PricesSlice.actions;

export default PricesSlice.reducer;
