import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ANIMATE_SCALE_TO_UP } from '../../constants/animator';
import { NavigationLinkProps } from './NavigationLink';
import styles from './NavigationLinkCounter.module.scss';
export function NavigationLinkCounter({
  counter,
  isExpanded,
  counterVariant
}: Partial<NavigationLinkProps>) {
  return <>
      {!!counter && <motion.span initial={ANIMATE_SCALE_TO_UP.initial} animate={ANIMATE_SCALE_TO_UP.animate} className={clsx(styles.counter, {
      [String(styles.isHidden)]: !isExpanded,
      [String(styles.textCounter)]: counterVariant === 'text',
      [String(styles.greenCounter)]: counterVariant === 'green'
    })}>
          {counter}
        </motion.span>}
    </>;
}