import React, { useRef } from 'react';
import { UseIntersectionObserverProperties, useIntersectionObserver } from '../hooks/useIntersectionObserver';
import styles from './LazyComponent.module.scss';
type Props = {
  children: React.ReactNode;
  className?: string;
} & UseIntersectionObserverProperties;
const threshold = [0, 0.25, 0.5, 0.75, 1];
export const LAZY_COMPONENT_ID = 'lazy-component';
export function LazyComponent({
  children,
  className,
  callback,
  triggerOnce
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    inView
  } = useIntersectionObserver({
    ref,
    triggerOnce,
    callback,
    options: {
      threshold
    }
  });
  const inViewRef = useRef(inView);
  if (triggerOnce) {
    inViewRef.current = inView;
  } else if (!inViewRef.current && inView) {
    inViewRef.current = true;
  }
  return <section className={`${styles.root} ${className ?? ''}`} ref={ref} id={LAZY_COMPONENT_ID}>
      {inViewRef.current ? children : null}
    </section>;
}