import { useTranslation } from 'react-i18next';
import BetMessage from './BetMessage';
type Props = {
  isInBetAddView: boolean;
  isInBetPlacedView: boolean;
  isPartialPending: boolean;
  exceedMaxAllowedStakeAmount?: boolean;
  showOddsWarningMessage?: boolean;
};
export function BetTitleHeaderWarnings({
  isInBetAddView,
  exceedMaxAllowedStakeAmount,
  isInBetPlacedView,
  isPartialPending,
  showOddsWarningMessage
}: Props) {
  const {
    t
  } = useTranslation();
  let message = '';
  if (showOddsWarningMessage) {
    message = t('txtOddsChangedWarn');
  } else if (isInBetAddView && exceedMaxAllowedStakeAmount) {
    message = t('newStakeOfferTitle');
  } else if (isInBetPlacedView && isPartialPending) {
    message = t('stakeModified');
  }
  return message ? <BetMessage msgType="warning" message={message} /> : null;
}