import { motion } from 'framer-motion';
import React from 'react';
import { ANIMATE_SLIDE_UP } from '../constants/animator';
import styles from './AnimateMobileView.module.scss';
type Props = {
  children: React.ReactNode;
  start: boolean;
};
export function AnimateMobileView({
  children,
  start
}: Props) {
  return <motion.div initial={ANIMATE_SLIDE_UP.initial} animate={start ? ANIMATE_SLIDE_UP.animate : {}} transition={{
    duration: ANIMATE_SLIDE_UP.duration
  }} className={styles.animateContainer}>
      {children}
    </motion.div>;
}