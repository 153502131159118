import { sleep } from 'utils/sleep';

let counter = 0;
let loaded = false;

const MINIMUM_LOADING_TIME = 700;

const animationStartTime = new Date();

const MAX_FETCH_RETRY = 5;

const fetchWithRetry = async (uri: string, options: RequestInit) => {
  let retryCounter = 0;

  // https://www.notion.so/shufflecom/Network-interrupting-autobet-ae9c78d710204b32b67f1237b836eea6
  // Retries a few times incase of intermittent network issues
  // Infinite loop, however it will escape this if conditions are met
  while (true) {
    try {
      return await fetch(uri, options);
    } catch (e) {
      if ((e as Error).message === 'Failed to fetch' && retryCounter < MAX_FETCH_RETRY) {
        retryCounter++;
        await sleep(retryCounter * 50);
        continue;
      }

      throw e;
    }
  }
};

// Counter helps count the initial loading
export const customFetch = (uri: string, options: RequestInit) => {
  counter++;
  return fetchWithRetry(uri, options).finally(() => {
    counter--;
    if (counter === 0 && !loaded) {
      loaded = true;
      const now = new Date();

      const diff = Math.max(
        0,
        MINIMUM_LOADING_TIME - (now.getTime() - animationStartTime.getTime()),
      );

      setTimeout(() => {
        if (typeof window !== 'undefined') {
          window?.animation?.destroy();
          document.getElementById('loading-container')?.remove();
        }
      }, diff);
    }
  });
};
