import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { InputVariant } from './TextInput';
import styles from './Input.module.scss';
export type InputProps = {
  hasIcon?: boolean;
  variant?: InputVariant;
  hasError?: boolean;
  hasPrefix?: boolean;
  isValid?: boolean;
  color?: string;
  'data-testid'?: string;
  $hasRightIcon?: boolean; // icon like a copy icon to the right of the input
} & InputHTMLAttributes<HTMLInputElement>;
export const Input = React.forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
  const {
    $hasRightIcon,
    isValid,
    hasPrefix,
    variant,
    hasError,
    ...rest
  } = props;
  return <input {...rest} ref={ref} style={{
    ...props.style,
    ...(props.color ? {
      color: props.color
    } : {})
  }} data-testid={props['data-testid']} placeholder={props.placeholder} {...typeof props.value !== 'undefined' ? {
    value: props.value
  } : {}} className={`${styles.root} ${hasPrefix ? styles.hasPrefix : ''} ${variant === 'outline' ? styles.outline : ''} ${hasError ? styles.hasError : ''}  ${isValid ? styles.isValid : ''} ${props.readOnly && variant !== 'outline' ? styles.readOnly : ''} ${$hasRightIcon ? styles.hasRightIcon : ''} ${props.className ?? ''}`} />;
});