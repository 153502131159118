import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { currencyOptions } from 'constants/currency';
import { Chain, Currency, WithdrawalInput } from 'generated/graphql';

export interface WithdrawState {
  currency: Currency;
  chain: Chain | null;
  amount: string | null;
  receiverAddress: string | null;
  receiverAddressTag: string | null;
  fee: string | null;

  /**
   * Has the 2FA or OTP code been sent?
   */
  has2FaSent: boolean;

  /**
   * Timestamp at which the withdrawal was initiated
   */
  initiatedAt: number | null;
}

const initialCurrency = currencyOptions[0]?.value as Currency;

export const initialState: WithdrawState = {
  currency: initialCurrency,
  chain: null,
  amount: null,
  receiverAddress: null,
  receiverAddressTag: null,
  fee: null,
  initiatedAt: null,
  has2FaSent: false,
};

const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    /**
     * Called whenever we are setting up a new withdrawal by clicking the
     * `Withdraw` button
     */
    newWithdrawal: (state: WithdrawState, action: PayloadAction<WithdrawalInput>) => {
      state.amount = action.payload.amount;
      state.currency = action.payload.currency;
      state.receiverAddress = action.payload.receiverAddress;
      state.chain = action.payload.chain;
      state.fee = action.payload.fee;

      // Empty string should be set to null
      if (typeof action.payload.receiverAddressTag !== 'undefined') {
        state.receiverAddressTag = action.payload.receiverAddressTag;
      }
    },
    resetWithdrawal: () => {
      return initialState;
    },
  },
});

export const { newWithdrawal, resetWithdrawal } = withdrawSlice.actions;

export default withdrawSlice.reducer;
