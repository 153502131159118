import dayjs from 'dayjs';

import { isEmpty } from 'lodash';
import { isProduction } from '../../../../constants/environment';
import {
  SportsBetStatus,
  SportsMarketProvider,
  SportsMarketSelectionStatus,
  SportsMarketStatus,
} from '../../../../generated/graphql';
import { BetSlipItem, BetSlipStatus } from '../../../../redux/slices/sportsBetSlice.type';

type Props = {
  bets: BetSlipItem[];
  betSlipStatus: BetSlipStatus;
  isOwner: boolean;
};

const legId = (leg: BetSlipItem) => leg.id;

const getCashOutStatus = ({ marketStatus, status, canCashOut }: BetSlipItem) => {
  if (canCashOut) {
    return SportsMarketStatus.OPEN;
  }

  if (
    marketStatus === SportsMarketStatus.SUSPENDED ||
    (marketStatus === SportsMarketStatus.OPEN && status === SportsMarketSelectionStatus.SUSPENDED)
  ) {
    return SportsMarketStatus.SUSPENDED;
  }

  if (
    marketStatus === SportsMarketStatus.CLOSED ||
    status === SportsMarketSelectionStatus.RESULTED ||
    status === SportsMarketSelectionStatus.UNPRICED
  ) {
    return SportsMarketStatus.CLOSED;
  }

  return SportsMarketStatus.CLOSED;
};

export function getBetSlipsCashoutVariant({
  bets,
  betSlipStatus,
  isOwner,
}: Props): 'hide' | 'suspended' | 'enabled' | 'disabled' | 'invalid' {
  const [sportsBet, ...sportsBetSelections] = bets;

  const closedLegs = bets.filter(bet => {
    return !bet.inPlay && bet.marketStatus === SportsMarketStatus.CLOSED;
  });

  const marketSuspendedLegs = bets.filter(bet => {
    return !bet.inPlay && bet.marketStatus === SportsMarketStatus.SUSPENDED;
  });

  const hasPrimaryBetWithoutPendingOrPartialPending =
    sportsBet?.sportsBetStatus &&
    ![SportsBetStatus.PENDING, SportsBetStatus.PARTIAL_PENDING].includes(
      sportsBet.sportsBetStatus as SportsBetStatus,
    );

  const lostLegs = sportsBetSelections.filter(
    ({ sportsBetStatus }) => sportsBetStatus === SportsBetStatus.LOST,
  );

  const invalidBetProviderLegs = bets.filter(
    ({ provider }) => provider && provider === SportsMarketProvider.SHUFFLE,
  );

  const cashedOutLegs = bets.filter(bet => bet.sportsBetStatus === SportsBetStatus.CASHED_OUT);

  const tradingStatusLegs = bets.filter(
    ({ status }) => status === SportsMarketSelectionStatus.TRADING,
  );

  const containsUnavailableSelection = !bets.every(({ status, marketStatus }) => {
    return (
      status === SportsMarketSelectionStatus.RESULTED ||
      (status === SportsMarketSelectionStatus.TRADING &&
        marketStatus !== SportsMarketStatus.SUSPENDED)
    );
  });

  const hasSuspendedLegs =
    bets.some(bet => getCashOutStatus(bet) === 'SUSPENDED') || marketSuspendedLegs.length > 0;

  const shouldHideButtonByBetSlipStatus = [
    BetSlipStatus.ADDING_BET,
    BetSlipStatus.CONFIRMING_BET,
    BetSlipStatus.BET_PLACING,
    BetSlipStatus.BET_PLACED,
  ].includes(betSlipStatus);

  if (isProduction) {
    // biome-ignore lint/suspicious/noConsoleLog: sentry debug
    console.log({
      lostLegs: lostLegs.map(legId),
      closedLegs: closedLegs.map(legId),
      invalidBetProviderLegs: invalidBetProviderLegs.map(legId),
      tradingStatusLegs: tradingStatusLegs.map(legId),
      unavailableSelectionLegs: containsUnavailableSelection
        ? bets.filter(
            bet =>
              bet.status === SportsMarketSelectionStatus.TRADING ||
              bet.status === SportsMarketSelectionStatus.RESULTED,
          )
        : [],
    });
  }

  if (hasPrimaryBetWithoutPendingOrPartialPending) {
    if (isProduction) {
      // biome-ignore lint/suspicious/noConsoleLog: sentry debug
      console.log({
        reason: `bet has status ${sportsBet?.sportsBetStatus}`,
        state: 'hide',
      });
    }
    return 'hide';
  }

  if (shouldHideButtonByBetSlipStatus || !isOwner) {
    if (isProduction) {
      // biome-ignore lint/suspicious/noConsoleLog: sentry debug
      console.log({
        reason: `not slip owner or invalid betSlip status, status: ${betSlipStatus}}`,
        state: 'hide',
      });
    }
    return 'hide';
  }

  if (cashedOutLegs.length > 0) {
    if (isProduction) {
      // biome-ignore lint/suspicious/noConsoleLog: sentry debug
      console.log({
        reason: 'has cashOut legs',
        state: 'disabled',
      });
    }

    return 'disabled';
  }

  for (const bet of bets) {
    if (
      bet.status === SportsMarketSelectionStatus.TRADING &&
      bet.inPlay &&
      bet.cashOutStatus &&
      bet.cashOutStatus !== SportsMarketStatus.OPEN
    ) {
      if (isProduction) {
        // biome-ignore lint/suspicious/noConsoleLog: sentry debug
        console.log({
          reason: `inPlay leg ${bet.id} has cashOutStatus ${bet.cashOutStatus}`,
          state: 'suspended',
        });
      }
      return 'suspended';
    }

    if (bet.marketStatus === SportsMarketStatus.SUSPENDED) {
      if (isProduction) {
        // biome-ignore lint/suspicious/noConsoleLog: sentry debug
        console.log({
          reason: `has market suspended ${bet.id}`,
          state: 'suspended',
        });
      }
      return 'suspended';
    }
  }

  if (hasSuspendedLegs) {
    if (isProduction) {
      // biome-ignore lint/suspicious/noConsoleLog: sentry debug
      console.log({
        reason: 'has suspended legs',
        state: 'suspended',
      });
    }
    return 'suspended';
  }

  if (
    !isEmpty(lostLegs) ||
    !isEmpty(closedLegs) ||
    !isEmpty(invalidBetProviderLegs) ||
    isEmpty(tradingStatusLegs) ||
    containsUnavailableSelection
  ) {
    if (isProduction) {
      // biome-ignore lint/suspicious/noConsoleLog: sentry debug
      console.log({
        reason: 'invalid leg status',
        state: 'hide',
      });
    }
    return 'hide';
  }

  for (const bet of bets) {
    if (bet.provider === SportsMarketProvider.GENIUS) {
      if (bet.status === SportsMarketSelectionStatus.TRADING) {
        if (!bet.inPlay && dayjs().isAfter(bet.marketExpiryTime)) {
          if (isProduction) {
            // biome-ignore lint/suspicious/noConsoleLog: sentry debug
            console.log({
              reason: `prematch leg ${bet.id} has past expiry`,
              state: 'hide',
            });
          }

          return 'hide';
        }

        if (bet.inPlay) {
          if (!bet.cashOutEnabled) {
            if (isProduction) {
              console.log({
                // biome-ignore lint/suspicious/noConsoleLog: sentry debug
                reason: `inPlay leg ${bet.id} has cashOut disabled`,
                state: 'hide',
              });
            }
            return 'hide';
          }
        }
      }
    }
  }

  return 'enabled';
}
