import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Router, { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { Loader } from '../components/Loader';
import { ANIMATE_BOTTOM_TO_UP_SHORT } from '../constants/animator';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { setPageLoadingConfig } from '../redux/slices/globalStateSlice';
import styles from './PageLoader.module.scss';
type Props = {
  children: React.ReactNode;
};
const ROUTE_LOADER_EXCLUSION = ['/transactions', '/settings', '/blog'];
const MANUAL_LOADER_EXCLUSION = ['/sports/[sport]/[category]/[tournament]/[fixture]'];
export const LOADER_TIMEOUT = 4000;
export const DELAY_RENDER = 100;
export const DELAY_LOADER_APPEAR = 300;
export const LOADED_PAGES = new Set();
const shouldExcludeRoute = (path: string) => ROUTE_LOADER_EXCLUSION.find(route => path.startsWith(route));
const shouldManualLoadRoute = (path: string) => MANUAL_LOADER_EXCLUSION.find(route => path === route);
export function PageLoader({
  children
}: Props) {
  const {
    asPath,
    pathname
  } = useRouter();
  const {
    scrollToTop
  } = useScrollToTop();
  const {
    t
  } = useTranslation();
  const dispatch = useDispatch();
  const {
    isPageLoading,
    hideViewport
  } = useSelector((app: AppState) => ({
    isPageLoading: app.globalState.isPageLoading,
    hideViewport: app.globalState.hideViewport
  }), shallowEqual);
  const loading = useRef(isPageLoading);
  const pathnameRef = useRef(pathname);
  const timer = useRef(0);
  const loaderTimer = useRef(0);
  loading.current = isPageLoading;
  pathnameRef.current = pathname;
  useEffect(() => {
    const start = (path: string, {
      shallow
    }: {
      shallow: boolean;
    }) => {
      if (shallow) {
        return;
      }
      if (!shouldExcludeRoute(path) && !LOADED_PAGES.has(path)) {
        window.scrollTo(0, 0);
        scrollToTop();
        dispatch(setPageLoadingConfig({
          isPageLoading: true,
          hideViewport: true
        }));
        clearTimeout(timer.current);
        timer.current = window.setTimeout(() => {
          if (loading.current) {
            dispatch(setPageLoadingConfig({
              isPageLoading: false,
              hideViewport: false
            }));
          }
        }, LOADER_TIMEOUT);
      }
      if (LOADED_PAGES.has(path)) {
        scrollToTop();
        dispatch(setPageLoadingConfig({
          isPageLoading: false,
          hideViewport: true
        }));
        clearTimeout(loaderTimer.current);
        loaderTimer.current = window.setTimeout(() => {
          dispatch(setPageLoadingConfig({
            isPageLoading: true,
            hideViewport: true
          }));
        }, DELAY_LOADER_APPEAR);
      }
    };
    const end = (path: string, {
      shallow
    }: {
      shallow: boolean;
    }) => {
      if (shallow) {
        return;
      }
      clearTimeout(loaderTimer.current);
      if (!shouldManualLoadRoute(pathnameRef.current) && !shouldExcludeRoute(path)) {
        setTimeout(() => {
          dispatch(setPageLoadingConfig({
            isPageLoading: false,
            hideViewport: false
          }));
        }, DELAY_RENDER);
      }
      if (LOADED_PAGES.has(path)) {
        setTimeout(() => {
          dispatch(setPageLoadingConfig({
            isPageLoading: false,
            hideViewport: false
          }));
        }, DELAY_RENDER);
      }
      LOADED_PAGES.add(path);
    };
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, [dispatch, scrollToTop]);
  useMount(() => {
    if (!shouldManualLoadRoute(pathnameRef.current)) {
      dispatch(setPageLoadingConfig({
        isPageLoading: false,
        hideViewport: false
      }));
    }
  });
  if (shouldExcludeRoute(asPath)) {
    return <>{children}</>;
  }
  return <>
      {isPageLoading && <div className={styles.loader}>
          <Loader className={styles.loaderIcon} />
          <p className={styles.loadingText}>{t('btnLoading').replace('...', '')}</p>
        </div>}

      <div className={styles.container}>
        <motion.div initial={ANIMATE_BOTTOM_TO_UP_SHORT.initial} animate={hideViewport ? {} : ANIMATE_BOTTOM_TO_UP_SHORT.animate} transition={hideViewport ? {
        ...ANIMATE_BOTTOM_TO_UP_SHORT.ease,
        duration: 0
      } : ANIMATE_BOTTOM_TO_UP_SHORT.ease}>
          {children}
        </motion.div>
      </div>
    </>;
}