import { useTranslation } from 'next-i18next';
import { CSSProperties, useMemo } from 'react';
import { CRYPTO_DECIMALS } from '../constants/decimalPlaces';
import { Currency } from '../generated/graphql';
import { Placement } from '../hooks/useRelativeFixPosition';
import { Tooltip } from './Tooltip';
import styles from './FiatWithTooltip.module.scss';
type Props = {
  value: string;
  currency: Currency;
  formattedCrypto?: string;
  dataTestId?: string;
  placement?: Placement;
  tooltipPosition?: CSSProperties;
  classNames?: Partial<{
    text: string;
  }>;
};
export const FiatWithTooltip = ({
  value,
  currency,
  formattedCrypto,
  dataTestId,
  placement,
  tooltipPosition
}: Props) => {
  const {
    t
  } = useTranslation();
  return <Tooltip placement={placement ?? 'bottom'} content={useMemo(() => `${t('intlNumber', {
    val: Number(value) ?? 0,
    minimumFractionDigits: CRYPTO_DECIMALS,
    maximumFractionDigits: CRYPTO_DECIMALS
  })} ${currency}`, [value, t, currency])} positionDisabled={!!tooltipPosition} style={tooltipPosition}>
      <span className={`${styles.evenlySpacedNumber} fiat-with-tool-tip-text`} data-testid={dataTestId}>
        {formattedCrypto}
      </span>
    </Tooltip>;
};