import { FiatCurrency } from 'generated/graphql';
import React from 'react';
import { Fiat } from '../Fiat';

/**
 * Icon for a fiat currency value.
 * NOTE. For plaintext symbol, please see `/utils/currency.ts:fiatSymbol`
 */
const FiatIcon = ({
  currency
}: {
  currency: FiatCurrency;
}) => {
  const Icon = Fiat[currency];
  if (!Icon) return null;
  return <Icon />;
};
export default React.memo(FiatIcon);