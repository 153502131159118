import uniqBy from 'lodash/uniqBy';
import { BetSlipStatus } from '../redux/slices/sportsBetSlice.type';
import { ENABLED_BET_SLIP_STATUS_OPTIONS } from './sportsSubscription';
import { FixtureOnScreen } from './sportsSubscription.type';

export function sportsSubscriptionMapFixtureIds({
  selectionsOnScreen,
  betSlipsOnScreen,
  modalBetSlipsOnScreen,
  betSlipStatus,
  myBetSlipsOnScreen,
}: {
  selectionsOnScreen: FixtureOnScreen[];
  modalBetSlipsOnScreen: FixtureOnScreen[];
  betSlipsOnScreen: FixtureOnScreen[];
  myBetSlipsOnScreen: FixtureOnScreen[];
  betSlipStatus?: BetSlipStatus;
}) {
  return uniqBy(
    [
      ...selectionsOnScreen,
      ...modalBetSlipsOnScreen,
      ...myBetSlipsOnScreen,
      ...(betSlipStatus && !ENABLED_BET_SLIP_STATUS_OPTIONS.includes(betSlipStatus)
        ? []
        : betSlipsOnScreen
      ).map(fixtureData => {
        return {
          id: fixtureData.id,
        };
      }),
    ],
    data => data.id,
  );
}
