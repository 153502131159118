import { Currency } from 'generated/graphql';
import React from 'react';
import { CURRENCY_CONFIGS } from 'constants/currency';
import styles from './CryptoIcon.module.scss';
const CryptoIcon = ({
  currency
}: {
  currency: Currency;
}) => {
  const iconSrc = CURRENCY_CONFIGS[currency].icon;
  return <div className={styles.root}>
      <img className={styles.image} src={`/icons/crypto/${iconSrc}`} alt={currency} loading="lazy" width={16} height={16} />
    </div>;
};
export default React.memo(CryptoIcon);