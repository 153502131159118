import { useBalanceUpdates } from 'hooks/wallet/useBalanceUpdates';
import { windowId } from '../../apollo/utils';
import { useBalanceUpdatedSubscription } from '../../generated/graphql';
import { useGlobalEmailVerified } from '../../hooks/useGlobalEmailVerified';
import { useGlobalUpdateVipLevel } from '../../hooks/useGlobalUpdateVipLevel';
import { useMyProfile } from '../../hooks/useMyProfile';
import { useViewDataSetup } from '../../hooks/useViewDataSetup';
import { useVipRewards } from '../../hooks/vip/useVipRewards';
import { useGlobalUpdateLanguage } from './useGlobalUpdateLanguage';
import { useUserTracking } from './useUserTracking';
export function LoggedInUserSetup() {
  useMyProfile();
  useVipRewards();
  useGlobalUpdateVipLevel();
  useUserTracking();
  useGlobalEmailVerified();
  useGlobalUpdateLanguage();
  useViewDataSetup();
  const {
    updateBalance
  } = useBalanceUpdates();
  useBalanceUpdatedSubscription({
    onData: ({
      data
    }) => {
      const newBalance = data.data?.balanceUpdated;
      const ignoreSubscription = newBalance?.windowId && newBalance?.windowId === windowId;
      if (newBalance && !ignoreSubscription) {
        updateBalance(newBalance);
      }
    }
  });
  return null;
}