import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OriginalGame } from 'generated/graphql';

export type PercentageInputMode = 'reset' | 'increaseBy';

export type AutoBetFormProps = {
  onWinMode: PercentageInputMode;
  onLossMode: PercentageInputMode;
  onWinPercentage: string;
  onLossPercentage: string;
  // These are the sources of truth for the auto bet PnL
  stopOnProfit: string;
  stopOnLoss: string;
};

export type AutoBetGameBetCount = {
  game: OriginalGame;
  betCount: string;
};

export type AutoBetGameFormData = {
  game: OriginalGame;
  formData: AutoBetFormProps;
};

export const defaultAutoBetFormValues: AutoBetFormProps = {
  onWinMode: 'reset',
  onLossMode: 'reset',
  onWinPercentage: '0.00',
  onLossPercentage: '0.00',
  stopOnProfit: '0.00',
  stopOnLoss: '0.00',
};

export interface AutobetState {
  totalPnL: number;
  hasPendingBet: boolean;
  gameAutoBetState: {
    [game in OriginalGame]: {
      betCount: string;
      formData: AutoBetFormProps;
    };
  };
}

const initialState: AutobetState = {
  totalPnL: 0,
  hasPendingBet: false,
  gameAutoBetState: {
    [OriginalGame.DICE]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.CRASH]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.WHEEL]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.LIMBO]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.MINES]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.PLINKO]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.KENO]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.ROULETTE]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.HILO]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.BLACKJACK]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
    [OriginalGame.TOWER]: {
      betCount: '0',
      formData: defaultAutoBetFormValues,
    },
  },
};

const originalGameSlice = createSlice({
  name: 'autobet',
  initialState,
  reducers: {
    setAutoBetFormData: (state: AutobetState, action: PayloadAction<AutoBetGameFormData>) => {
      const { game, formData } = action.payload;
      state.gameAutoBetState[game].formData = formData;
    },

    updateBetCount: (state: AutobetState, action: PayloadAction<AutoBetGameBetCount>) => {
      const { game, betCount } = action.payload;
      state.gameAutoBetState[game].betCount = betCount;
    },

    updatePendingBet: (state: AutobetState, action: PayloadAction<boolean>) => {
      state.hasPendingBet = action.payload;
    },

    // call it whenever the user leaves the original game page
    resetGame: state => {
      return {
        ...initialState,
        gameAutoBetState: state.gameAutoBetState, // keep the game auto bet state, only reset on refresh
      };
    },
  },
});

export const { setAutoBetFormData, resetGame, updateBetCount, updatePendingBet } =
  originalGameSlice.actions;

export default originalGameSlice.reducer;
