import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import DesktopNavigation from 'components/Navigation/DesktopNavigation';
import MobileNavigation from 'components/Navigation/MobileNavigation';
import usePreference from 'hooks/usePreference';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RightSidebarVariant, setRightSidebar } from 'redux/slices/browserPreferenceSlice';
import { client, clientWithoutAuth } from '../apollo/clients';
import { LiveChat } from '../components/LiveChat';
import { Loader, MIN_LOADER_CONTAINER_HEIGHT } from '../components/Loader';
import { MobileNavigationBar } from '../components/Navigation/MobileNavigationBar';
import { useGetChallengeCountQuery, useGetRaceLeaderBoardV2Query } from '../generated/graphql';
import { useIsLoggedIn } from '../hooks/auth/useIsLoggedIn';
import { useContainerClassName } from '../hooks/useContainerClassName';
import useGlobalState from '../hooks/useGlobalState';
import { isMobile } from '../utils/userAgent';
import { AnimateMobileView } from './AnimateMobileView';
import { AnimationPhase, CasinoLayoutProps } from './CasinoLayout.type';
import { PAGE_CONTENT_ID } from './constants';
import styles from './CasinoLayout.module.scss';
const BetSlipSideBar = dynamic(() => import('views/BetSlip/BetSlipSideBar'), {
  loading: () => <Loader variant="circle" containerHeight={MIN_LOADER_CONTAINER_HEIGHT} />
});
const VipSidebar = dynamic(() => import('views/Vip/VipSidebar'), {
  loading: () => <Loader variant="circle" containerHeight={MIN_LOADER_CONTAINER_HEIGHT} />
});
const ChatBox = dynamic(() => import('components/Chat/ChatBox'), {
  loading: () => <Loader variant="circle" containerHeight={MIN_LOADER_CONTAINER_HEIGHT} />
});
const CasinoLayout = ({
  children
}: CasinoLayoutProps) => {
  const [animationPhase, setAnimationPhase] = useState<AnimationPhase>('idle');
  const pageContentRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = useIsLoggedIn();
  const router = useRouter();
  const {
    rightSidebarVariant
  } = usePreference();
  const {
    activeMobileNav
  } = useGlobalState();
  const dispatch = useDispatch();
  const {
    className
  } = useContainerClassName();
  const isPersistStorePopulated = useSelector((app: AppState) => app.globalState.isPersistStorePopulated);
  const challengeCountResult = useGetChallengeCountQuery({
    client: isLoggedIn ? client : clientWithoutAuth,
    fetchPolicy: 'cache-and-network'
  });
  const raceLeaderBoardResult = useGetRaceLeaderBoardV2Query({
    client: clientWithoutAuth
  });
  const urlWithoutParam = router.asPath.split('?')[0];
  const onAnimationComplete = () => {
    setAnimationPhase('end');
  };
  const onAnimationStart = () => {
    setAnimationPhase('start');
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: dep
  useEffect(() => {
    if (pageContentRef.current) {
      pageContentRef.current.scrollTo(0, 0);
    }
  }, [urlWithoutParam]);
  useEffect(() => {
    if (isMobile && isPersistStorePopulated) {
      dispatch(setRightSidebar({
        variant: RightSidebarVariant.NONE
      }));
    }
  }, [dispatch, isPersistStorePopulated]);
  return <div className={styles.root}>
      <DesktopNavigation raceLeaderBoardResult={raceLeaderBoardResult} challengeCountData={challengeCountResult.data} />

      <main className={`${styles.pageContentWrapper} ${className}`}>
        <Header animationPhase={animationPhase} />

        <div id={PAGE_CONTENT_ID} ref={pageContentRef} className={styles.pageContent}>
          <div className={styles.mainContent}>{children}</div>

          <Footer />
        </div>

        <LiveChat />

        <MobileNavigationBar activeMobileNav={activeMobileNav} challengeCountData={challengeCountResult.data} raceLeaderBoardResult={raceLeaderBoardResult} />

        <MobileNavigation />
      </main>

      <aside className={`${styles.rightSide} ${rightSidebarVariant === RightSidebarVariant.NONE ? styles.disableMobileView : styles.showRightSide}`} onAnimationStart={rightSidebarVariant === RightSidebarVariant.NONE ? onAnimationStart : undefined} onAnimationEnd={onAnimationComplete}>
        {rightSidebarVariant === RightSidebarVariant.BET_SLIP && <AnimateMobileView start={rightSidebarVariant === RightSidebarVariant.BET_SLIP}>
            <BetSlipSideBar />
          </AnimateMobileView>}

        {rightSidebarVariant === RightSidebarVariant.CHAT && <AnimateMobileView start={rightSidebarVariant === RightSidebarVariant.CHAT}>
            <ChatBox animationPhase={animationPhase} className={rightSidebarVariant === RightSidebarVariant.CHAT ? '' : styles.hide} />
          </AnimateMobileView>}

        {rightSidebarVariant === RightSidebarVariant.VIP && <VipSidebar />}
      </aside>
    </div>;
};
export default React.memo(CasinoLayout);