import React from 'react';
import styles from './Icon.module.scss';
export const Icon = ({
  children,
  className,
  onClick
}: {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}) => <span onClick={onClick} className={`${styles.root} ${className ?? ''}`}>
    {children}
  </span>;