import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { i18n } from 'next-i18next';

export enum AlertsEnum {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export interface AlertType {
  id: string;
  message?: string;
  messages?: string[];
  type: AlertsEnum;
}

export interface AlertsState {
  alerts: AlertType[];
}

const initialState: AlertsState = {
  alerts: [],
};

export interface AddAlertType {
  // without id as that's added automatically
  message?: string;
  messages?: string[];
  duration?: number;
  type: AlertsEnum;
}

export interface RemoveAlertType {
  id: string;
}

const t = (name: string) => i18n?.t?.(name) ?? '';

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    // adds alerts to the end of the alerts array so new alerts appear below older alerts
    // when the timeout expires, the alert is removed from the front of the array
    addAlert: (state: AlertsState, action: PayloadAction<AddAlertType>) => {
      const AutoBetEndMessage = t('autoBetEnded');
      const AutoBetStartMessage = t('autoBetStarted');

      if (action.payload.message) {
        const hasIncludeAutoBetMessage = [AutoBetEndMessage, AutoBetStartMessage].includes(
          action.payload.message,
        );
        state.alerts = [
          ...state.alerts.filter(alert => {
            if (hasIncludeAutoBetMessage && alert.message) {
              return ![AutoBetEndMessage, AutoBetStartMessage].includes(alert.message);
            }

            return alert.message !== action.payload.message;
          }),
          {
            ...action.payload,
            id: Math.random().toString(),
          },
        ];
      }

      if (action.payload.messages && !!action.payload.messages.length) {
        state.alerts = [
          ...state.alerts,
          {
            type: action.payload.type,
            messages: action.payload.messages,
            id: Math.random().toString(),
          },
        ];
      }
    },
    // allow the user to remove an alert by clicking close
    removeAlert: (state: AlertsState, action: PayloadAction<RemoveAlertType>) => {
      state.alerts = state.alerts.filter(alert => alert.id !== action.payload.id);
    },
  },
});

export const { addAlert, removeAlert } = alertsSlice.actions;

export default alertsSlice.reducer;
