import { motion } from 'framer-motion';
import React from 'react';
import colors from '../../assets/styles/colors';
import styles from './MobileNavItemContent.module.scss';
export const MobileNavItemContent = ({
  name,
  Icon,
  active,
  disabled,
  indicatorNumber,
  navButtonRef
}: {
  name: string;
  Icon?: React.ReactNode;
  indicatorNumber?: number;
  active?: boolean;
  disabled?: boolean;
  navButtonRef: React.MutableRefObject<HTMLDivElement | null>;
}) => <div className={styles.mobileNavItemWrapper} style={{
  color: active ? colors.primaryViolet : colors.white,
  opacity: disabled ? 0.5 : 1
}} ref={navButtonRef}>
    <span className={`${styles.mobileNavIcon} ${active ? styles.active : ''}`}>
      {Icon}

      {!!indicatorNumber && <motion.span initial={{
      opacity: 0
    }} animate={{
      opacity: indicatorNumber ? 1 : 0
    }}>
          <span className={styles.count}>{indicatorNumber || 1}</span>
        </motion.span>}
    </span>

    <span className={styles.mobileNavText}>{name}</span>
  </div>;