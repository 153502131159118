import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RightSidebarVariant } from 'redux/slices/browserPreferenceSlice';

import { useStore } from 'hooks/useStore';
import { Breakpoint, defaultBreakpoints } from './useWindowWidth';

export const SIDEBAR_WIDTH_EXPANDED_PX = 240;
export const SIDEBAR_WIDTH_DEFAULT_PX = 65;
export const RIGHT_SIDEBAR_WIDTH_PX = 360;
const DEBOUNCE_TIME_OUT = 100;

const mediumBreakpoint = defaultBreakpoints[Breakpoint.MD];
const largeBreakpoint = defaultBreakpoints[Breakpoint.LG];

/**
 * Handles dynamic breakpoints given internal width of content, taking
 * sidebar open and close states into account.
 * @note To be called ONLY from `_app.tsx`
 */
const useGlobalBreakpointsAndScrollPosition = () => {
  const { isLeftSidebarExpanded, rightSidebarVariant } = useSelector(
    (state: AppState) => ({
      isLeftSidebarExpanded: state.browserPreference.isLeftSidebarExpanded,
      rightSidebarVariant: state.browserPreference.rightSidebarVariant,
    }),
    shallowEqual,
  );

  const { setState } = useStore();

  const updateSizeInfo = useCallback(
    (width: number) => {
      const rightSidebarOpen = rightSidebarVariant !== RightSidebarVariant.NONE;

      // We increase the breakpoints when sidebars are open by the pixel value
      // of their combines open widths; ensuring that the internal content's
      // breakpoints remain consistent.
      let additionalWidth = 0;

      if (width >= mediumBreakpoint && rightSidebarOpen) {
        additionalWidth = additionalWidth + RIGHT_SIDEBAR_WIDTH_PX;
      }

      if (width >= largeBreakpoint && isLeftSidebarExpanded) {
        additionalWidth = additionalWidth + SIDEBAR_WIDTH_EXPANDED_PX - SIDEBAR_WIDTH_DEFAULT_PX;
      }

      setState('contentWidth', () => width - additionalWidth);
      setState('width', () => width);
    },
    [isLeftSidebarExpanded, rightSidebarVariant, setState],
  );

  useEffect(() => {
    let timeout = 0;
    const updateWindowSize = () => {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        updateSizeInfo(window.innerWidth);
      }, DEBOUNCE_TIME_OUT);
    };

    updateWindowSize();

    window.addEventListener('resize', updateWindowSize);

    return () => window.removeEventListener('resize', updateWindowSize);
  }, [updateSizeInfo]);

  useEffect(() => {
    let timeout = 0;
    const updatePosition = () => {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        setState('scrollTop', () => document.documentElement.scrollTop);
      }, DEBOUNCE_TIME_OUT);
    };

    document.addEventListener('scroll', updatePosition);

    return () => document.removeEventListener('scroll', updatePosition);
  }, [setState]);
};

export default useGlobalBreakpointsAndScrollPosition;
