import { ApolloProvider } from '@apollo/client';
import Alerts from 'components/Alerts/Alerts';
import GlobalSetup from 'components/GlobalSetup/GlobalSetup';
import Banners from 'components/InfoBanner/Banners';
import { isNodeProduction, isProduction } from 'constants/environment';
import scripts from 'constants/scripts';
import Layout from 'layouts/Layout';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { client as apolloClient } from '../apollo/clients';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { GlobalModal } from '../components/Modal/GlobalModal';
import { RenderClient } from '../components/RenderClient';
import { PopupsLazy } from '../components/popupsLazy';
import store from '../redux/store';
import { SPLASH_ID } from './_document';
import '../assets/fonts/fonts.css';
import '../assets/styles/captcha.css';
import '../components/ErrorBoundary.css';
import '../styles/colors.css';
import '../styles/dragable.css';
import '../styles/genius.css';
import '../styles/global.scss';
import '../styles/mobileSheet.css';
import '../styles/splash.css';
import '../styles/variables.css';
import '../styles/zIndexes.css';
import { ModalLoaderContent } from 'components/Modal/ModalLoadContent';
import { SportsDevTool } from '../components/SportsDevTool';
import('../utils/sentry-integrations');
const Search = dynamic(() => import('../components/Search/Search').then(mod => mod.Search));
const GeoRestrictionModal = dynamic(() => import('components/modals/GeoRestriction/GeoRestrictionModal'), {
  loading: ModalLoaderContent
});
export let globalContext = {
  cxd: ''
};
if (isProduction && typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY && process.env.NEXT_PUBLIC_POSTHOG_HOST) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageleave: true,
    capture_pageview: true,
    loaded: _posthog => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
        email: true
      }
    },
    autocapture: {
      url_allowlist: ['^(?!.*\\/games\\/originals).*$']
    }
  });
}
function ShuffleApp({
  Component,
  pageProps
}: AppProps) {
  const [isMounted, setIsMounted] = useState(false);
  const router = useRouter();
  const globalSearch = {
    type: (router.query.search as 'sports' | 'casino'),
    isOpen: router.query.search === 'casino' || router.query.search === 'sports'
  };
  const [showGeoRestriction, setShowGeoRestriction] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    const splash = document.getElementById(SPLASH_ID);
    if (splash) {
      splash.style.display = 'none';
    }
    if (isNodeProduction) {
      // biome-ignore lint/suspicious/noConsoleLog: log
      console.log('%cSTOP', 'color:red; font-size: 50px');
      // biome-ignore lint/suspicious/noConsoleLog: log
      console.log('This is a developer feature, please beware that any activity here may cause you to lose your account.');
    }
  }, []);
  useEffect(() => {
    if (router.isReady && router.query.cxd && router.query.affid) {
      globalContext = {
        cxd: String(router.query.cxd)
      };
    }
  }, [router.isReady, router.query.cxd, router.query.affid]);
  useEffect(() => {
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  return <ErrorBoundary>
      <Script strategy="afterInteractive" id="segment" dangerouslySetInnerHTML={{
      __html: scripts.segment
    }} />
      <Script src="/geetest.js" strategy="afterInteractive" />
      <Script src="https://135e6de2-7372-4c94-8491-cf709e7f9b92.snippet.antillephone.com/apg-seal.js" strategy="afterInteractive" />
      <Script src="https://69fb7e94-6ecd-4b46-83a2-24bac03464d0.seals-xcm.certria.com/xcm-seal.js" strategy="afterInteractive" />
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-WS1L4WVMG4" strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WS1L4WVMG4');
            `}
      </Script>

      <div style={{
      transition: '0.3s opacity',
      opacity: isMounted ? 1 : 0
    }}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        </Head>
        <PostHogProvider client={posthog}>
          <ReduxProvider store={store}>
            <SportsDevTool />

            <ApolloProvider client={apolloClient}>
              <Layout casinoLayout={pageProps.casinoLayout} activityBoard={pageProps.activityBoard} sports={pageProps.sports}>
                <Component {...pageProps} />
              </Layout>

              <GlobalSetup setShowGeoRestriction={setShowGeoRestriction} />

              <RenderClient>
                <Banners />
                {showGeoRestriction && <GeoRestrictionModal setShowGeoRestriction={setShowGeoRestriction} />}
                <GlobalModal />
                <Alerts />
                <PopupsLazy />
              </RenderClient>

              {globalSearch.isOpen && <Search type={globalSearch.type} />}
            </ApolloProvider>
          </ReduxProvider>
        </PostHogProvider>
      </div>
    </ErrorBoundary>;
}
export default appWithTranslation(ShuffleApp);