import { ChatRoom, Language } from 'generated/graphql';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setChatRoom } from '../redux/slices/browserPreferenceSlice';
import { useLanguagePreference } from './useLanguagePreference';
import { useSportRouteCheck } from './useSportRouteCheck';

const LANGUAGE_TO_CHATROOM_MAP: Record<Language, ChatRoom | null> = {
  [Language.EN]: ChatRoom.ENGLISH,
  [Language.FR]: ChatRoom.FRENCH,
  [Language.ZH]: null,
  [Language.ES]: ChatRoom.SPANISH,
  [Language.PT]: null,
  [Language.KO]: null,
  [Language.JA]: ChatRoom.JAPANESE,
  [Language.DE]: ChatRoom.GERMAN,
  [Language.TR]: ChatRoom.TURKISH,
  [Language.HU]: null,
  [Language.RU]: null,
};

export function useSportsRouteCheck() {
  const dispatch = useDispatch();
  const { isSportPage, isSportTransactions } = useSportRouteCheck();
  const { languagePreference } = useLanguagePreference();

  useEffect(() => {
    if (!isSportTransactions && isSportPage) {
      dispatch(setChatRoom({ room: ChatRoom.SPORTS }));
    } else {
      dispatch(
        setChatRoom({ room: LANGUAGE_TO_CHATROOM_MAP[languagePreference] ?? ChatRoom.ENGLISH }),
      );
    }
  }, [dispatch, isSportPage, isSportTransactions, languagePreference]);

  return;
}
