import { useTranslation } from 'next-i18next';
import colors from '../../../../assets/styles/colors';
import Odds from '../../../../components/Odds';
import { SportsMarketProvider, SportsMarketSelectionStatus, SportsMarketStatus, SportsMatchState } from '../../../../generated/graphql';
import { BetSlipItem } from '../../../../redux/slices/sportsBetSlice.type';
import { BetSlipProps } from '../BetsGroup.type';
import styles from './BetGameEventInfo.module.scss';
import { BetGameEventInfoTextLink } from './BetGameEventInfoTextLink';
import { BetTicketText } from './BetTicketText';
import { getBetViewConfig } from './getBetViewConfig';
type Props = {
  hasMarketNotOpenInBetAdd: boolean;
  name: string;
  provider: string;
  showOddsWarningMessage: boolean;
  hasPlacedBetError?: boolean;
  shouldShowLiveEventInfo: boolean;
  shouldHideDate: boolean;
  time?: string;
  text?: string;
  isEventClosed: boolean;
  startTimeColor: string;
  startTimeForDisplay: string;
  liveMatchProgressTextContent: string;
  bet: BetSlipItem;
  matchState?: SportsMatchState;
  viewConfig: ReturnType<typeof getBetViewConfig>;
  minutesRemaining: number;
  shouldDisplayPieTimer: boolean;
} & Pick<BetSlipProps, 'odds' | 'matchStates' | 'actionPanelData' | 'errors'>;
export const BetGameEventInfoLink = ({
  actionPanelData,
  startTimeForDisplay,
  startTimeColor,
  isEventClosed,
  viewConfig,
  viewConfig: {
    isInBetAddView,
    shouldDisplayMarketStatus,
    isInBetPlacedView,
    isInBetPlacingView,
    isInBetPlacedViewError,
    isVoid,
    isCanceled,
    isPushed
  },
  liveMatchProgressTextContent,
  bet,
  odds,
  shouldHideDate,
  errors,
  shouldShowLiveEventInfo,
  hasMarketNotOpenInBetAdd,
  name,
  provider,
  showOddsWarningMessage,
  shouldDisplayPieTimer,
  minutesRemaining,
  time,
  text,
  matchState
}: Props) => {
  const {
    t
  } = useTranslation();
  const isMarketOpenAndTrading = shouldDisplayMarketStatus && !isEventClosed && bet.marketStatus === SportsMarketStatus.OPEN && bet.status === SportsMarketSelectionStatus.TRADING;
  const isSelectionResulted = bet.marketStatus === SportsMarketStatus.OPEN && bet.status === SportsMarketSelectionStatus.RESULTED;
  const isMarketClosedOrExpired = shouldDisplayMarketStatus && (isEventClosed || bet.marketStatus === SportsMarketStatus.CLOSED || isSelectionResulted);
  const isSelectionSuspendedOrUnPriced = bet.marketStatus === SportsMarketStatus.OPEN && (bet.status === SportsMarketSelectionStatus.SUSPENDED || bet.status === SportsMarketSelectionStatus.UNPRICED);
  const hasPlacedBetError = isInBetPlacedView && errors?.placedBetError;
  const isMarketSuspended = shouldDisplayMarketStatus && !isEventClosed && (bet.marketStatus === SportsMarketStatus.SUSPENDED || isSelectionSuspendedOrUnPriced);
  const isBetPlacedError = errors?.placedBetErrorIds?.includes(bet.id);
  const shouldDisplayOddsInBetPlacedErrorView = isBetPlacedError && isMarketOpenAndTrading;
  return <div className={styles.betTicketMarketBlock}>
      <div className={styles.selectionField}>
        <BetTicketText dimmed={hasMarketNotOpenInBetAdd} bold color={colors.primaryViolet} wrap capitalize={provider === SportsMarketProvider.ODDIN}>
          {name}
        </BetTicketText>

        {isInBetAddView && isMarketOpenAndTrading && <BetTicketText bold>
            <Odds autoHideIndicator={!showOddsWarningMessage} showColorOddsChanges showOddsChanges={isInBetAddView || isInBetPlacingView} oddsNumerator={bet.oddsNumerator} oddsDenominator={bet.oddsDenominator} totalOddsDecimal={bet.totalOddsDecimal} oddsDisplayPosition="right" />
          </BetTicketText>}

        {!isInBetAddView && !isInBetPlacedViewError && odds?.outputText && <BetTicketText bold>
            <Odds autoHideIndicator={!showOddsWarningMessage} showColorOddsChanges showOddsChanges={!isInBetPlacedView} oddsNumerator={bet.oddsNumerator} oddsDenominator={bet.oddsDenominator} totalOddsDecimal={bet.totalOddsDecimal} oddsDisplayPosition="right" />
          </BetTicketText>}

        {isInBetPlacedViewError && shouldDisplayOddsInBetPlacedErrorView && <BetTicketText bold>
            <Odds autoHideIndicator={!showOddsWarningMessage} showColorOddsChanges showOddsChanges oddsNumerator={bet.oddsNumerator} oddsDenominator={bet.oddsDenominator} totalOddsDecimal={bet.totalOddsDecimal} oddsDisplayPosition="right" />
          </BetTicketText>}

        {(isInBetAddView || isInBetPlacingView || isInBetPlacedViewError) && <>
            {isMarketSuspended && <BetTicketText color={colors.warning} bold>
                {t('txtSuspended')}
              </BetTicketText>}

            {isMarketClosedOrExpired && <BetTicketText color={colors.error} bold>
                {t('txtClosed')}
              </BetTicketText>}
          </>}
      </div>

      {bet.marketName && <div className={isVoid || isPushed ? styles.voided : ''}>
          <BetTicketText dimmed={hasMarketNotOpenInBetAdd || hasPlacedBetError} wrap color={isCanceled ? colors.gray400 : colors.gray300}>
            {bet.marketName}
          </BetTicketText>
        </div>}

      <div className={isVoid || isPushed ? styles.voided : ''}>
        <BetGameEventInfoTextLink isEventClosed={isEventClosed} shouldShowLiveEventInfo={shouldShowLiveEventInfo} viewConfig={viewConfig} matchState={matchState} actionPanelData={actionPanelData} shouldHideDate={shouldHideDate} hasMarketNotOpenInBetAdd={hasMarketNotOpenInBetAdd} startTimeForDisplay={startTimeForDisplay} startTimeColor={startTimeColor} shouldDisplayPieTimer={shouldDisplayPieTimer} minutesRemaining={minutesRemaining} time={time} text={text} bet={bet} liveMatchProgressTextContent={liveMatchProgressTextContent} />
      </div>
    </div>;
};