import { clientWithoutAuth } from 'apollo/clients';

import { useGetUserProfileQuery } from '../generated/graphql';

export const useProfile = (username?: string) => {
  const { data, loading, error } = useGetUserProfileQuery({
    skip: !username,
    variables: { username: username ?? '' },
    client: clientWithoutAuth,
  });

  return {
    profile: data?.user,
    loading,
    error,
  };
};
