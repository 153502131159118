import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { LazyComponent } from '../components/LazyComponent';
import { Loader } from '../components/Loader';
import CasinoLayout from './CasinoLayout';
import { PageLoader } from './PageLoader';
import styles from './Layout.module.scss';
const ActivityBoard = dynamic(() => import('views/ActivityBoard/ActivityBoard'), {
  loading: () => <Loader variant="circle" />,
  ssr: false
});
interface Props {
  children: React.ReactNode;
  casinoLayout?: boolean;
  activityBoard?: boolean;
  sports?: boolean;
}
const Layout = ({
  children,
  casinoLayout,
  activityBoard,
  sports
}: Props) => {
  const [shouldSkipRender, setShouldSkipRender] = useState(false);
  const [flushTable, setFlushTable] = useState(false);
  if (casinoLayout) {
    return <CasinoLayout>
        <PageLoader>
          {children}

          {activityBoard && <LazyComponent callback={({
          inView,
          entries
        }) => {
          entries?.forEach(entry => {
            if (entry.intersectionRatio <= 1 && entry.boundingClientRect.top < 0) {
              setFlushTable(true);
            } else {
              setFlushTable(false);
            }
          });
          setShouldSkipRender(!inView);
        }} triggerOnce={false} className={styles.table}>
              <ActivityBoard flushTable={flushTable} shouldSkipRender={shouldSkipRender} type={sports ? 'sports' : 'casino'} />
            </LazyComponent>}
        </PageLoader>
      </CasinoLayout>;
  }
  return <>{children}</>;
};
export default React.memo(Layout);