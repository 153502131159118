import { configureStore } from '@reduxjs/toolkit';
import { isProduction } from '../constants/environment';
import { persistStorageMiddleware } from './persistStorageMiddleware';
import rootReducer from './reducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(persistStorageMiddleware),
  devTools: !isProduction,
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
