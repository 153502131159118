import { useStore } from 'hooks/useStore';
import React, { useState } from 'react';
import { handleStreamResponse } from '../../../../components/handleStreamResponse';
import { useIsLoggedIn } from '../../../../hooks/auth/useIsLoggedIn';
import { useCheckVideoStream } from '../../../../hooks/useCheckVideoStream';
import { usePopup } from '../../../../hooks/usePopup';
import { PopupType } from '../../../../hooks/usePopup.type';
import isString from '../../../../utils/isString';
import styles from './BetSlipGameEventInfoActionPanel.module.scss';
import { getBetSlipActionPanelData } from './getBetSlipActionPanelData';
export type BetSlipGameEventInfoActionPanelType = {
  link: string;
  text?: string;
  content: string;
  name: string;
  time?: string;
  actionPanelSource: ReturnType<typeof getBetSlipActionPanelData>[number];
};
export const BetSlipGameEventInfoActionPanel = ({
  link,
  text,
  content,
  time,
  name,
  actionPanelSource
}: BetSlipGameEventInfoActionPanelType) => {
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const {
    updatePopup,
    removePopup,
    addPopup
  } = usePopup({
    slug: actionPanelSource.id
  });
  const {
    checkStream
  } = useCheckVideoStream({
    id: actionPanelSource.id,
    competitionName: actionPanelSource.competitionName,
    type: isString(actionPanelSource.provider) ? (`video-${actionPanelSource.provider.toLowerCase() ?? ''}` as PopupType) : ''
  });
  const {
    state: isStatsOpen
  } = useStore(state => actionPanelSource.liveWidgetUrl && !!state.popups[`${actionPanelSource.id}-widget`]);
  const {
    state: isPlaying
  } = useStore(state => !!state.popups[actionPanelSource.id]?.open);
  const onClickSportsWidget = (_e: React.MouseEvent<HTMLButtonElement>) => {
    if (actionPanelSource.liveWidgetUrl && isStatsOpen) {
      removePopup(`${actionPanelSource.id}-widget`);
    } else {
      addPopup({
        type: 'sports-widget',
        slug: `${actionPanelSource.id}-widget`,
        widgetUrl: actionPanelSource.liveWidgetUrl || '',
        title: name,
        footerLink: link
      });
    }
  };
  const onClickVideoPopup = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const type = (`video-${actionPanelSource.provider?.toLowerCase() ?? ''}` as PopupType);
    if (!isString(actionPanelSource.provider)) {
      return;
    }
    if (isPlaying) {
      removePopup(actionPanelSource.id);
      return;
    }
    if (!isLoggedIn) {
      return addPopup({
        type,
        competitionName: actionPanelSource.competitionName,
        slug: actionPanelSource.id,
        open: true,
        footerText: name,
        footerLink: link,
        error: 'auth'
      });
    }
    try {
      setLoading(true);
      addPopup({
        competitionName: actionPanelSource.competitionName,
        type,
        slug: actionPanelSource.id,
        open: true,
        footerText: name,
        footerLink: link,
        title: text ?? content,
        timeTitle: time
      });
      const streamResult = await checkStream();
      handleStreamResponse({
        slug: actionPanelSource.id,
        streamResult,
        updatePopup,
        type
      });
      updatePopup({
        type,
        slug: actionPanelSource.id,
        videoStreamSource: streamResult.videoStreamSource
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return <>
      {actionPanelSource.streamInfo?.isAvailable && <button type={'button'} onClick={onClickVideoPopup} className={`${styles.button} ${isPlaying ? styles.default : ''}`}>
          <img src={`/icons/play${isPlaying ? 'ing' : ''}.svg`} alt="play" />
        </button>}

      {actionPanelSource.liveWidgetUrl && <button type={'button'} onClick={onClickSportsWidget} disabled={loading} className={`${styles.button} ${isStatsOpen ? styles.default : ''}`}>
          <img src={`/icons/sports-stats${isStatsOpen ? '-open' : ''}.svg`} alt="stats" />
        </button>}
    </>;
};