import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import styles from './AnimateScoreNumber.module.scss';
type Props = {
  children?: string | number | null;
  className?: string;
};
const ANIMATION_DURATION = 1400;
export const AnimateScoreNumber = (props: Props) => {
  const [animate, setAnimate] = useState(false);
  const [value, setValue] = useState(props.children);
  useUpdateEffect(() => {
    setValue(props.children);
    setAnimate(true);
    const timer = setTimeout(() => {
      setAnimate(false);
      clearTimeout(timer);
    }, ANIMATION_DURATION);
    return () => clearTimeout(timer);
  }, [props.children]);
  if (!value) {
    return null;
  }
  return <span className={`${styles.root} ${props.className || ''} ${animate ? styles.show : ''}`}>
      {value}
    </span>;
};