import { setUser } from '@sentry/browser';
import { isProduction } from 'constants/environment';
import { useEffect } from 'react';

import { useStore } from 'hooks/useStore';
import versionFile from '../../../public/version.json';
import { BROWSER_ID, CORRELATION_USER_ID } from '../../apollo/utils';

export const useUserTracking = () => {
  const { state: profile } = useStore(state => ({
    id: state.profile.id,
    username: state.profile.username,
  }));

  useEffect(() => {
    try {
      if (isProduction) {
        const browserId = localStorage.getItem(BROWSER_ID);

        setUser({
          id: profile?.id || undefined,
          username: profile?.username || undefined,
          browserId,
          feVersion: versionFile.version,
        });
      }
    } catch (e) {
      console.error(`Failed to update user ${(e as Error)?.message || ''}`);
    }
  }, [profile.id, profile.username]);

  useEffect(() => {
    if (profile?.id) {
      localStorage.setItem(CORRELATION_USER_ID, profile?.id);
    }
  }, [profile?.id]);
};
