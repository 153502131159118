export function formatCompatNumber(number: number) {
  if (number < 1000) {
    return number.toString();
  }
  if (number >= 1000 && number < 1_000_000) {
    return `${(number / 1000).toFixed(0)}K`;
  }
  if (number >= 1_000_000 && number < 1_000_000_000) {
    return `${(number / 1_000_000).toFixed(0)}M`;
  }
  if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return `${(number / 1_000_000_000).toFixed(0)}B`;
  }
  if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return `${(number / 1_000_000_000_000).toFixed(0)}T`;
  }
}
