import Avatar from 'components/Avatar';
import useAvatar from 'hooks/useAvatar';
import { memo, useState } from 'react';
import { AnimationPhase } from '../../../layouts/CasinoLayout.type';
import { ExpandMenuElement } from './ExpandMenuElement';
import styles from './UserMenu.module.scss';
const UserMenu = ({
  animationPhase
}: {
  animationPhase?: AnimationPhase;
}) => {
  const avatar = useAvatar();
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  return <div className={styles.root}>
      <button className={`${styles.userMenuWrapper} ${animationPhase === 'start' ? styles.disable : ''}`}>
        <Avatar face={avatar?.face} background={avatar?.background} />
      </button>

      <ExpandMenuElement show={openProfileMenu} avatar={avatar} onClick={value => setOpenProfileMenu(value)} />
    </div>;
};
export default memo(UserMenu);