import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import { useTranslation } from 'next-i18next';
import { INTERCOM_WIDGET_CLASSNAME } from '../../constants/intercom';
import { GetChallengeCountQuery, useGetRaceLeaderBoardV2Query } from '../../generated/graphql';
import { useRouter } from 'next/router';
import { NavDivider } from './NavDivider';
import { NavList } from './NavList';
import { NavigationLink } from './NavigationLink';
import { NavigationProfile } from './NavigationProfile';
import { NavigationWeekly } from './NavigationWeekly';
import { CASINO_CATEGORIES } from './menu';
type Props = {
  isExpanded: boolean;
  challengeCountData?: GetChallengeCountQuery;
  raceLeaderBoardResult: ReturnType<typeof useGetRaceLeaderBoardV2Query>;
};
export const CasinoTab = ({
  isExpanded,
  challengeCountData,
  raceLeaderBoardResult
}: Props) => {
  const {
    t
  } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const {
    query
  } = useRouter();
  const preview = query.cms === 'preview';
  const blogHomeUrl = `/blog${preview ? '?cms=preview' : ''}`;
  const challengesCount = challengeCountData?.challenges?.totalCount || 0;
  return <>
      <NavList isExpanded={isExpanded}>
        <NavigationLink path="/" title={t('navHome')} iconUrl="/icons/home.svg" isExpanded={isExpanded} />
        <NavigationLink path="/favourites" title={t('navFavourites')} iconUrl="/icons/star.svg" isExpanded={isExpanded} disabled={!isLoggedIn} />
        <NavigationLink path="/casino/categories/latest-releases" title={t('latestReleases')} iconUrl="/icons/latest-releases.svg" isExpanded={isExpanded} />

        <NavigationLink path="/challenges" title={t('navChallenges')} iconUrl="/icons/challenge.svg" isExpanded={isExpanded} counter={challengesCount} />
        <NavigationLink path="/promotion" title={t('footer.promotions.text')} iconUrl="/icons/promotion.svg" isExpanded={isExpanded} disabled />

        <NavigationWeekly isExpanded={isExpanded} raceLeaderBoardResult={raceLeaderBoardResult} />

        {CASINO_CATEGORIES.map(({
        icon,
        iconUrl,
        ...item
      }, i) => item.isPrivate && !isLoggedIn ? null : <NavigationLink path={item.href} title={t(item.name)} icon={icon} iconUrl={iconUrl} key={i} isExpanded={isExpanded} />)}

        {isLoggedIn ? <NavigationProfile isExpanded={isExpanded} /> : <NavDivider isExpanded={isExpanded} />}

        <NavigationLink path="/vip-program" title="VIP" iconUrl="/icons/vip.svg" isExpanded={isExpanded} />

        <NavigationLink path={blogHomeUrl} title={t('Blog')} iconUrl="/icons/blog.svg" isExpanded={isExpanded} />

        <NavigationLink path={isLoggedIn ? '/affiliate/overview' : '/affiliate'} title={t('affiliate')} iconUrl="/icons/affiliate.svg" isExpanded={isExpanded} />

        <NavigationLink title={t('footer.liveChat.text')} iconUrl="/icons/live-support.svg" isExpanded={isExpanded} onClick={() => {}} className={INTERCOM_WIDGET_CLASSNAME} />
      </NavList>
    </>;
};
export default CasinoTab;