import { BetSlipItem } from '../../../../redux/slices/sportsBetSlice.type';
import { getBetViewConfig } from './getBetViewConfig';

export function findNextStartBet(bets: BetSlipItem[]) {
  const sortedBets = [...bets].sort((a, b) => {
    return new Date(a.startTime) < new Date(b.startTime) ? -1 : 1;
  });

  const findIndex = sortedBets.findIndex(
    bet =>
      !bet.isEventLive &&
      !bet.inPlay &&
      !getBetViewConfig({
        sportsBetSelectionStatus: bet.sportsBetSelectionStatus,
      }).isResulted,
  );

  return {
    findIndex,
    bet: sortedBets[findIndex],
  };
}
