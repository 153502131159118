import { useCheckVideoStream } from '../hooks/useCheckVideoStream';
import { PopupReturns, PopupType } from '../hooks/usePopup.type';

type Props = {
  streamResult: Awaited<ReturnType<ReturnType<typeof useCheckVideoStream>['checkStream']>>;
  slug: string;
  updatePopup: PopupReturns['updatePopup'];
  type: PopupType;
};

export function handleStreamResponse({ streamResult, slug, updatePopup, type }: Props) {
  if (streamResult?.videoStreamSource) {
    updatePopup({
      slug,
      videoStreamSource: streamResult.videoStreamSource,
      type,
      error: '',
    });
    return;
  }

  if (type === 'video-genius' && streamResult?.errors && Array.isArray(streamResult?.errors)) {
    const regionError = streamResult.errors.find(
      error => error?.message?.includes('1001') || error?.message?.includes('1002'),
    );
    const interruptedError = streamResult.errors.find(
      error => error?.message?.includes('1003') || error?.message?.includes('1004'),
    );

    if (regionError || interruptedError) {
      return updatePopup({
        slug,
        error: regionError ? 'region' : 'interrupted',
        type,
      });
    }
    return updatePopup({
      slug,
      error: 'generic',
      type,
    });
  }

  if (streamResult?.error) {
    updatePopup({
      slug,
      error: 'generic',
      type,
    });

    return;
  }
}
