import { LabelHTMLAttributes, ReactNode } from 'react';
import styles from './Label.module.scss';
interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  $float?: 'left' | 'right';
  $fontWeight?: string;
  $color?: string;
  children: ReactNode;
  className?: string;
}
export const Label = ({
  $float,
  $color,
  $fontWeight,
  children,
  className,
  ...rest
}: LabelProps) => <label style={{
  ...($fontWeight ? {
    fontWeight: $fontWeight
  } : {}),
  ...($color ? {
    color: $color
  } : {}),
  ...($float && $float === 'right' ? {
    justifyContent: 'flex-end'
  } : {})
}} className={`${styles.root} ${className ?? ''}`} {...rest}>
    {children}
  </label>;