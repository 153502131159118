import { sportsClient } from 'apollo/clients';
import { Sports, useGetSportsQuery } from 'generated/graphql';
import { useTranslation } from 'next-i18next';

import { SPORTS_CONFIG_OBJECT } from 'constants/SPORTS_CONFIG';
import { useMemo } from 'react';
import { useLanguagePreference } from './useLanguagePreference';

export function useSportsData() {
  const { languagePreference } = useLanguagePreference();
  const { t } = useTranslation();
  const { data, loading } = useGetSportsQuery({
    client: sportsClient,
    fetchPolicy: 'cache-and-network',
    variables: { language: languagePreference },
  });

  const getSportsName = (sports: Sports) => {
    const findSports = data?.sports.find(item => item.sports === sports);
    const sportsName = findSports?.name || t(sports);
    return findSports?.nameTranslation || sportsName;
  };

  const getSportsWeight = (sports: Sports) =>
    data?.sports.find(item => item.sports === sports)?.weight || 0;

  const sportsRecord = useMemo(() => {
    if (!data) return {};
    const sportsRecords: Partial<
      Record<
        Sports,
        (typeof data.sports)[number] & {
          name: string;
          counter: number;
          id: Sports;
        }
      >
    > = {};

    for (const sport of data.sports) {
      const sportConfig = SPORTS_CONFIG_OBJECT?.[sport.sports];

      if (sportConfig) {
        sportsRecords[sport.sports] = {
          ...sportConfig,
          ...sport,
          name: sport.nameTranslation || sport.name,
          counter: 0,
        };
      }
    }

    return sportsRecords;
  }, [data]);

  return {
    sportsData: data ? data.sports : [],
    sportsRecord,
    loading,
    getSportsName,
    getSportsWeight,
  };
}
