import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { AlertsEnum, addAlert } from '../redux/slices/alertsSlice';

import useGeoRestriction from './useGeoRestriction';

const WARNING_THROTTLE = 1000;

export function useGeoLocationCheck({
  setShowGeoRestriction,
}: {
  setShowGeoRestriction: (v: boolean) => void;
}) {
  const { t } = useTranslation();
  const { isGeoRestricted, countryName, geoRestrictionWarningPopupCounter } = useGeoRestriction();
  // Do not move this into useGeoRestriction hook, this is a global event
  const lastCounter = useRef<number>(geoRestrictionWarningPopupCounter);
  const useLastPopupTime = useRef<number>(0);
  const hasShownGeoRestriction = useRef(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isGeoRestricted && !hasShownGeoRestriction.current) {
      setShowGeoRestriction(true);
      hasShownGeoRestriction.current = true;
    }
  }, [isGeoRestricted, setShowGeoRestriction]);

  useEffect(() => {
    if (lastCounter.current !== geoRestrictionWarningPopupCounter) {
      const now = Date.now();
      if (now > useLastPopupTime.current + WARNING_THROTTLE) {
        dispatch(
          addAlert({
            message: t('txtPlatformRestrictionAlert', { country: countryName }),
            type: AlertsEnum.Error,
          }),
        );
        useLastPopupTime.current = now;
      }
    }
    lastCounter.current = geoRestrictionWarningPopupCounter;
  }, [geoRestrictionWarningPopupCounter, countryName, dispatch, t]);
}
