import colors from 'assets/styles/colors';
import clsx from 'clsx';
import React from 'react';
import styles from './BetTicketText.module.scss';
interface BetTicketTextProps {
  children: React.ReactNode | string;
  truncateText?: boolean;
  color?: string;
  rightAlign?: boolean;
  bold?: boolean;
  capitalize?: boolean;
  noCapitalize?: boolean;
  textDecoration?: 'underline' | 'none';
  wrap?: boolean;
  className?: string;
  dimmed?: boolean;
}
export function BetTicketText({
  children,
  truncateText = false,
  color = colors.white,
  rightAlign = false,
  bold = false,
  capitalize = false,
  noCapitalize = false,
  textDecoration = 'none',
  wrap = false,
  className,
  dimmed = false
}: BetTicketTextProps) {
  return <p className={clsx(className, styles.baseBetTicketText, {
    [String(styles.textTruncate)]: truncateText,
    [String(styles.textAlignRight)]: rightAlign,
    [String(styles.textBold)]: bold,
    [String(styles.textCapitalize)]: capitalize,
    [String(styles.textNoCapitalize)]: noCapitalize,
    [String(styles.textWrap)]: wrap,
    [String(styles.textUnderline)]: textDecoration === 'underline',
    [String(styles.dimmed)]: dimmed
  })} style={{
    color
  }}>
      {children}
    </p>;
}