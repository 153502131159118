import { Sports } from 'generated/graphql';

export const LIMIT_POPULAR_COMPETITIONS_SPORTS = 1;

export const POPULAR_SPORTS_LIST = Object.values(Sports);

export const DEFAULT_NUMBER_OF_COMPETITIONS = 3;
export const DEFAULT_NUMBER_OF_FIXTURES = 3;
export const LIMIT_COMPETITION_FOR_TOURNAMENT = 30;
export const BET_LIVE_NUMBER_OF_FIXTURES = 10;
export const BET_LIVE_NUMBER_OF_COMPETITIONS = 10;

export const UPCOMING_HOURS_48 = 48;
export const DEFAULT_UPCOMING_HOURS = UPCOMING_HOURS_48;

export enum SPORT_PAGE_TABS {
  FEATURES = 'featured',
  UPCOMING = 'upcoming',
  BET_LIVE = 'bet-live',
  FAVOURITES = 'favourites',
  MY_BETS = 'my-bets',
  ALL = 'all',
}

export const SIDE_BAR_WIDTH = 360;
export const BET_FOOTER_HEIGHT = 150;
