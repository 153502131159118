import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useStore } from '../hooks/useStore';
import { updateSportsDevtool } from '../redux/slices/globalStateSlice';
import { Visible } from './visible';
import { socketStatus, sportsWebSocketCorrelationId } from '../apollo/links';
import { getSubscribedFixtureIds } from '../hooks/useSportsSubscriptions';
import styles from './SportsDevToolContent.module.scss';
export enum DEV_TOOL_IDS {
  sub = 'sub',
  'sub-ids' = 'sub-ids',
  'fixture-id' = 'fixture-id',
  socket = 'socket',
}
export default function SportsDevToolContent() {
  const [view, setView] = useState<'fixtureId' | 'data'>('fixtureId');
  const currentData = getSubscribedFixtureIds();
  const {
    state: sportsFixture
  } = useStore(state => state.sportsFixture);
  const {
    register,
    watch
  } = useForm({
    defaultValues: {
      filter: ''
    }
  });
  const dispatch = useDispatch();
  const {
    filter
  } = watch();
  return <div className={styles.root}>
      <ul className={styles.control}>
        <li>
          <button disabled={view === 'fixtureId'} onClick={() => setView('fixtureId')}>
            Ids
          </button>
        </li>
        <li>
          <button disabled={view === 'data'} onClick={() => setView('data')}>
            Data
          </button>
        </li>
        <li className={styles.status}>
          <b id={DEV_TOOL_IDS.socket}>{socketStatus}</b>
        </li>
        <li>
          <button className={styles.close} onClick={() => {
          dispatch(updateSportsDevtool({
            enabled: false
          }));
        }}>
            Close
          </button>
        </li>
      </ul>

      <Visible show={view === 'fixtureId'}>
        <div className={styles.scrollContent} id={DEV_TOOL_IDS.sub}>
          <p>
            <b>Correlation ID:</b>
            <br />
            {sportsWebSocketCorrelationId}
          </p>
          <p>
            <b>Event page fixture id:</b> <span id={DEV_TOOL_IDS['fixture-id']} />
          </p>
          <pre id={DEV_TOOL_IDS['sub-ids']}>{JSON.stringify(currentData, null, 2)}</pre>
        </div>
      </Visible>

      <Visible show={view === 'data'}>
        <div className={styles.filter}>
          <div>
            <input {...register('filter')} placeholder={'Filter fixture id'} />
          </div>
          <p>
            Count: <b>{Object.keys(sportsFixture).length}</b>
          </p>
        </div>

        <div className={styles.scrollContent}>
          {Object.entries(sportsFixture).filter(([key]) => {
          return filter === '' || key === filter;
        }).map(([key, value]) => {
          return <span key={key}>
                  <p>ID: {key}</p>
                  <pre>{JSON.stringify(value, null, 2)}</pre>
                </span>;
        })}
        </div>
      </Visible>
    </div>;
}