import React from 'react';
import { Currency } from '../../../../generated/graphql';
import { BetSlipProps } from '../BetsGroup.type';
import { MultiBetLeg } from './MultiBetLeg';
import styles from './MultipleBetLegs.module.scss';
type Props = Pick<BetSlipProps, 'betIds' | 'errors' | 'betSlipStatus' | 'bets' | 'actions' | 'matchStates' | 'showOddsWarningMessage' | 'actionPanelData'> & {
  shouldShowReceipt: boolean;
  currency: Currency;
  setCollapse: React.Dispatch<React.SetStateAction<boolean[]>>;
  collapses: boolean[];
  highlightLegIndex: number;
};
export function MultipleBetLegs({
  shouldShowReceipt,
  bets,
  setCollapse,
  collapses,
  errors,
  betSlipStatus,
  betIds,
  currency,
  actions,
  showOddsWarningMessage,
  matchStates,
  highlightLegIndex,
  actionPanelData
}: Props) {
  return <div className={`${styles.root} ${shouldShowReceipt ? '' : styles.extraPaddingBottom}`}>
      {bets.map((bet, index) => {
      return <MultiBetLeg key={bet.id + index} errors={errors} actionPanelData={actionPanelData} currency={currency} actions={actions} betSlipStatus={betSlipStatus} betIds={betIds} bet={bet} totalBetLegsCount={bets.length} index={index} highlight={highlightLegIndex === index} collapse={collapses[index]} setCollapses={setCollapse} showOddsWarningMessage={showOddsWarningMessage} matchStates={matchStates} marketSelectionResultStatus={bet.marketSelectionResultStatus} />;
    })}
    </div>;
}