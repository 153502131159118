import { useStore } from 'hooks/useStore';

export enum Breakpoint {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
}

export const defaultBreakpoints: Record<Breakpoint, number> = {
  [Breakpoint.XS]: 600,
  [Breakpoint.SM]: 768,
  [Breakpoint.MD]: 992,
  [Breakpoint.LG]: 1140,
  [Breakpoint.XL]: 1500,
};

export const lessThanBreakpoint = ({
  breakpoint,
  offset = 0,
  width,
}: {
  breakpoint: Breakpoint;
  offset?: number;
  width?: number;
}) => {
  if (typeof width !== 'undefined') {
    return width < defaultBreakpoints[breakpoint] + offset;
  }

  if (typeof window !== 'undefined') {
    return window.innerWidth < defaultBreakpoints[breakpoint] + offset;
  }

  return false;
};

export const greaterThanBreakpoint = ({
  breakpoint,
  width,
  offset = 0,
}: {
  width?: number;
  breakpoint: Breakpoint;
  offset?: number;
}) => {
  if (typeof width !== 'undefined') {
    return width > defaultBreakpoints[breakpoint] + offset;
  }

  if (typeof window !== 'undefined') {
    return window.innerWidth < defaultBreakpoints[breakpoint] + offset;
  }

  return false;
};

export const getBreakpoint = (width: number) => {
  let breakpoint = Breakpoint.XS;
  if (width > defaultBreakpoints[Breakpoint.LG]) {
    breakpoint = Breakpoint.XL;
  } else if (width >= defaultBreakpoints[Breakpoint.MD]) {
    breakpoint = Breakpoint.LG;
  } else if (width >= defaultBreakpoints[Breakpoint.SM]) {
    breakpoint = Breakpoint.MD;
  } else if (width >= defaultBreakpoints[Breakpoint.XS]) {
    breakpoint = Breakpoint.SM;
  }

  return breakpoint;
};

/**
 * Tracks the breakpoints of the window.
 * Pass in `target = 'inner'` to get the width INSIDE the CasinoLayout.
 *
 * Use this ONLY when CSS media queries can't do the job.
 * This is necessary in some situations because the sidebars reduce the
 * width of the layout and while the screen might be large, the content
 * might be squished.
 */
const useWindowWidth = () => {
  const { state: width } = useStore(state => state.width);

  return {
    width,
  };
};

export default useWindowWidth;
