import Button from 'components/Buttons/Button';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styles from './TotpPrompt.module.scss';
export function TotpPrompt() {
  const {
    t
  } = useTranslation();
  const {
    push
  } = useRouter();
  const handleRedirect = async () => {
    await push({
      pathname: '/settings/security',
      query: {
        setupTotp: 'true'
      }
    });
  };
  return <div className={styles.root}>
      <p>{t('totpPromptMsg')}</p>
      <Button onClick={handleRedirect} color="secondary" height="lg">
        {t('txtEnableTfa')}
      </Button>
    </div>;
}