export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

type Deferred = {
  promise: Promise<unknown>;
  resolve: (value?: unknown) => void;
  reject: (reason?: unknown) => void;
};

export function defer() {
  const deferred = {} as Deferred;
  const promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  deferred.promise = promise;
  return deferred;
}
