import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';

import { useEmailVerifiedSubscription } from '../generated/graphql';
import { AlertsEnum, addAlert } from '../redux/slices/alertsSlice';
import { useStore } from './useStore';

export const useGlobalEmailVerified = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setState, state: emailVerifiedAt } = useStore(state => state.profile.emailVerifiedAt);

  useEmailVerifiedSubscription({
    skip: !!emailVerifiedAt,
    onData: ({ data }) => {
      const emailVerifiedAt = data.data?.emailVerified?.emailVerifiedAt;

      if (emailVerifiedAt) {
        setState('profile', profile => ({ ...profile, emailVerifiedAt }));

        dispatch(
          addAlert({
            type: AlertsEnum.Success,
            message: t('msgEmailVerified'),
          }),
        );
      }
    },
  });
};
