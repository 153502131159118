import BigNumber from 'bignumber.js';
import { CRYPTO_DECIMALS } from 'constants/decimalPlaces';
import usePreference from 'hooks/usePreference';
import React, { InputHTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIsomorphicLayoutEffect } from 'react-use';
import { Currency } from '../../generated/graphql';
import { useConversion } from '../../hooks/useConversion';
import { useCurrencyInputConversion } from '../../hooks/useCurrencyInputConversion';
import { CurrencyInputRaw } from './CurrencyInputRaw';
import { InputVariant } from './TextInput';
export interface CurrencyInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  currency: Currency;
  error?: string;
  classNames?: Partial<{
    input: string;
    error: string;
  }>;
  /*
   * Max value represents the maximum value the user can put
   * This value is used for rounding purposes
   */
  maxValue?: string;
  value: string;
  onChange?: (cryptoValue: string, entered: string) => void;
  suffix?: ReactNode;
  bgColor?: string;
  /* hide the  right label currency, default false */
  hideRightLabel?: boolean;
  maxBetEnabled?: boolean;
  maxBetLoading?: boolean;

  /**
   * Control the dimmed state; given that we have some disabled
   * states that don't need to be dimmed.
   */
  dimmed?: boolean;
  variant?: InputVariant;
  alwaysDisplayCryptoIcon?: boolean;
  onMaxBetClick?: () => void;
}

// value is always crypto currency
const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>(({
  label,
  currency,
  value,
  maxValue,
  suffix,
  error,
  classNames,
  bgColor,
  hideRightLabel = false,
  onChange = () => {},
  onBlur,
  dimmed = false,
  alwaysDisplayCryptoIcon = false,
  variant,
  maxBetEnabled,
  maxBetLoading,
  onMaxBetClick,
  ...rest
}, ref) => {
  const {
    displayInFiat
  } = usePreference();
  const fiatCurrency = useSelector((state: AppState) => state.prices.pricesFiatCurrency);
  const {
    inputToCrypto,
    inputToLabelCurrency
  } = useCurrencyInputConversion(currency);
  const {
    cryptoToFiat
  } = useConversion(currency);
  const [enteredValue, setEnteredValue] = useState(value);
  const lastOnchangeValue = useRef(value);
  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value;
    const onChangeValue = inputToCrypto(e.target.value, maxValue);
    lastOnchangeValue.current = onChangeValue;
    setEnteredValue(value);

    // Send transformed value
    onChange(onChangeValue, e.target.value);
  };
  useEffect(() => {
    // When value is changed separately
    if (lastOnchangeValue.current !== value) {
      lastOnchangeValue.current = value;
      setEnteredValue(displayInFiat ? cryptoToFiat(value) : value);
    }
  }, [value, displayInFiat, cryptoToFiat]);

  // Using useLayoutEffect here to ensure that the enteredValue is updated before the input is rendered
  useIsomorphicLayoutEffect(() => {
    // When displayInFiat preference is changed
    if (value) {
      setEnteredValue(displayInFiat ? cryptoToFiat(value) : BigNumber(value).toFixed(CRYPTO_DECIMALS));
    }
  }, [displayInFiat, fiatCurrency]);
  useEffect(() => {
    if (value === '') {
      setEnteredValue('');
    }
  }, [value]);
  return <CurrencyInputRaw label={label} error={error} classNames={classNames} ref={ref} rightLabel={!hideRightLabel ? inputToLabelCurrency(enteredValue, value) : ''} value={enteredValue} onChange={onChangeHandler} currency={currency} suffix={suffix} onBlur={onBlur} bgColor={bgColor} variant={variant} dimmed={dimmed} onMaxBetClick={onMaxBetClick} alwaysDisplayCryptoIcon={alwaysDisplayCryptoIcon} maxBetEnabled={maxBetEnabled} maxBetLoading={maxBetLoading} {...rest} />;
});
export default CurrencyInput;