import { ChatRoom, Language } from 'generated/graphql';
import { useRouter } from 'next/router';

interface ChatRoomIntercomLang {
  defaultChatRoomLanguage: ChatRoom;
  intercomLanguage: Language;
}

export const DEFAULT_LANGUAGE_MAPPING: ChatRoomIntercomLang = {
  defaultChatRoomLanguage: ChatRoom.ENGLISH,
  intercomLanguage: Language.EN,
};

export const LANGUAGE_MAPPING: Record<Language, ChatRoomIntercomLang> = {
  [Language.EN]: DEFAULT_LANGUAGE_MAPPING,
  [Language.ZH]: DEFAULT_LANGUAGE_MAPPING,
  [Language.FR]: {
    defaultChatRoomLanguage: ChatRoom.FRENCH,
    intercomLanguage: Language.EN,
  },
  [Language.ES]: {
    defaultChatRoomLanguage: ChatRoom.SPANISH,
    intercomLanguage: Language.EN,
  },
  [Language.PT]: DEFAULT_LANGUAGE_MAPPING,
  [Language.JA]: {
    defaultChatRoomLanguage: ChatRoom.JAPANESE,
    intercomLanguage: Language.JA,
  },
  [Language.KO]: DEFAULT_LANGUAGE_MAPPING,
  [Language.DE]: {
    defaultChatRoomLanguage: ChatRoom.GERMAN,
    intercomLanguage: Language.EN,
  },
  [Language.HU]: DEFAULT_LANGUAGE_MAPPING,
  [Language.TR]: {
    defaultChatRoomLanguage: ChatRoom.TURKISH,
    intercomLanguage: Language.EN,
  },
  [Language.RU]: DEFAULT_LANGUAGE_MAPPING,
};

export const LANGUAGE_WITH_LARGE_CHARACTER: Partial<Record<Language, boolean>> = {
  [Language.EN]: true,
  [Language.ZH]: true,
  [Language.JA]: true,
  [Language.KO]: true,
} as const;

export function useLanguagePreference() {
  const router = useRouter();

  let languagePreference = Language.EN;

  if (router && Object.values(Language).includes(router.locale as Language)) {
    languagePreference = router.locale as Language;
  }

  const chatRoomIntercomLang = LANGUAGE_MAPPING[languagePreference];

  return {
    ...chatRoomIntercomLang,
    languagePreference,
  };
}
