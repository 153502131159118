import { useTranslation } from 'next-i18next';

import { CRYPTO_DECIMALS } from '../constants/decimalPlaces';
import { Currency, FiatCurrency } from '../generated/graphql';

import { FormattedDisplayOptions } from './useAmount';
import { useDisplayAmount } from './useDisplayAmount';
import usePreference from './usePreference';

export const useFormattedDisplayAmount = (
  cryptoAmount: string,
  currency: Currency,
  {
    maxDigits = Number.POSITIVE_INFINITY,
    invert = false,
    showCryptoCurrency = false,
    showFiatCurrency = true,
  }: FormattedDisplayOptions = {},
) => {
  const { displayInFiat, fiatPreference } = usePreference();
  const { t } = useTranslation();

  const asFiat = invert ? !displayInFiat : displayInFiat;
  const displayAmount = useDisplayAmount({ displayInFiat, cryptoAmount, currency, invert }) ?? '';

  if (asFiat) {
    return t('intlCurrencyWithOptions', {
      val: displayAmount,
      currency: showFiatCurrency ? fiatPreference : '',
      currencyDisplay: [FiatCurrency.DKK, FiatCurrency.TRY].includes(fiatPreference)
        ? 'narrowSymbol'
        : 'symbol',
    })?.toLocaleUpperCase();
  }

  const totalDecimalLength = (displayAmount.split('.')[0]?.length ?? 0) + CRYPTO_DECIMALS;

  const cryptoDecimals = Math.min(
    CRYPTO_DECIMALS - (totalDecimalLength - maxDigits),
    CRYPTO_DECIMALS,
  );

  const suffix = showCryptoCurrency ? ` ${currency}` : '';

  return (
    t('intlNumber', {
      val: Number(displayAmount) ?? 0,
      minimumFractionDigits: cryptoDecimals,
      maximumFractionDigits: cryptoDecimals,
    }) + suffix
  );
};
