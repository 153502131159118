import BigNumber from 'bignumber.js';

import { ODDS_TOTAL_DECIMALS } from '../constants/decimalPlaces';
import { OddsFormat } from '../redux/slices/browserPreferenceSlice';

import { BetSlipItem } from '../redux/slices/sportsBetSlice.type';
import { formatOddsNumber } from './formatOddsNumber';
import { getSportBetOdds } from './getSportBetOdds';

type Props = {
  bets: BetSlipItem[];
  oddsFormat: OddsFormat;
};

type ReturnType = {
  outputDecimal: BigNumber;
  outputNumber: BigNumber;
  outputText: string;
};

export function calculateOddsTotal({ bets, oddsFormat }: Props): ReturnType {
  if (bets.length < 1) {
    return {
      outputDecimal: BigNumber(0),
      outputNumber: BigNumber(0),
      outputText: '',
    };
  }

  const betsResult = bets.map(bet => {
    return getSportBetOdds({
      oddsFormat: OddsFormat.Decimal,
      oddsNumerator: bet.oddsNumerator,
      oddsDenominator: bet.oddsDenominator,
      ...(bet.totalOddsDecimal ? { oddDecimal: BigNumber(bet.totalOddsDecimal) } : {}),
    });
  });
  const outputDecimal = betsResult
    .reduce(
      (previousValue, currentValue) => previousValue.times(currentValue.outputNumber),
      BigNumber(1),
    )
    .decimalPlaces(ODDS_TOTAL_DECIMALS, BigNumber.ROUND_DOWN);
  const [oddsNumerator, oddsDenominator] = BigNumber(outputDecimal.toFixed(2))
    .minus(1)
    .toFraction();

  let outputText = '';
  let outputNumber = BigNumber(0);

  switch (oddsFormat) {
    case OddsFormat.HongKong:
    case OddsFormat.Indonesian:
    case OddsFormat.Malaysian:
      outputNumber = getSportBetOdds({
        oddsFormat,
        oddDecimal: outputDecimal,
      }).outputNumber;
      outputText = formatOddsNumber({
        outputNumber,
        shouldAppendPlusSymbol: true,
        fixedDecimalPlace: 2,
      });
      break;
    case OddsFormat.American:
      outputNumber = getSportBetOdds({
        oddsFormat,
        oddDecimal: outputDecimal,
      }).outputNumber;
      outputText = formatOddsNumber({
        outputNumber,
        shouldAppendPlusSymbol: true,
      });
      break;
    case OddsFormat.Decimal:
      outputNumber = outputDecimal;
      outputText = formatOddsNumber({
        outputNumber,
        shouldAppendPlusSymbol: false,
        fixedDecimalPlace: 2,
      });
      break;
    case OddsFormat.Fractional:
      outputNumber = BigNumber(0);
      outputText = `${oddsNumerator}/${oddsDenominator}`;
      break;
    default:
      return {
        outputDecimal: BigNumber(0),
        outputNumber: BigNumber(0),
        outputText: '',
      };
  }

  return {
    outputDecimal,
    outputNumber,
    outputText,
  };
}
