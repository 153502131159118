import { SPORT_PAGE_TABS } from 'constants/sports';
import { MobileMenuName } from 'redux/slices/globalStateSlice';
import { MobileNavItemType, NavItem } from './menu.type';
export const CASINO_CATEGORIES: NavItem[] = [{
  href: '/casino/categories/originals',
  name: 'gameOriginals',
  iconUrl: '/icons/original.svg',
  isPrivate: false
}, {
  href: '/casino/categories/slots',
  name: 'gameSlots',
  iconUrl: '/icons/slots.svg',
  isPrivate: false
}, {
  href: '/casino/categories/live-casino',
  name: 'gameLiveCasino',
  iconUrl: '/icons/casino.svg',
  isPrivate: false
}, {
  href: '/casino/categories/blackjack',
  name: 'gameBlackjack',
  iconUrl: '/icons/blackjack.svg',
  isPrivate: false
}, {
  href: '/casino/categories/roulette',
  name: 'gameRoulette',
  iconUrl: '/icons/roulette.svg',
  isPrivate: false
}, {
  href: '/casino/categories/game-shows',
  name: 'gameGameShows',
  iconUrl: '/icons/game-show.svg',
  isPrivate: false
}, {
  href: '/casino/categories/baccarat',
  name: 'gameBaccarat',
  iconUrl: '/icons/baccarat.svg',
  isPrivate: false
}, {
  href: '/casino/providers',
  name: 'txtProviders',
  iconUrl: '/icons/providers.svg',
  isPrivate: false
}];
export const SPORTS_NAV: NavItem[] = [{
  href: `/sports?section=${SPORT_PAGE_TABS.FEATURES}`,
  name: 'navHome',
  iconUrl: '/icons/home.svg',
  isPrivate: false,
  shallow: true
}, {
  href: `/sports?section=${SPORT_PAGE_TABS.UPCOMING}`,
  name: 'navUpcoming',
  iconUrl: '/icons/calender-days.svg',
  isPrivate: false,
  hasCounter: true,
  shallow: true
}, {
  href: `/sports?section=${SPORT_PAGE_TABS.BET_LIVE}`,
  name: 'navBetLive',
  iconUrl: '/icons/bet-live.svg',
  isPrivate: false,
  hasCounter: true,
  shallow: true
}, {
  href: `/sports?section=${SPORT_PAGE_TABS.MY_BETS}`,
  name: 'tabMyBets',
  iconUrl: '/icons/my-bets.svg',
  isPrivate: true
}, {
  href: '/promotion',
  name: 'footer.promotions.text',
  iconUrl: '/icons/promotion.svg',
  isPrivate: false,
  disabled: true
}];
export const SPORT_MOBILE_NAV: MobileNavItemType[] = [{
  name: MobileMenuName.MENU,
  icon: <img src="/icons/menu.svg" alt="menu" />,
  active: true
}, {
  name: MobileMenuName.SEARCH,
  icon: <img src="/icons/search.svg" alt="menu" />,
  active: true
}, {
  name: MobileMenuName.CHAT,
  icon: <img src="/icons/chat.svg" alt="chat" />,
  active: true
}, {
  name: MobileMenuName.SPORTS_BET,
  icon: <img src="/icons/bet-slip.svg" alt="bet-slip" />,
  active: true
}, {
  href: '/',
  name: MobileMenuName.CASINO,
  icon: <img src="/icons/mobile-casino.svg" alt="casino" />,
  active: true
}];