import useGlobalState from 'hooks/useGlobalState';
import { useMobileNav } from 'hooks/useMobileNav';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { MobileMenuName } from 'redux/slices/globalStateSlice';
import usePreference from '../../hooks/usePreference';
import { useSportRouteCheck } from '../../hooks/useSportRouteCheck';
import { RightSidebarVariant } from '../../redux/slices/browserPreferenceSlice';
import { MobileNavItem } from './MobileNavItem';
import { SPORT_MOBILE_NAV } from './menu';
import { MobileNavItemType } from './menu.type';
import styles from './MobileNavigation.module.scss';
const MobileNavigation = () => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const {
    rightSidebarVariant
  } = usePreference();
  const {
    activeMobileNav
  } = useGlobalState();
  const {
    setActiveMobileNav
  } = useMobileNav();
  const {
    isSportPage
  } = useSportRouteCheck();
  const betSlipsLength = useSelector((state: AppState) => state.sportsBet.betSlips.length);
  const sportsBetMaintenance = useSelector((state: AppState) => state.globalState.sportsBetMaintenanceShow);
  const CASINO_MOBILE_NAV: MobileNavItemType[] = [{
    name: MobileMenuName.MENU,
    icon: <img src="/icons/menu.svg" alt="menu" />,
    active: true
  }, {
    name: MobileMenuName.SEARCH,
    icon: <img src="/icons/search.svg" alt="menu" />,
    active: true
  }, {
    name: MobileMenuName.CHAT,
    icon: <img src="/icons/chat.svg" alt="chat" />,
    active: true
  }, {
    href: '/vip-program',
    name: MobileMenuName.REWARDS,
    icon: <img src="/icons/rewards.svg" alt="rewards" />,
    active: true
  }, {
    href: sportsBetMaintenance ? '/?modal=sportMaintenance' : '/sports',
    name: MobileMenuName.SPORTS,
    icon: <img src="/icons/sport.svg" alt="sport" />,
    active: true
  }];
  const handleChangeActiveMenu = (item: MobileNavItemType) => {
    if (item.name !== activeMobileNav) {
      setActiveMobileNav(item.name);
    } else if (!item.href) {
      setActiveMobileNav(isSportPage ? MobileMenuName.SPORTS : MobileMenuName.CASINO);
    }
  };
  return <div className={styles.root}>
      {(isSportPage ? SPORT_MOBILE_NAV : CASINO_MOBILE_NAV).map(item => {
      let indicatorNumber;
      const isSportBetButton = item.name === MobileMenuName.SPORTS_BET;
      const isBetSlipsOpen = rightSidebarVariant === RightSidebarVariant.BET_SLIP;
      if (isSportBetButton) {
        indicatorNumber = betSlipsLength;
      }
      let active;
      if (isSportBetButton) {
        active = isBetSlipsOpen;
      } else if (item.href) {
        active = item.href === router.pathname && item.name === activeMobileNav;
      } else {
        active = item.name === activeMobileNav;
      }
      return <div key={item.name}>
            {item.active ? <MobileNavItem onClick={() => handleChangeActiveMenu(item)} href={item.href} isSportBetButton={isSportBetButton} active={active} name={t(item.name)} icon={item.icon} indicatorNumber={indicatorNumber} /> : <MobileNavItem name={t(item.name)} isSportBetButton={isSportBetButton} icon={item.icon} disabled indicatorNumber={indicatorNumber} />}
          </div>;
    })}
    </div>;
};
export default MobileNavigation;