import { NextRouter } from 'next/router';

import { omit } from '../../utils/omit';

import { ModalType, QueryTypes } from './GlobalModal.type';

type Props = {
  router: NextRouter;
  routerOptions?: {
    shallow?: boolean;
    locale?: string | false;
    scroll?: boolean;
    unstable_skipClientCache?: boolean;
  };
} & Partial<Omit<ModalType, 'isOpen' | 'classNames'>>;

export const closeGlobalModal = ({ router, routerOptions = { shallow: true } }: Props) => {
  router
    .replace(
      {
        pathname: router.pathname,
        query: omit(router.query, Object.values(QueryTypes)),
      },
      undefined,
      routerOptions,
    )
    .catch();
};
