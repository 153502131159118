import clsx from 'clsx';
import { SportsMarketStatus } from 'generated/graphql';
import { isUndefined } from 'lodash';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { useEffect, useRef } from 'react';
import SportIcon from 'views/SportsHome/components/SportIcon';
import Checkbox from '../../../../components/FormControls/Checkbox';
import { BetSlipItem, BetSlipStatus } from '../../../../redux/slices/sportsBetSlice.type';
import { isElementInViewport } from '../../../../utils/isElementInViewport';
import { BetSlipProps } from '../BetsGroup.type';
import styles from './BetTicketLegHeader.module.scss';
import { getBetViewConfig } from './getBetViewConfig';
interface BetTicketHeaderElementProps {
  bet: BetSlipItem;
  betSlipStatus: BetSlipStatus;
  marketStatus?: SportsMarketStatus;
  onUpdateAmount?: NonNullable<BetSlipProps['actions']>['onUpdateMultiBetAmount'];
  selectionIds: string[];
  checked: boolean;
  onHeaderClick?: () => void;
  showLiveBadge?: boolean;
  highlight: boolean;
  disabled: boolean;
  viewConfig: ReturnType<typeof getBetViewConfig>;
}
export const BetTicketLegHeader = ({
  disabled,
  bet: {
    fixtureName,
    sport,
    id,
    href
  },
  viewConfig: {
    isWon,
    isLost,
    isVoid,
    isPushed,
    isDeadHeat,
    isPartial
  },
  selectionIds,
  betSlipStatus,
  marketStatus,
  onUpdateAmount,
  checked,
  onHeaderClick,
  showLiveBadge = false,
  highlight
}: BetTicketHeaderElementProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    t
  } = useTranslation();
  const enableCollapse = !disabled && [BetSlipStatus.PENDING_BET, BetSlipStatus.RESULTED_BET, BetSlipStatus.BET_PLACED, BetSlipStatus.BET_VIEW_INFO].includes(betSlipStatus);
  const onUpdateChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const updatedBetSlips = isChecked ? [...selectionIds, id] : selectionIds.filter(currentId => currentId !== id);
    if (updatedBetSlips.length <= 1) {
      onUpdateAmount?.({
        betAmount: '',
        inputAmount: ''
      });
    }
    onUpdateAmount?.({
      selectionIds: updatedBetSlips
    });
  };
  useEffect(() => {
    const highLightHeader = () => {
      if (highlight && containerRef.current && !isElementInViewport({
        element: containerRef.current,
        padding: {
          bottom: 81
        }
      }).isInViewPort) {
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    };
    highLightHeader();
  }, [highlight]);
  return <div role={betSlipStatus ? 'button' : undefined} className={`${styles.betTicketHeader} ${highlight ? styles.highlight : ''} ${enableCollapse ? styles.enabled : ''}`} onClick={enableCollapse ? onHeaderClick : undefined} ref={containerRef}>
      {betSlipStatus === BetSlipStatus.ADDING_BET && href ? <Link href={href} className={styles.betTicketGameNameWrapper} title={fixtureName}>
          <SportIcon sport={sport} />
          <span className={styles.betTicketGameName}>{fixtureName}</span>
        </Link> : <p className={styles.betTicketGameTextNameWrapper}>
          <SportIcon sport={sport} />
          <span className={styles.betTicketGameName}>{fixtureName}</span>
        </p>}

      <div className={styles.legHeaderStatusWrapper}>
        {showLiveBadge && <span className={styles.liveBadge}>{t('txtLive')}</span>}

        {isWon && <span className={clsx(styles.betLegStatus, styles.betLegStatusWon)}>
            {t('won')} <img src="/icons/tick-circle.svg" alt="complete" />
          </span>}

        {isLost && <span className={clsx(styles.betLegStatus, styles.betLegStatusLost)}>
            {t('lost')} <img src="/icons/cross-error.svg" alt="close" className={styles.loseLeg} />
          </span>}

        {(isVoid || isPushed) && <span className={clsx(styles.betLegStatus, styles.betLegStatusVoidedOrCancelled)}>
            {isVoid ? t('void') : t('pushed')}{' '}
            <img src="/icons/cross-error-white.svg" alt="voided" className={styles.voidedLeg} />
          </span>}

        {(isDeadHeat || isPartial) && <span className={clsx(styles.betLegStatus, styles.betLegStatusDeadHeat)}>
            {isDeadHeat ? t('dead_heat') : t('partial')}{' '}
            <img src="/icons/tick-circle.svg" alt="complete" />
          </span>}

        {betSlipStatus === BetSlipStatus.ADDING_BET && <div className={`${styles.checkBoxStyle} ${betSlipStatus === BetSlipStatus.ADDING_BET && !isUndefined(marketStatus) && marketStatus !== SportsMarketStatus.OPEN ? styles.dimmed : ''}`}>
            <Checkbox checked={checked} onChange={onUpdateChecked} aria-label="select leg" />
          </div>}
      </div>
    </div>;
};