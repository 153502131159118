import { SportsCompetitorBodyFragment, SportsFixtureCompetitor } from 'generated/graphql';
import { isFinite, toNumber } from 'lodash';

// Abbreviation of competitor name should follow:
// remove characters in the parentheses, including the parentheses
// if competitor name is 2 or 3 words, abbreviate with first letter of each word (i.e Manchester United == MU, Rhode Island Rams == RIR)
// if competitor name is 1 word || >= 4 words, use the first 3 letters (i.e Australia == AUS, Cambodia == CAM)
// if the first word is less than 3 letters, use the first 3 letters of the second word (i.e FC Barcelona == FB, 1. FC Union Berlin == UNI)
export const abbreviateCompetitorName = (name: string) => {
  if (!name) {
    return '';
  }

  const MAX_LENGTH = 3;

  const words = name
    .replace(/\([^)]+\)/g, '')
    .trim()
    .split(' ');

  const isTwoOrThreeWords = words.length === 2 || words.length === 3;
  const firstWordIsNumberic = isFinite(toNumber(words[0]));

  const firstWordIsLongEnough = !firstWordIsNumberic && String(words[0]).length >= MAX_LENGTH;

  if (isTwoOrThreeWords && firstWordIsLongEnough) {
    return words
      .map(word => word.charAt(0).toUpperCase())
      .join('')
      .slice(0, MAX_LENGTH);
  }

  const wordForDisplay =
    words.find(word => {
      const isNumberic = isFinite(toNumber(word));
      return !isNumberic && word.length >= MAX_LENGTH;
    }) ||
    words[0] ||
    '';
  return wordForDisplay.slice(0, MAX_LENGTH).toUpperCase();
};

const defaultNames = {
  homeTeamDisplayName: '',
  awayTeamDisplayName: '',
  homeTeamFullName: '',
  awayTeamFullName: '',
  homeTeamNameInEnglish: '',
  awayTeamNameInEnglish: '',
};

export const getSportsCompetitors = ({
  competitors,
  abbreviate,
}: {
  competitors: SportsFixtureCompetitor[] | SportsCompetitorBodyFragment[] | undefined;
  abbreviate?: boolean;
}): typeof defaultNames => {
  if (!competitors) {
    return defaultNames;
  }

  let homeTeamDisplayName = '';
  let awayTeamDisplayName = '';
  let homeTeamFullName = '';
  let awayTeamFullName = '';
  let homeTeamNameInEnglish = '';
  let awayTeamNameInEnglish = '';

  // Need this type check because some field not exist in SportsFixtureCompetitor, all query in graphql is using SportsCompetitorBodyFragment as well
  for (const competitor of competitors as SportsCompetitorBodyFragment[]) {
    if (competitor.isHome) {
      homeTeamDisplayName =
        competitor?.competitor?.nameTranslation ||
        competitor?.competitor?.fullName ||
        competitor?.competitor?.name ||
        defaultNames.homeTeamDisplayName;
      homeTeamNameInEnglish = competitor?.competitor?.name || defaultNames.homeTeamNameInEnglish;
      homeTeamFullName = competitor?.competitor?.fullName || defaultNames.homeTeamFullName;
    } else {
      awayTeamDisplayName =
        competitor?.competitor?.nameTranslation ||
        competitor?.competitor?.fullName ||
        competitor?.competitor?.name ||
        defaultNames.awayTeamDisplayName;
      awayTeamNameInEnglish = competitor?.competitor?.name || defaultNames.awayTeamNameInEnglish;
      awayTeamFullName = competitor?.competitor?.fullName || defaultNames.awayTeamFullName;
    }
  }

  if (abbreviate) {
    return {
      homeTeamDisplayName: abbreviateCompetitorName(homeTeamDisplayName),
      awayTeamDisplayName: abbreviateCompetitorName(awayTeamDisplayName),
      homeTeamFullName,
      awayTeamFullName,
      homeTeamNameInEnglish: abbreviateCompetitorName(homeTeamNameInEnglish),
      awayTeamNameInEnglish: abbreviateCompetitorName(awayTeamNameInEnglish),
    };
  }

  return {
    // Using to display in UI
    homeTeamDisplayName,
    awayTeamDisplayName,
    // These values are using to compare conditions.
    homeTeamFullName,
    awayTeamFullName,
    // These values are using to compare conditions.
    homeTeamNameInEnglish,
    awayTeamNameInEnglish,
  };
};
