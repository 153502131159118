import BigNumber from 'bignumber.js';
import { Currency } from 'generated/graphql';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserPreferencesState, setCryptoPreference } from 'redux/slices/browserPreferenceSlice';
import { store, useStore } from 'hooks/useStore';
import { useCryptoToFiatByCurrency } from '../../hooks/useCryptoToFiatByCurrency';
export const useSetDefaultCurrency = ({
  browserPreference
}: {
  browserPreference: BrowserPreferencesState;
}) => {
  const {
    state: hasSetInitialBalance
  } = useStore(state => state.balances.hasSetInitial);
  const hasSetDefaultCurrency = useRef(false);
  const dispatch = useDispatch();
  const {
    cryptoPreference,
    cryptoPreferenceSet
  } = browserPreference;
  const {
    cryptoToFiatByCurrency
  } = useCryptoToFiatByCurrency();
  useEffect(() => {
    const balances = store.getState().balances.values;
    if (hasSetDefaultCurrency.current || !hasSetInitialBalance) {
      return;
    }
    const userHasBalances = Object.values(balances).some(balance => balance !== '0');
    if (!cryptoPreferenceSet && userHasBalances) {
      const largestFiatBalance = {
        currency: cryptoPreference,
        fiat: '0.00'
      };
      Object.entries(balances).forEach(([_currency, _balance]) => {
        const _asFiat = cryptoToFiatByCurrency(_balance, (_currency as Currency));
        if (BigNumber(_asFiat).isGreaterThan(largestFiatBalance.fiat)) {
          largestFiatBalance.currency = (_currency as Currency);
          largestFiatBalance.fiat = _asFiat;
        }
      });
      dispatch(setCryptoPreference(largestFiatBalance.currency));
      hasSetDefaultCurrency.current = true;
    }
  }, [cryptoPreferenceSet, cryptoToFiatByCurrency, cryptoPreference, dispatch, hasSetInitialBalance]);
};