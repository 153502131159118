import BigNumber from 'bignumber.js';

export function calculateCashOut(params: {
  amount: string | number;
  cashOutOddsDecimal: string | null;
}): string {
  const { amount, cashOutOddsDecimal } = params;

  if (cashOutOddsDecimal === null) {
    return '0';
  }

  // cashOutOddsDecimal return from BE
  const amountBN = BigNumber(amount || '0');

  if (amountBN.isZero()) {
    return '0';
  }

  const cashOut = amountBN.multipliedBy(cashOutOddsDecimal);

  return cashOut.decimalPlaces(8).toString();
}
