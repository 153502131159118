import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { MobileMenuName, setActiveMobileNav } from '../../redux/slices/globalStateSlice';
import { openGlobalOverlay } from '../Modal/openGlobalOverlay';
import styles from './SearchComponent.module.scss';
type Props = {
  isExpanded: boolean;
  isSportPage: boolean;
  isAtHomeRoute: boolean;
  isMobile?: boolean;
  animationComplete?: boolean;
  className: string;
};
export const SearchComponent = ({
  isExpanded,
  isSportPage,
  isAtHomeRoute,
  isMobile,
  animationComplete,
  className
}: Props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  return <div className={`${styles.buttonContainer} ${className || ''} ${isExpanded ? '' : styles.expandedButtonContainer}`}>
      <button className={styles.searchButton} onClick={() => {
      openGlobalOverlay({
        router,
        type: 'search',
        value: isSportPage && !isAtHomeRoute ? 'sports' : 'casino'
      });
      if (isMobile) {
        dispatch(setActiveMobileNav(MobileMenuName.SEARCH));
      }
    }}>
        <img src="/icons/search.svg" loading="lazy" alt="search" />
        {(isExpanded && animationComplete || isMobile) && <span>Search</span>}
      </button>
    </div>;
};