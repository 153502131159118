import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { GetAppSettingAndPricesQuery } from '../generated/graphql';
import { updateAppSetting } from '../redux/slices/appSettingSlice';

type Props = {
  setting?: GetAppSettingAndPricesQuery['appSettings'];
};

export function usePersistAppSetting(props?: Props) {
  const appSetting = useSelector((app: AppState) => app.appSetting, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.setting) {
      dispatch(
        updateAppSetting({
          details: props.setting,
        }),
      );
    }
  }, [dispatch, props?.setting]);

  return {
    appSetting,
  };
}
