import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setIsContentfulPreview } from '../redux/slices/globalStateSlice';

import useQueryParams from './useQueryParams';

export function useContentful({ isContentfulPreview }: { isContentfulPreview: boolean }) {
  const { addQueryParams } = useQueryParams();
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    // Persist `?preview=true` across all client-side navigation for Contentful previews.
    dispatch(setIsContentfulPreview(router.query.cms === 'preview'));
  }, [dispatch, router]);

  useEffect(() => {
    // Append ?preview=true to routes to let the getServerSideProps that it's preview mode.
    if (isContentfulPreview && !router.query.preview) {
      addQueryParams({ preview: 'true' }, false);
    }
  }, [addQueryParams, isContentfulPreview, router.query.preview]);
}
