import ClickOutsideWrapper from 'components/ClickOutsideWrapper';
import FormattedAmount from 'components/FormattedAmount';
import { BALANCE_MAX_DIGITS } from 'constants/currency';
import usePreference from 'hooks/usePreference';
import { useTranslation } from 'next-i18next';
import React, { MouseEventHandler, useState } from 'react';
import { ModalTypes } from '../../../constants/modal';
import { PlayOption } from '../../../hooks/usePopup.type';
import CryptoIcon from '../../CurrencyIcons/CryptoIcon';
import BalancePopup from './BalancePopup';
import { useWalletBalance } from 'hooks/wallet/useBalances';
import styles from './BalanceSelect.module.scss';
type Props = {
  children: React.ReactNode;
  className?: string;
  playOption: PlayOption;
};
const BUTTON_NAME = 'balance-button';
const BalanceSelect = ({
  children,
  className,
  playOption
}: Props) => {
  const [currentModal, setCurrentModal] = useState<ModalTypes | ''>('');
  const {
    t
  } = useTranslation();
  const {
    cryptoPreference
  } = usePreference();
  const [balanceDropdownOpen, setBalanceDropdownOpen] = useState(false);
  const userPreferenceBalance = useWalletBalance(cryptoPreference);
  const balanceBtnHandler: MouseEventHandler = e => {
    e.stopPropagation();
    setBalanceDropdownOpen(!balanceDropdownOpen);
  };
  const closeHandler = (e?: HTMLElement) => {
    if (!currentModal && e?.id !== BUTTON_NAME) {
      setBalanceDropdownOpen(false);
    }
  };
  return <div className={`${styles.root} ${className || ''}`}>
      <div className={styles.selectContainer}>
        <button className={styles.balanceBtn} id={BUTTON_NAME} onClick={balanceBtnHandler}>
          <CryptoIcon currency={cryptoPreference} />
          {playOption === PlayOption.REAL ? t('nav.balance.button') : <FormattedAmount value={userPreferenceBalance} currency={cryptoPreference} maxDigits={BALANCE_MAX_DIGITS} dataTestId="balance" hideTooltip={balanceDropdownOpen} />}

          <img src="/icons/chevron.svg" alt="arrow" className={`${styles.arrow} ${balanceDropdownOpen ? styles.arrowDown : ''}`} />
        </button>

        {balanceDropdownOpen && <ClickOutsideWrapper onClickOutside={closeHandler}>
            <BalancePopup currentModal={currentModal} setCurrentModal={setCurrentModal} closePopup={() => setBalanceDropdownOpen(false)} />
          </ClickOutsideWrapper>}
      </div>

      {children}
    </div>;
};
export default BalanceSelect;