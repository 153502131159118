import { useCallback, useEffect } from 'react';

import { useLanguagePreference } from './useLanguagePreference';
import { useStore } from './useStore';

interface Traits {
  email?: string;
  signedUpAt?: string;
  userId?: string | null;
  name?: string | null;
  intercom?: {
    language_override?: string;
  };
}

interface Integrations {
  Intercom?: {
    user_hash: string;
  };
}

interface Analytics {
  identify: (a: string | Traits, b?: Traits | Integrations, c?: Integrations) => void;
  reset: () => void;
}

declare global {
  interface Window {
    analytics: Analytics;
  }
}

export const useSegment = () => {
  const { state: profile } = useStore(state => {
    const profile = state.profile;

    return {
      id: profile.id,
      email: profile.email,
      createdAt: profile.createdAt,
      username: profile.username,
      intercomToken: profile.intercomToken,
    };
  });

  const { intercomLanguage } = useLanguagePreference();

  const identify = useCallback(
    (traits: Traits, integrations?: Integrations) => {
      if (window.analytics) {
        // TODO: Not important but Handle when window.analytics takes long to load
        // Should save all the data into an array and send them once analytics is ready
        // Also should log an error if this fails
        if (profile?.id) {
          const traitsArg = {
            email: profile?.email,
            createdAt: new Date(profile?.createdAt).toISOString(),
            name: profile?.username,
            ...traits,
          };

          window.analytics.identify(profile?.id, traitsArg, integrations);
        } else {
          // For non logged in users, segment will create an anon id internally
          window.analytics.identify(traits, integrations);
        }
      }
    },
    [profile],
  );

  const intercomUserLogin = useCallback(() => {
    if (profile?.intercomToken) {
      identify(
        {
          intercom: {
            language_override: intercomLanguage,
          },
        },
        {
          Intercom: {
            user_hash: profile.intercomToken,
          },
        },
      );
    }
  }, [profile?.intercomToken, identify, intercomLanguage]);

  const resetIntercom = useCallback(() => {
    const ajsUserId = localStorage.getItem('ajs_user_id');

    if (!profile.id && ajsUserId && ajsUserId !== 'null') {
      if (window.analytics) {
        window.analytics.reset();
      }

      // Delete intercom cookies instead of using Intercom('shutdown') as intercom is not loaded yet
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i] ? cookies[i]?.trim() : '';
        const cookieName = cookie?.split('=')[0];
        if (cookieName?.startsWith('intercom')) {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      }
    }
  }, [profile.id]);

  useEffect(() => {
    intercomUserLogin();
  }, [intercomUserLogin]);

  useEffect(() => {
    resetIntercom();
  }, [resetIntercom]);
};
