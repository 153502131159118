import { Currency } from 'generated/graphql';
import usePreference from 'hooks/usePreference';
import React, { CSSProperties } from 'react';
import { useFormattedDisplayAmount } from '../hooks/useFormattedDisplayAmount';
import { Placement } from '../hooks/useRelativeFixPosition';
import GeneralCurrencyIcon from './CurrencyIcons/GeneralCurrencyIcon';
import { FiatWithTooltip } from './FiatWithTooltip';
import styles from './FormattedAmount.module.scss';
type Props = {
  value: string;
  currency: Currency;
  maxDigits?: number;
  hideTooltip?: boolean;
  showCryptoCurrency?: boolean;
  invert?: boolean;
  showIcon?: boolean;
  dataTestId?: string;
  placement?: Placement;
  tooltipPosition?: CSSProperties;
};
const FormattedAmount = ({
  value,
  currency,
  maxDigits = Number.POSITIVE_INFINITY,
  hideTooltip = false,
  invert = false,
  showCryptoCurrency = false,
  showIcon = false,
  dataTestId,
  placement,
  tooltipPosition
}: Props) => {
  const {
    displayInFiat
  } = usePreference();
  const formattedValue = useFormattedDisplayAmount(value, currency, {
    invert,
    maxDigits,
    showCryptoCurrency
  });
  return <div className={styles.root}>
      {showIcon && <GeneralCurrencyIcon currency={currency} />}
      {displayInFiat && !hideTooltip ? <FiatWithTooltip placement={placement} value={value} currency={currency} formattedCrypto={formattedValue} dataTestId={dataTestId} tooltipPosition={tooltipPosition} /> : <span className={`${styles.evenlySpacedNumber} formatted-amount-value`} data-testid={dataTestId}>
          {formattedValue}
        </span>}
    </div>;
};
export default React.memo(FormattedAmount);