import { SportsBetSelectionStatus } from '../../../generated/graphql';
import { BetSlipItem } from '../../../redux/slices/sportsBetSlice.type';
import { SportBetUISpecificStatus, SportsBetTicketStatusType } from './BetsGroup.type';

export function getPrimarySportsBetStatus({
  sportsBetStatus,
  hasMultiSelections,
  bets,
}: {
  sportsBetStatus?: SportsBetTicketStatusType;
  hasMultiSelections: boolean;
  bets: BetSlipItem[];
}) {
  let primarySportsBetStatus = sportsBetStatus;

  if (hasMultiSelections) {
    const allWin = bets.every(bet => bet.sportsBetSelectionStatus === SportsBetSelectionStatus.WON);

    if (allWin) {
      primarySportsBetStatus = SportBetUISpecificStatus.WIN;
    } else if (bets.some(bet => bet.sportsBetSelectionStatus === SportsBetSelectionStatus.LOST)) {
      primarySportsBetStatus = SportBetUISpecificStatus.LOSS;
    }
  }

  return primarySportsBetStatus;
}
