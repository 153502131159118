import BigNumber from 'bignumber.js';
import { CRYPTO_DECIMALS } from 'constants/decimalPlaces';
import { Currency } from 'generated/graphql';
import { useStore } from 'hooks/useStore';
import { useCallback } from 'react';

export type CurrencyMapping = Record<Currency, string>;

export interface BalancesState {
  balances: CurrencyMapping;
  balancesDeltas: Partial<CurrencyMapping>;
}

export const DEFAULT_BALANCES: CurrencyMapping = {
  [Currency.BTC]: '0',
  [Currency.ETH]: '0',
  [Currency.USDT]: '0',
  [Currency.USDC]: '0',
  [Currency.TRX]: '0',
  [Currency.MATIC]: '0',
  [Currency.LTC]: '0',
  [Currency.XRP]: '0',
  [Currency.SOL]: '0',
  [Currency.DOGE]: '0',
  [Currency.BUSD]: '0',
  [Currency.BNB]: '0',
  [Currency.SHIB]: '0',
  [Currency.SHFL]: '0',
  [Currency.BONK]: '0',
  [Currency.WIF]: '0',
  [Currency.TON]: '0',
  [Currency.AVAX]: '0',
};

export const useBalanceUpdates = () => {
  const { setState } = useStore();

  const updateBalance = useCallback(
    (data: {
      currency: Currency;
      amount: string;
      newDeltaAmount?: string;
    }) => {
      setState('balances', balances => {
        const { currency, amount, newDeltaAmount } = data;
        const { values, balancesDeltas } = balances;

        values[currency] = amount;
        const balanceDeltasCopy = { ...balancesDeltas };
        if (newDeltaAmount) {
          balanceDeltasCopy[currency] = BigNumber(newDeltaAmount).toFixed(CRYPTO_DECIMALS);
        }

        return { ...balances, balancesDeltas: balanceDeltasCopy };
      });
    },
    [setState],
  );

  const updateBalances = useCallback(
    (data: { currency: Currency; amount: string }[]) => {
      setState('balances', balances => {
        const balancesObj: Partial<CurrencyMapping> = data.reduce((prev, balance) => {
          return {
            ...prev,
            [balance.currency]: balance.amount,
          };
        }, {});

        return { ...balances, values: { ...balances.values, ...balancesObj }, hasSetInitial: true };
      });
    },
    [setState],
  );

  const changeOverrideBalance = useCallback(
    (data: { currency: Currency; deltaAmount: string }) => {
      setState('balances', balances => {
        const { currency, deltaAmount } = data;

        const balanceDeltasCopy = { ...balances.balancesDeltas };
        balanceDeltasCopy[currency] = BigNumber(balances.balancesDeltas[currency] || '0')
          .plus(deltaAmount)
          .toFixed(CRYPTO_DECIMALS);

        return { ...balances, balancesDeltas: balanceDeltasCopy };
      });
    },
    [setState],
  );

  const clearBalanceDeltas = useCallback(() => {
    setState('balances', balances => ({ ...balances, balancesDeltas: {} }));
  }, [setState]);

  const adjustBalanceButRetainTotal = useCallback(
    (data: { newBalance: string; currency: Currency }) => {
      setState('balances', balances => {
        const { newBalance, currency } = data;
        const { values, balancesDeltas } = balances;
        const valuesCopy = { ...values };
        const balancesDeltasCopy = { ...balancesDeltas };

        // balance shown must remain the same,  balanceShown = balance + delta,
        // balanceShown = balance + delta
        // for oldBalanceShown = newBalanceShown, newBalance+newDelta = oldBalance+oldDelta, therefore newDelta = oldBalance + oldDelta - newBalance
        const newDelta = BigNumber(values[currency])
          .plus(balancesDeltas[currency] || 0)
          .minus(newBalance);

        valuesCopy[currency] = newBalance;
        balancesDeltasCopy[currency] = newDelta.toFixed(CRYPTO_DECIMALS);
        return { ...balances, values: valuesCopy, balancesDeltas: balancesDeltasCopy };
      });
    },
    [setState],
  );

  return {
    updateBalance,
    updateBalances,
    clearBalanceDeltas,
    changeOverrideBalance,
    adjustBalanceButRetainTotal,
  };
};
