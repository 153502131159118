import Button from 'components/Buttons/Button';
import RoundButton from 'components/Buttons/RoundButton';
import ClickOutsideWrapper from 'components/ClickOutsideWrapper';
import { AuthModalTabType, WalletModalTabType } from 'constants/modal';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAvatar from '../../hooks/useAvatar';
import { AnimationPhase } from '../../layouts/CasinoLayout.type';
import { RightSidebarVariant, setRightSidebar } from '../../redux/slices/browserPreferenceSlice';
import { MobileMenuName, setActiveMobileNav } from '../../redux/slices/globalStateSlice';
import { ModalUrl } from '../Modal/GlobalModal.type';
import { openGlobalModal } from '../Modal/openGlobalModal';
import BalanceSelect from './Balance/BalanceSelect';
import IconMenu from './IconMenu/IconMenu';
import { ExpandMenuElement } from './UserMenu/ExpandMenuElement';
import UserMenu from './UserMenu/UserMenu';
import { PlayOption } from 'hooks/usePopup.type';
import dynamic from 'next/dynamic';
import { useStore } from '../../hooks/useStore';
import styles from './Header.module.scss';
const GoogleOneTapLogin = dynamic(() => import('components/GoogleOneTapLogin/index'), {
  ssr: false
});
type Props = {
  animationPhase?: AnimationPhase;
};
export const Header = ({
  animationPhase
}: Props) => {
  const router = useRouter();
  const {
    state: providerPopupNotOpen
  } = useStore(state => Object.values(state.popups).some(popup => popup.play === PlayOption.REAL && popup.slug === router.query.slug && !popup.open));
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const navMenuRef = useRef<HTMLDivElement>(null);
  const [loadGoogleOneTap, setLoadGoogleOneTap] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const dispatch = useDispatch();
  const {
    t
  } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const avatar = useAvatar();
  const isAtHomeRoute = router.pathname === '/' || router.pathname === '/sports';
  const homeRoute = router.pathname.includes('/sports') ? '/sports' : '/';
  const onClickProfile = () => {
    setOpenProfileMenu(!openProfileMenu);
  };
  const onClickOutside = () => {
    setOpenProfileMenu(false);
  };
  useEffect(() => {
    setIsMounted(true);
    if (isMounted && isAtHomeRoute && !isLoggedIn) {
      setLoadGoogleOneTap(true);
    }
  }, [isLoggedIn, isAtHomeRoute, isMounted]);
  return <div className={styles.navWrapper} id="nav-header">
      <div className={styles.iOSChromeScrollHider} />
      <div className={`${isLoggedIn ? '' : styles.login} ${styles.navContainer}`}>
        <div className={styles.desktopLogoWrapper}>
          <Link href={homeRoute} passHref legacyBehavior>
            <a className={`${styles.desktopLogo} ${isAtHomeRoute ? styles.disableLink : ''}`} title="Shuffle casino">
              <img src="/icons/logo.svg" className={styles.logo} alt="logo" />
            </a>
          </Link>
        </div>

        <div className={styles.mobileLogo}>
          <Link href={homeRoute} className={`${styles.iconWrapper} ${isAtHomeRoute ? styles.disableLink : ''}`} onClick={() => {
          dispatch(setRightSidebar({
            variant: RightSidebarVariant.NONE
          }));
          dispatch(setActiveMobileNav(MobileMenuName.CASINO));
        }}>
            <img src={`/icons/${isLoggedIn ? 'logo-small' : 'logo'}.svg`} alt="logo" />
          </Link>
        </div>

        <div className={styles.navMenu} ref={navMenuRef}>
          {isLoggedIn ? <div className={styles.btnContainer}>
              <BalanceSelect playOption={providerPopupNotOpen ? PlayOption.REAL : PlayOption.NONE}>
                <button className={styles.walletBtn} id="wallet-btn" onClick={() => {
              openGlobalModal({
                router,
                tab: WalletModalTabType.DEPOSIT,
                type: ModalUrl.WALLET
              });
            }}>
                  <img src="/icons/wallet.svg" alt="wallet" />
                  <span className={styles.walletBtnText}>{t('wallet')}</span>
                </button>
              </BalanceSelect>
            </div> : <>
              <Button className={`${styles.authButton} ${styles.transparentButton}`} color="tertiary" value={t('btnLogin')} onClick={() => {
            openGlobalModal({
              router,
              tab: AuthModalTabType.LOGIN,
              type: ModalUrl.AUTH
            });
          }} />
              <Button className={styles.authButton} value={t('btnRegister')} onClick={() => {
            openGlobalModal({
              router,
              tab: AuthModalTabType.REGISTER,
              type: ModalUrl.AUTH
            });
          }} />
            </>}
        </div>

        {isLoggedIn && <>
            <div className={styles.navIconsMenu}>
              <IconMenu />
              <UserMenu animationPhase={animationPhase} />
            </div>

            <div className={`${styles.mobileUser} ${styles.displayOnMobile}`}>
              <ClickOutsideWrapper onClickOutside={onClickOutside}>
                <RoundButton color="tertiary" icon={<img src="/icons/profile-user.svg" alt="user" />} onClick={onClickProfile} />
                <ExpandMenuElement avatar={avatar} show={openProfileMenu} onClick={onClickProfile} />
              </ClickOutsideWrapper>
            </div>
          </>}
      </div>

      {loadGoogleOneTap && <GoogleOneTapLogin />}
    </div>;
};
export default Header;