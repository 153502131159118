import { bindActionCreators } from '@reduxjs/toolkit';
import { USER_LOGOUT } from 'constants/general';
import { AuthModalType } from 'constants/modal';
import Router from 'next/router';
import { globalStateSlice } from 'redux/slices/globalStateSlice';
import { genId } from 'utils/genId';

import versionFile from '../../public/version.json';
import { openGlobalModal } from '../components/Modal/openGlobalModal';
import { RefreshTokenDocument } from '../generated/graphql';
import { authSlice } from '../redux/slices/authSlice';
import {
  RightSidebarVariant,
  browserPreferenceSlice,
} from '../redux/slices/browserPreferenceSlice';
import { loginSlice } from '../redux/slices/loginSlice';
import store from '../redux/store';
import { checkValidJWT } from '../utils/jwt';

import { ModalUrl } from 'components/Modal/GlobalModal.type';
import { client } from './clients';

const authActions = bindActionCreators(authSlice.actions, store.dispatch);
const loginActions = bindActionCreators(loginSlice.actions, store.dispatch);
const browserStateActions = bindActionCreators(browserPreferenceSlice.actions, store.dispatch);
const globalStateActions = bindActionCreators(globalStateSlice.actions, store.dispatch);

export const signOut = () => {
  store.dispatch({ type: USER_LOGOUT });
  browserStateActions.setRightSidebar({ variant: RightSidebarVariant.NONE });
  client.clearStore();
};

export const setUserCountry = (country: string | null) => {
  if (country) {
    globalStateActions.setUserCountry(country);
  }
};

export const geoRestrictionAlert = () => {
  globalStateActions.geoRestrictionWarningPopupIncrement();
};

export const getRefreshedTokenPromise = async () => {
  const refreshToken = store.getState().auth.refreshToken;
  try {
    if (!checkValidJWT(refreshToken)) {
      // If refresh not empty, show session expired modal
      if (refreshToken?.length) {
        signOut();
        openGlobalModal({
          router: Router,
          type: ModalUrl.AUTH,
          tab: AuthModalType.SessionExpired,
        });
      }
      return;
    }

    const { data, errors } = await client.mutate({
      mutation: RefreshTokenDocument,
      variables: { refreshToken },
    });

    if (data?.refreshToken) {
      const { accessToken, refreshToken } = data.refreshToken ?? {};

      if (accessToken && refreshToken) {
        authActions.onLogin({ accessToken, refreshToken });
        loginActions.clearDetails();
      }

      return data.refreshToken;
    }

    if (errors && errors.length > 0) {
      if (
        errors[0]?.message &&
        ['USER_SESSION_NOT_FOUND', 'INVALID_REFRESH_TOKEN'].includes(errors[0].message)
      ) {
        signOut();
        openGlobalModal({
          router: Router,
          type: ModalUrl.AUTH,
          tab: AuthModalType.SessionExpired,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAccessToken = () => {
  return store.getState().auth.accessToken;
};

export const BROWSER_ID = 'BROWSER_ID';

export const CORRELATION_USER_ID = 'CORRELATION_USER_ID';

export const windowId = genId();

let requestCounter = 0;
export function genCorrelationId() {
  if (typeof window === 'undefined') {
    return null;
  }

  let browserId = localStorage.getItem(BROWSER_ID);
  const correlationUserId = localStorage.getItem(CORRELATION_USER_ID) || 'anon';

  if (!browserId) {
    browserId = genId();
    localStorage.setItem(BROWSER_ID, browserId);
  }

  const currentTime = new Date().getTime().toString(32);

  requestCounter++;

  return `${browserId}-${windowId}-${versionFile.version}-${currentTime}-${requestCounter.toString(
    32,
  )}::${correlationUserId}`;
}
