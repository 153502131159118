import { VipLevelParams } from 'hooks/vip/useVip';
import React from 'react';
import VipIcon from './VipIcon';
import styles from './VipBadge.module.scss';
interface VipBadgeProps extends VipLevelParams {
  label?: string;
  className?: string;
}
const VipBadge = ({
  label,
  icon,
  className
}: VipBadgeProps) => {
  return <span className={`${styles.root} ${className || ''}`}>
      {icon && <VipIcon icon={icon} />}
      {label && <span>{label}</span>}
    </span>;
};
export default React.memo(VipBadge);