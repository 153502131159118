import { motion } from 'framer-motion';
import React from 'react';
import { useDispatch } from 'react-redux';
import { SvgProps } from '../../assets/svgs/svg.type';
import { ANIMATE_FADE_IN } from '../../constants/animator';
import { toggleLeftSidebar } from '../../redux/slices/browserPreferenceSlice';
import { Tooltip } from '../Tooltip';
import { NavigationLink } from './NavigationLink';
import styles from './ExpandableLinks.module.scss';
type Props = {
  isParentNavExpanded: boolean;
  name: string;
  icon: string;
  links: {
    title: string;
    icon?: React.ReactNode | React.FC<SvgProps> | null;
    iconUrl?: string;
    onClick?: () => void;
    href?: string;
  }[];
  open: boolean;
  className?: string;
  toggleIsOpen: () => void;
};
export function ExpandableLinks(props: Props) {
  const {
    isParentNavExpanded,
    className,
    name,
    icon,
    links,
    open: isOpen,
    toggleIsOpen
  } = props;
  const dispatch = useDispatch();
  const onClick = () => {
    if (!isParentNavExpanded) {
      dispatch(toggleLeftSidebar({
        isLeftSidebarExpanded: true
      }));
    }
    if (!(isOpen && !isParentNavExpanded)) {
      toggleIsOpen();
    }
  };
  return <Tooltip content={name} disabled={isParentNavExpanded} placement="right">
      <div className={`${styles.root}  ${isOpen ? styles.isOpen : styles.isClosed} ${isParentNavExpanded ? styles.isExpanded : styles.collapsed} ${className || ''}`}>
        <button onClick={onClick} className={`${styles.toggleButton} ${isOpen ? styles.toggleButtonOpen : ''}`} aria-label={isOpen ? 'close' : 'open'}>
          <span className={styles.title}>
            <img src={icon} alt="icon" />
            {isParentNavExpanded ? name : <img src="/icons/chevron.svg" alt="arrow" className={styles.arrow} />}
          </span>
          {isParentNavExpanded && <span aria-hidden className={`${styles.arrowButton} ${isOpen ? styles.arrowButtonOpen : ''}`}>
              <img src="/icons/chevron.svg" alt="arrow" />
            </span>}
        </button>

        {isParentNavExpanded && <motion.div initial={ANIMATE_FADE_IN.initial} animate={isOpen ? ANIMATE_FADE_IN.animate : {}} className={styles.navs}>
            {links.map((link, index) => <NavigationLink className={styles.nestedLinks} key={link.title + index} path={link.href} onClick={link.onClick} title={link.title} icon={(link.icon as React.FC<SvgProps>)} iconUrl={link.iconUrl} isExpanded={isParentNavExpanded} />)}
          </motion.div>}
      </div>
    </Tooltip>;
}