import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CrashPlayInput } from 'generated/graphql';
import { CrashGameLiveBet } from 'hooks/games/useCrashGameBetCollator';
import uniqBy from 'lodash/uniqBy';
import CRASH from 'views/games/OriginalGameCrash/constants';

const MAX_RESULTS_HISTORY_SIZE = 64;

export interface CrashGameResult {
  /** Will be defined if the current user placed this bet */
  betId?: string;
  gameId: number;
  isWin: boolean;
  multiplier: number;
  seed: string;
  numPlayers: number;
  numPayouts: number;
}

export interface CrashCurrentPayout extends CrashPlayInput {
  betId: string;
  payout: string;
  cashoutAt: number;
}

export interface CrashCurrentBet extends CrashPlayInput {
  betId: string;
  crashGameId?: number;
}

export interface CrashState {
  betMultiplier: number;
  bets: CrashGameLiveBet[];
  queuedBet: CrashPlayInput | null;
  currentBet: CrashCurrentBet | null;
  currentPayout: CrashCurrentPayout | null;
  recentResults: CrashGameResult[];
}

const initialState: CrashState = {
  betMultiplier: CRASH.CASHOUT_AT_DEFAULT,
  queuedBet: null,
  currentBet: null,
  currentPayout: null,
  recentResults: [],
  bets: [],
};

export const crashSlice = createSlice({
  name: 'crash',
  initialState,
  reducers: {
    appendCrashBets: (state, action: PayloadAction<CrashGameLiveBet[]>) => {
      const _bets = [...state.bets, ...action.payload];
      state.bets = uniqueByBetId(_bets);
    },
    clearCrashBets: state => {
      state.bets = [];
    },
    setCrashBetMultiplier: (state, action: PayloadAction<number>) => {
      state.betMultiplier = action.payload;
    },
    appendCrashRecentResults: (state, action: PayloadAction<CrashGameResult[]>) => {
      // Take the results from the most recent fetch and merge it into our results array.
      const recentWithDuplicates = [...state.recentResults, ...action.payload];
      state.recentResults = uniqBy(recentWithDuplicates, 'gameId')
        .sort((a, b) => b.gameId - a.gameId)
        .slice(0, MAX_RESULTS_HISTORY_SIZE);
    },
    setCurrentPayout: (state, action: PayloadAction<CrashCurrentPayout | null>) => {
      state.currentPayout = action.payload;
    },
    setCurrentBet: (state, action: PayloadAction<CrashCurrentBet | null>) => {
      state.currentBet = action.payload;
    },
    setQueuedCrashBet: (state, action: PayloadAction<CrashPlayInput | null>) => {
      state.queuedBet = action.payload;
    },
    resetCrashData: state => {
      return {
        ...initialState,
        betMultiplier: state.betMultiplier, //betMultiplier persists during active window and only reset on refresh
      };
    },
  },
});

export const {
  setCurrentBet,
  clearCrashBets,
  appendCrashBets,
  setCurrentPayout,
  setCrashBetMultiplier,
  appendCrashRecentResults,
  setQueuedCrashBet,
  resetCrashData,
} = crashSlice.actions;

/**
 * Grab the unique bets from a list of Crash bets, favouring those whose
 * username and VIP level is defined. This ensures that when we get our own
 * bet from the crashGameBetPayout and crashGameMyBetPayout subscriptions,
 * we don't see ourselves as "Hidden" (anonymous).
 */
export const uniqueByBetId = (bets: CrashGameLiveBet[]): CrashGameLiveBet[] => {
  // Map each bet against its bet ID.
  const map = new Map<string, CrashGameLiveBet>();

  bets.forEach(bet => {
    const existing = map.get(bet.betId);

    if (!existing) {
      map.set(bet.betId, { ...bet });
      return;
    }

    // Get the payout that exists; else get the one that is above zero.
    const hasAnyPayout = existing.payout !== undefined || bet.payout !== undefined;
    const payoutAsNumber = hasAnyPayout
      ? Math.max(Number(existing.payout ?? 0), Number(bet.payout ?? 0))
      : undefined;

    let user = null;

    if (existing.user) {
      user = { ...existing.user };
    } else if (bet.user) {
      user = { ...bet.user };
    }

    // We've got this bet already but we found a duplicate with user details.
    const combined: CrashGameLiveBet = {
      ...existing,
      multiplier: existing.multiplier ?? bet.multiplier,
      payout: payoutAsNumber ? payoutAsNumber.toString() : undefined,
      user,
    };

    map.set(bet.betId, combined);
  });

  return Array.from(map.values());
};

export default crashSlice.reducer;
