import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { SportsBetStatus } from '../../../../generated/graphql';
import styles from './BetSlipBadgeStatus.module.scss';
type Props = {
  isLoss: boolean;
  isLost: boolean;
  isPartialAndPayoutLessThanStake: boolean;
  isDeadHeatAndPayoutLessThanStake: boolean;
  isCashedOut: boolean;
  betStatus?: SportsBetStatus;
  isCanceled: boolean;
  isPushed: boolean;
  isVoid: boolean;
  updatedStatus?: string;
};
export const BetSlipBadgeStatus = ({
  isLoss,
  isLost,
  updatedStatus,
  isPartialAndPayoutLessThanStake,
  isDeadHeatAndPayoutLessThanStake,
  isCashedOut,
  betStatus,
  isCanceled,
  isPushed,
  isVoid
}: Props) => {
  const {
    t
  } = useTranslation();
  return <span className={clsx(styles.betStatus, {
    [String(styles.betStatusError)]: isLoss || isLost,
    [String(styles.betStatusWarning)]: isPartialAndPayoutLessThanStake || isDeadHeatAndPayoutLessThanStake,
    [String(styles.betStatusCashedOut)]: isCashedOut || betStatus === SportsBetStatus.CASHED_OUT,
    [String(styles.betStatusCancelled)]: isCanceled || isPushed || isVoid
  })}>
      {betStatus ? t(`${betStatus.toLowerCase()}`) : updatedStatus && t(`${updatedStatus.toLowerCase()}`)}
    </span>;
};