import BigNumber from 'bignumber.js';
import * as React from 'react';
import styles from './TokenPercentageText.module.scss';
export function TokenPercentageText({
  children,
  value,
  className
}: {
  children: React.ReactNode;
  value: number | string;
  className?: string;
}) {
  return <span className={`${BigNumber(value).isPositive() ? styles.positive : styles.negative} ${className ?? ''}`}>
      {children}
    </span>;
}