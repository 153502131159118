import omitBy from 'lodash/omitBy';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { ModalType } from '../components/Modal/GlobalModal.type';

type BuildModalParamsOptions = {
  /** Output a path and query other than the current router's pathname. */
  pathname?: string;
  /** Erase the current query before adding extras */
  override?: boolean;
};

/**
 * The query key that is allowed to control modals.
 * We use `md-` (Modal Data) to pass data into the URL which controls modals.
 * Utilize useModalData to extract the data passed into the URLs for a modal.
 */
export type ModalQueryKey = `md-${string}`;
export type QueryParams = Record<string, string | number>;
export const MODAL_DATA_PREFIX = 'md-';

export const createModalDataKey = (key: string): ModalQueryKey => {
  return `${MODAL_DATA_PREFIX}${key}`;
};

const useQueryParams = () => {
  const router = useRouter();

  const addQueryParams = useCallback(
    (params: Record<string, string>, shallow = true) => {
      router.replace(
        { pathname: router.pathname, query: { ...router.query, ...params } },
        undefined,
        { shallow, scroll: false },
      );
    },
    [router],
  );

  /**
   * Add modal parameters with modal data to the URL.
   * Pass in your parameters such as... `{ username: 'bob' }`
   * and they will get automatically transformed in the URL into the
   * format... `{ md-username: 'bob' }`.
   *
   * This transformation ensures that we can keep track of which query
   * parameters belong to the modal and which are supplementary.
   *
   * See `useModalData` for retreiving this data in your components.
   */
  const buildModalParams = useCallback(
    (
      modal: ModalType['type'],
      params: Record<string, string>,
      options?: BuildModalParamsOptions,
    ) => {
      const [currentPath, existingParams] = router.asPath.split('?');

      const query = new URLSearchParams(options?.override ? undefined : existingParams);
      query.set('modal', modal);

      Object.entries(params).forEach(([key, value]) => {
        query.set(`${MODAL_DATA_PREFIX}${key}`, String(value));
      });

      return `${options?.pathname ?? currentPath}?${query.toString()}`;
    },
    [router.asPath],
  );

  /**
   * Removes all modal related query parameters from a route.
   */
  const removeModalParams = useCallback(() => {
    const _query = omitBy(
      router.query,
      (_, key) => key === 'modal' || key.startsWith(MODAL_DATA_PREFIX),
    );

    const pathname = router.asPath.split('?')[0];
    router.replace({ pathname, query: _query }, undefined, { shallow: true });
  }, [router]);

  /**
   * Removes an arbitrary set of params by keys.
   * Omitting `keys` will remove all params.
   */
  const removeParams = useCallback(
    (keys?: string[]) => {
      const pathname = router.asPath.split('?')[0];

      if (!keys && pathname) {
        router.replace(pathname, undefined, { shallow: true });
        return;
      }

      const _query = omitBy(router.query, (_, key) => keys?.includes(key));
      router.replace({ pathname, query: _query }, undefined, { shallow: true });
    },
    [router],
  );

  return {
    addQueryParams,
    buildModalParams,
    removeModalParams,
    removeParams,
  };
};

export default useQueryParams;
