import WrappedImage from 'components/WrappedImage';
import { BgEnum, FaceEnum, backgrounds, faces } from 'hooks/useAvatar';
import React, { useState } from 'react';
import { imageQuality } from 'utils/imageQuality';
const BASE_AVATAR_SIZE = 40;
const quality = imageQuality();
import styles from './Avatar.module.scss';
interface AvatarProps {
  face?: FaceEnum;
  size?: number;
  background?: BgEnum;
  classNames?: Partial<{
    root: string;
  }>;
}
const Avatar = ({
  face,
  background,
  classNames,
  size = BASE_AVATAR_SIZE
}: AvatarProps) => {
  const [loaded, setLoaded] = useState(false);
  return <div className={`${styles.root} ${loaded ? styles.loaded : ''} ${classNames?.root || ''}`} style={{
    background: !loaded || typeof background === 'undefined' ? 'unset' : backgrounds[background]
  }}>
      {typeof face !== 'undefined' && <WrappedImage onLoadingComplete={() => setLoaded(true)} width={size} height={size} src={faces[face]} alt="user-avatar" quality={quality} />}
    </div>;
};
export default React.memo(Avatar);