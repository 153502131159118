import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Currency, Sports } from 'generated/graphql';

import isString from '../../utils/isString';
import {
  BetSlipItem,
  BetSlipStatus,
  CashOutBetSlip,
  MultiBet,
  SportsBetState,
} from './sportsBetSlice.type';

export type CashOutBetSlips = Record<string, CashOutBetSlip>;

export const POSITION_DEFAULT_VALUE = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};

export const initialState: SportsBetState = {
  betSlips: [],
  cashOutBetSlips: {},
  betSlipStatus: BetSlipStatus.EMPTY,
  multiBet: {
    inputAmount: '',
    betAmount: '',
    selectionIds: [],
  },
  betSlipPlacedErrors: [],
  betSlipOddChangesIds: [],
  mobileBetAddedPosition: POSITION_DEFAULT_VALUE,
  betAddedPosition: POSITION_DEFAULT_VALUE,
  viewData: { region: '', dma: '', device: '' },
  preferredDefaultMarket: {},
};

export const sportsBetSlice = createSlice({
  name: 'sportsBet',
  initialState,
  reducers: {
    setBetSlip: (state: SportsBetState, action: PayloadAction<{ betSlips: BetSlipItem[] }>) => {
      state.betSlips = action.payload.betSlips;
    },
    resetBetSlip: (state: SportsBetState) => {
      state.betSlips = [];
      state.betSlipStatus = BetSlipStatus.EMPTY;
      state.multiBet = initialState.multiBet;
      state.betSlipPlacedErrors = [];
      state.betSlipOddChangesIds = [];
    },
    updatePreferredDefaultMarket: (
      state: SportsBetState,
      action: PayloadAction<{ preferredDefaultMarket: Partial<Record<Sports, string>> }>,
    ) => {
      state.preferredDefaultMarket = {
        ...state.preferredDefaultMarket,
        ...action.payload.preferredDefaultMarket,
      };
    },
    updateMultiBet: (state: SportsBetState, action: PayloadAction<Partial<MultiBet>>) => {
      if (isString(action.payload.betAmount)) {
        state.multiBet.betAmount = action.payload.betAmount;
      }
      if (isString(action.payload.inputAmount)) {
        state.multiBet.inputAmount = action.payload.inputAmount;
      }
      if (action.payload.selectionIds) {
        state.multiBet.selectionIds = action.payload.selectionIds;
      }
    },
    updateAddBetPosition: (
      state: SportsBetState,
      action: PayloadAction<SportsBetState['betAddedPosition']>,
    ) => {
      state.betAddedPosition = {
        top: action.payload.top,
        left: action.payload.left,
        width: action.payload.width,
        height: action.payload.height,
      };
    },
    updateMobileBetPosition: (
      state: SportsBetState,
      action: PayloadAction<SportsBetState['mobileBetAddedPosition']>,
    ) => {
      state.mobileBetAddedPosition = {
        top: action.payload.top,
        left: action.payload.left,
        width: action.payload.width,
        height: action.payload.height,
      };
    },
    changeBetSlipStatus: (
      state: SportsBetState,
      action: PayloadAction<{ betSlipStatus: BetSlipStatus }>,
    ) => {
      state.betSlipStatus = action.payload.betSlipStatus;
    },
    changeBetSlipDropdown: (
      state: SportsBetState,
      action: PayloadAction<{ betSlipOption: BetSlipStatus }>,
    ) => {
      if (
        action.payload.betSlipOption === BetSlipStatus.PENDING_BET ||
        action.payload.betSlipOption === BetSlipStatus.RESULTED_BET
      ) {
        state.betSlips = initialState.betSlips;
        state.multiBet = initialState.multiBet;
      }

      state.betSlipStatus = action.payload.betSlipOption;
    },
    setBetSlipPlacedError: (
      state: SportsBetState,
      action: PayloadAction<{ errors: SportsBetState['betSlipPlacedErrors'] }>,
    ) => {
      state.betSlipPlacedErrors = action.payload.errors;
    },
    setCashOutBetSlips: (
      state: SportsBetState,
      action: PayloadAction<
        {
          id: string;
        } & CashOutBetSlip
      >,
    ) => {
      state.cashOutBetSlips = {
        ...state.cashOutBetSlips,
        [action.payload.id]: {
          amount: action.payload.amount,
          currency: action.payload.currency,
          odds: action.payload.odds,
        },
      };
    },
    setCashOutBetSlipStatus: (
      state: SportsBetState,
      action: PayloadAction<
        {
          id: string;
        } & Pick<CashOutBetSlip, 'status'>
      >,
    ) => {
      const currentBet = state.cashOutBetSlips[action.payload.id];
      state.cashOutBetSlips = {
        ...state.cashOutBetSlips,
        [action.payload.id]: {
          ...(currentBet
            ? currentBet
            : {
                amount: '',
                currency: '' as Currency,
                odds: '',
              }),
          status: action.payload.status,
        },
      };
    },
    updateBetSlipOddsChangesIds: (
      state: SportsBetState,
      action: PayloadAction<{ ids: string[] }>,
    ) => {
      state.betSlipOddChangesIds = action.payload.ids.every(id =>
        state.betSlipOddChangesIds.includes(id),
      )
        ? state.betSlipOddChangesIds
        : [...state.betSlipOddChangesIds, ...action.payload.ids];
    },
    acceptBetSlipOddsChange: (state: SportsBetState, action: PayloadAction<{ id: string }>) => {
      state.betSlipOddChangesIds = state.betSlipOddChangesIds.filter(
        item => item !== action.payload.id,
      );
    },
    resetBetSlipOddsChangesIds: (state: SportsBetState) => {
      state.betSlipOddChangesIds = [];
    },
    updateViewData: (state: SportsBetState, action: PayloadAction<SportsBetState['viewData']>) => {
      state.viewData = action.payload;
    },
  },
});

export const {
  setCashOutBetSlips,
  setBetSlip,
  resetBetSlip,
  changeBetSlipStatus,
  changeBetSlipDropdown,
  updateMultiBet,
  updatePreferredDefaultMarket,
  setBetSlipPlacedError,
  updateAddBetPosition,
  updateMobileBetPosition,
  updateBetSlipOddsChangesIds,
  acceptBetSlipOddsChange,
  resetBetSlipOddsChangesIds,
  updateViewData,
  setCashOutBetSlipStatus,
} = sportsBetSlice.actions;

export default sportsBetSlice.reducer;
