import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// For oauth
// TODO: Merge with authSlice
export interface LoginState {
  loginToken: string;
  oauthToken?: string;
  error?: string;
}

export interface LoginResponse {
  loginToken: string;
  oauthToken?: string;
  error?: string;
}

const initialState: LoginState = {
  loginToken: '',
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    storeDetails: (state: LoginState, action: PayloadAction<Partial<LoginResponse>>) => {
      return { ...state, ...action.payload };
    },
    clearDetails: () => {
      return initialState;
    },
  },
});

export const { storeDetails, clearDetails } = loginSlice.actions;

export default loginSlice.reducer;
