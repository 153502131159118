import usePreference from 'hooks/usePreference';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetChallengeCountQuery, useGetRaceLeaderBoardV2Query } from '../../generated/graphql';
import useGlobalState from '../../hooks/useGlobalState';
import { toggleLeftSidebar } from '../../redux/slices/browserPreferenceSlice';
import NavigationContent, { HOME_ROUTES } from './NavigationContent';
import { NavigationTabs } from './NavigationTabs';
import styles from './DesktopNavigation.module.scss';
type Props = {
  challengeCountData?: GetChallengeCountQuery;
  raceLeaderBoardResult: ReturnType<typeof useGetRaceLeaderBoardV2Query>;
};
export const DesktopNavigation = ({
  challengeCountData,
  raceLeaderBoardResult
}: Props) => {
  const [animationComplete, setAnimationComplete] = useState(true);
  const [animationStart, setAnimationStart] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    isLeftSidebarExpanded: isExpanded
  } = usePreference();
  const {
    activeNavMenuType
  } = useGlobalState();
  const isAtHomeRoute = HOME_ROUTES.find(route => router.pathname.startsWith(route));
  useEffect(() => {
    if (typeof isExpanded === 'undefined') {
      dispatch(toggleLeftSidebar({
        isLeftSidebarExpanded: true
      }));
    }
  }, [dispatch, isExpanded]);
  const toggleSidebar = useCallback(() => {
    setAnimationStart(true);
    dispatch(toggleLeftSidebar({
      isLeftSidebarExpanded: !isExpanded
    }));
  }, [dispatch, isExpanded]);
  const onAnimationComplete = () => {
    setAnimationComplete(true);
  };
  const onAnimationStart = () => {
    setAnimationComplete(false);
  };
  return <>
      {isExpanded && <div className={styles.root}>
          <div className={styles.sidebarOverlay} onClick={toggleSidebar} />
          <div className={styles.sidebarSmallSizePadding} />
        </div>}

      <nav className={`${styles.root} ${styles.sidebar} ${isExpanded ? styles.isExpanded : ''}`}>
        <div className={styles.sidebarHeader}>
          <button className={styles.menu} onClick={toggleSidebar} aria-label="Toggle menu">
            <img src="/icons/menu.svg" alt="menu" />
          </button>
          <NavigationTabs className={animationComplete && isExpanded ? styles.showTabs : styles.hideTabs} isExpanded={isExpanded} active={isAtHomeRoute ? '' : activeNavMenuType} />
        </div>

        <NavigationContent raceLeaderBoardResult={raceLeaderBoardResult} challengeCountData={challengeCountData} animationComplete={animationComplete} onAnimationStart={onAnimationStart} onAnimationComplete={onAnimationComplete} animationStart={animationStart} isExpanded={isExpanded} />
      </nav>
    </>;
};
export default React.memo(DesktopNavigation);