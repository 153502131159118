import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateViewData } from '../redux/slices/sportsBetSlice';

export const GENIUS_API = 'https://viewer-data.production.geniuslive.geniussports.com/';

export function useViewDataSetup() {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateViewerData = async () => {
      try {
        const data = await fetch(GENIUS_API);
        const viewerData = await data.json();
        dispatch(updateViewData(viewerData));
      } catch {}
    };

    updateViewerData();
  }, [dispatch]);
}
