import { Sports, SportsMarketProvider } from 'generated/graphql';
import groupBy from 'lodash/groupBy';

import { BetSlipItem } from '../redux/slices/sportsBetSlice.type';

type Props = {
  betSlipData: BetSlipItem[];
};

export function checkMultiBet({ betSlipData }: Props) {
  if (betSlipData.length < 2) {
    return false;
  }

  const groupedBets = groupBy(betSlipData, 'fixtureName');
  const groupedBetsBySport = groupBy(betSlipData, 'sport');
  const groupedBetsByProvider = groupBy(betSlipData, 'provider');

  const hasManualMarket = (groupedBetsByProvider?.[SportsMarketProvider.SHUFFLE]?.length ?? 0) > 0;
  const hasDifferentProvider = Object.keys(groupedBetsByProvider).length > 1;

  if (hasManualMarket || hasDifferentProvider) {
    return false;
  }

  if (hasDifferentProvider) {
    return false;
  }

  const groupedBetsSameCompetition = groupBy(
    groupedBetsBySport[Sports.MOTOR_SPORT],
    'competitionId',
  );
  const isNotCheckMulti = groupedBetsSameCompetition
    ? Object.values(groupedBetsSameCompetition).some(item => item.length > 1)
    : false;

  if (isNotCheckMulti) {
    return false;
  }

  for (const key in groupedBets) {
    const groupedBet = groupedBets[key];
    if (typeof groupedBet?.length !== 'undefined' && groupedBet.length > 1) {
      return false;
    }
  }

  return true;
}
