import { useGetMyProfileQuery } from 'generated/graphql';
import { useBalanceUpdates } from 'hooks/wallet/useBalanceUpdates';
import posthog from 'posthog-js';
import { useRef } from 'react';
import { isProduction } from '../constants/environment';
import { DEFAULT_STORE } from './defaultExternalStore';
import { useStore } from './useStore';

export const useMyProfile = () => {
  const { setState } = useStore();
  const hasUserBalancedUpdateFromProfile = useRef(false);
  const posthogIdentified = useRef(false);

  const { updateBalances } = useBalanceUpdates();

  const { data, error } = useGetMyProfileQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const user = data?.me;

      if (user && !error) {
        if (isProduction && user.id && !posthogIdentified.current) {
          posthog.identify(user.id, {
            id: user.id,
            name: user.username,
            username: user.username,
            email: user.email,
            vipLevel: user.vipLevel,
          });
          posthogIdentified.current = true;
        }

        setState('profile', () => {
          return { ...user } as (typeof DEFAULT_STORE)['profile'];
        });
        if (!hasUserBalancedUpdateFromProfile.current) {
          updateBalances(user.account.balances);
          hasUserBalancedUpdateFromProfile.current = true;
        }
      }
    },
  });
};
