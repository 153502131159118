import { useTranslation } from 'next-i18next';
import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice.type';
import BetMessage from './BetMessage';
type Props = {
  placedBetError?: boolean;
  isInBetAddView: boolean;
  betSlipStatus?: BetSlipStatus;
  exceedMaxAllowedStakeAmount?: boolean;
  showError: boolean;
  errorText: string;
};
export function BetTitleHeaderErrors({
  exceedMaxAllowedStakeAmount,
  placedBetError,
  betSlipStatus,
  isInBetAddView,
  showError,
  errorText
}: Props) {
  const {
    t
  } = useTranslation();
  return <>
      {betSlipStatus === BetSlipStatus.BET_PLACED && placedBetError && !exceedMaxAllowedStakeAmount && <BetMessage msgType="error" message={t('txtBetCouldNotBePlaced')} />}

      {betSlipStatus === BetSlipStatus.BET_PLACED && exceedMaxAllowedStakeAmount && <BetMessage msgType="error" message={t('betSlipMaxStakeError')} />}

      {isInBetAddView && showError && <BetMessage msgType="error" message={t(errorText)} />}
    </>;
}