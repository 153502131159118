import { captureMessage } from '@sentry/nextjs';
import { useDispatch } from 'react-redux';
import { clientWithoutAuth } from '../apollo/clients';
import { useGetAppSettingAndPricesQuery } from '../generated/graphql';
import { BrowserPreferencesState } from '../redux/slices/browserPreferenceSlice';
import { updatePrices } from '../redux/slices/pricesSlice';
import { usePersistAppSetting } from './usePersistAppSetting';

const POLLING_MS = 30_000;

export const usePricesAndAppSettingPoll = ({
  browserPreference: { fiatPreference },
}: {
  browserPreference: BrowserPreferencesState;
}): void => {
  const dispatch = useDispatch();

  const { data } = useGetAppSettingAndPricesQuery({
    variables: {
      currency: fiatPreference,
    },
    pollInterval: POLLING_MS,
    client: clientWithoutAuth,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (data.fiatCurrency !== fiatPreference) {
        return captureMessage('GetPrices not matching correct fiat currency');
      }

      if (data?.cryptoPrices || data?.fiatPrices) {
        const pricesObjArr = data?.cryptoPrices;
        const fiatPricesObjArr = data?.fiatPrices;

        dispatch(
          updatePrices({
            fiatCurrency: fiatPreference,
            prices: pricesObjArr,
            fiatPrices: fiatPricesObjArr,
          }),
        );
      }
    },
  });

  usePersistAppSetting({
    setting: data?.appSettings,
  });
};
