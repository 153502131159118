import { CurrencyMapping, DEFAULT_BALANCES } from 'hooks/wallet/useBalanceUpdates';
import {
  Currency,
  Language,
  LoginVerificationMethod,
  OauthProvider,
  Sports,
  SportsAcceptOddsChange,
  UserAuthMethod,
  VipLevel,
} from '../generated/graphql';
import { SportsFixtureStoreType } from './defaultExternalStore.type';
import { Popup } from './usePopup.type';

const PROFILE_DEFAULT = {
  id: null as null | string,
  username: null as null | string,
  email: '',
  emailVerifiedAt: '',
  vipLevel: VipLevel.UNRANKED,
  createdAt: '',
  otpSentAt: '',
  updatedAt: '',
  passwordUpdatedAt: '',
  anonymous: false,
  marketingConsentEnabled: true,
  acceptOddsChange: SportsAcceptOddsChange.ANY,
  linkedOauthProviders: [] as Array<OauthProvider>,
  verificationMethod: UserAuthMethod.DEFAULT,
  loginVerificationMethod: LoginVerificationMethod.NONE,
  chatBanUntil: null as string | null,
  avatar: 0,
  avatarBackground: 0,
  bets: null as number | null,
  language: Language.EN,
  intercomToken: '',
  usdWagered: null as number | null,
  xp: null as number | null,
  raceInfo: {
    id: '',
    startingWager: '',
    rank: null as number | null,
    raceEntryId: '' as string | null,
  } as {
    id: string;
    startingWager: string;
    rank: number | null;
    raceEntryId: string | null;
  } | null,
  currentSession: null as {
    id: string;
    ip?: string | null;
    ua?: string | null;
    os?: string | null;
    device?: string | null;
    browser?: string | null;
    country?: string | null;
    city?: string | null;
    updatedAt: string;
    createdAt: string;
    refreshedAt?: string | null;
    lastUsedAt: string;
    active: boolean;
  } | null,
  account: {
    id: '',
    balances: [] as Array<{
      __typename?: 'Balance';
      currency: Currency;
      amount: string;
    }>,
    vaultBalances: [] as Array<{
      __typename?: 'CurrencyAmount';
      currency: Currency;
      amount: string;
    }>,
  },
};

export const DEFAULT_STORE = {
  popups: {} as Record<string, Popup>,
  isDragging: false,
  width: typeof window === 'undefined' ? 0 : window.innerWidth,
  contentWidth: typeof window === 'undefined' ? 0 : window.innerWidth,
  scrollTop: typeof window === 'undefined' ? 0 : document.documentElement.scrollTop,
  sportsFixture: {} as SportsFixtureStoreType,
  sportsCounter: {
    liveTotal: null as null | number,
    upcomingTotal: null as null | number,
    live: {} as Partial<Record<Sports, number>>,
    upcoming: {} as Partial<Record<Sports, number>>,
  },
  profile: PROFILE_DEFAULT,
  balances: {
    values: DEFAULT_BALANCES,
    // will always be crypto values, not fiat
    balancesDeltas: {} as Partial<CurrencyMapping>,
    hasSetInitial: false,
  },
};
