import { ReactNode } from 'react';
export const LinkTarget = ({
  children,
  marginLess,
  onClick
}: {
  children: ReactNode;
  marginLess?: boolean;
  onClick: () => void;
}) => {
  return <a onClick={() => onClick()} style={marginLess ? {
    marginTop: 'unset'
  } : {}}>
      {children}
    </a>;
};