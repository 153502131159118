import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

import { PAGE_CONTENT_ID } from '../layouts/constants';

export function useScrollToTop() {
  const { pathname } = useRouter();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = document.getElementById(PAGE_CONTENT_ID);

    if (node) {
      containerRef.current = node as HTMLDivElement;
    }
  }, []);

  const scrollToTop = useCallback(() => {
    containerRef.current?.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (pathname) {
      scrollToTop();
    }
  }, [pathname, scrollToTop]);

  return {
    scrollToTop,
  };
}
