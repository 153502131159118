import { UAParser } from 'ua-parser-js';

const parser = new UAParser();
const { type } = parser.getDevice();

export const userAgent = parser.getResult();

export const isMobile = type === 'mobile' || type === 'tablet';

export const checkIsMobileFromUserAgent = (userAgent?: string) => {
  const parser = new UAParser(userAgent);
  const { type } = parser.getDevice();
  return type === 'mobile' || type === 'tablet';
};

// using hls
export const checkIos = () => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') return false;

  return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator?.vendor);
};

// using hls
export const checkSafariOnMacOs = () => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') return false;

  return (
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    /Mac OS X/i.test(navigator.userAgent || navigator?.vendor)
  );
};
