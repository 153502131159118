import BigNumber from 'bignumber.js';
import { SelectOption } from 'components/FormControls/Select';
import { EDGE_BPS } from 'constants/games';
import {
  Currency,
  GetBetInfoQuery,
  GetMinesActiveBetQuery,
  ShuffleOriginalAction,
} from 'generated/graphql';
import { Mines } from 'shufflecom-calculations/lib/games/mines';

export enum MinesGameOutcome {
  WIN = 'WIN',
  LOSS = 'LOSS',
}

export enum MinesFlowState {
  NO_GAME_ACTIVE = 'NO_GAME_ACTIVE',
  GAME_IN_PROGRESS = 'GAME_IN_PROGRESS',
  VIEWING_RESULTS = 'VIEWING_RESULTS',
}

interface GetStateReturn {
  currency?: Currency; // no currency if bet is not defined, default to cryptoPreference
  selectedTiles: number[];
  mineLocations: number[];
  outcome: MinesGameOutcome | null;
  minesCount: number;
  numMinesRemaining: number;
  numGemsRemaining: number;
  state: MinesFlowState;
  betAmount: BigNumber;
}

export class MinesUtils {
  static DEFAULT_MINES_QTY = 3;
  static AUTOBET_VIEWING_DELAY = 1000;
  static AUTOBET_RESET_DELAY = 200;
  static TURBO_AUTOBET_VIEWING_DELAY = 400;
  static TURBO_AUTOBET_RESET_DELAY = 100;
  static MINES_TILES_COUNT = 25;
  static MINES_SELECT_OPTIONS: SelectOption<string>[] = [
    ...Array(this.MINES_TILES_COUNT - 1).keys(),
  ].map(index => ({
    value: String(index + 1),
    label: String(index + 1),
  }));

  static deriveSelectedTiles = (actions?: Array<ShuffleOriginalAction> | null) => {
    if (!actions?.length) {
      return [];
    }

    return actions.flatMap(({ action }) => action.mines?.selected ?? []);
  };

  static deriveMineLocations = (actions?: Array<ShuffleOriginalAction> | null) => {
    const actionHavingMine = actions?.find(a => a.action.mines?.minesResult?.length ?? false);
    return actionHavingMine?.action.mines?.minesResult ?? [];
  };

  static getMinesBetInfo = (actions: GetBetInfoQuery['bet']['shuffleOriginalActions']) => {
    const detailsArr =
      actions?.map(a => ({
        minesResult: a.action.mines?.minesResult ?? [],
        selected: a.action.mines?.selected ?? [],
      })) ?? [];

    const totalSelected: number[] = [];
    const totalMines: number[] = [];
    detailsArr.forEach(({ minesResult, selected }) => {
      totalSelected.push(...selected);
      totalMines.push(...minesResult);
    });

    return { selected: totalSelected, mines: totalMines };
  };

  static deriveOutcome = (bet?: GetMinesActiveBetQuery['minesActiveBet'] | null) => {
    // We can't derive WIN from their payout because a bet of ZERO is valid.
    const actions = bet?.shuffleOriginalActions ?? [];
    const finalAction = actions[actions.length - 1]?.action.mines ?? null;

    if (!finalAction || !finalAction?.minesResult || finalAction?.minesResult?.length === 0) {
      return null;
    }

    // If we have our mines result but we didn't select anything, that's a win.
    const isWin =
      (finalAction.minesResult.length >= 1 && finalAction.selected?.length === 0) ||
      BigNumber(bet?.multiplier ?? 0).isGreaterThan(0);
    return isWin ? MinesGameOutcome.WIN : MinesGameOutcome.LOSS;
  };

  static deriveAutoOutcome = (bet?: GetMinesActiveBetQuery['minesActiveBet'] | null) => {
    const actions = bet?.shuffleOriginalActions ?? [];

    // Mines auto always has a single action before cashout.
    const action = actions[0]?.action.mines ?? null;
    if (!action || !action.minesResult) {
      return null;
    }

    const selected = action.selected ?? [];
    const hasHitMine = action.minesResult.some(mine => selected.includes(mine));
    return hasHitMine ? MinesGameOutcome.LOSS : MinesGameOutcome.WIN;
  };

  static calculateMinesMultiplier = (gemsFound: number, mines: number) => {
    if (!mines) return BigNumber(0);

    // Ensure that gemsFound + 1 + minesQuantity doesn't exceed MINES_TILES_COUNT
    // This occurs in the case, for example, where mines = 24 on your first move.
    const minesCapped = Math.min(this.MINES_TILES_COUNT - gemsFound, mines);

    return Mines.calculateMultiplierBN(gemsFound, minesCapped, EDGE_BPS);
  };

  static getState = (bet: GetMinesActiveBetQuery['minesActiveBet']): GetStateReturn => {
    const actions = bet?.shuffleOriginalActions ?? [];
    if (!actions || actions.length === 0 || !bet) {
      return {
        selectedTiles: [],
        mineLocations: [],
        minesCount: MinesUtils.DEFAULT_MINES_QTY,
        numMinesRemaining: MinesUtils.DEFAULT_MINES_QTY,
        numGemsRemaining: MinesUtils.MINES_TILES_COUNT - MinesUtils.DEFAULT_MINES_QTY,
        outcome: null,
        state: MinesFlowState.NO_GAME_ACTIVE,
        betAmount: BigNumber(0),
      };
    }
    // if it comes here, it means that the game is either in progress or the player is viewing results
    const outcome = MinesUtils.deriveOutcome(bet);
    const minesCount = bet?.shuffleOriginalActions?.[0]?.action.mines?.minesCount ?? 0;
    const numMinesRemaining = outcome === MinesGameOutcome.LOSS ? minesCount - 1 : minesCount;
    const state = outcome ? MinesFlowState.VIEWING_RESULTS : MinesFlowState.GAME_IN_PROGRESS;
    const selectedTiles = MinesUtils.deriveSelectedTiles(bet?.shuffleOriginalActions);

    return {
      currency: bet?.currency,
      selectedTiles,
      mineLocations: MinesUtils.deriveMineLocations(bet?.shuffleOriginalActions),
      outcome,
      minesCount,
      numMinesRemaining,
      numGemsRemaining: MinesUtils.MINES_TILES_COUNT - minesCount - selectedTiles.length,
      state,
      betAmount: new BigNumber(bet.amount),
    };
  };
}
