import colors from 'assets/styles/colors';
import BigNumber from 'bignumber.js';
import { GameOutcome, IGameResult, InputErrors } from 'constants/games';

interface LimboGameState {
  recentResults: ILimboGameResult[];
  result?: ILimboGameResult;
  winChanceError: InputErrors;
  multiplierError: InputErrors;
}

export const LIMBO_CONSTANTS = {
  WIN_CHANCE_DP: 8,
  MULTIPLIER_DP: 2,
  LIMBO_RESULT_TRANSITION_TIME: 250, // needs to match the duration of the ticking sound
  TURBO_RESULT_TRANSITION_TIME: 100,
  LIMBO_RESULTS_PAUSE: 150,
  MIN_MULTIPLIER: 1.01,
  MAX_MULTIPLIER: 1000000,
  MIN_WIN_CHANCE: 0.000099,
  MAX_WIN_CHANCE: 98.01980198,
  INITIAL_STATE: {
    recentResults: [],
    result: undefined,
    winChanceError: InputErrors.NONE,
    multiplierError: InputErrors.NONE,
  } as LimboGameState,
  LIMBO_DEFAULT_MULTIPLIER: '2.00',
};

export enum LimboStatus {
  NONE = 0,
  WIN = 1,
  LOSS = 2,
}

export interface ILimboGameResult extends IGameResult {
  betTargetMultiplier: string;
}

export class LimboCalculations {
  // returnToPlayer = multiplier * winChance/100
  static returnToPlayer = BigNumber(0.99);

  // win chance is a percentage
  static calculateWinChanceFromMultiplier(multiplier: BigNumber) {
    return BigNumber(LimboCalculations.returnToPlayer).dividedBy(multiplier).multipliedBy(100);
  }

  static calculateMultiplierFromWinChance(winChance: BigNumber) {
    return BigNumber(LimboCalculations.returnToPlayer).dividedBy(winChance).multipliedBy(100);
  }

  static calculateProfitOnWin(multiplier: BigNumber, betAmount: BigNumber) {
    return multiplier.minus(1).multipliedBy(betAmount);
  }

  /**
   *
   * @param bet The multiplier that the player bet on
   * @param actual The actual multiplier
   * @returns DRAW if the bet and actual are the same, WIN if the actual is greater than the bet, LOSE otherwise
   */
  static getGameOutcome(bet: BigNumber, actual: BigNumber) {
    if (bet.isEqualTo(actual)) {
      return GameOutcome.DRAW;
    }

    return bet.isLessThan(actual) ? GameOutcome.WIN : GameOutcome.LOSE;
  }
}

export const getLimboResultColor = (status: LimboStatus) => {
  switch (status) {
    case LimboStatus.LOSS:
      return colors.error;
    case LimboStatus.WIN:
      return colors.success;
    default:
      return colors.white;
  }
};
