import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sportsStreamClient } from '../apollo/clients';
import {
  CreateSportsStreamMutation,
  FixtureStreamProtocol,
  SportsStreamInfoQuery,
  SportsStreamProvider,
  useCreateSportsStreamMutation,
  useSportsStreamInfoLazyQuery,
} from '../generated/graphql';
import { updateViewData } from '../redux/slices/sportsBetSlice';
import { checkIos, checkSafariOnMacOs } from '../utils/userAgent';
import { useLanguagePreference } from './useLanguagePreference';
import { PopupType } from './usePopup.type';
import { GENIUS_API } from './useViewDataSetup';

const defaultStreamSource = {
  url: '',
  expiresAt: '',
  token: '',
  providerId: '',
  drm: {
    fairplay: '',
    fairplayCertificate: '',
    playready: '',
    widevine: '',
  },
};

const NFL = 'NFL';

type Props = {
  id: string;
  competitionName?: string;
  type: PopupType | '';
};

function chosenProtocol({
  sportsStreamInfo,
  competitionName,
}: {
  sportsStreamInfo?: SportsStreamInfoQuery | null;
  competitionName?: string;
}) {
  const hasHLS = sportsStreamInfo?.sportsStreamInfo?.find(result =>
    result?.protocols?.find(protocol => protocol === FixtureStreamProtocol.HLS),
  );
  const hasDash = sportsStreamInfo?.sportsStreamInfo?.find(result =>
    result?.protocols?.find(protocol => protocol === FixtureStreamProtocol.DASH),
  );

  if ((!hasHLS && !hasDash) || (checkIos() && !hasHLS)) {
    return '';
  }

  // apply extra check for DRM
  if (competitionName === NFL) {
    if ((checkIos() || checkSafariOnMacOs()) && hasHLS) {
      return FixtureStreamProtocol.HLS;
    }
    if (!(checkIos() || checkSafariOnMacOs()) && hasDash) {
      return FixtureStreamProtocol.DASH;
    }

    return '';
  }

  if (hasHLS) {
    return FixtureStreamProtocol.HLS;
  }
  if (hasDash && !checkIos()) {
    return FixtureStreamProtocol.DASH;
  }

  return '';
}

export function useCheckVideoStream({ id, competitionName, type }: Props) {
  const { languagePreference } = useLanguagePreference();
  const [videoStreamSource, setVideoStreamSource] =
    useState<CreateSportsStreamMutation['createSportsStream']>(defaultStreamSource);

  const storedViewData = useSelector((app: AppState) => app.sportsBet.viewData);
  const dispatch = useDispatch();

  const [sportsStreamInfo] = useSportsStreamInfoLazyQuery({
    client: sportsStreamClient,
  });
  const [create] = useCreateSportsStreamMutation({
    client: sportsStreamClient,
  });

  const checkStream = useCallback(
    async (payload?: { fixtureId: string }) => {
      try {
        let viewerData;

        if (!type) {
          return { error: 'generic' };
        }

        if (!storedViewData?.device) {
          const data = await fetch(GENIUS_API);
          viewerData = await data.json();
          dispatch(updateViewData(viewerData));
        } else {
          viewerData = storedViewData;
        }

        if (type === 'video-genius' && (!viewerData.region || !viewerData.device)) {
          return { error: 'generic' };
        }

        const sportsStreamInfoResult = await sportsStreamInfo({
          variables: {
            fixtureId: payload?.fixtureId ?? id,
            viewerData,
            lang: languagePreference,
          },
        });

        if (type === 'video-genius') {
          if (sportsStreamInfoResult.error) {
            return { error: 'generic' };
          }

          const protocol = chosenProtocol({
            sportsStreamInfo: sportsStreamInfoResult.data,
            competitionName,
          });

          if (!protocol) {
            return { error: 'generic' };
          }

          const createStreamResult = await create({
            variables: {
              data: {
                fixtureId: payload?.fixtureId ?? id,
                protocol: protocol as FixtureStreamProtocol,
                provider: SportsStreamProvider.GENIUS,
                viewerData,
              },
            },
          });

          if (createStreamResult.errors) {
            return {
              errors: createStreamResult.errors,
            };
          }

          if (!createStreamResult.data?.createSportsStream) {
            return { error: 'generic' };
          }

          setVideoStreamSource(createStreamResult.data.createSportsStream);

          return {
            videoStreamSource: createStreamResult.data.createSportsStream,
          };
        }

        if (type === 'video-oddin') {
          const streamDetail = sportsStreamInfoResult.data?.sportsStreamInfo
            ?.find(info => info.streams)
            ?.streams?.at(0);

          const videoStreamSource = {
            url: streamDetail?.streamUrl ?? '',
            type: streamDetail?.name,
            providerId: type,
            expiresAt: '',
            token: '',
          };

          setVideoStreamSource(videoStreamSource);

          return {
            videoStreamSource,
          };
        }

        return {
          videoStreamSource: { url: '', expiresAt: '', token: '' },
        };
      } catch (e) {
        console.error(`Failed to get stream detail ${(e as Error)?.message ?? ''}`);
        return { error: 'generic' };
      }
    },
    [
      storedViewData,
      languagePreference,
      sportsStreamInfo,
      id,
      competitionName,
      create,
      dispatch,
      type,
    ],
  );

  return {
    checkStream,
    videoStreamSource,
  };
}
