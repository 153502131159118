import { useSelector } from 'react-redux';

import { useCountryLocale } from './useCountryLocale';
import { useGlobalData } from './useGlobalData';
import { useLanguagePreference } from './useLanguagePreference';

/**
 * Determine if the user is currently accessing the website from a
 * restricted region.
 */
const useGeoRestriction = () => {
  const { languagePreference } = useLanguagePreference();

  const { t: localeT } = useCountryLocale(languagePreference);

  const userCountry = useSelector((state: AppState) => state.globalState.userCountry);
  const geoRestrictionWarningPopupCounter = useSelector(
    (state: AppState) => state.globalState.geoRestrictionWarningPopupCounter,
  );

  const { appSettings } = useGlobalData();

  const countryName = userCountry ? localeT(userCountry) : '';

  return {
    isGeoRestricted: !!userCountry && !!appSettings?.geoRestrictedRegions?.includes(userCountry),
    country: userCountry,
    countryName: countryName,
    geoRestrictionWarningPopupCounter,
  };
};

export default useGeoRestriction;
