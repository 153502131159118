import { AnimationProps, motion } from 'framer-motion';
import * as React from 'react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ANIMATE_BOTTOM_TO_UP } from '../../constants/animator';
import useWindowWidth, { Breakpoint, lessThanBreakpoint } from '../../hooks/interface/useWindowWidth';
import { MobileModal } from './MobileModal';
import { ModalClose } from './ModalClose';
import { ModalContent } from './ModalContent';
import styles from './Modal.module.scss';
export const MODAL_NAME = ('modal-component' as const);
type Props = {
  target?: typeof MODAL_NAME;
  footer?: ReactNode | string;
  onClose?: () => void;
  onBack?: () => void;
  isOpen?: boolean;
  children?: React.ReactNode;
  fullscreen?: boolean;
  classNames?: Partial<{
    modalRoot: string;
    modalContent: string;
    modalBody: string;
    modalClose: string;
    mobileModal: string;
  }>;
  animation?: Partial<AnimationProps>;
  refs?: Partial<{
    modalRoot: React.RefObject<HTMLDivElement>;
    modalContent: React.RefObject<HTMLDivElement>;
    modalBody: React.RefObject<HTMLDivElement>;
    modalClose: React.RefObject<HTMLDivElement>;
    mobileModal: React.RefObject<HTMLDivElement>;
  }>;
};
export function Modal({
  target,
  footer,
  isOpen,
  classNames,
  onClose,
  onBack,
  children,
  fullscreen,
  animation,
  refs
}: Props) {
  const [open, setOpen] = useState(!!isOpen);
  const targetDom = typeof document === 'undefined' ? '' : document.querySelector(`#${target}`);
  const {
    width
  } = useWindowWidth();
  const isMobile = lessThanBreakpoint({
    width,
    breakpoint: Breakpoint.SM
  });
  const toggleOpenByDerivedState = useCallback(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen);
    }
  }, [isOpen]);
  useEffect(() => {
    const closeModal = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onClose) {
        setOpen(false);
        onClose();
      }
    };
    window.addEventListener('keyup', closeModal);
    return () => window.removeEventListener('keyup', closeModal);
  }, [onClose]);
  useEffect(() => toggleOpenByDerivedState(), [toggleOpenByDerivedState]);
  if (!open) {
    return null;
  }
  if (isMobile && !fullscreen) {
    return <MobileModal open={open} onModalClose={onClose} className={classNames?.mobileModal} footer={footer}>
        {children}
      </MobileModal>;
  }
  if (fullscreen) {
    return <motion.div initial={animation?.initial ?? ANIMATE_BOTTOM_TO_UP.initial} animate={animation?.animate ?? ANIMATE_BOTTOM_TO_UP.animate} transition={animation?.transition} className={styles.fullscreen}>
        {onClose && <ModalClose onModalClose={onClose} classNames={classNames} />}

        {children}
      </motion.div>;
  }
  if (targetDom) {
    return ReactDOM.createPortal(<ModalContent onModalClose={onClose} open={open} classNames={classNames} animation={animation} footer={footer} onBack={onBack} refs={refs}>
        {children}
      </ModalContent>, targetDom);
  }
  return <ModalContent onModalClose={onClose} open={open} onBack={onBack} classNames={classNames} animation={animation} footer={footer}>
      {children}
    </ModalContent>;
}