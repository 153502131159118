import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { TextInput } from '../../FormControls/TextInput';
import styles from './BalanceTypeSearch.module.scss';
type Props = {
  setFilterCrypto: React.Dispatch<React.SetStateAction<string>>;
  filterCrypto: string;
};
export function BalanceTypeSearch(props: Props) {
  const {
    t
  } = useTranslation();
  return <div className={styles.root}>
      <div className={styles.wrapper}>
        <TextInput prefix={<img src="/icons/search.svg" alt="search" />} placeholder={t('phSearch')} onChange={e => {
        props.setFilterCrypto(e.target.value);
      }} value={props.filterCrypto} />

        <button aria-label="Clear input" className={`${styles.cleanButton} ${props.filterCrypto ? '' : styles.hideButton}`} tabIndex={props.filterCrypto ? undefined : -1} onClick={() => {
        props.setFilterCrypto('');
      }}>
          <img aria-hidden="true" src="/icons/c-remove.svg" alt="close" />
        </button>
      </div>
    </div>;
}