import { ModalTypes } from 'constants/modal';
import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  changeBetSlipDropdown,
  changeBetSlipStatus,
  initialState,
  resetBetSlip,
  setBetSlip,
  setBetSlipPlacedError,
  updateBetSlipOddsChangesIds,
  updateMultiBet,
} from 'redux/slices/sportsBetSlice';
import { calculateEstimatePayout } from 'utils/calculateSportsBet';

import { GeneralModalProps } from '../components/modals/GeneralModal/GeneralModal';
import { checkMultiBet } from '../utils/checkMultiBet';
import { MAX_NUMBER_OF_BETS } from '../views/BetSlip/components/BetSlip';

import BigNumber from 'bignumber.js';
import { getSportsMarketStatus } from 'utils/getSportsMarketStatus';
import {
  BetSlipItem,
  BetSlipStatus,
  MultiBet,
  SportsBetState,
} from '../redux/slices/sportsBetSlice.type';
import { useConversion } from './useConversion';
import { useGlobalData } from './useGlobalData';
import usePreference from './usePreference';
import { useBalances } from './wallet/useBalances';

export const useSportsBetValue = () => {
  return useSelector((state: AppState) => state.sportsBet, shallowEqual);
};

export const useSportsBet = () => {
  const { betSlips, betSlipStatus, multiBet } = useSelector(
    (state: AppState) => state.sportsBet,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateMultiBetAction = useCallback(
    (multiBet: Partial<MultiBet>) => dispatch(updateMultiBet(multiBet)),
    [dispatch],
  );

  const updateSelectionIds = useCallback(
    (id: string) =>
      updateMultiBetAction({
        selectionIds: [...new Set([...multiBet.selectionIds, id])].slice(0, MAX_NUMBER_OF_BETS),
      }),
    [multiBet.selectionIds, updateMultiBetAction],
  );

  const addSportsBet = useCallback(
    ({
      selection,
      setCurrentModal,
    }: {
      selection: BetSlipItem;
      setCurrentModal: Dispatch<SetStateAction<GeneralModalProps['currentModal']>>;
    }) => {
      if (betSlipStatus !== BetSlipStatus.BET_PLACED && betSlips.length === MAX_NUMBER_OF_BETS) {
        setCurrentModal?.({
          type: ModalTypes.GENERAL,
          config: {
            header: t('txtMaxBetsReached'),
            body: t('txtMaxBetsReachedDescription', {
              maxBets: MAX_NUMBER_OF_BETS,
            }),
            confirmBtnText: t('btnDone'),
            onConfirm: () =>
              setCurrentModal?.(data => ({
                ...data,
                type: '',
              })),
          },
        });
        return;
      }

      const placedBet = betSlipStatus === BetSlipStatus.BET_PLACED;
      const currentBetSlip = betSlips;

      if (betSlipStatus === BetSlipStatus.BET_PLACED) {
        dispatch(resetBetSlip());
        dispatch(changeBetSlipStatus({ betSlipStatus: BetSlipStatus.ADDING_BET }));
        dispatch(
          updateMultiBet({
            selectionIds: [selection.id],
          }),
        );
      } else {
        updateSelectionIds(selection.id);
      }

      if (!currentBetSlip.length || betSlipStatus === BetSlipStatus.CONFIRMING_BET) {
        dispatch(changeBetSlipStatus({ betSlipStatus: BetSlipStatus.ADDING_BET }));
        dispatch(setBetSlipPlacedError({ errors: [] }));
      }

      if (
        betSlipStatus === BetSlipStatus.RESULTED_BET ||
        betSlipStatus === BetSlipStatus.PENDING_BET
      ) {
        dispatch(
          changeBetSlipDropdown({
            betSlipOption: BetSlipStatus.ADDING_BET,
          }),
        );
      }

      const betSlipItem = {
        ...selection,
        betAmount: '',
        estPayout: '',
      };
      const newBetSlip = [...(placedBet ? [] : currentBetSlip), betSlipItem];

      if (
        !checkMultiBet({
          betSlipData: newBetSlip,
        }) &&
        newBetSlip.length > 1
      ) {
        dispatch(
          updateMultiBet({
            selectionIds: [],
            betAmount: initialState.multiBet.betAmount,
            inputAmount: initialState.multiBet.inputAmount,
          }),
        );
      } else {
        const updateSelectIds = newBetSlip.map(item => item.id);
        updateMultiBetAction({
          selectionIds: updateSelectIds,
        });
      }
      dispatch(setBetSlip({ betSlips: newBetSlip }));
    },

    [betSlipStatus, betSlips, dispatch, t, updateSelectionIds, updateMultiBetAction],
  );

  const removeSportBet = useCallback(
    (selection: BetSlipItem) => {
      const newBetSlip = betSlips.filter(item => item.id !== selection.id);
      dispatch(setBetSlip({ betSlips: newBetSlip }));

      if (newBetSlip.length === 0) {
        dispatch(changeBetSlipStatus({ betSlipStatus: BetSlipStatus.EMPTY }));
      }

      if (selection.id) {
        const updateSelectIds = newBetSlip.map(item => item.id);
        updateMultiBetAction({
          selectionIds: updateSelectIds,
        });
      }

      if (
        !checkMultiBet({
          betSlipData: newBetSlip,
        })
      ) {
        dispatch(
          updateMultiBet({
            selectionIds: [],
            betAmount: initialState.multiBet.betAmount,
            inputAmount: initialState.multiBet.inputAmount,
          }),
        );
      }
    },
    [betSlips, dispatch, updateMultiBetAction],
  );

  const clearBetSlip = useCallback(() => {
    dispatch(resetBetSlip());
  }, [dispatch]);

  const updateBetAmount = useCallback(
    ({
      inputAmount,
      betAmount,
      selection,
    }: { inputAmount: string; betAmount: string; selection: BetSlipItem }) => {
      const newBetSlips = betSlips.map(item => {
        if (item.id === selection.id) {
          return {
            ...item,
            inputAmount,
            betAmount,
            estPayout: calculateEstimatePayout({
              betAmount,
              oddsNumerator: item.oddsNumerator,
              oddsDenominator: item.oddsDenominator,
            }),
          };
        }
        return item;
      });

      dispatch(setBetSlip({ betSlips: newBetSlips }));
    },
    [betSlips, dispatch],
  );

  const updateBetSlipStatus = useCallback(
    (betSlipStatus: BetSlipStatus) => {
      dispatch(changeBetSlipStatus({ betSlipStatus }));
    },
    [dispatch],
  );

  const setBetSlipPlacedErrorsAction = useCallback(
    (errors: SportsBetState['betSlipPlacedErrors']) => {
      dispatch(setBetSlipPlacedError({ errors }));
      if (!!errors.length && betSlipStatus === BetSlipStatus.CONFIRMING_BET) {
        dispatch(changeBetSlipStatus({ betSlipStatus: BetSlipStatus.ADDING_BET }));
      }
    },
    [betSlipStatus, dispatch],
  );

  return {
    addSportsBet,
    removeSportBet,
    clearBetSlip,
    updateBetAmount,
    updateMultiBetAction,
    updateBetSlipStatus,
    setBetSlipPlacedErrorsAction,
  };
};

export const useSportsCheckReuse = () => {
  const { getSportSetting } = useGlobalData();
  const { cryptoPreference } = usePreference();
  const { fiatToCrypto } = useConversion(cryptoPreference);
  const { minBetUSD } = getSportSetting();
  const currencyMapping = useBalances();
  const dispatch = useDispatch();
  const { setBetSlipPlacedErrorsAction, updateMultiBetAction } = useSportsBet();

  const { betSlips, betSlipPlacedErrors, multiBet } = useSelector(
    (state: AppState) => state.sportsBet,
    shallowEqual,
  );

  const handleModifiedStake = useCallback(() => {
    const hasMultiError = betSlipPlacedErrors.some(error => error.isMultiBet);
    const betSlipPlacedErrorsIds = hasMultiError
      ? betSlips.map(bet => bet.id)
      : betSlipPlacedErrors.map(error => error.id);

    const betSlipReuse =
      betSlipPlacedErrors.length > 0
        ? betSlips.filter(bet => betSlipPlacedErrorsIds.includes(bet.id))
        : betSlips;

    const betSlipsModifiedStake = betSlipReuse.map(bet => {
      const findErrorBet = betSlipPlacedErrors.find(
        error => error.id === bet.id && !error.isMultiBet,
      );

      if (!findErrorBet) {
        return hasMultiError
          ? {
              ...bet,
              inputAmount: '',
              betAmount: '',
              estPayout: '',
            }
          : bet;
      }

      if (findErrorBet.maxAllowedStake) {
        const isLessThanMinBet = BigNumber(findErrorBet.maxAllowedStake).isLessThan(
          fiatToCrypto(minBetUSD.toString()),
        );
        const isLessThanCurrentCurrency = BigNumber(currencyMapping[cryptoPreference]).isLessThan(
          findErrorBet.maxAllowedStake,
        );

        if (isLessThanMinBet) {
          setBetSlipPlacedErrorsAction([]);
        }

        return {
          ...bet,
          inputAmount:
            isLessThanMinBet || isLessThanCurrentCurrency
              ? ''
              : String(findErrorBet.maxAllowedStake),
          betAmount:
            isLessThanMinBet || isLessThanCurrentCurrency
              ? ''
              : String(findErrorBet.maxAllowedStake),
          estPayout: isLessThanMinBet
            ? ''
            : calculateEstimatePayout({
                betAmount: String(findErrorBet.maxAllowedStake),
                oddsNumerator: bet.oddsNumerator,
                oddsDenominator: bet.oddsDenominator,
              }),
        };
      }
      return bet;
    });

    const findErrorMultiBet = multiBet.selectionIds.find(betId =>
      betSlipPlacedErrors.find(error => error.id === betId && error.isMultiBet),
    );

    if (!findErrorMultiBet) {
      updateMultiBetAction({
        betAmount: '',
        inputAmount: '',
        selectionIds: [],
      });
    }

    betSlipPlacedErrors.forEach(error => {
      if (error.isMultiBet && error.maxAllowedStake) {
        const isLessThanMinBet = BigNumber(error.maxAllowedStake).isLessThan(
          fiatToCrypto(minBetUSD.toString()),
        );
        const amount = isLessThanMinBet ? '' : String(error.maxAllowedStake);

        updateMultiBetAction({
          betAmount: amount,
          inputAmount: amount,
        });

        if (isLessThanMinBet) {
          setBetSlipPlacedErrorsAction([]);
        }
      }
    });

    return betSlipsModifiedStake;
  }, [
    currencyMapping,
    cryptoPreference,
    betSlipPlacedErrors,
    betSlips,
    fiatToCrypto,
    minBetUSD,
    multiBet,
    setBetSlipPlacedErrorsAction,
    updateMultiBetAction,
  ]);

  // Make sure the odds/status from bet slip always sync after press "Try again" or "Reuse bet slip"
  // When got Max stake exceeded error, should update stake amount again
  const checkReuseBet = useCallback(
    ({ modifiedStake }: { modifiedStake: boolean }) => {
      const betSlipsData = modifiedStake ? handleModifiedStake() : betSlips;
      const newBetSlips = betSlipsData.map(bet => {
        const { marketStatus } = getSportsMarketStatus({
          marketStatus: bet?.updatedMarketStatus ?? bet.marketStatus,
          marketExpiryTime: bet.marketExpiryTime,
          marketInPlay: bet.inPlay,
        });

        return {
          ...bet,
          marketStatus,
          oddsDenominator: bet?.updatedOddsDenominator ?? bet.oddsDenominator,
          oddsNumerator: bet?.updatedOddsNumerator ?? bet.oddsNumerator,
          status: bet?.updatedStatus ?? bet.status,
        };
      });

      dispatch(
        setBetSlip({
          betSlips: newBetSlips,
        }),
      );
      dispatch(updateBetSlipOddsChangesIds({ ids: [] }));
    },
    [betSlips, dispatch, handleModifiedStake],
  );

  return { checkReuseBet };
};
