import useGlobalState from 'hooks/useGlobalState';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { NavMenuType, setActiveNavMenuType } from 'redux/slices/globalStateSlice';
import { GetChallengeCountQuery, useGetRaceLeaderBoardV2Query } from '../../generated/graphql';
import { useSportRouteCheck } from '../../hooks/useSportRouteCheck';
import CasinoTab from './CasinoTab';
import { HomeTab } from './HomeTab';
import { NavigationSideAnimate } from './NavigationSideAnimate';
import { NavigationTabs } from './NavigationTabs';
import { NavigationToken } from './NavigationToken';
import { SearchComponent } from './SearchComponent';
import SportsTab from './SportsTab';
import styles from './NavigationContent.module.scss';
interface NavigationContentProps {
  isExpanded: boolean;
  animationStart?: boolean;
  isMobile?: boolean;
  onAnimationComplete?: () => void;
  onAnimationStart?: () => void;
  animationComplete?: boolean;
  challengeCountData?: GetChallengeCountQuery;
  raceLeaderBoardResult: ReturnType<typeof useGetRaceLeaderBoardV2Query>;
}
export const HOME_ROUTES = ['/info', '/settings', '/provably-fair', '/vip-program', '/affiliate', '/transactions', '/sports/transactions/'];
export const NavigationContent = ({
  isExpanded,
  animationStart,
  isMobile,
  onAnimationComplete,
  onAnimationStart,
  animationComplete,
  challengeCountData,
  raceLeaderBoardResult
}: NavigationContentProps) => {
  const router = useRouter();
  const {
    isSportPage
  } = useSportRouteCheck();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    pathname
  } = router;
  const isAtHomeRoute = !!HOME_ROUTES.find(route => router.pathname.startsWith(route));
  const {
    activeNavMenuType
  } = useGlobalState();
  useEffect(() => {
    // Check url is sport content should be active the menu type is SPORTS
    if (pathname.includes('/sports')) {
      dispatch(setActiveNavMenuType(NavMenuType.SPORTS));
    } else {
      dispatch(setActiveNavMenuType(NavMenuType.CASINO));
    }
  }, [dispatch, pathname]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: dep
  useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
  }, [activeNavMenuType]);
  return <>
      <div className={`${styles.navTabList} ${styles.mobileOnly} ${isExpanded ? styles.navTabListExpanded : ''}`}>
        <div className={styles.mobileNavigationTabsContainer}>
          <NavigationTabs isExpanded={isExpanded} active={isAtHomeRoute ? '' : activeNavMenuType} />
        </div>
      </div>

      <div>
        {!isExpanded && !isMobile && <div className={styles.navigationTabsContainer}>
            <NavigationTabs isExpanded={isExpanded} active={isAtHomeRoute ? '' : activeNavMenuType} />
          </div>}

        <SearchComponent isExpanded={isExpanded} isSportPage={isSportPage} isAtHomeRoute={isAtHomeRoute} isMobile={isMobile} animationComplete={animationComplete} className={String(styles.searchDesktop)} />
      </div>

      <div className={`${styles.navContent} ${isExpanded ? '' : styles.isExpanded}`} ref={containerRef}>
        <NavigationSideAnimate onAnimationStart={onAnimationStart} isExpanded={isExpanded} animationStart={animationStart} onAnimationComplete={onAnimationComplete}>
          <SearchComponent isExpanded={isExpanded} isSportPage={isSportPage} isAtHomeRoute={isAtHomeRoute} isMobile={isMobile} animationComplete={animationComplete} className={String(styles.mobileOnly)} />

          <NavigationToken isExpanded={isExpanded} />

          {isAtHomeRoute && <HomeTab isExpanded={isExpanded} raceLeaderBoardResult={raceLeaderBoardResult} />}

          {!isAtHomeRoute && activeNavMenuType === NavMenuType.CASINO && <CasinoTab isExpanded={isExpanded} challengeCountData={challengeCountData} raceLeaderBoardResult={raceLeaderBoardResult} />}

          {!isAtHomeRoute && activeNavMenuType === NavMenuType.SPORTS && <SportsTab isExpanded={isExpanded} raceLeaderBoardResult={raceLeaderBoardResult} />}
        </NavigationSideAnimate>
      </div>
    </>;
};
export default NavigationContent;