import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

export enum NavMenuType {
  CASINO = 'CASINO',
  SPORTS = 'SPORTS',
}

export enum MobileMenuName {
  MENU = 'navMenu',
  SEARCH = 'navSearch',
  CHAT = 'navChat',
  CASINO = 'navCasino',
  SPORTS = 'navSports',
  REWARDS = 'tabRewards',
  SPORTS_BET = 'txtBetSlip',
}

export interface GlobalState {
  activeMobileNav?: MobileMenuName;
  activeNavMenuType: NavMenuType;
  userCountry: string | null;
  geoRestrictionWarningPopupCounter: number;
  isContentfulPreview: boolean;
  isTurboModeOn: boolean;
  isPersistStorePopulated: boolean;
  isPageLoading: boolean;
  isSportHomePageLoading: boolean;
  hideViewport: boolean;
  sportsBetMaintenanceShow: boolean;
  sportsDevtool: {
    enabled: boolean;
  };
}

export const initialState: GlobalState = {
  isPageLoading: true,
  isSportHomePageLoading: true,
  hideViewport: true,
  activeMobileNav: MobileMenuName.CASINO,
  activeNavMenuType: NavMenuType.CASINO,
  userCountry: null,
  isPersistStorePopulated: false,
  geoRestrictionWarningPopupCounter: 0,
  isContentfulPreview: false,
  isTurboModeOn: false,
  sportsBetMaintenanceShow: false,
  sportsDevtool: {
    enabled: false,
  },
};

export const globalStateSlice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    setActiveNavMenuType: (state: GlobalState, action: PayloadAction<NavMenuType>) => {
      state.activeNavMenuType = action.payload;
    },
    setActiveMobileNav: (state: GlobalState, action: PayloadAction<MobileMenuName>) => {
      state.activeMobileNav = action.payload;
    },
    setUserCountry: (state: GlobalState, action: PayloadAction<string>) => {
      state.userCountry = action.payload;
    },
    geoRestrictionWarningPopupIncrement: (state: GlobalState) => {
      state.geoRestrictionWarningPopupCounter++;
    },
    setIsContentfulPreview: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isContentfulPreview = action.payload;
    },
    toggleTurboMode: (state: GlobalState) => {
      state.isTurboModeOn = !state.isTurboModeOn;
    },
    setPersistStorePopulated: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isPersistStorePopulated = action.payload;
    },
    setsSportUnderMaintenance: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.sportsBetMaintenanceShow = action.payload;
    },
    setSportHomePageLoading: (
      state: GlobalState,
      action: PayloadAction<{ isSportHomePageLoading: boolean }>,
    ) => {
      state.isSportHomePageLoading = action.payload.isSportHomePageLoading;
    },
    setPageLoadingConfig: (
      state: GlobalState,
      action: PayloadAction<Partial<{ isPageLoading: boolean; hideViewport: boolean }>>,
    ) => {
      if (typeof action.payload.isPageLoading !== 'undefined') {
        state.isPageLoading = action.payload.isPageLoading;
      }

      if (typeof action.payload.hideViewport !== 'undefined') {
        state.hideViewport = action.payload.hideViewport;
      }
    },
    updateSportsDevtool: (
      state: GlobalState,
      action: PayloadAction<Partial<GlobalState['sportsDevtool']>>,
    ) => {
      if (!isUndefined(action.payload.enabled)) {
        state.sportsDevtool.enabled = action.payload.enabled;
      }
    },
  },
});

export const {
  updateSportsDevtool,
  setSportHomePageLoading,
  setsSportUnderMaintenance,
  setPageLoadingConfig,
  setActiveNavMenuType,
  setActiveMobileNav,
  setIsContentfulPreview,
  toggleTurboMode,
  setPersistStorePopulated,
} = globalStateSlice.actions;

export default globalStateSlice.reducer;
