import React from 'react';
import styles from './Relative.module.scss';
export const Relative = ({
  children,
  style,
  className
}: {
  style?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
}) => <div className={`${styles.root} ${className || ''}`} style={style}>
    {children}
  </div>;