import dynamic from 'next/dynamic';
import { useStore } from 'hooks/useStore';
import { useRouter } from 'next/router';
import { MAX_POPUP_SIZE, usePopup } from '../hooks/usePopup';
const Popups = dynamic(() => import('./Popups').then(mod => mod.Popups), {
  ssr: false
});
export function PopupsLazy() {
  const router = useRouter();
  const gameSlug = (router.query.slug as string);
  const {
    state: popups
  } = useStore(state => state.popups);
  const {
    updatePopupsZIndex,
    addPopup,
    updatePopup,
    removePopup
  } = usePopup({
    slug: gameSlug
  });
  const popupsSize = Object.keys(popups).length;
  return popupsSize && popupsSize <= MAX_POPUP_SIZE ? <Popups updatePopupsZIndex={updatePopupsZIndex} popupsSize={popupsSize} addPopup={addPopup} popups={popups} updatePopup={updatePopup} removePopup={removePopup} /> : null;
}