import React from 'react';
import { INTERCOM_WIDGET_CLASSNAME } from '../constants/intercom';
import { useIsLoggedIn } from '../hooks/auth/useIsLoggedIn';
import RoundButton from './Buttons/RoundButton';
import styles from './LiveChat.module.scss';
export const LiveChat = React.memo(() => {
  const isLoggedIn = useIsLoggedIn();
  if (!isLoggedIn) return null;
  return <div className={`${styles.supportButtonWrapper} ${INTERCOM_WIDGET_CLASSNAME}`}>
      <RoundButton icon={<img src="/icons/headphone.svg" loading="lazy" alt="headphone" />} />
    </div>;
});