import colors from 'assets/styles/colors';
import clsx from 'clsx';
import Odds from 'components/Odds';
import dayjs from 'dayjs';
import { EventState, SportsMarketSelectionResultStatus, SportsMarketSelectionStatus, SportsMarketStatus } from 'generated/graphql';
import { useSportCountDown } from 'hooks/useSportCountDown';
import { useDateFormat } from '../../../../hooks/format/useDateFormat';
import { checkEventStateBeforeLive } from '../../../../utils/sportEventStatusCheck';
import { BetSlipProps, SportsBetTicketStatusType } from '../BetsGroup.type';
import { BetTicketLegHeader } from './BetTicketLegHeader';
import { BetTicketText } from './BetTicketText';
import { getBetViewConfig } from './getBetViewConfig';
import { useLiveMatchProgressText } from 'hooks/useLiveMatchProgressText';
import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import useEventStartTimeDisplay from '../../../../hooks/useEventStartTimeDisplay';
import { BetSlipItem } from '../../../../redux/slices/sportsBetSlice.type';
import { getSportsMarketStatus } from '../../../../utils/getSportsMarketStatus';
import { BetGameEventInfoTextLink } from './BetGameEventInfoTextLink';
import styles from './MultiBetLeg.module.scss';
import { getColorFormattedStartTime } from './getColorFormattedStartTime';
type BetTicketProps = {
  bet: BetSlipItem & {
    betTicketStatus?: SportsBetTicketStatusType;
  };
  showOddsWarningMessage?: boolean;
  index: number;
  totalBetLegsCount: number;
  collapse?: boolean;
  highlight: boolean;
  setCollapses: React.Dispatch<React.SetStateAction<boolean[]>>;
  marketSelectionResultStatus?: SportsMarketSelectionResultStatus;
} & Pick<BetSlipProps, 'actions' | 'betSlipStatus' | 'actionPanelData' | 'errors' | 'matchStates' | 'currency' | 'betIds'>;
export function MultiBetLeg({
  betSlipStatus,
  bet,
  betIds,
  setCollapses,
  index,
  totalBetLegsCount,
  collapse,
  bet: {
    name,
    marketName,
    oddsNumerator,
    oddsDenominator,
    startTime,
    id,
    status,
    sportsBetStatus,
    sportsBetSelectionStatus,
    marketStatus,
    inPlay: marketInPlay,
    marketExpiryTime,
    fixtureId
  },
  actions,
  errors,
  showOddsWarningMessage = false,
  matchStates,
  marketSelectionResultStatus,
  highlight,
  actionPanelData
}: BetTicketProps) {
  const {
    formatDate
  } = useDateFormat();
  const {
    t
  } = useTranslation();
  const formattedStartTime = formatDate(startTime, 'lll');
  const matchState = matchStates?.find(item => item.fixtureId === fixtureId);
  const isLive = matchState?.matchSummary?.eventState === EventState.LIVE;
  const liveMatchProgressText = useLiveMatchProgressText({
    matchState,
    sports: bet.sport
  });
  const {
    countdownTime,
    countdownStart,
    minutesRemaining,
    shouldShowVisualTimer
  } = useSportCountDown({
    dateTime: startTime,
    formattedDateTime: formattedStartTime,
    shortened: true,
    isLive
  });
  const {
    startTimeForDisplay
  } = useEventStartTimeDisplay({
    startTime,
    inPlayAllowed: bet.inPlayAllowed,
    formattedDateTime: formattedStartTime,
    eventState: matchState?.matchSummary?.eventState,
    countdownStart,
    countdownTime
  });
  const isEventSuspended = matchState?.matchSummary?.eventState === EventState.SUSPENDED;
  const isEventClosed = matchState?.matchSummary?.eventState === EventState.CLOSED;
  const isPreMatch = matchState?.matchSummary?.eventState === EventState.PREMATCH || dayjs(startTime).isAfter(dayjs());
  const {
    marketStatus: marketStatusWithInPlay
  } = getSportsMarketStatus({
    isEventSuspended,
    isEventClosed,
    marketStatus,
    marketInPlay,
    marketExpiryTime
  });
  const viewConfig = getBetViewConfig({
    betSlipStatus,
    sportsBetStatus: sportsBetStatus,
    sportsBetSelectionStatus: sportsBetSelectionStatus,
    marketSelectionStatus: status,
    isLive,
    isPreMatch,
    marketSelectionResultStatus
  });
  const eventStateBeforeLive = checkEventStateBeforeLive({
    startTime,
    inPlayAllowed: bet.inPlayAllowed
  });
  const {
    isInBetPlacedOrConfirmed,
    isInBetInfoView,
    isInBetAddView,
    isCanceled,
    isVoid,
    isPushed,
    shouldHideDate,
    shouldDisplayMarketStatus,
    isPending,
    isInBetPlacingView,
    isDeadHeat,
    isCashedOut,
    isInBetPendingView,
    isPartialPending
  } = viewConfig;
  const shouldShowLiveBadge = isLive && marketStatus !== SportsMarketStatus.RESULTED;
  const checked = betIds.some(slipId => slipId === id);
  const isLastLeg = totalBetLegsCount - 1 === index;
  const [time, text] = liveMatchProgressText.parts;
  let shouldDimmed = false;
  if (isInBetInfoView || isPending) {
    shouldDimmed = false;
  } else if (!checked || isCanceled) {
    shouldDimmed = true;
  }
  const isSuspended = shouldDisplayMarketStatus && (marketStatusWithInPlay === SportsMarketStatus.SUSPENDED || marketStatusWithInPlay === SportsMarketStatus.OPEN && (status === SportsMarketSelectionStatus.SUSPENDED || status === SportsMarketSelectionStatus.UNPRICED));
  const isClosed = shouldDisplayMarketStatus && (isEventClosed || marketStatusWithInPlay === SportsMarketStatus.CLOSED || marketStatusWithInPlay === SportsMarketStatus.OPEN && status === SportsMarketSelectionStatus.RESULTED);
  const hasMarketNotOpenInBetAdd = isInBetAddView && (marketStatusWithInPlay !== SportsMarketStatus.OPEN || status !== SportsMarketSelectionStatus.TRADING);
  const handleHeaderClick = useCallback(() => {
    setCollapses(data => {
      return data.map((item, currentIndex) => {
        if (index === currentIndex) {
          return !item;
        }
        return item;
      });
    });
  }, [setCollapses, index]);
  const startTimeColor = getColorFormattedStartTime({
    isCanceled,
    isVoid,
    isPushed,
    isDeadHeat,
    isCashedOut,
    isPreMatch,
    isLive,
    countdownStart,
    betSlipStatus,
    isInBetPendingView,
    isPartialPending,
    isPending,
    isInBetInfoView,
    eventStateBeforeLive
  });
  if (isInBetPlacedOrConfirmed && !checked) {
    return null;
  }
  return <div className={`${styles.multiLegWrapper} ${collapse ? styles.multiLegWrapperCollapse : ''}`}>
      <BetTicketLegHeader onUpdateAmount={actions?.onUpdateMultiBetAmount} bet={bet} disabled={isSuspended || isClosed} betSlipStatus={betSlipStatus} selectionIds={betIds} checked={checked} onHeaderClick={handleHeaderClick} showLiveBadge={shouldShowLiveBadge} highlight={highlight} viewConfig={viewConfig} />

      <div className={`${styles.betLegContent} ${isVoid || isPushed || errors?.isInsufficientFunds || errors?.isMinStakeNotMet ? styles.voided : ''} ${collapse ? styles.collapse : ''}`}>
        <div className={`${styles.betTicketBody} ${isLastLeg ? styles.isLastLeg : ''}`}>
          <div className={clsx(styles.betTicketMarketBlock, {
          [String(styles.betTicketMarketBlockDimmed)]: shouldDimmed
        })}>
            <div className={styles.legInfo}>
              <BetTicketText dimmed={hasMarketNotOpenInBetAdd} bold color={colors.primaryViolet} className={styles.legTitle}>
                {name}
              </BetTicketText>

              {!isSuspended && !isClosed && <BetTicketText bold>
                  <Odds autoHideIndicator={!showOddsWarningMessage} showColorOddsChanges showOddsChanges={isInBetAddView || isInBetPlacingView} oddsNumerator={oddsNumerator} oddsDenominator={oddsDenominator} oddsDisplayPosition="right" />
                </BetTicketText>}

              {isSuspended && <BetTicketText color={colors.warning} bold>
                  {t('txtSuspended')}
                </BetTicketText>}

              {isClosed && <BetTicketText color={colors.error} bold>
                  {t('txtClosed')}
                </BetTicketText>}
            </div>

            <div className={clsx(styles.marketInfoWrapper, {
            [String(styles.dimmed)]: hasMarketNotOpenInBetAdd
          })}>
              <BetTicketText dimmed={hasMarketNotOpenInBetAdd} wrap color={colors.gray300} className={styles.marketName}>
                {marketName}
              </BetTicketText>
            </div>

            <BetGameEventInfoTextLink isEventClosed={isEventClosed} shouldShowLiveEventInfo={viewConfig.shouldShowLiveEventInfo} viewConfig={viewConfig} matchState={matchState} actionPanelData={actionPanelData} shouldHideDate={shouldHideDate} hasMarketNotOpenInBetAdd={hasMarketNotOpenInBetAdd} startTimeForDisplay={startTimeForDisplay} startTimeColor={startTimeColor} shouldDisplayPieTimer={shouldShowVisualTimer} minutesRemaining={minutesRemaining} time={time} text={text} bet={bet} liveMatchProgressTextContent={liveMatchProgressText.content} />
          </div>
        </div>
      </div>
    </div>;
}