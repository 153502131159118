import dynamic from 'next/dynamic';
import { useSportRouteCheck } from '../../hooks/useSportRouteCheck';
import styles from './BottomBanners.module.scss';
import CookieBanner from './CookieBanner';
import VersionBanner from './VersionBanner';
const MaintenanceBanner = dynamic(() => import('./MaintenanceBanner'));
const Banners = () => {
  const {
    isSportPage
  } = useSportRouteCheck();
  return <div className={styles.root}>
      {isSportPage && <MaintenanceBanner />}
      <CookieBanner />
      <VersionBanner />
    </div>;
};
export default Banners;