import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';

import { useDateFormat } from './format/useDateFormat';

type Props = {
  dateTime: string;
  formattedDateTime: string;
  isLive: boolean;
  shortened?: boolean;
};

const SIXTY_MINUTES = 60;
const INTERVAL_DURATION_MILLISECONDS = 5000;
const INTERVAL_DURATION_MILISECONDS_COUNT_SECONDS = 1000;

export function useSportCountDown({ dateTime, formattedDateTime, shortened, isLive }: Props) {
  const [countdownTime, setCountdownTime] = useState(formattedDateTime);
  const [countdownStart, setCountdownStart] = useState(false);
  const [minutesRemaining, setMinutesRemaining] = useState(0);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const { t } = useTranslation();
  const { getRelativeDate } = useDateFormat();

  const getDashPosition = useCallback((dateTime: string) => dateTime.indexOf('-'), []);

  useEffect(() => {
    const checkCountDownTimer = () => {
      if (!dateTime) {
        return;
      }

      const minutesDifference = dayjs(dateTime)
        .startOf('minute')
        .diff(dayjs().startOf('minute'), 'minute');
      const secondsDifference = dayjs(dateTime).diff(dayjs(), 'second');

      setMinutesRemaining(minutesDifference);
      setSecondsRemaining(secondsDifference);

      if (secondsDifference < 0) {
        clearInterval(countdownTimer);
        setCountdownStart(false);
        return setCountdownTime(currentDatetime => {
          const dashPosition = getDashPosition(currentDatetime);
          return `${currentDatetime.substring(0, dashPosition + 1)} ${getRelativeDate(dateTime)}`;
        });
      }

      if (minutesDifference > SIXTY_MINUTES) {
        return;
      }

      setCountdownStart(true);

      setCountdownTime(dateTime => {
        const dashPosition = getDashPosition(dateTime);

        if (secondsDifference <= 0) {
          return t('aboutToStart');
        }

        if (!dashPosition) {
          return dateTime;
        }
        const prefix = shortened
          ? t('txtIn')
          : `${formattedDateTime.substring(0, dashPosition + 1)} ${t('txtIn')}`;

        return `${prefix} ${
          secondsDifference < 60 ? `${secondsDifference}s` : `${minutesDifference}m`
        }`;
      });
    };

    const shouldCountSecondsTick = secondsRemaining > 0 && secondsRemaining < 60;
    const countdownTimer = setInterval(
      checkCountDownTimer,
      shouldCountSecondsTick
        ? INTERVAL_DURATION_MILISECONDS_COUNT_SECONDS
        : INTERVAL_DURATION_MILLISECONDS,
    );

    checkCountDownTimer();

    return () => clearInterval(countdownTimer);
  }, [
    getDashPosition,
    getRelativeDate,
    dateTime,
    t,
    formattedDateTime,
    shortened,
    secondsRemaining,
  ]);

  const shouldShowVisualTimer = !isLive && secondsRemaining > 0 && minutesRemaining <= 60;

  return {
    countdownTime,
    countdownStart,
    minutesRemaining,
    secondsRemaining,
    shouldShowVisualTimer,
  };
}
