import React from 'react';
import { OriginalGame } from '../../../../generated/graphql';

export enum BetTab {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
  ADVANCED = 'ADVANCED',
}

export interface GameLayoutProps {
  children: React.ReactNode[];
  slug: OriginalGame;
  hideComingSoon?: boolean;
}
