import BigNumber from 'bignumber.js';
import FormattedAmount from 'components/FormattedAmount';
import { BALANCE_MAX_DIGITS } from 'constants/currency';
import { Currency } from 'generated/graphql';
import usePreference from 'hooks/usePreference';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setCryptoPreference } from 'redux/slices/browserPreferenceSlice';
import colors from '../../../assets/styles/colors';
import { changeBetSlipStatus, initialState, setBetSlip, updateMultiBet } from '../../../redux/slices/sportsBetSlice';
import CryptoIcon from '../../CurrencyIcons/CryptoIcon';
import { BetSlipStatus } from '../../../redux/slices/sportsBetSlice.type';
import styles from './BalanceItem.module.scss';
interface Props {
  currency: Currency;
  value: string;
  closePopup: () => void;
}
const BalanceItem = ({
  currency,
  value,
  closePopup
}: Props) => {
  const {
    cryptoPreference,
    hideZeroBalance
  } = usePreference();
  const {
    betSlipStatus,
    betSlips
  } = useSelector((appState: AppState) => ({
    betSlipStatus: appState.sportsBet.betSlipStatus,
    betSlips: appState.sportsBet.betSlips
  }), shallowEqual);
  const dispatch = useDispatch();
  const onUpdateSportBet = () => {
    if (betSlipStatus === BetSlipStatus.CONFIRMING_BET) {
      dispatch(changeBetSlipStatus({
        betSlipStatus: BetSlipStatus.ADDING_BET
      }));
    }
    if (betSlipStatus === BetSlipStatus.ADDING_BET) {
      dispatch(setBetSlip({
        betSlips: betSlips.map(item => ({
          ...item,
          betAmount: '',
          inputAmount: '',
          estPayout: ''
        }))
      }));
      dispatch(updateMultiBet({
        betAmount: initialState.multiBet.betAmount,
        inputAmount: initialState.multiBet.inputAmount
      }));
    }
  };
  const clickHandler = () => {
    dispatch(setCryptoPreference(currency));
    closePopup();
    onUpdateSportBet();
  };
  return <button className={`${styles.root} ${cryptoPreference === currency ? styles.active : ''}`} onClick={clickHandler} data-testid={`currency-${currency}`}>
      <span className={styles.cryptoIconWrapper}>
        <CryptoIcon currency={currency} />
        <p className={styles.currencyText}>{currency}</p>
      </span>

      {(!hideZeroBalance || BigNumber(value).isGreaterThan(0)) && <div className={styles.amount} style={{
      color: BigNumber(value).isEqualTo(0) ? colors.gray400 : ''
    }}>
          <FormattedAmount value={value} currency={currency} maxDigits={BALANCE_MAX_DIGITS} />
        </div>}
    </button>;
};
export default BalanceItem;