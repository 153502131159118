import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from } from '@apollo/client';

import cache from './cache';
import {
  authLink,
  errorLink,
  httpLink,
  kycHttpLink,
  nonAuthLink,
  splitLink,
  sportsHttpLink,
  uploadLink,
  wsLink,
  wsLinkSports,
  wsLinkStable,
} from './links';

export function createServerClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([nonAuthLink, httpLink]),
    cache: new InMemoryCache(),
  });
}

export function createSportsServerClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([nonAuthLink, sportsHttpLink]),
    cache: new InMemoryCache(),
  });
}

function createClient(links: ApolloLink) {
  return new ApolloClient({
    link: links,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
    cache,
  });
}

export const client = createClient(from([errorLink, authLink, splitLink]));

export const clientWsStable = createClient(
  from([errorLink, wsLinkStable!].filter(serverSideAvailable => serverSideAvailable)),
);

export const clientWsSports = createClient(
  from([errorLink, wsLinkSports!].filter(serverSideAvailable => serverSideAvailable)),
);

export const sportsClient = createClient(from([nonAuthLink, sportsHttpLink]));

export const sportsStreamClient = createClient(from([authLink, sportsHttpLink]));

export const kycClient = createClient(from([errorLink, authLink, kycHttpLink]));

client.onResetStore(async () => {
  // reset happens in global setup,
  // terminates ws connection so it will reconnect with new auth token
  wsLink?.client.terminate();
});

type CreateContentfulClientOptions = {
  /** The Contentful environment; defaults to `CONTENTFUL_ENVIRONMENT` */
  environment?: string;
  /** Access preview (unpublished) data? */
  preview?: boolean;
};

/**
 * Create an Apollo client hooked up to our Contentful CMS.
 * @note This should ONLY be used in SSR mode.
 */
export const createContentfulClient = ({
  preview = false,
  environment,
}: CreateContentfulClientOptions = {}) => {
  const base = process.env.CONTENTFUL_GRAPHQL_BASE;
  const spaceID = process.env.CONTENTFUL_SPACE_ID;
  const env = environment ?? process.env.CONTENTFUL_ENVIRONMENT ?? 'master';

  const authToken = preview
    ? process.env.CONTENTFUL_PREVIEW_TOKEN
    : process.env.CONTENTFUL_ACCESS_TOKEN;

  const uri = `${base}/spaces/${spaceID}/environments/${env}?access_token=${authToken}`;

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createHttpLink({ uri }),
    ssrMode: true,
  });
};

export const clientWithoutAuth = createClient(from([nonAuthLink, splitLink]));

export const clientWithUpload = createClient(from([authLink, uploadLink as unknown as ApolloLink]));
