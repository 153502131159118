import { EventState } from 'generated/graphql';
import { getEventState } from 'utils/sportsGenerator';

const useEventStartTimeDisplay = ({
  startTime,
  inPlayAllowed,
  formattedDateTime,
  eventState,
  countdownStart,
  countdownTime,
}: {
  startTime: string;
  inPlayAllowed?: boolean | null;
  formattedDateTime: string;
  eventState?: EventState | null;
  countdownStart: boolean;
  countdownTime: string;
}) => {
  let startTimeForDisplay = formattedDateTime;
  const eventStateText = getEventState({
    startTime,
    inPlayAllowed,
    formattedDateTime,
    eventState,
  });

  if (eventStateText && !countdownStart) {
    startTimeForDisplay = eventStateText;
  }

  if (countdownStart) {
    startTimeForDisplay = countdownTime;
  }

  return {
    eventStateText,
    startTimeForDisplay,
  };
};

export default useEventStartTimeDisplay;
