import { INTERCOM_WIDGET_CLASSNAME } from 'constants/intercom';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TELEGRAM_URL,
  TIKTOK_URL,
  TWITTER_URL,
} from 'constants/social';

export interface MenuItemType {
  label: string;
  url: string;
  className?: string;
  external: boolean;
  active: boolean;
}

export const SUPPORT: MenuItemType[] = [
  {
    label: 'footer.liveChat.text',
    url: '',
    className: INTERCOM_WIDGET_CLASSNAME,
    external: false,
    active: true,
  },
  {
    label: 'footer.helpCenter.text',
    url: 'https://help.shuffle.com',
    external: true,
    active: true,
  },
  {
    label: 'footer.gameResponsibly.text',
    url: 'https://www.begambleaware.org/',
    external: true,
    active: true,
  },
];

export const PLATFORM: MenuItemType[] = [
  {
    label: 'footer.provablyFair.text',
    url: '/provably-fair/overview',
    external: false,
    active: true,
  },
  {
    label: 'footer.affiliateProgram.text',
    url: '/affiliate',
    external: false,
    active: true,
  },
  {
    label: 'footer.redeemCode.text',
    url: '',
    external: false,
    active: true,
  },
  {
    label: 'footer.vipProgram.text',
    url: '/vip-program',
    external: false,
    active: true,
  },
];

export const POLICIES: MenuItemType[] = [
  {
    label: 'footer.termsOfService.text',
    url: '/info/terms',
    external: false,
    active: true,
  },
  {
    label: 'footer.privacyPolicy.text',
    url: '/info/privacy',
    external: false,
    active: true,
  },
  {
    label: 'footer.selfExclusion.text',
    url: '/info/self-exclusion',
    external: false,
    active: true,
  },
  {
    label: 'footer.AMLPolicy.text',
    url: '/info/aml',
    external: false,
    active: true,
  },
  {
    label: 'footer.license',
    url: '/info/license',
    external: false,
    active: true,
  },
  {
    label: 'navSports',
    url: '/info/sports',
    external: false,
    active: true,
  },
];

export const COMMUNITY: MenuItemType[] = [
  {
    label: 'footer.community.twitter.text',
    url: TWITTER_URL,
    external: true,
    active: true,
  },
  {
    label: 'footer.community.instagram.text',
    url: INSTAGRAM_URL,
    external: true,
    active: true,
  },
  {
    label: 'footer.community.tiktok.text',
    url: TIKTOK_URL,
    external: true,
    active: true,
  },
  {
    label: 'footer.community.facebook.text',
    url: FACEBOOK_URL,
    external: true,
    active: true,
  },
  {
    label: 'footer.community.telegram.text',
    url: TELEGRAM_URL,
    external: true,
    active: true,
  },
  {
    label: 'footer.community.telegram.text.merch',
    url: 'https://shuffle.store/',
    external: true,
    active: true,
  },
];
