import { BetSlipItem } from '../redux/slices/sportsBetSlice.type';

export function getBetIndexes({
  betSlips,
  fixtureId,
}: {
  fixtureId?: string;
  betSlips: BetSlipItem[];
}) {
  for (let i = 0; i < betSlips.length; i++) {
    if (betSlips[i]?.fixtureId === fixtureId) {
      return i;
    }
  }

  return -1;
}
