import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { ANIMATE_FADE_IN } from '../../constants/animator';
import styles from './NavigationSideAnimate.module.css';
type Props = {
  children: React.ReactNode | React.ReactNode[];
  isExpanded: boolean;
  animationStart?: boolean;
  onAnimationComplete?: () => void;
  onAnimationStart?: () => void;
};
export function NavigationSideAnimate({
  children,
  isExpanded,
  animationStart,
  onAnimationComplete,
  onAnimationStart
}: Props) {
  const [play, setPlay] = useState(false);
  const timer = useRef(0);
  const previous = useRef(isExpanded);
  useEffect(() => {
    setPlay(false);
    clearTimeout(timer.current);
    timer.current = window.setTimeout(() => {
      setPlay(true);
      previous.current = isExpanded;
    }, 250);
  }, [isExpanded]);
  if (!animationStart) {
    return <>{children}</>;
  }
  return <motion.div style={{
    display: !play || previous.current !== isExpanded ? 'none' : 'block'
  }} onAnimationStart={onAnimationStart} onAnimationComplete={onAnimationComplete} className={`${styles.root} ${isExpanded ? '' : styles.isExpanded}`} initial={ANIMATE_FADE_IN.initial} transition={{
    ease: 'easeIn'
  }} animate={play ? ANIMATE_FADE_IN.animate : {}}>
      {children}
    </motion.div>;
}