import BigNumber from 'bignumber.js';

import { FIAT_DECIMALS } from '../constants/decimalPlaces';
import { Currency } from '../generated/graphql';

type ConvertCryptoFiatOptions = {
  roundingMode?: BigNumber.RoundingMode;
  decimals?: number;
};

/**
 * Convert a crypto amount to a fiat amount.
 * This function is held outside of the above hook to allow it to be called from
 * runtime and testing contexts.
 */
export const convertCryptoToFiatByCurrency = (
  amount: string,
  currency: Currency,
  prices: Record<Currency, string>,
  options?: ConvertCryptoFiatOptions,
): string => {
  const { decimals = FIAT_DECIMALS, roundingMode } = options ?? {};
  const rate = prices[currency];
  const total = BigNumber(amount).multipliedBy(rate);
  return total.toFixed(decimals, roundingMode);
};
