import { useEffect } from 'react';

export function usePreventScrolling(enable = true) {
  useEffect(() => {
    if (enable) {
      document.body.classList.add('no_scroll');
    }

    return () => {
      document.body.classList.remove('no_scroll');
    };
  }, [enable]);
}
