import { Language } from 'generated/graphql';

const COOKIE_MAX_AGE = 60 * 60 * 24 * 365; // 1 year

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookieValue = parts.pop();
    if (cookieValue) {
      return cookieValue.split(';').shift();
    }

    return '';
  }

  return '';
}

export function setCookie(name: string, value: string, maxAge: number) {
  document.cookie = `${name}=${value}; max-age=${maxAge}; path=/`;
}

export function setLanguagePreferenceCookie(language: Language) {
  setCookie('language-preference', language, COOKIE_MAX_AGE);
}
