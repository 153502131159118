import { useEffect, useState } from 'react';
import { RightSidebarVariant } from '../redux/slices/browserPreferenceSlice';

import { Breakpoint, defaultBreakpoints } from './interface/useWindowWidth';
import usePreference from './usePreference';

const CONTAINER_QUERY_NAME = 'magic-container';
const ADDITIONAL_LEFT_SIDE_BAR_WIDTH = 175;
const ADDITIONAL_RIGHT_SIDE_BAR_WIDTH = 360;

// can't use useWindowSize here as it will cause issues with SSR
export function useContainerClassName() {
  const { rightSidebarVariant, isLeftSidebarExpanded } = usePreference();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const isNavOverlay =
    isClient &&
    typeof window !== 'undefined' &&
    document.body.clientWidth <= defaultBreakpoints[Breakpoint.LG];

  let containerClass = 'no-side-opened';
  let breakpointOffset = 0;

  if (rightSidebarVariant !== RightSidebarVariant.NONE && isLeftSidebarExpanded && !isNavOverlay) {
    containerClass = 'both-side-opened';
    breakpointOffset = ADDITIONAL_LEFT_SIDE_BAR_WIDTH + ADDITIONAL_RIGHT_SIDE_BAR_WIDTH;
  } else if (isLeftSidebarExpanded && !isNavOverlay) {
    breakpointOffset = ADDITIONAL_LEFT_SIDE_BAR_WIDTH;
    containerClass = 'left-side-opened-only';
  } else if (rightSidebarVariant !== RightSidebarVariant.NONE) {
    breakpointOffset = ADDITIONAL_RIGHT_SIDE_BAR_WIDTH;
    containerClass = 'right-side-opened-only';
  }

  return {
    className: `${CONTAINER_QUERY_NAME} ${containerClass}`,
    breakpointOffset,
  };
}
