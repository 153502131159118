import { ComponentSize } from '../Tag';
import styles from './ProgessBar.module.scss';
interface ProgressBarProps {
  /** A number between 0 and 100 */
  percentage: number;
  size?: Omit<ComponentSize, 'sm'>;
}
const ProgressBar = ({
  percentage,
  size = 'md'
}: ProgressBarProps) => {
  let pcAsString = `${percentage}%`;
  let height = 12;
  if (percentage < 0) pcAsString = '0%';
  if (percentage > 100) pcAsString = '100%';
  if (size === 'sm') height = 6;
  if (size === 'md') height = 8;
  return <div className={styles.progressBarContainer} style={{
    height
  }}>
      <div className={styles.progressBarColor} style={{
      height,
      width: pcAsString ? pcAsString : '0'
    }} />
    </div>;
};
export default ProgressBar;