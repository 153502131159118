import ProgressBar from 'components/ProgressBar/ProgessBar';
import useVip from 'hooks/vip/useVip';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Avatar from '../../components/Avatar';
import { Flex } from '../../components/Flex';
import VipBadge from '../../components/Vip/VipBadge';
import { AvatarProperties } from '../../hooks/useAvatar';
import styles from './UserMenuVipCard.module.scss';
type Props = {
  avatar?: AvatarProperties | null;
};

/**
 * User VIP progress in UserMenu
 */
const UserMenuVipCard = ({
  avatar
}: Props) => {
  const {
    t
  } = useTranslation();
  const {
    profile,
    current,
    next,
    progress
  } = useVip();
  const pcDisplay = t('intlNumberWithOptions', {
    val: progress / 100,
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return <Link href="/vip-program" passHref legacyBehavior>
      <a className={styles.userMenuVipWrapper}>
        <div className={styles.name}>
          <Avatar face={avatar?.face} background={avatar?.background} />
          <span className={styles.username}>{profile?.username}</span>
        </div>

        <hr className={styles.lineBreak} />

        <VipBadge icon={current.icon} label={current.label} />

        <div className={styles.userMenuVipProgress}>
          <ProgressBar percentage={progress} />
          <Flex justify="space-between" align="center">
            <span>{pcDisplay}</span>
            <VipBadge {...next} />
          </Flex>
        </div>
      </a>
    </Link>;
};
export default UserMenuVipCard;