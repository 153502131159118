import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { AlertType, AlertsEnum, removeAlert } from 'redux/slices/alertsSlice';
import colors from '../../assets/styles/colors';
import styles from './Alert.module.scss';
const animationProp = {
  initial: {
    opacity: 0,
    x: -25,
    maxHeight: 0
  },
  animate: {
    opacity: 1,
    x: 0,
    maxHeight: 500
  },
  exit: {
    opacity: 0,
    x: -25,
    maxHeight: 0
  }
};
export const alertColors = {
  [AlertsEnum.Success]: colors.success,
  [AlertsEnum.Error]: colors.error,
  [AlertsEnum.Warning]: colors.warning,
  [AlertsEnum.Info]: colors.info
};
export const Alert = ({
  message,
  messages,
  type,
  closeHandler
}: AlertType & {
  closeHandler: () => void;
}) => {
  return <button className={styles.root} onClick={closeHandler} aria-label="dismiss alert">
      <div className={styles.leftContainer} style={{
      backgroundColor: alertColors[type]
    }} aria-label={type}>
        <img src={`/icons/alerts/${type}.svg`} alt={type} />
      </div>

      <div className={styles.rightContainer}>
        <div>
          <span className={`${styles.percentageBar} ${styles.startPercentageAnimation}`} role="progressbar" onAnimationEnd={closeHandler} />
          {Array.isArray(messages) && !!messages?.length ? <div className={styles.multiMessage}>
              {messages.map((msg, index) => <p className={styles.text} key={index}>
                  {msg}
                </p>)}
            </div> : message && <p className={styles.text}>{message}</p>}
        </div>

        <img src="/icons/white-cross.svg" alt="close" />
      </div>
    </button>;
};
export const AlertWithAction = ({
  message,
  messages,
  type,
  id
}: AlertType) => {
  const dispatch = useDispatch();
  const closeHandler = () => dispatch(removeAlert({
    id
  }));
  return <motion.div initial={animationProp.initial} animate={animationProp.animate} exit={animationProp.exit}>
      <Alert id={id} type={type} message={message} messages={messages} closeHandler={closeHandler} />
    </motion.div>;
};
export default AlertWithAction;