import { Node } from '@react-types/shared';
import { useRef } from 'react';
import { AriaListBoxOptions, useListBox, useOption } from 'react-aria';
import { ListState } from 'react-stately';
import isString from 'utils/isString';
import styles from './ListBox.module.scss';
interface OptionProps<T> {
  item: Node<T>;
  state: ListState<T>;
}
interface ListBoxProps<T> extends AriaListBoxOptions<T> {
  state: ListState<T>;
}
function Option<T>({
  item,
  state
}: OptionProps<T>) {
  const ref = useRef(null);
  const {
    optionProps,
    isSelected,
    isDisabled
  } = useOption({
    key: item.key
  }, state, ref);
  return <li {...optionProps} ref={ref} className={`${styles.item} ${isSelected ? styles.selectedItem : ''} ${isDisabled ? styles.disabledItem : ''}`} data-testid={isString(item.key) ? item.key : undefined}>
      {item.rendered}
    </li>;
}
export function ListBox<T extends object>({
  state,
  ...props
}: ListBoxProps<T>) {
  const ref = useRef(null);
  const {
    listBoxProps
  } = useListBox(props, state, ref);
  return <ul {...listBoxProps} ref={ref} className={styles.root}>
      {[...state.collection].map(item => <Option key={item.key} item={item} state={state} />)}
    </ul>;
}