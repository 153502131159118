import { AnimationProps, motion } from 'framer-motion';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { ANIMATE_BOTTOM_TO_UP } from '../../constants/animator';
import { ModalClose } from './ModalClose';
import styles from './ModalContent.module.scss';
type Props = {
  onModalClose?: () => void;
  onBack?: () => void;
  open: boolean;
  classNames?: Partial<{
    modalRoot: string;
    modalContent: string;
    modalBody: string;
    modalClose: string;
  }>;
  children?: React.ReactNode;
  animation?: Partial<AnimationProps>;
  footer?: ReactNode | string;
  refs?: Partial<{
    modalRoot: React.RefObject<HTMLDivElement>;
    modalContent: React.RefObject<HTMLDivElement>;
    modalBody: React.RefObject<HTMLDivElement>;
    modalClose: React.RefObject<HTMLDivElement>;
  }>;
};
export const ModalContent = ({
  onModalClose,
  onBack,
  open,
  classNames,
  animation,
  children,
  footer,
  refs
}: Props) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return <div className={`${styles.root} ${open ? styles.show : styles.hide}`}>
      <div onClick={onModalClose} className={`${styles.overlay} ${mounted ? styles.overlayShow : ''}`} />

      <div role="dialog" aria-modal="true" className={classNames?.modalRoot || ''} ref={refs?.modalRoot}>
        <motion.div initial={animation?.initial ?? ANIMATE_BOTTOM_TO_UP.initial} animate={open ? animation?.animate ?? ANIMATE_BOTTOM_TO_UP.animate : {}} transition={animation?.transition} className={`${styles.modalBody} ${classNames?.modalBody || ''}`} ref={refs?.modalBody}>
          {onModalClose && <ModalClose ref={refs?.modalClose} onModalClose={onModalClose} classNames={classNames} />}

          {onBack && <button className={styles.backButton} onClick={onBack} type="button">
              <img src="/icons/chevron.svg" alt="go back" />
            </button>}

          <div className={`${styles.modalContent} ${footer ? styles.modalContentWithFooter : ''} ${classNames?.modalContent || ''}`} ref={refs?.modalContent}>
            {children}
          </div>

          {footer && <div>{footer}</div>}
        </motion.div>
      </div>
    </div>;
};