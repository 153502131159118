import { CreateSportsStreamMutation, FiatCurrency, OddinStreamType } from '../generated/graphql';

import { usePopup } from './usePopup';

export enum PlayOption {
  NONE = 'NONE',
  REAL = 'REAL',
  FUN = 'FUN',
}

export type Props = {
  slug: string;
};

export type PopupType =
  | 'game'
  | 'video-genius'
  | 'video-shuffle'
  | 'video-oddin'
  | 'chart'
  | 'sports-widget';

export type PlayerErrorType = 'generic' | 'region' | 'interrupted' | 'auth' | '';

export type Popup = {
  type: PopupType;
  open?: boolean;
  timeTitle?: string;
  competitionName?: string;
  title?: string;
  play?: PlayOption;
  zIndex?: number;
  slug: string;
  footerText?: string;
  footerLink?: string;
  fiatCurrency?: FiatCurrency;
  error?: PlayerErrorType;
  widgetUrl?: string;
  videoStreamSource?:
    | (CreateSportsStreamMutation['createSportsStream'] & {
        type?: OddinStreamType;
      })
    | null;
};

export type Popups = Map<string, Popup>;

export type PopupReturns = ReturnType<typeof usePopup>;
