import { WalletModalTabType } from 'constants/modal';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useIsLoggedIn } from '../../hooks/auth/useIsLoggedIn';
import { openGlobalModal } from '../Modal/openGlobalModal';
import { ModalUrl } from 'components/Modal/GlobalModal.type';
import { useState } from 'react';
import { ExpandableLinks } from './ExpandableLinks';
type Props = {
  isExpanded: boolean;
};
export function NavigationProfile({
  isExpanded
}: Props) {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const [isOpen, toggleOpen] = useState<boolean>(false);
  if (!isLoggedIn) {
    return null;
  }
  return <ExpandableLinks name={t('navProfile')} isParentNavExpanded={isExpanded} icon="/icons/profile.svg" links={[{
    onClick: () => {
      openGlobalModal({
        router,
        tab: WalletModalTabType.DEPOSIT,
        type: ModalUrl.WALLET
      });
    },
    iconUrl: '/icons/wallet.svg',
    title: t('wallet')
  }, {
    onClick: () => {
      openGlobalModal({
        router,
        tab: WalletModalTabType.DEPOSIT,
        type: ModalUrl.VAULT
      });
    },
    iconUrl: '/icons/vault.svg',
    title: t('walletVault')
  }, {
    iconUrl: '/icons/transactions.svg',
    title: t('userMenuTransactions'),
    href: '/transactions/deposits'
  }, {
    iconUrl: '/icons/setting.svg',
    title: t('userMenuSettings'),
    href: '/settings/account'
  }]} open={isOpen} toggleIsOpen={() => {
    toggleOpen(!isOpen);
  }} />;
}