import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';

import { useGetMyRaceInfoLazyQuery, useVipLevelSubscription } from '../generated/graphql';
import { AlertsEnum, addAlert } from '../redux/slices/alertsSlice';
import { useStore } from './useStore';

export const useGlobalUpdateVipLevel = () => {
  const { state: raceInfo, setState } = useStore(state => state.profile.raceInfo);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [getMyRaceInfo] = useGetMyRaceInfoLazyQuery({
    fetchPolicy: 'network-only',
  });

  useVipLevelSubscription({
    onData: ({ data }) => {
      const vipLevelUpdated = data.data?.vipLevelUpdated;

      if (!vipLevelUpdated || !vipLevelUpdated.vipLevel || !vipLevelUpdated.xp) {
        return;
      }

      setState('profile', profile => {
        if (
          profile.vipLevel &&
          vipLevelUpdated?.vipLevel &&
          profile.vipLevel !== vipLevelUpdated.vipLevel
        ) {
          dispatch(
            addAlert({
              type: AlertsEnum.Success,
              message: t('msgLevelUp', {
                level: t(vipLevelUpdated?.vipLevel),
              }),
            }),
          );
        }

        return {
          ...profile,
          xp: vipLevelUpdated.xp,
          ...(vipLevelUpdated.vipLevel ? { vipLevel: vipLevelUpdated.vipLevel } : {}),
          ...(vipLevelUpdated?.wagered ? { usdWagered: vipLevelUpdated?.wagered } : {}),
        };
      });

      if (vipLevelUpdated?.wagered && !raceInfo) {
        getMyRaceInfo()
          .then(({ data: myRaceInfoData }) => {
            if (myRaceInfoData?.myRaceInfo) {
              setState('profile', profile => {
                return {
                  ...profile,
                  raceInfo: {
                    id: myRaceInfoData.myRaceInfo?.id ?? '',
                    rank: myRaceInfoData.myRaceInfo?.rank ?? null,
                    startingWager: myRaceInfoData.myRaceInfo?.startingWager,
                    raceEntryId: myRaceInfoData.myRaceInfo?.raceEntryId ?? '',
                  },
                };
              });
            }
          })
          .catch();
      }
    },
  });
};
