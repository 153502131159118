import dayjs from 'dayjs';

const TEN_MINUTES = 10;

export enum BEFORE_EVENT_LIVE_STATES {
  ABOUT_TO_START = 'ABOUT_TO_START',
  DELAYED_START = 'DELAYED_START',
}

export const checkEventStateBeforeLive = ({
  startTime,
  inPlayAllowed,
}: {
  startTime: string;
  inPlayAllowed?: boolean | null;
}) => {
  const minutesDifference = dayjs(startTime).diff(dayjs(), 'minute');

  if (inPlayAllowed && minutesDifference <= 0 && Math.abs(minutesDifference) > TEN_MINUTES) {
    return BEFORE_EVENT_LIVE_STATES.DELAYED_START;
  }

  if (inPlayAllowed && minutesDifference <= 0 && Math.abs(minutesDifference) <= TEN_MINUTES) {
    return BEFORE_EVENT_LIVE_STATES.ABOUT_TO_START;
  }

  return null;
};
