import { VipLevel } from 'generated/graphql';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { VipLevelParams } from './useVip';

const UNRANKED = { icon: '/images/vip/unranked.svg' };
const WOOD = { icon: '/images/vip/wood.svg' };
const BRONZE = { icon: '/images/vip/bronze.svg' };
const SILVER = { icon: '/images/vip/silver.svg' };
const GOLD = { icon: '/images/vip/gold.svg' };
const PLATINUM = { icon: '/images/vip/platinum.svg' };
const JADE = { icon: '/images/vip/jade.svg' };
const SAPPHIRE = { icon: '/images/vip/sapphire.svg' };
const RUBY = { icon: '/images/vip/ruby.svg' };
const DIAMOND = { icon: '/images/vip/diamond.svg' };
const OPAL = { icon: '/images/vip/opal.svg' };
const DRAGON = { icon: '/images/vip/dragon.svg' };
const MYTHIC = { icon: '/images/vip/mythic.svg' };

export const VIP_LEVEL_MAP: Record<VipLevel, VipLevelParams> = {
  [VipLevel.UNRANKED]: UNRANKED,
  [VipLevel.WOOD]: WOOD,
  [VipLevel.BRONZE_1]: BRONZE,
  [VipLevel.BRONZE_2]: BRONZE,
  [VipLevel.BRONZE_3]: BRONZE,
  [VipLevel.BRONZE_4]: BRONZE,
  [VipLevel.BRONZE_5]: BRONZE,
  [VipLevel.SILVER_1]: SILVER,
  [VipLevel.SILVER_2]: SILVER,
  [VipLevel.SILVER_3]: SILVER,
  [VipLevel.SILVER_4]: SILVER,
  [VipLevel.SILVER_5]: SILVER,
  [VipLevel.GOLD_1]: GOLD,
  [VipLevel.GOLD_2]: GOLD,
  [VipLevel.GOLD_3]: GOLD,
  [VipLevel.GOLD_4]: GOLD,
  [VipLevel.GOLD_5]: GOLD,
  [VipLevel.PLATINUM_1]: PLATINUM,
  [VipLevel.PLATINUM_2]: PLATINUM,
  [VipLevel.PLATINUM_3]: PLATINUM,
  [VipLevel.PLATINUM_4]: PLATINUM,
  [VipLevel.PLATINUM_5]: PLATINUM,
  [VipLevel.JADE_1]: JADE,
  [VipLevel.JADE_2]: JADE,
  [VipLevel.JADE_3]: JADE,
  [VipLevel.JADE_4]: JADE,
  [VipLevel.JADE_5]: JADE,
  [VipLevel.SAPPHIRE_1]: SAPPHIRE,
  [VipLevel.SAPPHIRE_2]: SAPPHIRE,
  [VipLevel.SAPPHIRE_3]: SAPPHIRE,
  [VipLevel.SAPPHIRE_4]: SAPPHIRE,
  [VipLevel.SAPPHIRE_5]: SAPPHIRE,
  [VipLevel.RUBY_1]: RUBY,
  [VipLevel.RUBY_2]: RUBY,
  [VipLevel.RUBY_3]: RUBY,
  [VipLevel.RUBY_4]: RUBY,
  [VipLevel.RUBY_5]: RUBY,
  [VipLevel.DIAMOND_1]: DIAMOND,
  [VipLevel.DIAMOND_2]: DIAMOND,
  [VipLevel.DIAMOND_3]: DIAMOND,
  [VipLevel.DIAMOND_4]: DIAMOND,
  [VipLevel.DIAMOND_5]: DIAMOND,
  [VipLevel.OPAL_1]: OPAL,
  [VipLevel.OPAL_2]: OPAL,
  [VipLevel.OPAL_3]: OPAL,
  [VipLevel.OPAL_4]: OPAL,
  [VipLevel.OPAL_5]: OPAL,
  [VipLevel.DRAGON_1]: DRAGON,
  [VipLevel.DRAGON_2]: DRAGON,
  [VipLevel.DRAGON_3]: DRAGON,
  [VipLevel.DRAGON_4]: DRAGON,
  [VipLevel.DRAGON_5]: DRAGON,
  [VipLevel.MYTHIC]: MYTHIC,
};

/**
 * Use this hook to get the display properties for a given VIP level.
 * Intended to be a simple mapper hook and can be used for current user,
 * another user, or any other user-case.
 *
 * Note that `null` means the user is unranked and their next level
 * is BRONZE.
 */
const useVipLevel = (level?: VipLevel | null) => {
  const { t } = useTranslation();

  // Map each VIP level to its label, color and icon.
  return useMemo(() => {
    return level
      ? {
          ...VIP_LEVEL_MAP[level],
          label: t(level),
        }
      : {
          ...VIP_LEVEL_MAP[VipLevel.UNRANKED],
          label: t(VipLevel.UNRANKED),
        };
  }, [level, t]);
};

export default useVipLevel;
