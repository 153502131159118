import { Sports, SportsMatchPhase, SportsMatchState } from 'generated/graphql';

export const getLiveMatchScoreText = (params: {
  sport: Sports;
  matchState?: SportsMatchState | null;
}) => {
  const { sport, matchState } = params;

  let homeScore = matchState?.matchSummary?.homeScore || '0';
  let awayScore = matchState?.matchSummary?.awayScore || '0';
  let homeCurrentScore = '0';
  let awayCurrentScore = '0';
  let liveScoreText = `${homeScore}-${awayScore}`;
  let liveScoreList = [];
  let lap = '';

  if (sport === Sports.SOCCER) {
    const penaltiesPhase = matchState?.matchSummary?.phases?.find(
      phase => phase.matchPhase === SportsMatchPhase.PENALTIES,
    );
    if (penaltiesPhase) {
      const homePenaltyScore = penaltiesPhase.homeScore || '0';
      const awayPenaltyScore = penaltiesPhase.awayScore || '0';
      liveScoreText = `${homeScore}-${awayScore} (${homePenaltyScore}-${awayPenaltyScore})`;
      homeScore = `${homeScore} (${homePenaltyScore})`;
      awayScore = `${awayScore} (${awayPenaltyScore})`;
    }
  }

  if (sport === Sports.MOTOR_SPORT) {
    const potentialLap = matchState?.matchSummary?.matchStatusDisplay?.[0]?.rawNumber ?? null;
    lap = potentialLap ? `${potentialLap}` : '';
  }

  const liveMatchPhaseScore = matchState?.matchSummary?.currentPhase;
  const currentScoreInGame = `${homeScore}-${awayScore}`;
  liveScoreList = liveMatchPhaseScore
    ? [
        `${liveMatchPhaseScore.homeScore ?? 0}-${liveMatchPhaseScore.awayScore ?? 0}`,
        currentScoreInGame,
      ]
    : [currentScoreInGame];

  if (liveMatchPhaseScore?.homeScore) {
    homeCurrentScore = liveMatchPhaseScore.homeScore;
  }

  if (liveMatchPhaseScore?.awayScore) {
    awayCurrentScore = liveMatchPhaseScore.awayScore;
  }

  return {
    homeCurrentScore,
    awayCurrentScore,
    homeScore,
    awayScore,
    lap,
    liveScoreText,
    liveScoreList,
  };
};
