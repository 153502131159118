import clsx from 'clsx';
import React from 'react';
import styles from './BetMessage.module.scss';
export type MessageType = 'success' | 'error' | 'warning' | 'info';
interface BetMessageProps {
  message: string;
  msgType: MessageType;
  errorMsg?: string | React.ReactNode;
  successMsg?: string | React.ReactNode;
}
export const BetMessageIcon = ({
  msgType
}: {
  msgType: MessageType;
}) => {
  return <>
      {msgType === 'error' && <img alt="error" src="/icons/times-circle.svg" />}
      {msgType === 'warning' && <img alt="warning" src="/icons/warning.svg" />}
      {msgType === 'info' && <img alt="info" src="/icons/tick-circle.svg" />}
      {msgType === 'success' && <img alt="success" src="/icons/tick-circle.svg" />}
    </>;
};
const BetMessage = ({
  message,
  msgType,
  errorMsg,
  successMsg
}: BetMessageProps) => {
  return <>
      <div className={clsx(styles.betMessageBase, {
      [String(styles.betMessageSuccess)]: msgType === 'success',
      [String(styles.betMessageError)]: msgType === 'error',
      [String(styles.betMessageWarning)]: msgType === 'warning',
      [String(styles.betMessageInfo)]: msgType === 'info',
      [String(styles.betMessageRoundedTop)]: msgType === 'error' || msgType === 'warning' || !!successMsg,
      [String(styles.betMessageWhite)]: msgType === 'error' || msgType === 'warning'
    })}>
        <BetMessageIcon msgType={msgType} />
        <span>{message}</span>
      </div>
      {errorMsg && (msgType === 'error' || msgType === 'warning') && <div className={clsx(styles.betMessageSubTitle, {
      [String(styles.betMessageSubTitleError)]: msgType === 'error',
      [String(styles.betMessageSubTitleWarning)]: msgType === 'warning'
    })}>
          {errorMsg}
        </div>}
      {msgType === 'success' && successMsg && <div className={clsx(styles.betMessageSubTitle, styles.betMessageSubTitleSuccess)}>
          {successMsg}
        </div>}
    </>;
};
export default BetMessage;