import { captureMessage } from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useJoinChatMutation } from '../../../generated/graphql';
import { useMobileNav } from '../../../hooks/useMobileNav';
import { useSportsBet } from '../../../hooks/useSportsBet';
import { AlertsEnum, addAlert } from '../../../redux/slices/alertsSlice';
import { RightSidebarVariant } from '../../../redux/slices/browserPreferenceSlice';
import { BetSlipStatus } from '../../../redux/slices/sportsBetSlice.type';
import RoundButton from '../../Buttons/RoundButton';
import { Tooltip } from '../../Tooltip';
type Props = {
  isMenuExpanded?: boolean;
  rightSidebarVariant: RightSidebarVariant;
};
export function ChatMenuButton({
  isMenuExpanded,
  rightSidebarVariant
}: Props) {
  const {
    updateBetSlipStatus
  } = useSportsBet();
  const {
    t: errorT
  } = useTranslation('errors');
  const [chatJoin] = useJoinChatMutation();
  const {
    toggleChatBox
  } = useMobileNav();
  const {
    clearBetSlip
  } = useSportsBet();
  const {
    t
  } = useTranslation();
  const {
    betSlipStatus,
    chatRoom
  } = useSelector((state: AppState) => {
    return {
      betSlipStatus: state.sportsBet.betSlipStatus,
      chatRoom: state.browserPreference.chatRoom
    };
  }, shallowEqual);
  const dispatch = useDispatch();
  const shouldShowChat = rightSidebarVariant === RightSidebarVariant.CHAT;
  const showChatBox = async () => {
    toggleChatBox(!shouldShowChat);
    if (betSlipStatus === BetSlipStatus.BET_PLACED) {
      updateBetSlipStatus(BetSlipStatus.EMPTY);
      clearBetSlip();
    }
    if (chatRoom) {
      try {
        const {
          errors
        } = await chatJoin({
          variables: {
            data: {
              chatRoom
            }
          }
        });
        if (errors && errors?.length > 0) {
          throw new Error(errors?.[0]?.message);
        }
      } catch (e) {
        captureMessage(`Chat failed ${(e as Error).message || ''}`);
        dispatch(addAlert({
          type: AlertsEnum.Error,
          message: errorT('ERROR_OCCURRED')
        }));
      }
    } else {
      captureMessage('Chat failed missing chat room');
    }
  };
  return <Tooltip content={t('txtChat')} placement={isMenuExpanded ? 'right' : 'bottom'}>
      <RoundButton data-testid="chat-button" onClick={showChatBox} color={shouldShowChat ? 'primary' : 'tertiary'} icon={<img src="/icons/chat.svg" alt="chat" />} />
    </Tooltip>;
}