import { useTranslation } from 'next-i18next';
import { memo, useCallback, useEffect, useState } from 'react';
import Button from '../Buttons/Button';
import styles from './ClipboardCopy.module.scss';
interface CopyIconProps {
  value: string;
  color?: string;
  isButton?: boolean;
  className?: string;
}
const ClipboardCopy = ({
  value,
  color,
  isButton,
  className
}: CopyIconProps) => {
  const [copied, setCopied] = useState(false);
  const {
    t
  } = useTranslation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: dep
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(value);
    setCopied(true);
  }, [value]);
  return <div onClick={handleCopy} className={`${styles.root} ${className || ''}`}>
      {!isButton && <>
          {copied ? <p className={styles.copiedText}>{t('clipboardCopied')}</p> : <img style={color ? {} : {
        filter: 'var(--filter-white)'
      }} src="/icons/copy.svg" alt="copy" />}
        </>}
      {isButton && <Button className={styles.button} value={t(copied ? 'clipboardCopied' : 'btnCopy')} />}
    </div>;
};
export default memo(ClipboardCopy);