import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  accessToken: string;
  refreshToken: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLogin: (state: AuthState, action: PayloadAction<LoginResponse>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    updateAuthStore: (_: AuthState, action: PayloadAction<LoginResponse>) => action.payload,
  },
});

export const { onLogin, updateAuthStore } = authSlice.actions;

export default authSlice.reducer;
