import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { useSportsOdds } from 'hooks/useSportsOdds';
import React, { useMemo } from 'react';
import { useFormattedOdds } from '../../hooks/useFormattedOdds';
import styles from './Odds.module.scss';
export interface OddsProps {
  oddsNumerator?: string;
  oddsDenominator?: string;
  showOddsChanges?: boolean;
  showColorOddsChanges?: boolean;
  autoHideIndicator?: boolean;
  totalOddsDecimal?: string;
  oddsDisplayPosition?: 'left' | 'right';
  classNames?: {
    root?: string;
  };
}
const Odds = ({
  oddsNumerator,
  oddsDenominator,
  showOddsChanges = false,
  oddsDisplayPosition = 'left',
  showColorOddsChanges = false,
  autoHideIndicator = true,
  totalOddsDecimal,
  classNames
}: OddsProps) => {
  const {
    getFormattedOdds
  } = useFormattedOdds();
  const oddsFormatted = useMemo(() => {
    return getFormattedOdds({
      ...(totalOddsDecimal ? {
        oddDecimal: BigNumber(totalOddsDecimal)
      } : {}),
      oddsNumerator,
      oddsDenominator
    });
  }, [getFormattedOdds, oddsDenominator, oddsNumerator, totalOddsDecimal]);
  const {
    isOddsChanges,
    isOddsIncrease,
    isOddsDecrease
  } = useSportsOdds({
    odds: oddsFormatted.outputDecimal.toString(),
    autoHideIndicator: autoHideIndicator
  });
  const OddsChange = showOddsChanges && isOddsChanges && <span className={styles.oddsUpdate}>
      {isOddsIncrease && <img src="/icons/arrow-increase.svg" alt="increase" />}
      {isOddsDecrease && <img src="/icons/arrow-decrease.svg" alt="decrease" />}
    </span>;
  return <span className={`${styles.oddsWrapper} ${classNames?.root || ''}`}>
      {oddsDisplayPosition === 'right' && OddsChange}
      <span className={clsx({
      [String(styles.oddsIncrease)]: showColorOddsChanges && isOddsIncrease,
      [String(styles.oddsDecrease)]: showColorOddsChanges && isOddsDecrease
    })}>
        {oddsFormatted.outputText}
      </span>
      {oddsDisplayPosition === 'left' && OddsChange}
    </span>;
};
export default React.memo(Odds);