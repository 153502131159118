import { ReactNode } from 'react';
import styles from './Visible.module.scss';
export function Visible({
  children,
  show
}: {
  children: ReactNode;
  show?: boolean;
}) {
  return <section className={show ? '' : styles.hide}>{children}</section>;
}