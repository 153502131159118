import React from 'react';
import { ButtonHeight } from './ButtonVariants';
import styles from './PrimaryButton.module.scss';
interface ButtonProps {
  height?: ButtonHeight;
  isRound?: boolean;
  hasMargin?: boolean;
  fillIcon?: boolean;
  bold?: boolean;
  noDisabledStyle?: boolean;
  onClick?: React.MouseEventHandler;
  as?: string;
  className?: string;
  disabled?: boolean;
  value?: string | number | string[] | readonly string[];
}
const buttonHeightStyles: Record<ButtonHeight, string> = {
  sm: String(styles.buttonHeightSmall),
  md: String(styles.buttonHeightMedium),
  lg: String(styles.buttonHeightLarge)
};
export const PrimaryButton = ({
  children,
  height,
  hasMargin,
  bold,
  fillIcon,
  noDisabledStyle,
  className,
  onClick,
  disabled,
  value,
  as,
  isRound,
  ...rest
}: ButtonProps & {
  children: React.ReactNode;
}) => {
  const classNameString = `${styles.root} ${isRound ? styles.isRound : ''} ${hasMargin ? styles.hasMargin : ''} ${noDisabledStyle ? '' : styles.noDisabledStyle} ${!noDisabledStyle && fillIcon ? styles.fillIcon : ''} ${bold ? styles.bold : ''} ${className || ''} ${buttonHeightStyles[height ?? 'md']}`;
  if (as === 'a') {
    return <a onClick={onClick} className={classNameString} {...rest}>
        {children}
      </a>;
  }
  return <button value={value} disabled={disabled} onClick={onClick} className={classNameString} {...rest}>
      {children}
    </button>;
};