import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  KenoRiskLevel,
  OriginalGame,
  PlinkoRiskLevel,
  WheelRiskLevel,
  WheelSegments,
} from 'generated/graphql';
import { DiceDirection } from 'shufflecom-calculations/lib/games/dice';
import { LIMBO_CONSTANTS } from 'utils/games/limbo';
import { MinesUtils } from 'utils/games/mines';

import { BlackjackTab } from 'views/games/OriginalGameBlackjack/utils';
import { BetTab } from '../../views/games/components/GameLayout/GameLayout.type';

interface commonGamePersistState {
  activeTab: BetTab;
}

const commonInitialState: commonGamePersistState = {
  activeTab: BetTab.MANUAL,
};

// DICE
export const DEFAULT_DICE_ROLL_VALUE = '50.50';

export interface DiceGamePersistState extends commonGamePersistState {
  rollValue: string;
  betDirection: DiceDirection;
}

const diceInitialState: DiceGamePersistState = {
  ...commonInitialState,
  rollValue: DEFAULT_DICE_ROLL_VALUE,
  betDirection: DiceDirection.ABOVE,
};

// LIMBO
export interface LimboGamePersistState extends commonGamePersistState {
  targetMultiplier: string;
}

const limboInitialState: LimboGamePersistState = {
  ...commonInitialState,
  targetMultiplier: LIMBO_CONSTANTS.LIMBO_DEFAULT_MULTIPLIER,
};

// KENO
export interface KenoGamePersistState extends commonGamePersistState {
  selectedTiles: number[];
  riskLevel: KenoRiskLevel;
}

const kenoInitialState: KenoGamePersistState = {
  ...commonInitialState,
  selectedTiles: [],
  riskLevel: KenoRiskLevel.CLASSIC,
};

// MINES
export interface MineGamePersistState extends commonGamePersistState {
  minesCount: number;
  selectedTilesAuto: number[];
}

const minesInitialState: MineGamePersistState = {
  ...commonInitialState,
  minesCount: MinesUtils.DEFAULT_MINES_QTY,
  selectedTilesAuto: [],
};

// WHEEL
export interface WheelGamePersistState extends commonGamePersistState {
  riskLevel: WheelRiskLevel;
  segments: WheelSegments;
}

const wheelInitialState: WheelGamePersistState = {
  ...commonInitialState,
  riskLevel: WheelRiskLevel.MEDIUM,
  segments: WheelSegments.TWENTY,
};

// PLINKO
export interface PlinkoGamePersistState extends commonGamePersistState {
  localStorage: {
    numberOfRows: number;
    riskLevel: PlinkoRiskLevel;
  };
}

export const plinkoInitialState: PlinkoGamePersistState = {
  ...commonInitialState,
  localStorage: {
    numberOfRows: 8,
    riskLevel: PlinkoRiskLevel.MEDIUM_RISK,
  },
};

// BLACKJACK
interface BlackjackGamePersistState {
  activeTab: BlackjackTab;
}

const blackjackInitialState: BlackjackGamePersistState = {
  activeTab: BlackjackTab.STANDARD,
};

export type GamePersistStates = {
  [OriginalGame.DICE]: DiceGamePersistState;
  [OriginalGame.LIMBO]: LimboGamePersistState;
  [OriginalGame.KENO]: KenoGamePersistState;
  [OriginalGame.MINES]: MineGamePersistState;
  [OriginalGame.WHEEL]: WheelGamePersistState;
  [OriginalGame.PLINKO]: PlinkoGamePersistState;
  [OriginalGame.BLACKJACK]: BlackjackGamePersistState;
  [OriginalGame.ROULETTE]: commonGamePersistState;
  [OriginalGame.CRASH]: commonGamePersistState;
};

export interface GamesState {
  /** Will be fiat if the user's `displayInFiat` preference is true, crypto otherwise */
  betAmount: string;
  isPlaying: boolean;
  persistStateGames: {
    [OriginalGame.DICE]: DiceGamePersistState;
    [OriginalGame.LIMBO]: LimboGamePersistState;
    [OriginalGame.KENO]: KenoGamePersistState;
    [OriginalGame.MINES]: MineGamePersistState;
    [OriginalGame.WHEEL]: WheelGamePersistState;
    [OriginalGame.PLINKO]: PlinkoGamePersistState;
    [OriginalGame.BLACKJACK]: BlackjackGamePersistState;
    [OriginalGame.ROULETTE]: commonGamePersistState;
    [OriginalGame.CRASH]: commonGamePersistState;
  };
}

// Persistent storage for games
const initialState: GamesState = {
  betAmount: '0.00',
  isPlaying: false,
  persistStateGames: {
    [OriginalGame.DICE]: diceInitialState,
    [OriginalGame.LIMBO]: limboInitialState,
    [OriginalGame.KENO]: kenoInitialState,
    [OriginalGame.MINES]: minesInitialState,
    [OriginalGame.WHEEL]: wheelInitialState,
    [OriginalGame.PLINKO]: plinkoInitialState,
    [OriginalGame.BLACKJACK]: blackjackInitialState,
    [OriginalGame.ROULETTE]: commonInitialState,
    [OriginalGame.CRASH]: commonInitialState,
  },
};

export const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    updateIsPlaying: (state: GamesState, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload;
    },
    updateBetAmount: (state: GamesState, action: PayloadAction<string>) => {
      if (Number.isNaN(Number(action.payload))) return state;
      state.betAmount = action.payload;
    },
    updateGamePersistState: <T extends keyof GamePersistStates>(
      state: GamesState,
      action: PayloadAction<{
        game: T;
        persistState: Partial<GamePersistStates[T]>;
      }>,
    ) => {
      const { game, persistState } = action.payload;
      state.persistStateGames[game] = {
        ...state.persistStateGames[game],
        ...persistState,
      };
    },
    updatePlinkoLocalStorage: (
      state: GamesState,
      action: PayloadAction<Partial<PlinkoGamePersistState['localStorage']>>,
    ) => {
      state.persistStateGames[OriginalGame.PLINKO].localStorage = {
        ...state.persistStateGames[OriginalGame.PLINKO].localStorage,
        ...action.payload,
      };
    },
  },
});

export const {
  updateBetAmount,
  updateIsPlaying,
  updateGamePersistState,
  updatePlinkoLocalStorage,
} = gamesSlice.actions;

export default gamesSlice.reducer;
