const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'fr', 'zh', 'es', 'pt', 'ko', 'ja', 'de', 'hu', 'tr', 'ru'],
    localeDetection: false,
  },
  localePath: path.resolve('./.locales'),
  react: { useSuspense: false },
};
