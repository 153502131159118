import BigNumber from 'bignumber.js';
import { i18n } from 'next-i18next';

import { OddsFormat } from '../redux/slices/browserPreferenceSlice';

import { formatOddsNumber } from './formatOddsNumber';

type Props = {
  oddsFormat: OddsFormat;
  oddDecimal?: BigNumber;
  oddsNumerator?: string;
  oddsDenominator?: string;
  precision?: number;
};

export const getSportBetOdds = ({
  oddsFormat,
  oddDecimal,
  oddsNumerator,
  oddsDenominator,
  precision = 2,
}: Props) => {
  const oddsNumeratorBN = BigNumber(oddsNumerator || 0);
  const oddsDenominatorBN = BigNumber(oddsDenominator || 0);
  const decimal =
    oddDecimal?.decimalPlaces(precision) ||
    oddsNumeratorBN
      .dividedBy(oddsDenominatorBN)
      .plus(1)
      .decimalPlaces(precision, BigNumber.ROUND_DOWN);
  const [fractionOddsNumerator, fractionOddsDenominator] = oddDecimal
    ? BigNumber(oddDecimal.toFixed(2)).minus(1).toFraction()
    : [];

  let outputText = '';
  let outputNumber = BigNumber(0);

  switch (oddsFormat) {
    case OddsFormat.Decimal:
      outputNumber = decimal;
      outputText = formatOddsNumber({
        outputNumber,
        shouldAppendPlusSymbol: false,
        fixedDecimalPlace: 2,
      });
      outputText = i18n?.t
        ? i18n.t('intlNumber', {
            val: outputText ?? 0,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : outputText;
      break;
    case OddsFormat.Fractional:
      outputText = oddDecimal
        ? `${fractionOddsNumerator}/${fractionOddsDenominator}`
        : `${oddsNumerator}/${oddsDenominator}`;
      outputNumber = BigNumber(decimal);
      break;
    case OddsFormat.American:
      if (decimal.gte(2)) {
        outputNumber = decimal.minus(1).times(100);
      } else {
        outputNumber = BigNumber(-100).dividedBy(decimal.minus(1)).decimalPlaces(0);
      }
      outputText = formatOddsNumber({
        outputNumber,
        shouldAppendPlusSymbol: true,
      });
      break;
    case OddsFormat.HongKong:
      outputNumber = decimal.minus(1).decimalPlaces(2);
      outputText = formatOddsNumber({
        outputNumber,
        shouldAppendPlusSymbol: true,
        fixedDecimalPlace: 2,
      });
      outputText = i18n?.t
        ? i18n.t('intlNumber', {
            val: outputText ?? 0,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : outputText;
      break;
    case OddsFormat.Indonesian:
      if (decimal.lt(2)) {
        outputNumber = BigNumber(-1).dividedBy(decimal.minus(1)).decimalPlaces(2);
      } else {
        outputNumber = decimal.minus(1);
      }
      outputText = formatOddsNumber({
        outputNumber,
        shouldAppendPlusSymbol: true,
        fixedDecimalPlace: 2,
      });
      outputText = i18n?.t
        ? i18n.t('intlNumber', {
            val: outputText ?? 0,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : outputText;
      break;
    case OddsFormat.Malaysian:
      if (decimal.lte(2)) {
        outputNumber = decimal.minus(1).decimalPlaces(2);
      } else {
        outputNumber = BigNumber(-1).dividedBy(decimal.minus(1)).decimalPlaces(2);
      }
      outputText = formatOddsNumber({
        outputNumber,
        shouldAppendPlusSymbol: true,
        fixedDecimalPlace: 2,
      });
      outputText = i18n?.t
        ? i18n.t('intlNumber', {
            val: outputText ?? 0,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : outputText;
      break;
    default:
      return {
        outputNumber: BigNumber(0),
        outputText: '',
        outputDecimal: decimal,
      };
  }

  return {
    outputNumber,
    outputText,
    outputDecimal: decimal,
  };
};
