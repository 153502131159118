import React from 'react';
import styles from './Container.module.scss';
const CONTAINER_CLASSNAME = 'container';
export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * Primary UI component for user interaction
 */
const Container = React.forwardRef<HTMLDivElement, ContainerProps>(({
  className,
  children
}, ref) => {
  return <section ref={ref} className={`${styles.root} ${CONTAINER_CLASSNAME} ${className || ''}`}>
        {children}
      </section>;
});
export default Container;