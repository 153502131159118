import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  EventState,
  Sports,
  SportsFixtureBannerType,
  SportsMarketProvider,
} from 'generated/graphql';
import { i18n } from 'next-i18next';

import { abbreviateCompetitorName } from './getSportsCompetitors';
import { BEFORE_EVENT_LIVE_STATES, checkEventStateBeforeLive } from './sportEventStatusCheck';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const t = (name: string) => i18n?.t?.(name) ?? name;

export const generateSportsFromSlug = (sportSlug: string) => {
  return (sportSlug ? sportSlug.toString().replace('-', '_').toUpperCase() : '') as Sports;
};

export const generateCustomSportTitle = (name: string) => {
  if (name === 'CSGO') {
    return 'CS';
  }
  return name;
};

export const generateSlug = (sports: string) => {
  return sports.replace(/[ _]/g, '-').toLowerCase();
};

export const generateSportsFixtureName = ({
  homeTeamDisplayName,
  awayTeamDisplayName,
  fixtureNameDefault,
  fixtureBannerType,
  provider,
  abbreviate = false,
}: {
  homeTeamDisplayName: string;
  awayTeamDisplayName: string;
  fixtureNameDefault: string;
  fixtureBannerType: SportsFixtureBannerType;
  provider: SportsMarketProvider | null;
  abbreviate?: boolean;
}) => {
  const isManualMarket = provider && provider === SportsMarketProvider.SHUFFLE;
  const isMultiCompetitorStyle = fixtureBannerType === SportsFixtureBannerType.MULTI_COMPETITOR;
  const shouldUseFixtureNameDefault = isMultiCompetitorStyle || isManualMarket;

  if (shouldUseFixtureNameDefault) {
    return fixtureNameDefault;
  }

  if (abbreviate) {
    return `${abbreviateCompetitorName(homeTeamDisplayName)} vs. ${abbreviateCompetitorName(awayTeamDisplayName)}`;
  }

  return `${homeTeamDisplayName} vs ${awayTeamDisplayName}`;
};

export const convertMatchSetNumber = (number: number): string => {
  let suffix = 'th';
  const lastDigit: number = number % 10;
  const lastTwoDigits: number = number % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    suffix = 'st';
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    suffix = 'nd';
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    suffix = 'rd';
  }

  return number + suffix;
};

export const getEventState = ({
  startTime,
  inPlayAllowed,
  formattedDateTime,
  eventState,
}: {
  startTime: string;
  inPlayAllowed?: boolean | null;
  formattedDateTime: string;
  eventState?: EventState | null;
}) => {
  const eventStateBeforeLive = checkEventStateBeforeLive({
    startTime,
    inPlayAllowed,
  });

  const isEventSuspended = eventState === EventState.SUSPENDED;

  if (!startTime || !formattedDateTime) {
    return '';
  }

  if (isEventSuspended) {
    return t('txtSuspended');
  }

  if (eventStateBeforeLive === BEFORE_EVENT_LIVE_STATES.DELAYED_START) {
    return t('delayedStart');
  }

  if (eventStateBeforeLive === BEFORE_EVENT_LIVE_STATES.ABOUT_TO_START) {
    return t('aboutToStart');
  }

  return '';
};
