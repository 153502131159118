export const preventNonNumericInput = (e: React.KeyboardEvent<HTMLInputElement>) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const preventNonNumericInputIncludingPeriod: React.KeyboardEventHandler = e => {
  if (['.', 'e', 'E', '+', '-'].includes(e.key)) e.preventDefault();
};

export function focusAndOpenKeyboard(el?: HTMLInputElement) {
  if (el) {
    const element = document.createElement('input');
    element.style.position = 'absolute';
    element.style.top = `${el.offsetTop + 7}px`;
    element.style.left = `${el.offsetLeft}px`;
    element.style.height = '0';
    element.style.opacity = '0';
    document.body.appendChild(element);
    element.focus();

    setTimeout(() => {
      el.focus();
      el.click();
      document.body.removeChild(element);
    }, 100);
  }
}
