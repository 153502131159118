import React from 'react';
import styles from './ModalClose.module.scss';
type Props = {
  onModalClose: () => void;
  classNames?: Partial<{
    modalRoot: string;
    modalContent: string;
    modalBody: string;
    modalClose: string;
  }>;
};
export const ModalClose = React.forwardRef<HTMLDivElement, Props>(({
  onModalClose,
  classNames
}, ref) => {
  return <div className={styles.modalHeader} ref={ref}>
        <button aria-label="Close modal" className={`${styles.closeButton} ${classNames?.modalClose || ''}`} id="close-modal" onClick={onModalClose}>
          <img src="/icons/times.svg" alt="times" />
        </button>
      </div>;
});