import { EventState } from 'generated/graphql';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import colors from '../../../../assets/styles/colors';
import { useDateFormat } from '../../../../hooks/format/useDateFormat';
import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice.type';
import { BetGameEventInfoProps } from './BetGameEventInfo';
import styles from './BetGameEventInfo.module.scss';
import { BetTicketText } from './BetTicketText';
import { findNextStartBet } from './findNextStartBet';
type Props = {
  hasMarketNotOpenInBetAdd: boolean;
  isVoid: boolean;
  allBetsResulted: boolean;
  oddsOutputText: string;
  isInBetAddOrConfirmed: boolean;
  startTime: string;
  collapses: boolean[];
} & Pick<BetGameEventInfoProps, 'odds' | 'setAnimateLegIndex' | 'bets' | 'setCollapse' | 'betIds' | 'betSlipStatus' | 'matchStates'>;
const BET_SLIPS_STATS_SHOW_LEGS = [BetSlipStatus.PENDING_BET, BetSlipStatus.RESULTED_BET, BetSlipStatus.BET_PLACED, BetSlipStatus.BET_VIEW_INFO];
export const BetGameEventInfoMultiple = ({
  startTime,
  setCollapse,
  bets,
  collapses,
  setAnimateLegIndex,
  isVoid,
  allBetsResulted,
  isInBetAddOrConfirmed,
  oddsOutputText,
  odds,
  betIds,
  hasMarketNotOpenInBetAdd,
  betSlipStatus,
  matchStates
}: Props) => {
  const timerRef = useRef(0);
  const [showCollapseLegs, setShowCollapseLegs] = useState([BetSlipStatus.PENDING_BET, BetSlipStatus.RESULTED_BET].includes(betSlipStatus));
  const {
    t
  } = useTranslation();
  const {
    formatDate
  } = useDateFormat();
  const {
    findIndex: foundBetIndex,
    bet
  } = findNextStartBet(bets);
  const formattedStartTime = formatDate(bet?.startTime ?? startTime, 'lll');
  const isAllBetsLive = useMemo(() => matchStates?.every(({
    matchSummary
  }) => matchSummary?.eventState === EventState.LIVE), [matchStates]);
  const onClickNextLeg = () => {
    clearTimeout(timerRef.current);
    setAnimateLegIndex(foundBetIndex);
    setCollapse(data => {
      data[foundBetIndex] = false;
      return data;
    });
    setShowCollapseLegs(false);
    timerRef.current = window.setTimeout(() => {
      setAnimateLegIndex(-1);
    }, 500);
  };
  const onClickLeg = () => {
    setShowCollapseLegs(prevState => {
      setCollapse(data => data.map(() => !prevState));
      return !prevState;
    });
  };
  useEffect(() => {
    setShowCollapseLegs(collapses.some(collapse => collapse));
  }, [collapses]);
  return <div className={styles.betTicketMarketBlock}>
      <div className={styles.totalLegsField}>
        <BetTicketText dimmed={hasMarketNotOpenInBetAdd} bold color={colors.primaryViolet}>
          {betIds.length} {t('legs')}
        </BetTicketText>
        <BetTicketText bold>{odds?.outputText ?? oddsOutputText}</BetTicketText>
      </div>

      {!isInBetAddOrConfirmed && !allBetsResulted && !isAllBetsLive && <button className={`${styles.legsButton} ${isVoid ? styles.voidedTime : ''}`} onClick={onClickNextLeg}>
          <BetTicketText>
            {t('nextLeg')}: {formattedStartTime}
            <img src="/icons/chevron.svg" alt="chevron" className={styles.arrow} />
          </BetTicketText>
        </button>}

      {BET_SLIPS_STATS_SHOW_LEGS.includes(betSlipStatus) && <button type={'button'} title="Expand / collapse all legs" className={styles.showLegs} onClick={onClickLeg}>
          {t(showCollapseLegs ? 'showBetLegs' : 'hideBetLegs')}{' '}
          <img className={`${styles.legsIcon} ${showCollapseLegs ? '' : styles.hideLegsIcon}`} src="/icons/chevron.svg" alt="arrow" />
        </button>}
    </div>;
};