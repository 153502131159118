import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

interface IntersectionObserverOptions {
  threshold: number | number[];
  root?: Element;
  rootMargin?: string;
}

export interface UseIntersectionObserverProperties {
  triggerOnce?: boolean;
  ref?: RefObject<Element> | null;
  element?: Element;
  options?: IntersectionObserverOptions;
  callback?: ({
    entries,
    inView,
  }: {
    entries?: IntersectionObserverEntry[];
    inView: boolean;
  }) => void;
  disabled?: boolean;
}

export const useIntersectionObserver = ({
  ref,
  element,
  triggerOnce = true,
  options = { threshold: 0 },
  callback,
  disabled,
}: UseIntersectionObserverProperties) => {
  const [inView, setInView] = useState(false);
  const dom = ref?.current;
  const callBackRef = useRef(callback);

  const handleIntersect = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!intersectObs) return;

      if (triggerOnce) {
        const inView = entries.some(e => e.isIntersecting);

        if (inView) {
          callBackRef.current?.({ entries, inView });
          intersectObs.disconnect();
        }

        setInView(inView);
        return;
      }

      const inView = !!entries[entries.length - 1]?.isIntersecting;

      callBackRef.current?.({ entries, inView });
      setInView(inView);
    },
    [triggerOnce],
  );

  const [intersectObs] = useState(() =>
    typeof window !== 'undefined' ? new IntersectionObserver(handleIntersect, options) : undefined,
  );

  useEffect(() => {
    if (!intersectObs || disabled) return;

    let domNode;

    if (ref?.current) {
      domNode = ref.current;
    } else if (element && !dom) {
      domNode = element;
    }

    if (domNode) {
      intersectObs.observe(domNode);
    }

    return () => intersectObs.disconnect();
  }, [dom, intersectObs, element, ref, disabled]);

  return { inView };
};
