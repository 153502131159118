import { Language, useUpdatePreferencesMutation } from 'generated/graphql';
import { useLanguagePreference } from 'hooks/useLanguagePreference';
import { useStore } from 'hooks/useStore';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getCookie, setLanguagePreferenceCookie } from '../../utils/appCookie';
import { omit } from '../../utils/omit';
export const useGlobalUpdateLanguage = () => {
  const {
    languagePreference
  } = useLanguagePreference();
  const [updatePreferences] = useUpdatePreferencesMutation();
  const router = useRouter();
  const {
    query,
    pathname,
    replace,
    locale
  } = router;
  const {
    state: language
  } = useStore(state => state.profile.language);
  useEffect(() => {
    const {
      langRedirected
    } = query;
    if (langRedirected) {
      const newQuery = omit(query, ['langRedirected']);
      replace({
        pathname,
        query: newQuery
      }, undefined, {
        shallow: true,
        locale
      }).catch();
    }
  }, [replace, pathname, query, locale]);
  useEffect(() => {
    const languagePreference = getCookie('language-preference');
    if (languagePreference) {
      setLanguagePreferenceCookie((languagePreference as Language));
    }
  }, []);
  useEffect(() => {
    if (language !== languagePreference) {
      updatePreferences({
        variables: {
          data: {
            language: languagePreference
          }
        }
      }).catch();
    }
  }, [languagePreference, language, updatePreferences]);
};