import React, { HTMLAttributes } from 'react';
import styles from './Flex.module.scss';
type Props = {
  column?: boolean;
  wide?: boolean;
  align?: string;
  justify?: string;
  gap?: string;
  color?: string;
  wrap?: string;
  padding?: string;
  opacity?: number;
  children?: React.ReactNode[] | React.ReactNode;
  id?: string;
  className?: string;
} & HTMLAttributes<HTMLDivElement>;
export const Flex = (props: Props) => {
  return <div id={props.id} className={`${styles.root} ${props.className || ''}`} style={{
    flexDirection: props.column ? 'column' : 'row',
    alignItems: props.align ?? 'unset',
    justifyContent: props.justify ?? 'unset',
    gap: props.gap ?? 'unset',
    width: props.wide ? '100%' : 'unset',
    color: props.color ?? 'unset',
    flexWrap: (props.wrap as 'unset') ?? 'unset',
    ...(props.padding ? {
      padding: props.padding
    } : {}),
    ...(props.opacity ? {
      opacity: props.opacity
    } : {})
  }} onMouseDown={props.onMouseDown}>
      {props.children}
    </div>;
};