import { AuthModalTabType } from 'constants/modal';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RightSidebarVariant,
  setRightSidebar,
  toggleBetSlipSidebar,
} from 'redux/slices/browserPreferenceSlice';
import { MobileMenuName, setActiveMobileNav } from 'redux/slices/globalStateSlice';

import { openGlobalModal } from '../components/Modal/openGlobalModal';
import { openGlobalOverlay } from '../components/Modal/openGlobalOverlay';

import { ModalUrl } from 'components/Modal/GlobalModal.type';
import { useIsLoggedIn } from './auth/useIsLoggedIn';
import { useSportRouteCheck } from './useSportRouteCheck';

export const useMobileNav = () => {
  const dispatch = useDispatch();
  const { isSportPage } = useSportRouteCheck();
  const isLoggedIn = useIsLoggedIn();
  const router = useRouter();
  const rightSidebarVariant = useSelector(
    (state: AppState) => state.browserPreference.rightSidebarVariant,
  );

  const toggleChatBoxHandler = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        dispatch(setActiveMobileNav(MobileMenuName.CHAT));
        dispatch(setRightSidebar({ variant: RightSidebarVariant.CHAT }));
      } else {
        dispatch(setRightSidebar({ variant: RightSidebarVariant.NONE }));
      }
    },
    [dispatch],
  );

  const setActiveMobileNavHandler = useCallback(
    (itemName: MobileMenuName) => {
      dispatch(setRightSidebar({ variant: RightSidebarVariant.NONE }));

      if (itemName === MobileMenuName.CHAT) {
        if (!isLoggedIn) {
          return openGlobalModal({
            router,
            type: ModalUrl.AUTH,
            tab: AuthModalTabType.LOGIN,
          });
        }
        dispatch(setRightSidebar({ variant: RightSidebarVariant.CHAT }));
      } else if (itemName === MobileMenuName.SEARCH) {
        openGlobalOverlay({
          router,
          type: 'search',
          value: isSportPage ? 'sports' : 'casino',
        });
      } else if (itemName === MobileMenuName.SPORTS_BET) {
        dispatch(
          toggleBetSlipSidebar({
            open: rightSidebarVariant !== RightSidebarVariant.BET_SLIP,
          }),
        );
      }

      dispatch(setActiveMobileNav(itemName));
    },
    [dispatch, isLoggedIn, isSportPage, rightSidebarVariant, router],
  );

  return {
    toggleChatBox: toggleChatBoxHandler,
    setActiveMobileNav: setActiveMobileNavHandler,
  };
};
