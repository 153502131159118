import {
  GetBetInfoQuery,
  OriginalGame,
  PlinkoRiskLevel,
  WheelRiskLevel,
  WheelSegments,
} from 'generated/graphql';

export enum ModalTypes {
  NONE = 0,

  // Auth
  EMAIL = 1,
  AUTHENTICATION = 2,
  CREATE_NEW_PASSWORD = 3,
  CHANGE_PASSWORD = 4,
  PASSWORD = 5,

  // Wallet
  WALLET = 6,
  CURRENCY = 7,
  WITHDRAWAL_SUCCESS = 8,
  WITHDRAWAL_OTP = 9,
  TIP_SUCCESS = 10,
  TIP_INFO = 11,
  TIP_OTP = 12,

  // Other
  GENERAL = 13,
  CREATE_CAMPAIGN = 14,

  // Users
  USER = 15,
  USER_EDIT = 16,
  USER_LOOKUP = 17,

  // Bets
  BET = 18,
  RESULTS = 19,
  BET_LOOKUP = 20,
  SPORT_RESULTS = 21,
  PROVABLY_FAIR = 22,

  // Chat
  CHAT_RULES = 23,
  CHAT_RAIN = 24,
  CHAT_RAIN_INFO = 25,

  // Promotions
  VIP_UPGRADE_BONUS_MODAL = 26,
  DAILY_RAKEBACK_MODAL = 27,
  MONTHLY_BONUS_MODAL = 28,
  REDEEM_CODE_LOOKUP = 29,
  WEEKLY_BONUS_MODAL = 30,
  RACE_CLAIM = 31,
  RACE_INFO = 32,
  RAKEBACK_MODAL = 33,
  PROMO_REDEEM = 34,
  VIP_BONUS_MODAL = 35,

  // Games
  CRASH_PUBLIC_GAME_RESULT = 36,
  CRASH_GAME_TRENDS_MODAL = 37,
  GAME_RESTRICTION_MODAL = 38,
  GAME_HOTKEYS = 39,

  // Oauth
  VERIFY_WITH_OAUTH = 40,

  //TOTP
  TOTP_MODAL = 41,
  TOTP_SETUP = 42,
  TOTP_DISABLE = 43,

  REQUEST_STATS = 44,
}

export enum AuthModalType {
  LoginAndRegister = 'LOGIN_AND_REGISTER',
  ForgotPassword = 'FORGOT_PASSWORD',
  ResetPassword = 'RESET_PASSWORD',
  OTPVerification = 'OTP_VERIFICATION',
  OauthTotpVerification = 'OAUTH_TOTP_VERIFICATION',
  SessionExpired = 'SESSION_EXPIRED',
  RegisterMoreDetails = 'REGISTER_MORE_DETAILS',
}

export type GeneralModalIcon = 'success' | 'verification' | 'logout' | 'mail' | 'new';

export enum WalletModalTabType {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  TIP = 'tip',
  BUY_CRYPTTO = 'buy-cryptto',
  LINK_ACCOUNT = 'link-account',
}

export enum AuthModalTabType {
  LOGIN = 'login',
  REGISTER = 'register',
}

export enum ProvablyFairTabType {
  SEEDS = 'seeds',
  VERIFY = 'verify',
}

export interface ModalProps {
  header?: string;
  body?: string;
  icon?: GeneralModalIcon;
  link?: string;
  linkText?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

// currently only for withdrawing from vault
export interface ProvablyFairProps {
  serverSeed: string;
  hashedServerSeed: string;
  nonce: string;
  clientSeed: string;
  gameSlug: string; // Currently only dice
  verifyGameType: OriginalGame; // Allows the modal to know which game to verify
  plinkoRowCount: string; // Plinko only
  plinkoRiskLevel: PlinkoRiskLevel; // Plinko only
  numberOfMines: number; // Mines only
  wheelRiskLevel: WheelRiskLevel; // Wheel only
  wheelSegmentCount: WheelSegments; // Wheel only
  originalActions?: GetBetInfoQuery['bet']['shuffleOriginalActions'];
}

export const initialProvablyFairData: ProvablyFairProps = {
  serverSeed: '',
  hashedServerSeed: '',
  clientSeed: '',
  gameSlug: '',
  nonce: '',
  verifyGameType: OriginalGame.DICE,
  numberOfMines: 3, // Default values
  plinkoRowCount: '8',
  plinkoRiskLevel: PlinkoRiskLevel.MEDIUM_RISK,
  wheelRiskLevel: WheelRiskLevel.MEDIUM,
  wheelSegmentCount: WheelSegments.TWENTY,
};
export const LIVE_GAME_STATS_ID = 'live-game-stats';
export const MODAL_ID = 'modal';
