import RoundButton from 'components/Buttons/RoundButton';
import usePreference from 'hooks/usePreference';
import { RightSidebarVariant } from 'redux/slices/browserPreferenceSlice';
import { RenderMenuItem } from './RenderMenuItem';
import styles from './IconMenu.module.scss';
const IconMenu = () => {
  const {
    rightSidebarVariant
  } = usePreference();
  const shouldShowRightSidebar = rightSidebarVariant !== RightSidebarVariant.NONE;
  return <>
      <div className={`${styles.menuWrapper} ${shouldShowRightSidebar ? styles.showMenuWrapper : ''}`}>
        <RenderMenuItem rightSidebarVariant={rightSidebarVariant} />
      </div>

      <div className={`${styles.menu} ${shouldShowRightSidebar ? styles.showMenu : ''}`}>
        <RoundButton color="tertiary" icon={<img src="/icons/three-dots.svg" alt="dots" />} />

        <div className={styles.expandMenuWrapper}>
          <div className={styles.expandMenuWrapperInner}>
            <RenderMenuItem isMenuExpanded rightSidebarVariant={rightSidebarVariant} />
          </div>
        </div>

        <img src="/icons/chevron.svg" alt="arrow" className={styles.arrowIcon} />
      </div>
    </>;
};
export default IconMenu;