import React from 'react';
import styles from './NavList.module.scss';
type Props = {
  isExpanded: boolean;
  children: React.ReactNode;
};
export const NavList = ({
  isExpanded,
  children
}: Props) => {
  return <div className={`${styles.root} ${isExpanded ? styles.isExpanded : ''}`}>{children}</div>;
};