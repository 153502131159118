import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRealTime } from '../components/InfoBanner/VersionBanner';
import { closeGlobalModal } from '../components/Modal/closeGlobalModal';
import { AppSettings } from '../generated/graphql';
import { setsSportUnderMaintenance } from '../redux/slices/globalStateSlice';
import { useSportRouteCheck } from './useSportRouteCheck';

export const MAINTENANCE_PAGE = '/?modal=sportMaintenance';

type Props = {
  sportsMaintenance: AppSettings['sportsMaintenance'];
};

const CHECK_DURATION = 30_000;
const MAINTENANCE_URL = ['/transactions/sports-bets'];

export function useSportsMaintenance({ sportsMaintenance }: Props) {
  const router = useRouter();
  const { asPath, replace } = router;
  const routerRef = useRef(router);
  const timerRef = useRef(0);

  const dispatch = useDispatch();
  const isSport = useSportRouteCheck();
  const isSportPage = isSport.isSportPage || MAINTENANCE_URL.some(path => asPath.startsWith(path));
  const sportsBetMaintenanceShow = useSelector(
    (state: AppState) => state.globalState.sportsBetMaintenanceShow,
  );
  const hasMaintenanceModal = asPath.includes(MAINTENANCE_PAGE);

  const removeMaintenance = useCallback(() => {
    if (asPath.includes(MAINTENANCE_PAGE)) {
      closeGlobalModal({
        router: routerRef.current,
      });
    }

    if (sportsBetMaintenanceShow) {
      dispatch(setsSportUnderMaintenance(false));
    }

    clearInterval(timerRef.current);
  }, [dispatch, sportsBetMaintenanceShow, asPath]);

  useEffect(() => {
    if (!sportsMaintenance.maintenanceEndDateTimeUTC) {
      return;
    }

    function checkSportsMaintenanceInterval() {
      const now = getRealTime();

      if (
        now.diff(sportsMaintenance.maintenanceStartDateTimeUTC) > 0 &&
        dayjs(sportsMaintenance.maintenanceEndDateTimeUTC).diff(now) > 0
      ) {
        if (!sportsBetMaintenanceShow) {
          dispatch(setsSportUnderMaintenance(true));
        }

        if (!hasMaintenanceModal && isSportPage) {
          replace(MAINTENANCE_PAGE).catch();
        }
      } else {
        removeMaintenance();
      }
    }

    checkSportsMaintenanceInterval();

    timerRef.current = window.setInterval(checkSportsMaintenanceInterval, CHECK_DURATION);

    return () => clearInterval(timerRef.current);
  }, [
    sportsMaintenance,
    sportsBetMaintenanceShow,
    replace,
    dispatch,
    removeMaintenance,
    isSportPage,
    hasMaintenanceModal,
  ]);

  useEffect(() => {
    const checkEndDateRemoved = () => {
      if (sportsBetMaintenanceShow && !sportsMaintenance.maintenanceEndDateTimeUTC) {
        removeMaintenance();
      }
    };

    checkEndDateRemoved();
  }, [sportsMaintenance, sportsBetMaintenanceShow, removeMaintenance]);
}
