import { AuthModalTabType, AuthModalType, WalletModalTabType } from 'constants/modal';

export enum ModalUrl {
  SPORT_BET = 'sportBet',
  BET = 'bet',
  WALLET = 'wallet',
  AUTH = 'auth',
  USER = 'user',
  VAULT = 'vault',
  CHALLENGE_PRIZE = 'challengePrize',
  SEARCH = 'search',
  PROMO = 'c',
  REDEEM = 'redeem',
  RESET_PASSWORD = 'resetPassword',
  CRASH_GAME = 'crashGame',
  SPORT_MAINTENANCE = 'sportMaintenance',
}

export type ModalType = {
  type: ModalUrl;
  isOpen: boolean;
  id?: string;
  name?: string;
  code?: string;
  currency?: string;
  amount?: string;
  tab?: WalletModalTabType | AuthModalTabType | AuthModalType | 'vault';
  classNames?: Partial<{
    modalContent: string;
    modalBody: string;
  }>;
};

export enum QueryTypes {
  'md-tab' = 'md-tab',
  'md-id' = 'md-id',
  modal = 'modal',
  'md-name' = 'md-name',
  'md-currency' = 'md-currency',
  'md-amount' = 'md-amount',
  'md-code' = 'md-code',
  resetToken = 'resetToken',
}

export type ModalOnClose = () => void;
