import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import Alert from './Alert';
import styles from './Alerts.module.scss';
const Alerts = () => {
  const {
    alerts
  } = useSelector((state: AppState) => state.alerts);
  return <div className={styles.root}>
      <AnimatePresence>
        {alerts.map(alert => {
        return <Alert message={alert.message} messages={alert.messages} type={alert.type} id={alert.id} key={alert.id} />;
      })}
      </AnimatePresence>
    </div>;
};
export default Alerts;