import colors from 'assets/styles/colors';
import { memo } from 'react';
import styles from './PieTimer.module.scss';
interface PieTimerProps {
  count: number;
  className?: string;
}
const MINUTES_IN_ONE_HOUR = 60;
const PieTimerComponent = ({
  count,
  className
}: PieTimerProps) => {
  const timeRemainingInOneHour = MINUTES_IN_ONE_HOUR - count;
  const degrees = timeRemainingInOneHour / MINUTES_IN_ONE_HOUR * 360;
  return <span className={`${styles.pieTimer} ${className ? className : ''}`} style={{
    background: `conic-gradient(${colors.gray500} ${degrees}deg, ${colors.success} 0)`
  }} />;
};
export const PieTimer = memo(PieTimerComponent);