import { Language } from 'generated/graphql';
import { useCallback, useEffect, useState } from 'react';

interface LocaleData {
  locale: string;
  countries: Record<string, string | string[]>;
}

const lazyImports: Record<Language, () => Promise<LocaleData>> = {
  [Language.EN]: () => import('i18n-iso-countries/langs/en.json'),
  [Language.ES]: () => import('i18n-iso-countries/langs/es.json'),
  [Language.FR]: () => import('i18n-iso-countries/langs/fr.json'),
  [Language.JA]: () => import('i18n-iso-countries/langs/ja.json'),
  [Language.KO]: () => import('i18n-iso-countries/langs/ko.json'),
  [Language.PT]: () => import('i18n-iso-countries/langs/pt.json'),
  [Language.ZH]: () => import('i18n-iso-countries/langs/zh.json'),
  [Language.DE]: () => import('i18n-iso-countries/langs/de.json'),
  [Language.HU]: () => import('i18n-iso-countries/langs/hu.json'),
  [Language.TR]: () => import('i18n-iso-countries/langs/tr.json'),
  [Language.RU]: () => import('i18n-iso-countries/langs/ru.json'),
};

export function useCountryLocale(language: Language) {
  const [countryI18n, setCountryI18n] = useState<LocaleData | null>(null);

  useEffect(() => {
    lazyImports[language]().then((data: LocaleData) => {
      setCountryI18n(data);
    });
  }, [language]);

  return {
    t: useCallback(
      (key: string) => {
        if (!countryI18n) {
          return key;
        }

        const data = countryI18n.countries[key];

        if (!data) return key; // return key if no data

        return Array.isArray(data) ? data[0] : data;
      },
      [countryI18n],
    ),
  };
}
