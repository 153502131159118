import clsx from 'clsx';
import Container from 'components/Container/Container';
import { BE_GAMBLE_AWARE_URL } from 'constants/social';
import usePreference from 'hooks/usePreference';
import { usePrice } from 'hooks/usePrice';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { memo, useState } from 'react';
import { useMount } from 'react-use';
import AntillephoneLicense from './License/AntillephoneLicense';
import XCMLicense from './License/XCMLicense';
import { COMMUNITY, MenuItemType, PLATFORM, POLICIES, SUPPORT } from './menu';
const LanguageAndOddSelectors = dynamic(() => import('./LanguageAndOddSelectors').then(({
  LanguageAndOddSelectors
}) => LanguageAndOddSelectors));
import { RenderClient } from 'components/RenderClient';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ModalUrl } from 'components/Modal/GlobalModal.type';
import { useDispatch } from 'react-redux';
import { updateSportsDevtool } from '../../redux/slices/globalStateSlice';
import { openGlobalModal } from '../Modal/openGlobalModal';
import styles from './Footer.module.scss';
const MenuItemLink = ({
  external,
  url,
  label,
  className = ''
}: MenuItemType) => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  if (!url) {
    return label === 'footer.redeemCode.text' ? <li className={styles.menuItem} onClick={() => {
      openGlobalModal({
        router,
        type: ModalUrl.REDEEM
      });
    }}>
        {t(label)}
      </li> : <li className={clsx(className, styles.menuItem)}>{t(label)}</li>;
  }
  return <li className={clsx(className, styles.menuItem)}>
      {external ? <a target="_blank" href={url} rel="noopener noreferrer">
          {t(label)}
        </a> : <Link href={url} passHref legacyBehavior>
          <a>{t(label)}</a>
        </Link>}
    </li>;
};
const Column = memo(({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={clsx(styles.column, className)}>{children}</div>;
});
const Footer = ({
  className
}: {
  className?: string;
}) => {
  const {
    t
  } = useTranslation();
  const {
    cryptoPreference,
    fiatPreference
  } = usePreference();
  const priceOfCryptoPreference = usePrice(cryptoPreference);
  const [xcmScriptLoaded, setXcmScriptLoaded] = useState(false);
  const [antillephoneScriptLoaded, setAntillephoneScriptLoaded] = useState(false);
  const dispatch = useDispatch();
  useMount(() => {
    const intervalApg = setInterval(() => {
      if (window.apg_135e6de2_7372_4c94_8491_cf709e7f9b92) {
        setAntillephoneScriptLoaded(true);
        clearTimeout(intervalApg);
      }
    }, 50);
    const intervalXcm = setInterval(() => {
      if (window.xcm_69fb7e94_6ecd_4b46_83a2_24bac03464d0) {
        setXcmScriptLoaded(true);
        clearTimeout(intervalXcm);
      }
    }, 50);
  });
  return <footer className={`${styles.footerWrapper} ${className || ''}`} data-testid="footer">
      <Container className={styles.container}>
        <div className={`${styles.row}`}>
          <Column className={clsx('mobile-top', styles.columnOrder0)}>
            <div className={styles.brandingWrapper}>
              <Link title="Shuffle casino" href="/">
                <div className={styles.icon}>
                  <img src="/icons/logo.svg" alt="logo" />
                </div>
              </Link>
            </div>
          </Column>
          <Column className={styles.columnOrder2}>
            <div className={styles.menuHeading}>{t('footer.header.support')}</div>
            <ul className={styles.menuList}>
              {SUPPORT.map((item: MenuItemType) => <MenuItemLink key={item.label} {...item} />)}
            </ul>
          </Column>
          <Column className={styles.columnOrder3}>
            <div className={styles.menuHeading}>{t('footer.header.platform')}</div>
            <ul className={styles.menuList}>
              {PLATFORM.map((item: MenuItemType) => item.active && <MenuItemLink key={item.label} {...item} />)}
            </ul>
          </Column>
          <Column className={styles.columnOrder4}>
            <div className={styles.menuHeading}>{t('footer.header.policy')}</div>
            <ul className={styles.menuList}>
              {POLICIES.map((item: MenuItemType) => <MenuItemLink key={item.label} {...item} />)}
            </ul>
          </Column>
          <Column className={styles.columnOrder5}>
            <div className={styles.menuHeading}>{t('footer.header.community')}</div>
            <ul className={styles.menuList}>
              {COMMUNITY.map((item: MenuItemType) => <MenuItemLink key={item.label} {...item} />)}
            </ul>
          </Column>
          <Column className={styles.columnOrder1}>
            <RenderClient>
              <LanguageAndOddSelectors display="mobileAboveOnly" />
            </RenderClient>
          </Column>
        </div>

        <RenderClient>
          <LanguageAndOddSelectors display="mobileOnly" />
        </RenderClient>

        <div className={styles.description}>{t('footerDescription')}</div>

        <div className={styles.footerBottom}>
          <span onDoubleClick={() => {
          dispatch(updateSportsDevtool({
            enabled: true
          }));
        }}>
            {`1 ${cryptoPreference} = `}
            {t('intlCurrencyWithOptions', {
            val: priceOfCryptoPreference,
            currency: fiatPreference
          })}
          </span>

          <div className={styles.bottomRight}>
            <span className={styles.copyrightText}>
              {t('footerCopyright', {
              year: new Date().getFullYear()
            })}
            </span>
            <div className={styles.bottomRightIcons}>
              {antillephoneScriptLoaded && <AntillephoneLicense />}
              {xcmScriptLoaded && <XCMLicense />}
              <a className={styles.iconLink} target="_blank" href={BE_GAMBLE_AWARE_URL} rel="noopener noreferrer">
                <img src="/icons/eighteen-plus.svg" loading="lazy" alt="18 plus" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>;
};
export default React.memo(Footer);