import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Currency, SportsBetStatus, SportsMarketSelectionStatus, SportsMarketStatus } from '../../../../generated/graphql';
import SportIcon from '../../../SportsHome/components/SportIcon';
import { BetSlipProps, SportBetUISpecificStatus } from '../BetsGroup.type';
import { BetTitleHeaderErrors } from './BetTitleHeaderErrors';
import { BetTitleHeaderWarnings } from './BetTitleHeaderWarnings';
import { getErrorText } from './betSlipErrors';
import { getBetViewConfig } from './getBetViewConfig';
import { Tooltip } from '../../../../components/Tooltip';
import { BetSlipItem } from '../../../../redux/slices/sportsBetSlice.type';
import { BetSlipBadgeStatus } from './BetSlipBadgeStatus';
import styles from './BetTitleHeader.module.scss';
type Props = {
  hasMultiSelections?: boolean;
  bet: BetSlipItem;
  showLiveBadge?: boolean;
  isPayoutMoreThanStakeAmount: boolean;
  betStatus?: SportsBetStatus;
  cashOutInfo: {
    amount: string;
    currency: Currency;
    odds: string;
  };
  betIds: string[];
} & Pick<BetSlipProps, 'actions' | 'betIds' | 'betSlipStatus' | 'warnings' | 'errors' | 'sportsBetStatus' | 'showOddsWarningMessage'>;
export function BetTitleHeader({
  cashOutInfo,
  betSlipStatus,
  bet,
  betIds,
  hasMultiSelections,
  sportsBetStatus,
  errors,
  warnings,
  showOddsWarningMessage = false,
  showLiveBadge = false,
  isPayoutMoreThanStakeAmount,
  betStatus,
  actions
}: Props) {
  const {
    t
  } = useTranslation();
  let updatedStatus = hasMultiSelections ? sportsBetStatus ?? bet?.sportsBetStatus : sportsBetStatus;
  if (updatedStatus === SportsBetStatus.WON) {
    updatedStatus = SportBetUISpecificStatus.WIN;
  }
  if (updatedStatus === SportsBetStatus.LOST) {
    updatedStatus = SportBetUISpecificStatus.LOSS;
  }
  const {
    shouldHideStatusBadgeAndShowOdds,
    isPartialPending,
    isInBetInfoView,
    isInBetAddView,
    isInBetAddOrConfirmed,
    isDeadHeat,
    isPartial,
    isCashedOut,
    isLoss,
    isLost,
    isCanceled,
    isPushed,
    isVoid,
    isInBetPendingView,
    isInBetResultView,
    isInBetPlacedView
  } = getBetViewConfig({
    betSlipStatus,
    sportsBetStatus: updatedStatus
  });
  if (!updatedStatus && isCashedOut && isInBetInfoView) {
    updatedStatus = bet.sportsBetStatus;
  }
  const showError = errors?.isInsufficientFunds || errors?.isMinStakeNotMet;
  const errorText = errors ? getErrorText(errors) : '';
  const hasHeader = isInBetAddView && (showOddsWarningMessage || warnings?.exceedMaxAllowedStakeAmount || showError) || isInBetPlacedView && (errors?.placedBetError && !warnings?.exceedMaxAllowedStakeAmount || isPartialPending || warnings?.exceedMaxAllowedStakeAmount);
  const hasMarketNotOpenInBetAdd = isInBetAddOrConfirmed && (bet.marketStatus !== SportsMarketStatus.OPEN || bet.status !== SportsMarketSelectionStatus.TRADING);
  const isPartialAndPayoutLessThanStake = isPartial && !isPayoutMoreThanStakeAmount;
  const isDeadHeatAndPayoutLessThanStake = isDeadHeat && !isPayoutMoreThanStakeAmount;
  return <>
      <BetTitleHeaderErrors isInBetAddView={isInBetAddView} placedBetError={errors?.placedBetError} betSlipStatus={betSlipStatus} exceedMaxAllowedStakeAmount={warnings?.exceedMaxAllowedStakeAmount} errorText={errorText} showError={!!showError} />

      {!showError && hasHeader && <BetTitleHeaderWarnings isInBetAddView={isInBetAddView} isInBetPlacedView={isInBetPlacedView} isPartialPending={isPartialPending} showOddsWarningMessage={showOddsWarningMessage} exceedMaxAllowedStakeAmount={warnings?.exceedMaxAllowedStakeAmount} />}

      <div className={styles.container}>
        <div className={clsx(styles.betTicketHeader, {
        [String(styles.betTicketHeaderNoBorderRadius)]: !hasMarketNotOpenInBetAdd && hasHeader
      })}>
          {hasMultiSelections ? <>
              <img className={styles.sportsBetSlip} src="/icons/sports-bet-slip.svg" alt="sport bet" />
              <div className={styles.betTitleSection}>
                {betIds.length} {t('standardMulti')}
              </div>
            </> : <Link href={bet?.href || ''} className={styles.betTicketGameNameWrapper} onClick={actions?.onClickHeader} title={bet?.fixtureName}>
              <SportIcon sport={bet?.sport} />
              <span className={styles.betTicketGameName}>{bet?.fixtureName}</span>
            </Link>}

          <div className={styles.actionsSection}>
            {(isInBetPendingView || isInBetResultView || isInBetPlacedView || isInBetInfoView) && actions?.onClickViewDetail && !errors?.placedBetError && <Tooltip placement="left" content={t('btnViewDetails')}>
                  <button className={styles.viewDetailBtn} data-testid="bet-brand-footer-view" onClick={actions.onClickViewDetail}>
                    <img src="/icons/information.svg" width={16} height={16} loading="lazy" alt="information" />
                  </button>
                </Tooltip>}

            {!hasMultiSelections && (showLiveBadge || actions?.onRemove && isInBetAddView) && <div className={styles.liveBadgeAndRemoveBlock}>
                {showLiveBadge && <span className={styles.liveBadge}>{t('txtLive')}</span>}
                {actions?.onRemove && isInBetAddView && <button className={styles.removeButton} onClick={actions?.onRemove}>
                    <img src="/icons/bet-slip-cross.svg" alt="close" />
                  </button>}
              </div>}

            {(!isInBetAddOrConfirmed && shouldHideStatusBadgeAndShowOdds && updatedStatus || betStatus) && <BetSlipBadgeStatus isLoss={isLoss} isLost={isLost} updatedStatus={updatedStatus} isPartialAndPayoutLessThanStake={isPartialAndPayoutLessThanStake} isDeadHeatAndPayoutLessThanStake={isDeadHeatAndPayoutLessThanStake} isCashedOut={isCashedOut || !!(cashOutInfo.amount && cashOutInfo.odds)} betStatus={betStatus} isCanceled={isCanceled} isPushed={isPushed} isVoid={isVoid} />}
          </div>
        </div>

        <div className={styles.divider} />
      </div>
    </>;
}