import { Sports } from 'generated/graphql';

// show possession icon in live match state if true
export const PossessionSportsType: Sports[] = [
  Sports.BASKETBALL,
  Sports.ICE_HOCKEY,
  Sports.SOCCER,
  Sports.TENNIS,
  Sports.VOLLEYBALL,
  Sports.CRICKET,
  Sports.HANDBALL,
  Sports.FUTSAL,
  Sports.BADMINTON,
  Sports.CS2,
  Sports.VALORANT,
  Sports.ECRICKET,
];

export const Esports = [
  Sports.CS2,
  Sports.CS2_DUELS,
  Sports.EFOOTBALL,
  Sports.EBASKETBALL,
  Sports.LOL,
  Sports.DOTA2,
  Sports.OVERWATCH,
  Sports.KOG,
  Sports.SC2,
  Sports.ROCKETLEAGUE,
  Sports.VALORANT,
  Sports.SC1,
  Sports.COD,
  Sports.R6,
  Sports.W3,
  Sports.HALO,
  Sports.WR,
  Sports.AOV,
  Sports.AOE,
  Sports.ML,
  Sports.ECRICKET,
];

// config new sport here, icon, menus, market mapping etc
export type SportsConfig = {
  id: Sports;
  name: string;
  href: string;
  iconUrl: string;
};

export const SPORTS_CONFIG_OBJECT: Partial<Record<Sports, SportsConfig>> = {
  [Sports.AMERICAN_FOOTBALL]: {
    id: Sports.AMERICAN_FOOTBALL,
    name: Sports.AMERICAN_FOOTBALL,
    href: '/sports/american-football',
    iconUrl: '/icons/sports/american-football.svg',
  },
  [Sports.BASKETBALL]: {
    id: Sports.BASKETBALL,
    name: Sports.BASKETBALL,
    href: '/sports/basketball',
    iconUrl: '/icons/sports/basketball.svg',
  },
  [Sports.BASEBALL]: {
    id: Sports.BASEBALL,
    name: Sports.BASEBALL,
    href: '/sports/baseball',
    iconUrl: '/icons/sports/baseball.svg',
  },
  [Sports.ICE_HOCKEY]: {
    id: Sports.ICE_HOCKEY,
    name: Sports.ICE_HOCKEY,
    href: '/sports/ice-hockey',
    iconUrl: '/icons/sports/ice-hockey.svg',
  },
  [Sports.SOCCER]: {
    id: Sports.SOCCER,
    name: Sports.SOCCER,
    href: '/sports/soccer',
    iconUrl: '/icons/sports/soccer.svg',
  },
  [Sports.TENNIS]: {
    id: Sports.TENNIS,
    name: Sports.TENNIS,
    href: '/sports/tennis',
    iconUrl: '/icons/sports/tennis.svg',
  },
  [Sports.MMA]: {
    id: Sports.MMA,
    name: Sports.MMA,
    href: '/sports/mma',
    iconUrl: '/icons/sports/mma.svg',
  },
  [Sports.BOXING]: {
    id: Sports.BOXING,
    name: Sports.BOXING,
    href: '/sports/boxing',
    iconUrl: '/icons/sports/boxing.svg',
  },
  [Sports.TABLE_TENNIS]: {
    id: Sports.TABLE_TENNIS,
    name: Sports.TABLE_TENNIS,
    href: '/sports/table-tennis',
    iconUrl: '/icons/sports/table-tennis.svg',
  },
  [Sports.GOLF]: {
    id: Sports.GOLF,
    name: Sports.GOLF,
    href: '/sports/golf',
    iconUrl: '/icons/sports/golf.svg',
  },
  [Sports.RUGBY_UNION]: {
    id: Sports.RUGBY_UNION,
    name: Sports.RUGBY_UNION,
    href: '/sports/rugby-union',
    iconUrl: '/icons/sports/rugby-union.svg',
  },
  [Sports.VOLLEYBALL]: {
    id: Sports.VOLLEYBALL,
    name: Sports.VOLLEYBALL,
    href: '/sports/volleyball',
    iconUrl: '/icons/sports/volleyball.svg',
  },
  [Sports.CRICKET]: {
    id: Sports.CRICKET,
    name: Sports.CRICKET,
    href: '/sports/cricket',
    iconUrl: '/icons/sports/cricket.svg',
  },
  [Sports.MOTOR_SPORT]: {
    id: Sports.MOTOR_SPORT,
    name: Sports.MOTOR_SPORT,
    href: '/sports/motor-sport',
    iconUrl: '/icons/sports/motor-sport.svg',
  },
  [Sports.NOVELTIES]: {
    id: Sports.NOVELTIES,
    name: Sports.NOVELTIES,
    href: '/sports/novelties',
    iconUrl: '/icons/sports/novelties.svg',
  },
  [Sports.DARTS]: {
    id: Sports.DARTS,
    name: Sports.DARTS,
    href: '/sports/darts',
    iconUrl: '/icons/sports/darts.svg',
  },
  [Sports.SQUASH]: {
    id: Sports.SQUASH,
    name: Sports.SQUASH,
    href: '/sports/squash',
    iconUrl: '/icons/sports/squash.svg',
  },
  [Sports.HANDBALL]: {
    id: Sports.HANDBALL,
    name: Sports.HANDBALL,
    href: '/sports/handball',
    iconUrl: '/icons/sports/handball.svg',
  },
  [Sports.FUTSAL]: {
    id: Sports.FUTSAL,
    name: Sports.FUTSAL,
    href: '/sports/futsal',
    iconUrl: '/icons/sports/futsal.svg',
  },
  [Sports.BADMINTON]: {
    id: Sports.BADMINTON,
    name: Sports.BADMINTON,
    href: '/sports/badminton',
    iconUrl: '/icons/sports/badminton.svg',
  },
  [Sports.CS2]: {
    id: Sports.CS2,
    name: Sports.CS2,
    href: '/sports/cs2',
    iconUrl: '/icons/sports/cs2.svg',
  },
  [Sports.CS2_DUELS]: {
    id: Sports.CS2_DUELS,
    name: Sports.CS2_DUELS,
    href: '/sports/cs2-duels',
    iconUrl: '/icons/sports/cs2-duels.svg',
  },
  [Sports.EFOOTBALL]: {
    id: Sports.EFOOTBALL,
    name: Sports.EFOOTBALL,
    href: '/sports/efootball',
    iconUrl: '/icons/sports/fifa.svg',
  },
  [Sports.EBASKETBALL]: {
    id: Sports.EBASKETBALL,
    name: Sports.EBASKETBALL,
    href: '/sports/ebasketball',
    iconUrl: '/icons/sports/nba2k.svg',
  },
  [Sports.LOL]: {
    id: Sports.LOL,
    name: Sports.LOL,
    href: '/sports/lol',
    iconUrl: '/icons/sports/lol.svg',
  },
  [Sports.DOTA2]: {
    id: Sports.DOTA2,
    name: Sports.DOTA2,
    href: '/sports/dota2',
    iconUrl: '/icons/sports/dota2.svg',
  },
  [Sports.OVERWATCH]: {
    id: Sports.OVERWATCH,
    name: Sports.OVERWATCH,
    href: '/sports/overwatch',
    iconUrl: '/icons/sports/overwatch.svg',
  },
  [Sports.KOG]: {
    id: Sports.KOG,
    name: Sports.KOG,
    href: '/sports/kog',
    iconUrl: '/icons/sports/kog.svg',
  },
  [Sports.SC2]: {
    id: Sports.SC2,
    name: Sports.SC2,
    href: '/sports/sc2',
    iconUrl: '/icons/sports/sc2.svg',
  },
  [Sports.ROCKETLEAGUE]: {
    id: Sports.ROCKETLEAGUE,
    name: Sports.ROCKETLEAGUE,
    href: '/sports/rocketleague',
    iconUrl: '/icons/sports/rocketleague.svg',
  },
  [Sports.VALORANT]: {
    id: Sports.VALORANT,
    name: Sports.VALORANT,
    href: '/sports/valorant',
    iconUrl: '/icons/sports/valorant.svg',
  },
  [Sports.SC1]: {
    id: Sports.SC1,
    name: Sports.SC1,
    href: '/sports/sc1',
    iconUrl: '/icons/sports/sc1.svg',
  },
  [Sports.COD]: {
    id: Sports.COD,
    name: Sports.COD,
    href: '/sports/cod',
    iconUrl: '/icons/sports/cod.svg',
  },
  [Sports.R6]: {
    id: Sports.R6,
    name: Sports.R6,
    href: '/sports/r6',
    iconUrl: '/icons/sports/rainbowsix.svg',
  },
  [Sports.W3]: {
    id: Sports.W3,
    name: Sports.W3,
    href: '/sports/w3',
    iconUrl: '/icons/sports/w3.svg',
  },
  [Sports.HALO]: {
    id: Sports.HALO,
    name: Sports.HALO,
    href: '/sports/halo',
    iconUrl: '/icons/sports/halo.svg',
  },
  [Sports.WR]: {
    id: Sports.WR,
    name: Sports.WR,
    href: '/sports/wr',
    iconUrl: '/icons/sports/wr.svg',
  },
  [Sports.AOV]: {
    id: Sports.AOV,
    name: Sports.AOV,
    href: '/sports/aov',
    iconUrl: '/icons/sports/aov.svg',
  },
  [Sports.AOE]: {
    id: Sports.AOE,
    name: Sports.AOE,
    href: '/sports/aoe',
    iconUrl: '/icons/sports/aoe.svg',
  },
  [Sports.ML]: {
    id: Sports.ML,
    name: Sports.ML,
    href: '/sports/ml',
    iconUrl: '/icons/sports/ml.svg',
  },
  [Sports.ECRICKET]: {
    id: Sports.ECRICKET,
    name: Sports.ECRICKET,
    href: '/sports/ecricket',
    iconUrl: '/icons/sports/ecricket.svg',
  },
};

export const SPORTS_CONFIG: SportsConfig[] = Object.values(SPORTS_CONFIG_OBJECT);
