import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { MobileMenuName, NavMenuType, setActiveMobileNav, setActiveNavMenuType } from '../../redux/slices/globalStateSlice';
import { isMobile } from '../../utils/userAgent';
import { openGlobalModal } from '../Modal/openGlobalModal';
import { ModalUrl } from 'components/Modal/GlobalModal.type';
import { SPORT_PAGE_TABS } from '../../constants/sports';
import styles from './NavigationTab.module.scss';
type Props = {
  active: string;
  isExpanded: boolean;
  className?: string;
};
export function NavigationTabs({
  active,
  isExpanded,
  className = ''
}: Props) {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const sportsBetMaintenance = useSelector((state: AppState) => state.globalState.sportsBetMaintenanceShow);
  const tabs = [{
    value: NavMenuType.CASINO,
    href: '/',
    label: 'tabCasino',
    icon: 'dice'
  }, {
    value: NavMenuType.SPORTS,
    href: `/sports?section=${SPORT_PAGE_TABS.FEATURES}`,
    label: 'tabSports',
    icon: '/sports/tennis',
    disabled: sportsBetMaintenance
  }];
  const onSwitchTab = (id: string) => {
    dispatch(setActiveNavMenuType((id as NavMenuType)));
  };
  const openModal = () => {
    openGlobalModal({
      type: ModalUrl.SPORT_MAINTENANCE,
      router
    });
  };
  if (!isExpanded) {
    return <div className={`${styles.mobileContainer} ${active ? '' : styles.noSelect} ${className}`}>
        {tabs.map(({
        icon,
        disabled,
        label,
        value,
        href
      }) => {
        if (disabled) {
          return <a key={value} onClick={openModal} className={active === value ? styles.active : ''} href="#">
                <img src={`/icons/${icon}.svg`} alt={t(label)} />
              </a>;
        }
        return <Link key={value} href={href} passHref legacyBehavior>
              <a onClick={() => {
            onSwitchTab(value);
          }} className={active === value ? styles.active : ''}>
                <img src={`/icons/${icon}.svg`} alt={t(label)} />
              </a>
            </Link>;
      })}
      </div>;
  }
  return <div className={`${styles.root} ${className}`}>
      <div className={`${styles.container} ${active ? '' : styles.noSelect}`}>
        {tabs.map(({
        label,
        disabled,
        value,
        href
      }) => {
        if (disabled) {
          return <a key={value} onClick={openModal} className={active === value ? styles.active : ''} href="#">
                <span>{t(label)}</span>
              </a>;
        }
        return <Link key={value} href={href} onClick={() => {
          onSwitchTab(value);
          if (isMobile) {
            dispatch(setActiveMobileNav(value === NavMenuType.SPORTS ? MobileMenuName.CASINO : MobileMenuName.SPORTS));
          }
        }} className={active === value ? styles.active : ''}>
              <span>{t(label)}</span>
            </Link>;
      })}
      </div>
    </div>;
}