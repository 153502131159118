import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetGlobalDataQuery } from '../../generated/graphql';

export const DEFAULT_SETTING = { maxBetUSD: 0, maxPayoutUSD: 0 };

export const appSettingInitialState = {
  details: {
    id: '',
    dice: DEFAULT_SETTING,
    hilo: DEFAULT_SETTING,
    limbo: DEFAULT_SETTING,
    mines: DEFAULT_SETTING,
    crash: DEFAULT_SETTING,
    keno: DEFAULT_SETTING,
    plinko: DEFAULT_SETTING,
    blackjack: DEFAULT_SETTING,
    roulette: DEFAULT_SETTING,
    wheel: DEFAULT_SETTING,
    tower: DEFAULT_SETTING,
    rain: {
      minTipUSD: 0,
    },
    kycAge: [],
    geoRestrictedRegions: [],
    __typename: 'AppSettings',
    sportsBet: {
      __typename: 'SportsBetSetting',
      minBetUSD: 0,
      minPartialBetRatio: 0,
    },
    sportsMaintenance: {
      maintenanceStartDateTimeUTC: null,
      maintenanceEndDateTimeUTC: null,
      warningMaintenanceStartDateTimeUTC: null,
    },
  } as GetGlobalDataQuery['appSettings'],
};

const appSettingSlice = createSlice({
  name: 'appSetting',
  initialState: appSettingInitialState,
  reducers: {
    updateAppSetting: (
      state,
      action: PayloadAction<{
        details: Partial<typeof appSettingInitialState.details>;
      }>,
    ) => {
      state.details = {
        ...state.details,
        ...action.payload.details,
      };
    },
  },
});

export const { updateAppSetting } = appSettingSlice.actions;

export default appSettingSlice.reducer;
