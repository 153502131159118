import { useCallback } from 'react';

import { getSportBetOdds } from '../utils/getSportBetOdds';

import usePreference from './usePreference';

type Props = Omit<Parameters<typeof getSportBetOdds>[0], 'oddsFormat'>;
export function useFormattedOdds(props?: Props) {
  const { oddsFormat } = usePreference();

  return {
    getFormattedOdds: useCallback(
      (props: Props) =>
        getSportBetOdds({
          ...props,
          oddsFormat,
        }),
      [oddsFormat],
    ),
    odds: getSportBetOdds({
      ...props,
      oddsFormat,
    }),
  };
}
