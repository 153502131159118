import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import versionFile from '../../../public/version.json';
import { localVsServerTimeValidation } from '../../utils/localVsServerTimeValidation';
import { BottomBanner } from './BottomBanner';
const [currentMajor, currentMinor, currentPatch] = versionFile.version.split('.');
const POLLING_INTERVAL = 15000;
export const {
  setServerDateTime,
  getServerClientTimeDiff,
  getIsValidClientDateTime,
  getRealTime
} = localVsServerTimeValidation();
const VersionBanner = () => {
  const {
    t
  } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);
  const router = useRouter();
  const handleRefresh = useCallback(() => {
    // refresh page
    router.reload();
  }, [router]);
  const pollAPI = useCallback(async () => {
    const response = await fetch('/version.json');
    const serverDate = response.headers.get('date');
    setServerDateTime(serverDate);
    const {
      version: latestVersion
    } = await response.json();
    const [latestMajor, latestMinor, latestPatch] = latestVersion.split('.');

    // force a refresh if majoris different
    const shouldRefresh = currentMajor !== latestMajor;
    // show banner if major version is the same but patch or minor versions are different
    const shouldShowBanner = !shouldRefresh && (currentPatch !== latestPatch || currentMinor !== latestMinor);
    if (shouldRefresh) {
      router.reload();
    }
    if (shouldShowBanner) {
      setShowBanner(true);
    }
  }, [router]);
  useEffect(() => {
    pollAPI();
    const interval = setInterval(pollAPI, POLLING_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [pollAPI]);
  if (!showBanner) return null;
  return <BottomBanner icon="⚙️" title={t('txtRefreshUpdate')} onClick={handleRefresh} buttonTitle={t('btnRefresh')} />;
};
export default VersionBanner;