import React from 'react';
import styles from './NavigationLinkWrapper.module.css';
type Props = {
  children?: React.ReactNode;
  className?: string;
};
export function NavigationLinkWrapper({
  children,
  className
}: Props) {
  return <div className={`${styles.root} ${className || ''}`}>{children}</div>;
}