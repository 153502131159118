import { OddsFormat } from 'redux/slices/browserPreferenceSlice';

import { getSportBetOdds } from './getSportBetOdds';

export function checkInvalidOddsDisplay(params: {
  oddsNumerator: string;
  oddsDenominator: string;
}) {
  const { oddsNumerator, oddsDenominator } = params;
  const { outputDecimal } = getSportBetOdds({
    oddsNumerator,
    oddsDenominator,
    oddsFormat: OddsFormat.Decimal,
  });

  return outputDecimal.isEqualTo(1);
}
