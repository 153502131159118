import BigNumber from 'bignumber.js';
import Switch from 'components/Switch';
import CurrencyModal from 'components/modals/Currency/CurrencyModal';
import { CURRENCY_CONFIGS, selectableCurrencies } from 'constants/currency';
import { ModalTypes } from 'constants/modal';
import { motion } from 'framer-motion';
import usePreference from 'hooks/usePreference';
import { useBalances } from 'hooks/wallet/useBalances';
import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHideZeroBalances, toggleDisplayInFiat, toggleHideZeroBalances } from 'redux/slices/browserPreferenceSlice';
import { ANIMATE_UP_TO_BOTTOM } from '../../../constants/animator';
import { MODAL_NAME, Modal } from '../../Modal/Modal';
import BalanceItem from './BalanceItem';
import { BalanceTypeSearch } from './BalanceTypeSearch';
import colors from '../../../assets/styles/colors';
import styles from './BalancePopup.module.scss';
interface Props {
  closePopup: () => void;
  currentModal: ModalTypes | '';
  setCurrentModal: Dispatch<SetStateAction<ModalTypes | ''>>;
}
const BalancePopup = ({
  closePopup,
  currentModal,
  setCurrentModal
}: Props) => {
  const [filterCrypto, setFilterCrypto] = useState('');
  const {
    displayInFiat,
    fiatPreferenceSet,
    hideZeroBalance
  } = usePreference();
  const dispatch = useDispatch();
  const {
    t
  } = useTranslation();
  const currencyMapping = useBalances();
  const onToggle = (enabled: boolean) => {
    if (enabled && !fiatPreferenceSet) {
      setCurrentModal(ModalTypes.CURRENCY);
    }
    dispatch(toggleDisplayInFiat());
  };
  const hasCurrencyValue = !!selectableCurrencies.find(currency => BigNumber(currencyMapping[currency]).isGreaterThan(0));
  const filteredCurrencies = selectableCurrencies.filter(currency => {
    return !filterCrypto || currency.toLowerCase().startsWith(filterCrypto.toLowerCase()) || CURRENCY_CONFIGS[currency].name.toLowerCase().startsWith(filterCrypto.toLowerCase());
  }).filter(currency => {
    if (hideZeroBalance && BigNumber(currencyMapping[currency]).isLessThanOrEqualTo(0)) {
      return null;
    }
    return currency;
  });
  useEffect(() => {
    if (!hasCurrencyValue && hideZeroBalance) {
      dispatch(setHideZeroBalances({
        hideZeroBalance: false
      }));
    }
  }, [dispatch, hasCurrencyValue, hideZeroBalance]);
  return <>
      <Modal onClose={() => setCurrentModal('')} isOpen={currentModal === ModalTypes.CURRENCY} target={MODAL_NAME}>
        <CurrencyModal onClose={() => setCurrentModal('')} />
      </Modal>

      <div className={styles.root}>
        <motion.div initial={ANIMATE_UP_TO_BOTTOM.initial} animate={ANIMATE_UP_TO_BOTTOM.animate}>
          <div className={styles.box}>
            <BalanceTypeSearch setFilterCrypto={setFilterCrypto} filterCrypto={filterCrypto} />

            <div className={styles.container}>
              {filteredCurrencies.map(currency => <BalanceItem key={currency} closePopup={closePopup} currency={currency} value={currencyMapping[currency]} />)}
            </div>

            <div className={styles.option}>
              <Switch labelFloat="left" label={t('lblDisplayInFiat')} value={displayInFiat} onToggle={onToggle} backgroundColor={colors.gray900} testId="toggle-fiat" />
              {hasCurrencyValue && <Switch labelFloat="left" label={t('hideZeroBalances')} value={hideZeroBalance} backgroundColor={colors.gray900} onToggle={() => dispatch(toggleHideZeroBalances())} testId="hide-zero-balances" />}
            </div>
          </div>
        </motion.div>
      </div>
    </>;
};
export default BalancePopup;