import {
  SportsBetSelectionStatus,
  SportsMarketSelectionResultStatus,
  SportsMarketSelectionStatus,
  SportsMarketStatus,
} from 'generated/graphql';

import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice.type';
import {
  SportBetUISpecificStatus,
  SportsBetTicketStatus,
  SportsBetTicketStatusType,
} from '../BetsGroup.type';

type Props = Partial<{
  betSlipStatus: BetSlipStatus;
  sportsBetStatus: SportsBetTicketStatusType;
  sportsBetSelectionStatus: SportsBetSelectionStatus;
  marketSelectionStatus: SportsMarketSelectionStatus;
  cashOutStatus?: string;
  marketSelectionResultStatus?: SportsMarketSelectionResultStatus;
  isLive?: boolean;
  isPreMatch?: boolean;
  isBetPlacedError?: boolean;
}>;

export function getBetViewConfig({
  betSlipStatus,
  sportsBetStatus,
  sportsBetSelectionStatus,
  marketSelectionStatus,
  cashOutStatus,
  marketSelectionResultStatus,
  isPreMatch = false,
  isLive = false,
  isBetPlacedError = false,
}: Props) {
  const isInBetInfoView = betSlipStatus === BetSlipStatus.BET_VIEW_INFO;
  const isInBetConfirmedView = betSlipStatus === BetSlipStatus.CONFIRMING_BET;
  const isInEmptyView = betSlipStatus === BetSlipStatus.EMPTY;
  const isInBetPlacingView = betSlipStatus === BetSlipStatus.BET_PLACING;
  const isInBetAddView = betSlipStatus === BetSlipStatus.ADDING_BET;
  const isInBetPlacedView = betSlipStatus === BetSlipStatus.BET_PLACED;
  const isInBetPendingView = betSlipStatus === BetSlipStatus.PENDING_BET;
  const isInBetResultView = betSlipStatus === BetSlipStatus.RESULTED_BET;
  const isInBetPlacedOrConfirmed = isInBetConfirmedView || isInBetPlacingView || isInBetPlacedView;
  const isInBetAddOrConfirmed = isInBetAddView || isInBetConfirmedView || isInBetPlacingView;
  const isInBetPlacedViewError = isInBetPlacedView && isBetPlacedError;

  const isCanceled = sportsBetStatus === SportsBetTicketStatus.CANCELLED;
  const isDeadHeat = sportsBetStatus === SportsBetTicketStatus.DEAD_HEAT;
  const isCashedOut = sportsBetStatus === SportsBetTicketStatus.CASHED_OUT;
  const isPending = sportsBetStatus === SportsBetTicketStatus.PENDING;
  const isVoid =
    sportsBetStatus === SportBetUISpecificStatus.VOID ||
    sportsBetStatus === SportsBetTicketStatus.VOIDED ||
    sportsBetSelectionStatus === SportsBetSelectionStatus.VOIDED ||
    sportsBetSelectionStatus === SportsBetSelectionStatus.PROVIDER_VOIDED;
  const isPushed =
    sportsBetStatus === SportBetUISpecificStatus.PUSH ||
    sportsBetSelectionStatus === SportsBetSelectionStatus.PUSHED ||
    marketSelectionResultStatus === SportsMarketSelectionResultStatus.PUSHED;

  const isPartialPending = sportsBetStatus === SportsBetTicketStatus.PARTIAL_PENDING;
  const isPartial =
    sportsBetStatus === SportsBetTicketStatus.PARTIAL ||
    sportsBetSelectionStatus === SportsBetSelectionStatus.PARTIAL;
  const isWin =
    sportsBetStatus === SportBetUISpecificStatus.WIN ||
    sportsBetStatus === SportsBetTicketStatus.WON;
  const isLoss =
    sportsBetStatus === SportBetUISpecificStatus.LOSS ||
    sportsBetStatus === SportsBetTicketStatus.LOST;

  const isWon =
    sportsBetSelectionStatus === SportsBetSelectionStatus.WON ||
    marketSelectionResultStatus === SportsMarketSelectionResultStatus.WINNER;
  const isLost =
    sportsBetSelectionStatus === SportsBetSelectionStatus.LOST ||
    marketSelectionResultStatus === SportsMarketSelectionResultStatus.LOSER;
  const isTrading = marketSelectionStatus === SportsMarketSelectionStatus.TRADING;
  const isUnPriced = marketSelectionStatus === SportsMarketSelectionStatus.UNPRICED;
  const isSuspended = marketSelectionStatus === SportsMarketSelectionStatus.SUSPENDED;

  const isSelectionStatusCanBet = !isLost || !isWon;

  const isSportBetStatusCanBet = isLost || isVoid || isWon || isCanceled;

  const shouldShowReceipt =
    isWin ||
    isWon ||
    isLoss ||
    isLost ||
    isVoid ||
    isCashedOut ||
    isDeadHeat ||
    isPending ||
    isPartialPending ||
    isPartial ||
    isInBetPlacedView ||
    isPushed ||
    isInBetInfoView;

  const isResulted =
    isWon ||
    isWin ||
    isLoss ||
    isLost ||
    isVoid ||
    (isCashedOut && !isPreMatch) ||
    isDeadHeat ||
    isPartial;

  const shouldHideStatusBadgeAndShowOdds =
    !isTrading && !isUnPriced && !isSuspended && !isPending && !isPartialPending;

  const shouldShowStats =
    isInBetConfirmedView ||
    isInBetPlacingView ||
    isInBetInfoView ||
    isInBetPlacedView ||
    isInBetPendingView ||
    isInBetResultView;

  const shouldShowEstimateAmount =
    !sportsBetStatus || isCashedOut || isPending || isInBetPlacedOrConfirmed || isPartialPending;

  const shouldHideDate =
    (isCanceled || isWon || isLoss || isLost || isVoid || isPushed || isDeadHeat || isCashedOut) &&
    !isPreMatch;

  const shouldShowCashOutSuspended = !isCashedOut && cashOutStatus === SportsMarketStatus.SUSPENDED;

  const shouldShowCashOutOpen = !isCashedOut && cashOutStatus === SportsMarketStatus.OPEN;

  const shouldShowCashedOut = isCashedOut;

  const shouldShowLiveEventInfo = isLive && !isInBetAddOrConfirmed;

  const shouldShowFooterViewAllBets = isInEmptyView || isInBetPendingView || isInBetResultView;
  const shouldShowFooterStakeAndPayout =
    !isInEmptyView && !isInBetPendingView && !isInBetResultView;

  return {
    isInBetPendingView,
    isInBetResultView,
    isInEmptyView,
    isInBetInfoView,
    isInBetPlacedView,
    isInBetConfirmedView,
    isInBetPlacedOrConfirmed,
    isInBetPlacedViewError,
    isInBetAddOrConfirmed,
    isInBetAddView,
    isInBetPlacingView,
    isPending,
    isWon,
    isLost,
    isWin,
    isLoss,
    isVoid,
    isPartialPending,
    isPartial,
    isDeadHeat,
    isCanceled,
    isCashedOut,
    isPushed,
    isResulted,
    isSelectionStatusCanBet,
    isSportBetStatusCanBet,
    shouldShowReceipt,
    shouldHideDate,
    shouldHideStatusBadgeAndShowOdds,
    shouldShowEstimateAmount,
    shouldShowStats,
    shouldDisplayMarketStatus:
      isInBetAddView || isInBetConfirmedView || (isInBetPlacedView && isBetPlacedError),
    shouldShowCashOutSuspended,
    shouldShowCashOutOpen,
    shouldShowCashedOut,
    shouldShowLiveEventInfo,
    shouldShowFooterViewAllBets,
    shouldShowFooterStakeAndPayout,
  };
}
