import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SportsMarketStatus } from 'generated/graphql';
import { i18n } from 'next-i18next';

extend(utc);

const t = (name: string) => i18n?.t?.(name) ?? name;

export const shouldDisableOutcome = (marketStatus: SportsMarketStatus) =>
  marketStatus === SportsMarketStatus.SUSPENDED || marketStatus === SportsMarketStatus.CLOSED;

export const getMarketStatusText = (marketStatus: SportsMarketStatus) => {
  return (
    {
      [SportsMarketStatus.OPEN]: '',
      [SportsMarketStatus.ROLLED_BACK]: '',
      [SportsMarketStatus.SUSPENDED]: t('txtSuspended'),
      [SportsMarketStatus.CLOSED]: t('txtClosed'),
      [SportsMarketStatus.RESULTED]: t('txtResulted'),
    }[marketStatus] || ''
  );
};

export const getSportsMarketStatus = ({
  isEventSuspended,
  isEventClosed,
  marketStatus,
  marketExpiryTime,
  marketInPlay,
}: {
  marketStatus: SportsMarketStatus;
  marketInPlay: boolean;
  marketExpiryTime?: string;
  isEventSuspended?: boolean;
  isEventClosed?: boolean;
}) => {
  const isExpired =
    marketStatus === SportsMarketStatus.OPEN &&
    !marketInPlay &&
    marketExpiryTime &&
    dayjs().isAfter(dayjs.utc(marketExpiryTime));

  const marketStatusUpdate = isExpired || isEventClosed ? SportsMarketStatus.CLOSED : marketStatus;

  const shouldDisableAllSelections =
    isEventClosed ||
    shouldDisableOutcome(marketStatusUpdate) ||
    (isEventSuspended && String(marketStatusUpdate) === SportsMarketStatus.SUSPENDED);

  const marketStatusText = getMarketStatusText(marketStatusUpdate);

  return {
    marketStatus: marketStatusUpdate,
    marketStatusText,
    shouldDisableAllSelections,
  };
};
