import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { ImageLoaderProps } from 'next/image';
import isString from '../../utils/isString';
type WrappedImageProps = Omit<NextImageProps, 'unoptimized'>;
const EXTERNAL_URL_REGEX = /^http(s)?:/;

// Whitelist external assets; eg Contentful images
const OPTIMIZED_EXTERNAL_URLS: string[] = [String(process.env.NEXT_PUBLIC_CONTENTFUL_ASSET_URL)];
export const nextImageLoader = ({
  src,
  width
}: ImageLoaderProps) => {
  return `${src}&width=${width}`;
};
const WrappedImage = ({
  src,
  ...props
}: WrappedImageProps) => {
  if (!src || !isString(src)) {
    return null;
  }
  const unoptimized = !props.loader && !OPTIMIZED_EXTERNAL_URLS.some(url => src.startsWith(url)) && EXTERNAL_URL_REGEX.test(src);

  // Avoid all optimizations from external URLs; namely IMGIX.
  return <NextImage src={src} unoptimized={unoptimized} {...props} />;
};
export default WrappedImage;