import BigNumber from 'bignumber.js';
import { BlackjackActiveBetQuery, Currency } from 'generated/graphql';
import {
  Blackjack,
  BlackjackAction,
  HandOutcome,
  InsuranceStatus,
  PerfectPairType,
  TwentyOnePlusThreeType,
} from 'shufflecom-calculations/lib/games/blackjack';
import { CardDetail, CardGames, CardValue } from 'shufflecom-calculations/lib/games/cardGames';

export enum BlackjackState {
  STARTING = 'STARTING', // Player is placing bet
  BUYING_INSURANCE = 'BUYING_INSURANCE', // Player is buying insurance(only if dealer has upcard ace)
  PLAYING_MAIN_HAND = 'PLAYING_MAIN_HAND', // Player is playing main hand (will occur if game did not immediately due to blackjack)
  PLAYING_SPLIT_HAND = 'PLAYING_SPLIT_HAND', // Player is playing split hand
  GAME_OVER = 'GAME_OVER', // show cashout screen with payout
}

export enum BlackjackTab {
  STANDARD = 'STANDARD',
  SIDEBET = 'SIDEBET',
}

export enum BlackjackMainOrSplit {
  MAIN = 'MAIN',
  SPLIT = 'SPLIT',
}

export enum CardState {
  WIN = 'win',
  PUSH = 'push',
  LOSS = 'loss',
  PLAYING = 'playing', // playing current hand (only relevant for split where the user has 2 hands)
  NONE = 'none',
}

export const cardStateMapping: Partial<Record<HandOutcome, CardState>> = {
  [HandOutcome.WIN]: CardState.WIN,
  [HandOutcome.BLACKJACK]: CardState.WIN,
  [HandOutcome.PUSH]: CardState.PUSH,
  [HandOutcome.LOSS]: CardState.LOSS,
};

export interface GetStateReturn {
  state: BlackjackState;
  mainHand: CardDetail[];
  splitHand: CardDetail[];
  currency?: Currency;
  dealerHand: CardDetail[];
  currentlyMainOrSplit: BlackjackMainOrSplit;
  availableActions: BlackjackAction[];

  perfectPairStatus?: PerfectPairType;
  twentyOnePlusThreeStatus?: TwentyOnePlusThreeType;
  insuranceStatus?: InsuranceStatus;

  tab?: BlackjackTab;
  totalBetAmount: BigNumber;

  originalMainBetAmount: BigNumber;
  twentyOnePlusThreeAmount: BigNumber;
  perfectPairAmount: BigNumber;
  mainHandOutcome: HandOutcome;
  splitHandOutcome: HandOutcome;
  isGameActive: boolean;
}

const noActiveGameData: GetStateReturn = {
  dealerHand: [],
  mainHand: [],
  splitHand: [],
  currentlyMainOrSplit: BlackjackMainOrSplit.MAIN,
  availableActions: [],
  state: BlackjackState.STARTING,
  mainHandOutcome: HandOutcome.PENDING,
  splitHandOutcome: HandOutcome.PENDING,
  totalBetAmount: BigNumber(0),
  originalMainBetAmount: BigNumber(0),
  twentyOnePlusThreeAmount: BigNumber(0),
  perfectPairAmount: BigNumber(0),
  isGameActive: false,
};

export class BlackjackUtils {
  static DEFAULT_SIDEBET_LIMIT = 100; // USD
  static CARD_ENTER_DURATION = 300;
  static CARD_EXIT_DURATION = 200;

  static getState(data: BlackjackActiveBetQuery['blackjackActiveBet']): GetStateReturn {
    // return dealer cards, player main and sidebet, game result as calculated
    // game state like push, which hand etc
    const soa = data?.shuffleOriginalActions ?? [];
    const lastAction = soa[soa.length - 1]?.action?.blackjack;

    if (!soa || soa.length === 0 || !lastAction || !data) {
      return noActiveGameData;
    }

    const {
      dealerHand: dealerHandIndexes,
      insuranceStatus,
      mainPlayerActions,
      mainPlayerHand,
      splitPlayerActions,
      splitPlayerHand,
      perfectPairWin,
      twentyOnePlusThreeWin,
      perfectPairAmount,
      twentyOnePlusThreeAmount,
      mainHandOutcome,
      splitHandOutcome,
      originalMainBetAmount,
    } = lastAction;

    const mainHand = mainPlayerHand.map(card => CardGames.getCardDetails(card));
    const splitHand = splitPlayerHand.map(card => CardGames.getCardDetails(card));
    const dealerHand = dealerHandIndexes.map(card => CardGames.getCardDetails(card)); // when game is ongoing, there should only be 1 dealer card (upcard)

    const atMainHand = Blackjack.playingMainHand(
      mainPlayerHand,
      splitPlayerHand,
      mainPlayerActions,
      splitPlayerActions,
    );

    const hasSplitHand = splitPlayerHand.length > 0;
    const currentHand = atMainHand ? mainPlayerHand : splitPlayerHand;
    const availableActions =
      dealerHandIndexes.length === 1
        ? Blackjack.availableNextActions(
            currentHand,
            dealerHandIndexes as [number],
            insuranceStatus,
            atMainHand && !hasSplitHand,
          )
        : [];

    // Players are only considered to have won the sidebet if they have bet on that sidebet and won.
    const perfectPairStatus =
      BigNumber(perfectPairAmount).isGreaterThan(0) && perfectPairWin ? perfectPairWin : undefined;
    const twentyOnePlusThreeStatus =
      BigNumber(twentyOnePlusThreeAmount).isGreaterThan(0) && twentyOnePlusThreeWin
        ? twentyOnePlusThreeWin
        : undefined;

    const hasSidebetAmt =
      BigNumber(perfectPairAmount).isGreaterThan(0) ||
      BigNumber(twentyOnePlusThreeAmount).isGreaterThan(0);

    const returnData: GetStateReturn = {
      mainHand,
      splitHand,
      dealerHand,
      currentlyMainOrSplit: atMainHand ? BlackjackMainOrSplit.MAIN : BlackjackMainOrSplit.SPLIT,
      availableActions,
      perfectPairStatus,
      twentyOnePlusThreeStatus,
      tab: undefined,
      state: BlackjackState.STARTING,

      totalBetAmount: BigNumber(perfectPairAmount),
      originalMainBetAmount: BigNumber(originalMainBetAmount), // original bet amount for main hand (before double downs, splits and insurance)
      twentyOnePlusThreeAmount: BigNumber(twentyOnePlusThreeAmount),
      perfectPairAmount: BigNumber(perfectPairAmount),
      currency: data.currency,
      mainHandOutcome,
      splitHandOutcome,
      insuranceStatus,
      isGameActive: false,
    };

    // return data for each game state
    if (data?.completedAt) {
      returnData.state = BlackjackState.GAME_OVER;
      returnData.availableActions = [];
    } else if (insuranceStatus === InsuranceStatus.ELIGIBLE) {
      returnData.state = BlackjackState.BUYING_INSURANCE;
    } else if (atMainHand) {
      returnData.state = BlackjackState.PLAYING_MAIN_HAND;
    } else {
      returnData.state = BlackjackState.PLAYING_SPLIT_HAND;
    }

    if (BlackjackUtils.isGameActive(returnData.state)) {
      returnData.tab = hasSidebetAmt ? BlackjackTab.SIDEBET : BlackjackTab.STANDARD;
      returnData.isGameActive = true;
    }

    return returnData;
  }

  static isGameActive(state: BlackjackState) {
    return (
      state === BlackjackState.BUYING_INSURANCE ||
      state === BlackjackState.PLAYING_MAIN_HAND ||
      state === BlackjackState.PLAYING_SPLIT_HAND
    );
  }

  // TODO: Add tests for this
  static getHandValueText(cards: CardDetail[]) {
    const value = Blackjack.calcHandValue(
      cards.map(card => CardGames.getCardIndexFromDetails(card)),
    );

    // Assume that all aces are worth 1 point (this is to calculate hard hand value)
    const cardValueMap: Record<CardValue, number> = {
      '2': 2,
      '3': 3,
      '4': 4,
      '5': 5,
      '6': 6,
      '7': 7,
      '8': 8,
      '9': 9,
      '10': 10,
      A: 1,
      J: 10,
      Q: 10,
      K: 10,
    };

    const hardCardSum = cards.reduce((acc, cur) => acc + cardValueMap[cur.value], 0);
    if (hardCardSum === value) {
      return `${value}`;
    }
    return `${hardCardSum}/${value}`;
  }
}
