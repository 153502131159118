import { BEFORE_EVENT_LIVE_STATES } from 'utils/sportEventStatusCheck';
import colors from '../../../../assets/styles/colors';

import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice.type';

export const getColorFormattedStartTime = ({
  isCanceled,
  isVoid,
  isPushed,
  isDeadHeat,
  isCashedOut,
  isPreMatch,
  isLive,
  countdownStart,
  betSlipStatus,
  isInBetPendingView,
  isPartialPending,
  isPending,
  isInBetInfoView,
  eventStateBeforeLive,
}: {
  isCanceled: boolean;
  isVoid: boolean;
  isPushed: boolean;
  isDeadHeat: boolean;
  isCashedOut: boolean;
  isLive: boolean;
  isInBetPendingView: boolean;
  isPartialPending: boolean;
  isPending: boolean;
  isInBetInfoView: boolean;
  isPreMatch: boolean;
  countdownStart: boolean;
  betSlipStatus?: BetSlipStatus;
  eventStateBeforeLive?: BEFORE_EVENT_LIVE_STATES | null;
}) => {
  const isAboutToStart = eventStateBeforeLive === BEFORE_EVENT_LIVE_STATES.ABOUT_TO_START;
  const isDelayedStart = eventStateBeforeLive === BEFORE_EVENT_LIVE_STATES.DELAYED_START;

  if (isCanceled || isVoid || isPushed || isDeadHeat || (isCashedOut && !isPreMatch)) {
    return colors.gray500;
  }

  if (isLive || countdownStart || isAboutToStart) {
    return colors.success;
  }

  if (isDelayedStart) {
    return colors.warning;
  }

  if (
    betSlipStatus === BetSlipStatus.BET_PLACED ||
    isInBetPendingView ||
    ((isPartialPending || isPending) && isInBetInfoView) ||
    isPreMatch
  ) {
    return colors.white;
  }

  return colors.gray300;
};
