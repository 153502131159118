import dayjs from 'dayjs';

const TOLERANCE = 500 * 1000;

export function localVsServerTimeValidation() {
  let isValidClientDateTime = true;
  let serverClientTimeDiff = 0;

  const setServerDateTime = (dateTime: string | null) => {
    serverClientTimeDiff = dayjs().diff(dateTime);
    isValidClientDateTime = Math.abs(dayjs().diff(dateTime)) <= TOLERANCE;
  };

  return {
    getRealTime: () => {
      return dayjs().subtract(serverClientTimeDiff);
    },
    getServerClientTimeDiff: () => {
      return serverClientTimeDiff;
    },
    getIsValidClientDateTime: () => {
      return isValidClientDateTime;
    },
    setServerDateTime,
  };
}
