import { ReactNode } from 'react';
import Button from '../Buttons/Button';
import styles from './BottomBanner.module.scss';
export type Props = {
  icon: string;
  title: string;
  linkButton?: ReactNode;
  onClick: () => void;
  buttonTitle: string;
};
export function BottomBanner({
  icon,
  onClick,
  title,
  linkButton,
  buttonTitle
}: Props) {
  return <section className={styles.root}>
      <div className={styles.bannerBody}>
        <div className={styles.bannerImage}>{icon}</div>
        <p>{title}</p>
      </div>
      <div className={styles.buttonsGroup}>
        {linkButton}
        <Button onClick={onClick} value={buttonTitle} />
      </div>
    </section>;
}