import Link from 'next/link';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateMobileBetPosition } from '../../redux/slices/sportsBetSlice';
import { MobileNavItemContent } from './MobileNavItemContent';
import styles from './MobileNavItem.module.scss';
interface MobileMenuItemProps {
  name: string;
  icon?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  href?: string;
  onClick?: () => void;
  indicatorNumber?: number;
  isSportBetButton: boolean;
}
export const MobileNavItem = ({
  name,
  icon: Icon,
  active = false,
  disabled = false,
  href,
  onClick,
  indicatorNumber,
  isSportBetButton
}: MobileMenuItemProps) => {
  const navButtonRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSportBetButton && navButtonRef.current) {
      const rect = navButtonRef.current.getBoundingClientRect();
      dispatch(updateMobileBetPosition({
        width: rect.width,
        height: rect.height,
        top: rect.top,
        left: rect.left
      }));
    }
  }, [dispatch, isSportBetButton]);
  if (href) {
    return <Link href={href} legacyBehavior>
        <a onClick={onClick}>
          <MobileNavItemContent name={name} Icon={Icon} active={active} disabled={disabled} indicatorNumber={indicatorNumber} navButtonRef={navButtonRef} />
        </a>
      </Link>;
  }
  return <button className={styles.button} onClick={onClick}>
      <MobileNavItemContent name={name} Icon={Icon} active={active} disabled={disabled} indicatorNumber={indicatorNumber} navButtonRef={navButtonRef} />
    </button>;
};