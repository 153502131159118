import { Currency } from '../generated/graphql';

import { useConversion } from './useConversion';

export const useDisplayAmount = ({
  cryptoAmount,
  currency,
  invert,
  displayInFiat,
}: {
  cryptoAmount: string;
  currency: Currency;
  displayInFiat: boolean;
  invert?: boolean;
}) => {
  const { cryptoToFiat } = useConversion(currency);
  const asFiat = invert ? !displayInFiat : displayInFiat;

  if (asFiat) return cryptoToFiat(cryptoAmount);

  return cryptoAmount;
};
